import React from 'react';
import { Icon } from "@material-ui/core";

const FontIcon = (props) => {

    const ClassIcon = () => {        
        switch (props.icon.font) {
            case 'fa':               
                return (<Icon {...props} className={props.icon.type} />)                
            default:
                return (<Icon {...props}>{props.icon.type}</Icon>)
        }
    }

    return ( 
        <ClassIcon />
    );
}
 
export default FontIcon;