import React from 'react';
import DefaultLayout from '../../layout/default';
import OfferPageListing from './components/PageListing';

const Ofertas = () => {
    return (
        <DefaultLayout>
            <OfferPageListing />
        </DefaultLayout>
    );
}

export default Ofertas;