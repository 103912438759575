import React from 'react';
import { Title, Input, Button, Form, Error } from './style';
import { Checkbox, CircularProgress } from '@material-ui/core';
import NewSubdomainLayout from './NewSubdomainLayout';
import Register from '../../components/auth/Register';

import bgImg from '../../img/bg-new-subdomains.jpg';

const NewSubdomainPage = () => {

  const theme = {
    text1: 'Ganhe até',
    value: '1.000',
    text2: 'para gastar na Netshoes!',
    text: '#ffffff',
    backgroundColor: '#5c3d82',
    opacity: '0.9',
    highlightColor: '#FBB331',
    buttonTextColor: '#fff',
    bannerImage: bgImg,
  }
  
  // inputs
  const [ email, setEmail ] = React.useState('');
  const [ isChecked, setIsChecked ] = React.useState(false);

  // helpers
  const [ loading, setLoading ] = React.useState(false);
  const [ error, setError ] = React.useState('');
  const [ success, setSuccess ] = React.useState(false);

  function validateEmail(email) {
    let emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.([a-z]+))?$/i;
    if(emailRegex.test(email)) {
      setError('');
      return true;
    } else {
      setError('Por favor, insira um e-mail válido.');
      return false;
    }
  }

  function handleChange({target}) {
    setEmail(target.value);
    if (error) validateEmail(email);
  }

  function handleSubmit(e) {
    e.preventDefault();
    
    if(validateEmail(email)) {
      setLoading(true);
      
      // lógica para salvar e-mail no banco aqui
      // ...
      // após salvar com sucesso:

      setLoading(false);
      setSuccess(true);
    } 

  }

  return (
    <NewSubdomainLayout theme={theme}>
      {!success ? (
        <>
          <Title size="3" highlightColor={theme.highlightColor}>
            {`${theme.text1} `}
            <span>
              {`R$ ${theme.value}`}
            </span>
            {` ${theme.text2}`}
          </Title>

          <h3>Insira seu e-mail para concorrer:</h3>
          <Form>
            <Input 
              name="email"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={handleChange}
              borderColor={theme.highlightColor}
            />
            <Button 
              onClick={handleSubmit} 
              disabled={loading || !isChecked} 
              bgColor={theme.highlightColor}
              textColor={theme.buttonTextColor}  
            >
              {!loading ? (
                'Avançar'
              ):(
                <CircularProgress color={theme.buttonTextColor}  style={{width: '1rem', height: '1rem'}}/>
              )}
            </Button>
          </Form>
          {error.length > 0 && <Error color={theme.highlightColor}>{error}</Error>}
          <div className="checkboxWrapper">
            <Checkbox 
              inputProps={{ 'aria-label': 'Aceito receber notícias e novos produtos BigTester por e-mail.' }}
              size="small"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              style={{
                color: theme.highlightColor,
              }} 
            />
            <p>Aceito receber notícias e novos produtos BigTester por e-mail.</p>
          </div>
        </>
      ) : (
        <>
          <Title size="1.5">
            Finalize o cadastro no Big Tester para garantir sua participação:
          </Title>
          <div style={{marginLeft: '-2rem'}}>
            <Register emailDefault={email}/>
          </div>
        </>
      )}
    </NewSubdomainLayout>
  )
}

export default NewSubdomainPage
