import React from "react";
import { Grid, Typography } from "@material-ui/core";

import WinnerPaper from "../../../components/home/winners/Paper";
import DefaultBtns from "../../../components/common/DefaultBtns";
import translate from "../../../locales/translate";
import styled from "styled-components";
import ApiService from "../../../helpers/ApiService";

const smileColors = ["", "blue", "pink", "green"];

const GridContainer = styled(Grid)`
  padding: 24px;
  background-color: white;
`;

const GridImage = styled(Grid)`
background-image: url("/img/bg_esq.jpg");
padding-bottom: 18px;
`;

const Winners = ({ extended }) => {
  const [winners, setWinners] = React.useState([]);

  const getWinners = () => {
      ApiService.get('/winners', {
          limit: extended ? 4 : 3,
      }).then(({ data }) => {
          setWinners(data.winners.map(win => ({
              ...win,
              location: `${win.user.address.city} (${win.user.address.state})`
          })))
      }).catch( _ => {})
  }

  React.useEffect(() => {
    getWinners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridContainer
      spacing={2}
      container
      id="lastwinners"
      justify="center"
      style={{ maxWidth: "100%" }}
    >
      <Grid item xs={12} style={{ marginBottom: 18 }}>
        <Typography variant="body2" className="light--text">
          bigtester
        </Typography>
        <Typography variant="h5" className="dark--text text-bold">
          {translate("ps.winnersTitle")}
        </Typography>
      </Grid>
      <GridImage item xs={12}>
        <Grid container justify="center">
          {[...Array(extended ? 4 : 3)].map((a, index) => {
            return winners[index] ? (
              <Grid
                item
                xs={12}
                md={extended ? 3 : 4}
                key={index}
                style={{ padding: "21px 12px" }}
              >
                <WinnerPaper
                  fullWidth
                  winner={winners[index]}
                  visible
                  smileColor={smileColors[index]}
                />
              </Grid>
            ) : (
              ""
            );
          })}
        </Grid>
      </GridImage>
      <Grid item xs={12} style={{ textAlign: "center", margin: 18 }}>
        <DefaultBtns />
      </Grid>
    </GridContainer>
  );
};

export default Winners;
