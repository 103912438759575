import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import React from 'react';

const CardReseller = ({data}) => {
  return (
    <a href={data.url} target="_blank" rel="noopener noreferrer">
      <Card>
        <CardMedia
          image={data.image}
          style={{ height: 140 }}
          title='Reseller'
        />
        <CardContent>
          <Typography variant="body1" className="text-bold dark--text">
            {data.description}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
}

export default CardReseller;