import React from 'react';
import { useHistory } from "react-router-dom";
import { Card, Grid, Box, Typography } from '@material-ui/core';
import styled from 'styled-components'
import FontIcon from '../common/FontIcon';

const StyledCard = styled(Card)`
        background-color: ${props => props.color || 'var(--primary)'} !important;
        height: 175px;
        &:hover {
            box-shadow: 0px 0px 10px grey;
            backdrop-filter: grayscale(100%);
        }
`
const IconGrid = styled(Grid)`
    display: flex;
    align-items: center;
    flex: 1 !important;
    position: relative;
`
const CardTitle = styled(Grid)`
    background-color: rgba(0,0,0, 0.2);
    padding-left: 16px;
    display: flex;
    align-items: center;
    flex: initial !important;
    min-height: 40px;
    text-transform: lowercase;
`

const ExtendedDescription = styled(Box)`
    position: absolute;
    text-align: center;
    color: white;
    margin: 20px;
    padding: 20px;    
    font-weight: 700;
    text-shadow: 0px 1px 4px rgba(0,0,0,0.5);
    text-transform: lowercase;
`

const ActionCard = ({ action, extended }) => {
    let history = useHistory();
    
    const handleCardClick = () => {
        history.push(action.page)
    }

    return (
        <StyledCard color={action.color} elevation={0} className="cursor" onClick={handleCardClick}>
            <Grid container direction="column" style={{ height: '100%' }}>
                <IconGrid item xs={12}>
                    <FontIcon
                        icon={action.icon}
                        style={{
                            fontSize: 100,
                            opacity: 0.1,
                            margin: 'auto',
                        }}
                    />
                    {extended && <ExtendedDescription>
                        <center>{action.description}</center>
                    </ExtendedDescription>}
                </IconGrid>
                <CardTitle item xs={12}>
                    <Typography variant="body2" className="white--text text-bold">
                        {action.title}
                    </Typography>
                </CardTitle>
            </Grid>
        </StyledCard>
    );
}

export default ActionCard;