import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core'

import styled from 'styled-components'


const MiniCard = styled(Card)`
    width: 110px;
    height: 110px;
    box-shadow: unset !important;
    border-radius: 30px !important;
    display: inline-block;
    &.blue {
        border-bottom: 10px solid rgba(213,237,248, 1);
        border-left: 5px solid rgba(213,237,248, 1);
        border-right: 5px solid rgba(213,237,248, 1);
        border-top: 2px solid rgba(213,237,248, 1);
    }
    &.green {
        border-bottom: 10px solid rgba(220,239,238, 1);
        border-left: 5px solid rgba(220,239,238, 1);
        border-right: 5px solid rgba(220,239,238, 1);
        border-top: 2px solid rgba(220,239,238, 1);
    }
    &.yellow {
        border-bottom: 10px solid rgb(245,237,212);
        border-left: 5px solid rgb(245,237,212);
        border-right: 5px solid rgb(245,237,212);
        border-top: 2px solid rgb(245,237,212);
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        & img {
            width: 70%;
        }
    }

    @media screen and (min-width: 600px) and (max-width: 800px) {
        width: 60px;
        height: 60px;
        & img {
            height: 30px;
        }
    }

    @media screen and (min-width: 800px) and (max-width: 1000px) {
        width: 70px;
        height: 70px;
        & img {
            height: 40px;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1200px) {
        width: 80px;
        height: 80px;
        & img {
            height: 50px;
        }
    }
`

const Title = styled(Typography)`
    color: var(--darktext);
    font-weight: 700;
    @media screen and (max-width: 1000px) {
        font-size: 16px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1200px) {
        font-size: 20px;
    }
`

const SubTitle = styled(Typography)`
    color: var(--darktext);
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1000px) and (max-width: 1200px) {
        font-size: 16px;
    }
`

const IconCard = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={4} md={4}>
                <MiniCard className={props.color}>
                    <Grid container alignItems="center" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '10%' }}>
                            <img src={'/img/icons/' + props.icon} alt="Icon" />
                        </Grid>
                    </Grid>
                </MiniCard>
            </Grid>
            <Grid item xs={7} style={{ alignSelf: 'center', marginLeft: ".5rem" }}>
                <Title variant="h6" style={{ display: 'inline-block' }} >
                    { props.content.title }
                </Title>
                <SubTitle style={{ display: 'block' }}>
                    { props.content.text }
                </SubTitle>
            </Grid>
        </Grid>
    );
}

export default IconCard;