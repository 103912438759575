/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import LoginLayout from "../../layout/login";
import {
  Container,
  Grid,
  CircularProgress,
  Fade,
  Typography,
} from "@material-ui/core";
import ConfirmEmailImage from "../../img/confirma_email.png";
import styled from "styled-components";
// import Router, { useRouter } from "next/router";
import { useHistory, useLocation } from "react-router-dom";
import ApiService from "../../helpers/ApiService";

const StyledProgress = styled(CircularProgress)`
  color: var(--secondary) !important;
`;

const ConfirmEmail = () => {
  const [confirmed, setConfirmed] = React.useState(false);
  const [confirmedError, setConfirmedError] = React.useState(false);
  let history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
console.log('params',useQuery());
  const confirmEmail = () => {
    ApiService.get(`confirmar-email?token=${query.get('token')}&email=${query.get('email')}`)
      .then(({ data }) => {
        if (data.success) {
          ApiService.updateToken(data.token);
          setConfirmed(true);
          setTimeout(() => {
            history.push("/missoes")
          }, 6000);
        } else {
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setConfirmedError(true);
        }, 6000);
      });
  };

  React.useEffect(() => {
    document.title = "Confirmar e-mail - Big Tester";
    // if (token) confirmEmail();
    confirmEmail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginLayout>
      <Grid container>
        <Container maxWidth="lg">
          <Grid
            container
            className="dark--text"
            style={{ textAlign: "center", margin: "32px 0" }}
          >
            <Grid item xs={12}>
              {confirmed ? (
                <div className="typewriter">
                  <h1 style={{ fontSize: 42 }}>
                    <b>E-mail confirmado com sucesso! </b>
                  </h1>
                </div>
              ) : confirmedError ? (
                <div className="typewriter">
                  <h1 style={{ fontSize: 42 }}>
                    <b>
                      Ops! Houve algum erro ou esse email já foi confirmado{" "}
                    </b>
                  </h1>
                </div>
              ) : (
                <div className="typewriter">
                  <h1 style={{ fontSize: 42 }}>
                    <b>Estamos confirmando seu e-mail . . . </b>
                  </h1>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <img
                src={ConfirmEmailImage}
                alt="Confirmando e-mail"
                style={{ margin: "32px 0" }}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "24px 0" }}>
              <Fade in={!confirmed && !confirmedError} timeout={1000}>
                <StyledProgress size={80} thickness={5} />
              </Fade>
              {confirmed && (
                <Typography variant="h4">
                  Aguarde que você sera redirecionado ou{" "}
                  <a
                    onClick={() => {
                      history.push("/inicio");
                    }}
                  >
                    Clique Aqui.
                  </a>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </LoginLayout>
  );
};

export default ConfirmEmail;
