import React from 'react';
import { Grid, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components'

const CloseBtn = styled(Button)`
    position: fixed !important;
    width: 36px !important;
    min-width: 36px !important;
    height: 36px !important;
    top: 8px !important;
    right: 8px !important;
    color: white !important;
    border-radius: 50% !important;
`

const Video = (props) => {
    const closeVideo = () => {
        props.close(false)
    }

    return (
        <Grid container>
            <CloseBtn size="small" onClick={closeVideo}>
                <CloseIcon />
            </CloseBtn>
            <iframe width="100%" height="500"
                src="https://www.youtube.com/embed/4ChMDRjJ9q8"
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
            />
        </Grid>
    );
}

export default Video;