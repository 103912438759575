import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core'
import DefaultBtns from '../../../../components/common/DefaultBtns'
import translate from '../../../../locales/translate';
import styled from 'styled-components'
import Iphone from "../../../../img/iphonex.png";

const GridContainer = styled(Grid)`
    height: auto;
    max-height:300vh;
    margin: unset;
    background-color: var(--secondary);
    /* margin-top: 48px; */
    padding: 32px;
    background-image: url('/src/img/smiley_bg.jpeg');
    background-repeat: no-repeat;
    background-position: bottom right -150px;
    position: relative;
    z-index: 2;
    @media screen and (min-width: 960px) {
        max-height: 180vh;
        margin-bottom: 48px;
    }
    @media screen and (max-width: 960px) {
        background-position: bottom right 20%;
        height: auto !important;
    }

    @media (min-width: 1024px) and (max-device-width: 1024px){
        max-height: 600px;
        height: 200vh;
    }
`

const StyledImage = styled.img`
    height: 80vh;
    max-height: 620px;
    margin-top: -10vh;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 4;
`

const Title = styled(Typography)`
    font-weight: 700;
    @media screen and (max-width: 400px) {
        font-size: 32px !important;
    }
`

const Content = styled(Typography)`
    @media screen and (max-width: 400px) {
        font-size: 14px !important;
    }
`

const Awards = () => {
    return (
        <GridContainer container id="awards">
            <Grid item xs={12} md={6}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: 'unset', paddingBottom: 'unset' }}>
                        <Typography variant="caption" className="dark--text">
                            Bigtester
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Title variant="h3" className="dark--text" style={{ fontWeight: 700 }}>
                            {translate("lp.iphone.title")}
                        </Title>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 16 }}>
                        <Content variant="body1" className="dark--text">
                            {translate("lp.iphone.text")}
                        </Content>
                    </Grid>
                    <DefaultBtns />
                </Grid>
            </Grid>
            <Hidden smDown>
                <Grid item xs={6} style={{ textAlign: 'center', position: 'relative' }}>
                    <StyledImage src={Iphone} alt="iPhone" />
                </Grid>
            </Hidden>
        </GridContainer>
    );
}

export default Awards;