import React from 'react';
import { Redirect } from 'react-router';
import { ReactComponent as SuccessSvg } from '../../img/bt_success-v2.svg'
import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';

const Section = styled.section`
  width: 90vw;
  height: 70vh;
  padding: 15vh 5vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: scale(0.5);
  animation: fadeIn 500ms forwards;

  @keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
`;

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '2rem',
    textAlign: 'center'
  }
}));

const SuccessPage = () => {

  const classes = useStyles();

  const [ redirect, setRedirect ] = React.useState(false)

  React.useEffect( () => {
    setTimeout( ()=> {
      setRedirect(true)
    }, 6 * 1000)
  }, [])

  return (
    <Section>

      <SuccessSvg />

      <Typography variant="body1" className={`dark--text ${classes.root}`}>
        Obrigado pelo seu cadastro. <br/>Você será redirecionado em alguns segundos.
      </Typography>

      { redirect && <Redirect to="/inicio" /> }

    </Section>
  )
}

export default SuccessPage;