import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core'

import translate from '../../../../locales/translate';
import './smileAnimation.css'
import { Smile } from '../../../../img/icons'


import styled from 'styled-components'

const TitleShadow = styled(Typography)`
    position: absolute;
    align-self: flex-start;
    color: #CFD8DC;
    font-weight: 700;
    opacity: 0.4;
    top: 50px;
    left: 25px;
    font-size: 50px;
    @media screen and (min-width: 960px) {
        left: 100px;
        margin-left: 18px;
    }
`

const Background = ({ showSmiles }) => {
    return (
        <Grid container alignItems="flex-end" style={{ height: '100%' }}>
            <TitleShadow variant="h1">
                {translate('lp.how.title') }
            </TitleShadow>
            <Hidden smDown>
                {showSmiles && <div className="hiw-bg-smile-anim normal">
                    <Smile />
                </div>}
                {showSmiles && <div className="hiw-bg-smile-anim pink">
                    <Smile />
                </div>}
                {showSmiles && <div className="hiw-bg-smile-anim green">
                    <Smile />
                </div>}
                {showSmiles && <div className="hiw-bg-smile-anim blue">
                    <Smile />
                </div>}
            </Hidden>
        </Grid>
    );
}

export default Background;