import React from "react";
import { withRedux } from "../../helpers/redux";
import Helmet from "react-helmet";
import { useSelector, shallowEqual } from "react-redux";

const useStore = () => {
  return useSelector(
    (state) => ({
      helmetContent: state.helmetContent,
    }),
    shallowEqual
  );
};

const HelmetComponent = () => {
  const [metaData, setMetaData] = React.useState([]);
  const { helmetContent } = useStore();

  React.useEffect(() => {
    setMetaData(helmetContent);
  }, [helmetContent]);

  return (
    <>
      <Helmet>
        <meta name="teste" content="teste" />
        {metaData.map((item, index) => {
          return <meta name={item.name} content={item.content} key={index} />;
        })}
      </Helmet>
    </>
  );
};

export default withRedux(HelmetComponent);
