import { LOCALES } from "../locales";

export default {
  [LOCALES.PORTUGUES]: {
    //FirstScreen LandingPage
    // Navbar
    "lp.navbar.winners": "testadores",
    "lp.navbar.who": "quem somos",
    "lp.navbar.how": "como funciona",
    "lp.navbar.btn": "entrar",

    // Login
    "lp.login.cardTitle1": "Já é cadastrado? ",
    "lp.login.cardTitle2": " Acesse e continue ganhando pontos!",
    "lp.login.cellphone": "Celular",
    "lp.login.wrong": "E-mail ou celular inválidos",
    "lp.login.enterBtn": "Entrar",
    "lp.login.noRegisterQuestion":
      "Não tem cadastro? Clique aqui e faça agora mesmo!",
    "lp.login.havelogin":"Já possui cadastro?",
    "lp.login.enterBtn.alternative": "Enviar",
    "lp.login.forgotPhone.link":"esqueci meu número",
    "lp.login.forgotPhone.link.alternative":"Voltar",
    "lp.login.forgotPhone": "Esqueceu seu número de telefone?",
    "lp.login.forgotPhone.message": "Digite seu email e enviaremos uma mensagem pra você!",
    "lp.login.forgotPhone.success": "email enviado com sucesso, verifique sua caixa de entrada!",
    "lp.login.forgotPhone.warning": "o email informado é inválido!",
    "lp.login.forgotPhone.error": "sinto muito, mas não foi possível enviar o e-mail neste momento, por favor tente novamente mais tarde!",

    // Buttons
    "lp.btn.howWork": "como funciona",
    "lp.btn.register": "cadastre-se",
    "lp.btn.want": "eu quero",
    "lp.btn.viewMore": "ver mais produtos",

    // Highlight
    "lp.text": "O maior site de teste de produtos da América Latina",

    // RegisterForm
    "lp.form.title": "Receba produtos grátis para testar na sua casa!",
    "lp.form.name": "Nome Completo",
    "lp.form.lastname": "Sobrenome",
    "lp.form.phone": "Celular",
    "lp.form.birthday": "Nascimento",
    "lp.form.masc": "Masculino",
    "lp.form.fem": "Feminino",
    "lp.form.btn": "cadastrar-se",

    "lp.form.invalidFullName": "Por favor, insira o nome completo",
    "lp.form.errBithday": "Idade inválida a idade mínima deve ser: ",
    "lp.form.invalidBirthday": "Data de nascimento inválida",
    "lp.form.invalidCellphone": "Telefone inválido",
    "lp.form.errCellphone": "Número de telefone inválido",
    "lp.form.notPossible":
      "Não foi possível criar sua conta. Confira os dados e tente novamente.",
    "lp.form.invalidEmail": "E-mail inválido",
    "lp.form.invalidCamp": "Campo inválido",
    "lp.form.obrCamp": "Campo obrigatório",
    "lp.form.successfullyRegisterMessageTitle": "Sucesso",
    "lp.form.successfullyRegisterMessage":
      "Obrigado por se registrar, você receberá um e-mail de confirmação em sua caixa de entrada, por favor verifique-o. Importante, ao primeiro login de cada dia você receberá 20 pontos para usar no sistema, portanto não perca esta oportunidade e boa sorte!",
    "lp.form.dateParseErrorMessage":
      "Erro de data, por favor verifique se a data informada no formulário está correta!",
    "lp.form.terms1": "Aceito os ",
    "lp.form.terms2": "Termos de compromisso ",
    "lp.form.terms3": "e a ",
    "lp.form.terms4": "Política de Privacidade",
    "lp.form.terms5": "Aceito receber notícias e novos produtos BigTester em meu email",
    "lp.form.emailAlredyInUse": "O email informado já está em uso, tente login!",
    "lp.form.emailError": "O email informado já está em uso, não é possível prosseguir!",
    "lp.form.notice.terms": "Antes de prosseguir é necessário aceitar os termos compromisso e a política de de privacidade.",
    "lp.form.greetings": "É muito bom ter você com a gente novamente. Encontramos seu cadastro em nossa base e já fizemos os creditos de seus pontos! Importante, ao primeiro login de cada dia você receberá 20 pontos para usar no sistema, portanto não perca esta oportunidade e boa sorte!",
    "lp.form.register.error.409": "E-mail já cadastrado na versão anterior do BigTester. Experimente fazer login.",

    // HowWorks
    "lp.how.title": "Como funciona?",
    "lp.how.subtitle1": "1. Cadastre-se",
    "lp.how.text1":
      "Fazendo o seu cadastro totalmente grátis, o Big Tester te oferece produtos e ofertas incríveis!",
    "lp.how.subtitle2": "2. Crie seu perfil",
    "lp.how.text2":
      "Fique bem na foto! Personalize seu perfil e garanta que os produtos grátis cheguem até a sua casa!",
    "lp.how.subtitle3": "3. Troque seus pontos",
    "lp.how.text3":
      "Seja ativo no Big Tester. Quanto mais pontos ganhar, mais produtos você pode trocar! São centenas de produtos e ainda tem o sorteio de prêmios! Não perca!",

    // Who We Are
    "lp.whoWeAre.title": "Quem somos",
    "lp.whoWeAre.text": `O Big tester é uma plataforma digital totalmente interativa, onde possibilita ao usuário ter uma experiência única de testar produtos ou serviços de forma gratuita ou participar de seleções e de sorteios de forma segura! Além disso, é uma vitrine que possibilita a intermediação entre marca/produto ao consumidor final, proporcionamento sempre benefícios ao mesmo, como campanha promocionais, produtos gratuitos, e serviços para testes ou descontos exclusivos.`,

    // iPhone
    "lp.iphone.title":
      "Além de testar produtos em casa, concorra a três iPhones por ano!",
    "lp.iphone.text": `Todos os anos, o Big Tester sorteia 3 iPhones! Se você faz seu cadastro no Big Tester,
                            você tem acesso a cupons para o sorteio de cada iPhone. Quanto mais você interage com o Big Tester,
                            aproveitando as campanhas, missões, respondendo sobre seu perfil de consumo, convidando seus amigos e participando
                            dos rankings, mais pontos você acumula e, desta forma, pode trocar por mais cupons. Quanto mais cupons você tiver,
                            mais chances tem de ganhar o iPhone! Vai perder essa oportunidade?`,

    // lastWinners
    "lp.lastW.title": "Últimos testadores",
    "lp.lastW.subTitle.1": "Já somos",
    "lp.lastW.subTitle.2": "7.7 milhões",
    "lp.lastW.subTitle.3": " de testadores",
    "lp.lastW.text": `Milhares de testadores se cadastram todos os meses! São milhões de pontos distribuídos, milhares de cupons gerados para os sorteios e centenas de produtos sendo enviados para a casa das pessoas. Ta esperando o que pra se tornar um testador?`,
    "lp.lastW.subnum.1": "Mais de 1 bilhão",
    "lp.lastW.subtext.1": "de pontos distribuídos",
    "lp.lastW.subnum.2": "1 Bi",
    "lp.lastW.subtext.2": "de pontos conquistados",
    "lp.lasW.drawNotice":"aguardando sorteio",

    //winnersCustom
    "lp.lastCustom.title": "Junte-se aos vencedores",
    "lp.lastCustom.subTitle.1": "Faça como eles",
    "lp.lastCustom.subTitle.2": "Junte-se ao time dos campeões",

    // Products
    "lp.product.title": "Produtos que você pode testar",
    "lp.product.point": "pontos",
    "lp.product.btn": "ver mais produtos",
    "lp.product.exchange.histBtn": "consulte seu histórico de trocas",
    "lp.product.exchanged.title": "{uname}, este é seu histórico de produtos",
    "lp.product.exchaged.btBack": "voltar à tela de produtos",

    //Contato
    "lp.contact.title": "Fale com a gente!",
    "lp.contact.socialmedia": "Ou vem pelas redes sociais",
    "lp.contact.errors.name": "Nome inválido",
    "lp.contact.errors.email": "Insira um e-mail válido",
    "lp.contact.errors.phone": "Insira um telefone válido",
    "lp.contact.errors.message": "Sua mensagem deve conter de 5 a 500 caracteres",
    "lp.contact.success.title": "Sucesso!",
    "lp.contact.success.text": "Suas informações de contato foram enviadas com sucesso, aguarde nosso contato!",
    "lp.contact.message": "Entraremos em contato em breve",

    // Posts
    "lp.posts.title": "últimos posts",

    // Cookies
    "lp.cookies":
      "Nós usamos cookies para fornecer serviços e recursos oferecidos em nosso site, e melhorar a experiência do usuário.",
    "lp.cookies.link": "Saiba mais",
    "lp.cookies.button": "Aceitar!",
    

    // Contact
    "contact.title": "Contato",
    "contact.invalidCamp": "Campo Inválido",
    "contact.googleMaps":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.5731282722813!2d-46.66061278448496!3d-23.547850966979794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce577f1cc235b5%3A0x46efa75fcd46396b!2sRua%20Sergipe%2C%20475%20-%20Consola%C3%A7%C3%A3o%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001243-001!5e0!3m2!1sen!2sbr!4v1577735236092!5m2!1sen!2sbr",
    "contact.street":
      "Rua Sergipe, 475, 5˚ andar, Higienópolis - São Paulo, SP - Brasil",
    "contact.cep": "CEP: 01.243-001",
    "contact.number": "+55 (11) 4371-5300",
    "contact.leaveMessage": "Deixe Sua Mensagem",
    "contact.namePlaceholder": "Nome",
    "contact.phonePlaceholder": "Telefone",
    "contact.messagePlaceholder": "Mensagem",
    "contact.button": "Enviar",

    // ForAllUsed
    "perPage": " por página",
    "points": "pontos",

    // Buttons
    "btn.iWant": "eu quero",
    "btn.howWorks": "como funciona",
    "btn.register": "cadastre-se",
    "btn.knowMore": "saiba mais",
    "btn.knowMore.disabled": "encerrada",
    "btn.sendOffert": "enviar oferta",
    "btn.tradePoints": "trocar pontos",
    "btn.inviteFriends": "convidar amigos",
    "btn.seeRanking": "ver ranking",

    // GainMorePoints | Cards near Footer
    "cards.majorTitle": "Ganhe mais pontos",
    "card1.title": "Patrocinadas",
    "card1.text": "Participe de campanhas de teste de produtos",
    "card2.title": "Convidar amigos",
    "card2.text": "Convide amigos e ganhe pontos",
    "card3.title": "Ofertas",
    "card3.text": "Aprovete essas ofertas exclusivas",
    "card4.title": "Perfil de consumo",
    "card4.text": "Responda sobre seu perfil de consumo",
    "card.recommended": "Recomendado",
    "card.participating": "Participando",

    // Footer
    "footer.language": "Idiomas",
    "footer.iconsText": "Curta nossas redes sociais",
    "footer.privacyTitle": "Política de privacidade",
    "footer.termsTitle": "Termos de Compromisso",
    "footer.privacyText":
      "©Adclick Ltda - Todos os direitos reservados. Este site é de propriedade da Adclick Ltda, única responsável por seu conteúdo, e destina-se a residentes do Brasil.",
    "footer.warning":
      "Imagens meramente ilustrativas. O BigTester é responsável pela distribuição de 100% dos produtos ofertados no site. A compra e distribuição dos prêmios são de única e exclusiva responsabilidade do Big Tester. Os fabricantes e/ou distribuidores dos produtos ofertados como prêmios não organizam e não participam da política comercial do site. Participe gratuitamente, acumule pontos e troque seus pontos pelos produtos ofertados.",
    "footer.contact.title": "Entre em Contato",
    "footer.contact.text.01": "Sou uma empresa",
    "footer.contact.text.02": "Sou um tester",

    // Navbar
    "nav.mission": "Missões",
    "nav.campaign": "Campanhas",
    "nav.cons": "Consumo",
    "nav.aval": "Avalie Produtos",
    "nav.product": "Produtos",
    "nav.free": "Grátis",
    "nav.follow": "siga e ganhe + pontos",
    "nav.draw": "sorteio",
    "nav.invite": "convide amigos",
    "nav.click.myaccount": "minha conta",
    "nav.click.mypoints": "meus pontos",
    "nav.points": "pontos",
    "nav.quit": "sair",

    "nav.avatar.menu.account": "Minha conta",
    "nav.avatar.menu.mypoints": "Meus Pontos",
    "nav.avatar.menu.mycupons": "Meus Cupons",
    "nav.avatar.menu.exit": "Sair",

    // Navbar bottom Mobile
    "nav.mobile.start": "início",
    "nav.mobile.points": "missões",
    "nav.mobile.products": "produtos",
    "nav.mobile.draw": "sorteio",
    "nav.mobile.campaigns": "campanhas",
    "nav.mobile.follow": "influencers",

    //
    // StartScreen | Beginning
    "ss.title": "Olá, ",
    "ss.btn.pt1": "ganhar ",
    "ss.btn.pt2": " pontos",
    "ss.btn.pt2.alt": " pts.",
    "ss.subtitle.missions": "Novas missões",
    "ss.subtitle.dashboard": "Dashboard",
    "ss.dashboard.card1": "Missões",
    "ss.dashboard.card2": "Campanhas",
    "ss.dashboard.card3": "Convidar amigos",
    "ss.dashboard.card4": "Ofertas",
    "ss.subtitle.fixedmissions": "Ganhe mais pontos",
    "ss.name.fixedmission1": "Responda algumas perguntas e ganhe pontos",
    "ss.name.fixedmission2": "Siga nas redes sociais e pontue",
    "ss.name.fixedmission3": "Clique aqui e ganhe produtos grátis",
    "ss.new.mission.button.text.gain.points": "ganhar {points} pontos",
    "ss.new.mission.button.text.registered.points": "{points} registrados",
    "ss.earn.more.points.button.text": "muitos",
    //

    //
    // MissionsScreen
    "ms.title": "Missões",
    "ms.titleText": "Complete as missões e acumule muitos pontos!",
    "ms.text.01": "Atenção:",
    "ms.text.02":
      "Os pontos serão computados após a adesão da oferta contida na missão.",
    "ms.text.03":
      "Caso haja problemas em computar seus pontos, entre em contato via e-mail ou WhatsApp enviando um print comprovando a adesão à missão.",
    "ms.text.04":
      "Aqui você pode realizar missões e ganhar pontos e depois trocá-los por produtos.",
    "ms.text.05":
      "Toda semana são novas missões com ofertas exclusivas para testadores e você nunca para de acumular pontos!",
    "ms.detailTitle.pt1": "Você possuí ",
    "ms.detailTitle.pt2": " missões disponíveis",
    "ms.otherMissions": "Outras missões",
    "ms.btn.pt1": "ganhar ",
    "ms.btn.pt2": " pontos",
    "ms.subTitle1": "Books",
    "ms.subTitle2": "Movies",
    "ms.subTitle3": "Baby",
    // "ms.subTitle4":"Industrial",
    "ms.subTitle4": "Electronics",

    "ms.card.button.text.gain": "ganhar {points} pontos",
    "ms.card.button.text.points": "{points} pontos registrados",
    //

    //
    // CampaignScreen
    "cs.title": "Campanhas patrocinadas",
    "cs.titleText":
      "Se candidatando às campanhas patrocinadas, você pode ser selecionado para receber produtos em sua casa e também ganhar muitos pontos!",
    "cs.campaignPerPage": "Campanhas por página:",
    "cs.subTitle": "Ganhe mais pontos",
    "cs.csOver": "A campanha está encerrada!",
    "cs.csOverWhen": "Campanha finalizada em:",
    "cs.otherCampaigns": "confira outras campanhas",
    "cs.winners": "Ganhadores",
    "cs.active": "Ativas",
    "cs.over": "Encerradas",
    //

    //
    // CampaignScreen - Detail
    "cs.detail.period": "Período da campanha",
    "cs.detail.start": "Início",
    "cs.details.end": "Final",
    "cs.detail.patro": "Essa campanha é patrocinada por",
    "cs.detail.patro.after": "Esta campanha foi patrocinada por",
    "cs.detail.social": "Curta nas redes sociais",
    "cs.detail.btn1": "já participando",
    "cs.detail.btn2": "candidatar-se",
    "cs.detail.btn3": "conheça os ganhadores",
    "cs.detail.btn4": "candidatos ainda não selecionados",
    "cs.detail.btn5": "Atualizar meus dados",
    "cs.detail.noaddress": "Para se candidatar às campanhas, é necessário que seu seus dados, tais como CPF e endereço, estejam cadastrados no Big Tester!",
    "cs.btn.continue": "continuar",
    "cs.continueText": "Ou pressione enter",
    "cs.inputText": "Sua resposta",
    "cs.watch": "Assista o vídeo para confirmar sua inscrição.",
    "cs.reseller.title": "Aproveite e aumente suas chances de ganhar cadastrando-se nestes parceiros!",
    "cs.reseller.skip": "Desejo perder esta oportunidade...",
    "cs.end.mission": "Quer ter mais chances de ser selecionado? Cumpra as missões e aumente suas chances",
    "cs.end.title": "Parabéns! Você se candidatou a campanha!",
    "cs.end.subTitle1":
      "Seus",
    "cs.end.subTitle2":
      "pontos serão creditados assim que a campanha disponibilizá-los.",
    "cs.end.text1":
      "Foi enviada a confirmação de sua candidatura em seu e-mail. Confira se nosso e-mail não caiu em sua Caixa de Spam ou Lixo Eletrônico. É muito importante que veja este e outros e-mails do BigTester para aproveitar toda a experiência das campanhas.",
    "cs.end.text2":
      "Caso sua candidatura seja selecionada, enviaremos uma mensagem neste mesmo e-mail. Então fique atento!",
    "cs.end.btn": "Ver mais campanhas",
    "cs.end.btn2": "troque seus pontos agora!",
    "cs.end.addressTitle": "Atenção, testador!",
    "cs.end.addressText": "Em seu cadastro, este endereço está correto?",
    "cs.end.addressTextNoAddress1": "Você não possui endereço cadastrado no Big Tester.",
    "cs.end.addressTextNoAddress2": "Para receber o produto que está se candidatando, é necessário preencher seu endereço completo na área Minha Conta ou clicando no botão abaixo.",
    "cs.end.addressButton": "Atualizar endereço",
    "cs.detail.titleshare": "Quando for selecionado, não esqueça de divulgar nas redes sociais",
    "cs.detail.descriptionshare1": "É ótimo receber presente e produtos em casa, não é mesmo? Portanto, quando chegar seus produtos, poste no Instagram uma foto com a hashtag #bigtester em Modo Público e tagando o @big_tester, o que acha? Nesta missão esperamos que faça fotos incríveis com o seu produto! Pode ser dentro do carro, na faculdade, na sua casa, no trabalho, e onde mais você quiser! Não se esqueça de registrar em uma foto irada, com direito a muuuuitos clicks, hein?",
    "cs.detail.descriptionshare2": "Te convidamos a avaliar o produto da campanha e nos dar a sua opinião sobre o recebido! Grave stories no Instagram dando testemunho e contando o que achou da embalagem, do produto e da sua experiência em geral! Não esqueça de marcar nosso perfil do @big_tester, que poderemos repostar no nosso perfil! Seu feedback é extremamente importante!",
    "cs.detail.timer1": "Para confirmar sua candidatura, assista o vídeo pelos próximos",
    "cs.btn.confirm": "Confirmar candidatura",
    "cs.legacy.table.name": "Nome",
    "cs.legacy.table.email": "E-mail",
    //

    //
    // ConsumptionScreen / FlowScreen
    "cons.init.title": "Responda estas perguntas e ganhe muitos pontos!",
    "cons.end.title1": "Parabéns!",
    "cons.end.title2": "Você completou todas as perguntas disponíveis!",
    "cons.end.subTitle":
      "Em breve, traremos mais perguntas e pontos para você.",
    "cons.end.redirect": "Você está sendo redirecionado para as Missões dentro de alguns segundos...",
    //

    //
    // ProductScreen
    "ps.title": "Produtos para você avaliar",
    "ps.title2": "Confira a lista de produtos para trocar por pontos e avaliar. Caso tenha dúvidas, consulte o",
    "ps.regulation": "Regulamento",
    "ps.products": "produtos",
    "ps.classifications": "CATEGORIAS",
    "ps.winnersTitle": "últimos testadores",
    "ps.dispatchSuccess":
      "Seu Produto será enviado em breve, fique atento ao seu email.",
    "ps.dispatchFail":
      "Não foi possível realizar sua troca. Entre em contato com a gente!",
    "ps.new": "LANÇAMENTO",
    "ps.trade": "trocar agora",
    "ps.buy": "compre agora",
    "product.toast": "Produto Trocado com sucesso!",
    "ps.text.title": "Testador!",
    "ps.text.01": `Visando melhorar sua comodidade e contornar problemas constantes com entregas, logística e greve intermitente dos correios, informamos que grande parte dos produtos para troca são produtos virtuais e, por conta disto, suas imagens são meramente ilustrativas e são apenas sugestões de trocas`,
    "ps.text.02": `Sendo assim:`,
    "ps.text.02.01": `No ato da troca do produto virtual, nossos profissionais entrarão em contato em até 10 dias úteis através de e-mail e/ou WhatsApp para confirmação de seus dados.`,
    "ps.text.02.02": `Após o contato feito, o produto virtual será enviado em seu e-mail em até 20 dias úteis e não será enviado nenhum tipo de produto físico no caso de troca por produtos virtuais.`,
    "ps.text.02.03": `Você tem livre escolha de como utilizar os valores dos cartões virtuais trocados aqui.`,
    "ps.text.02.04": `Alguns dos produtos digitais são acumulativos, podendo ser utilizado mais de um voucher para comprar o seu produto no site do fornecedor.`,
    "ps.text.03.01": `Caso tenha dúvidas sobre alguma troca, entre em contato conosco pelo e-mail `,
    "ps.text.03.email": `instagram@bigtester.com.br`,
    "ps.text.03.02": `. Teremos o prazer em responder.`,
    "ps.search-field.placeholder": "Busca",
    //

    //ProductDetail
    "pd.exchangeSuccess": "produto trocado com sucesso",
    "pd.exchangeSuccessDetail":
      "Entraremos em contato em até 48 horas para confirmar!",
    "pd.exchangeError": "Algo deu errado!",
    "pd.Products": "Products",
    "pd.title.avaliate": "avaliações e comentários",
    "pd.btn.avaliate": "avaliar também",
    "pd.title.otherProducts": "outros produtos dessa categoria",
    "pd.title.lastProducts": "últimos produtos avaliados",
    "pd.swal.ok.title": "Produto trocado com sucesso!",
    "pd.swal.ok.text":
      "Em até 48 horas entraremos em contato para confirmação!",
    "pd.swal.notOk.title": "Houve um erro",
    "pd.swal.notOk.text": "Consulte a equipe BigTester",
    "pd.access": "Acessar",
    "pd.productOwned": "Produto digital adquirido",
    "pd.productVoucher.detail.message": "Ao trocar seus pontos por este produto você receberá um link disponibilizado por um de nossos parceiros onde poderá obter mais informações sobre como obter o produto físico",
    "pd.exchange.prompt.message": "Você está prestes a trocar {price} pontos pelo produto {product}. Quer mesmo fazer a troca?",
    "pd.exchange.prompt.btn-confirm": "Sim",
    "pd.exchange.prompt.btn-cancel": "Não",
    "pd.exchange.prompt.fail-message": "Não foi possível realizar a troca no momento!",
    "pd.exchange.quantity-error.prompt.message": "Sinto muito, mas o produto {product} não possui quantidade disponível para troca <span style='color:var(--instagram-hover);'>{quantity}</span> unidades. Por Favor tente outro produto!",
    "pd.exchange.insufficient-points.prompt.message": "Sinto muito, mas você não possui pontos suficientes para troca, <span style='color:var(--instagram-hover);'>{balance}</span> de <span style='color:var(--primary);'>{price}</span> pts!",
    "pd.evaluate.dialog.title": "O que achou do produto?",
    "pd.evaluate.dialog.stars.amount": "de 1 a 5",
    "pd.evaluate.dialog.textfield.placeholder": "Descreva um pouco do que achou desse produto...",
    "pd.evaluate.dialog.button.send": "enviar avaliação",
    "pd.evaluate.dialog.bottom-message.error": "Você deve especificar uma classificação, caso queira cancelar sua avaliação, clique no botão fechar (canto superior direito deste diálogo)",
    //

    //
    // FreeScreen
    "fs.title": "Grátis",
    "fs.titleText":
      "Adquira produtos totalmente grátis! Acumule pontos e troque por mais produtos, aqueles que estão disponíveis na seção de produtos do site, pois os desta seção são gratuitos!",
    "fs.titleText.02":
      "Todos os serviços e entregas disponíveis abaixo são de inteira responsabilidade do fabricante.",
    "fs.sendOffer": "enviar oferta",
    "fs.searchPlaceholder": "Procure aqui...",
    "fs.offertsPerPage": "Ofertas por página:",
    "fs.search-field.placeholder": "Procure aqui...",
    "fs.pagination.of": "de",
    //

    //
    // DrawingScreen
    "ds.title": "Sorteio",
    "ds.subTitle1":
      "O Big Tester sempre realiza sorteios para você! É muito simples participar!",
    "ds.subTitle2": "Você possui",
    "ds.subTitle3": "números da sorte",
    "ds.when.Text1": "O sorteio será realizado no dia",
    "ds.when.Text2": "Quanto mais cupons, mais chances de ganhar!",
    "ds.when.Text3": "Troque",
    "ds.when.Text4": "50 pontos",
    "ds.when.Text5": "por ",
    "ds.lucky": "1 número da sorte",
    "ds.btnTrade": "trocar 50 pontos por 1 cupom",
    "ds.btnGoToHome": "Voltar para Home",

    "ds.p1.title": "Como adquirir números da sorte?",
    "ds.p1.subTitle.pt1":
      "Número da Sorte é seu cupom de sorteio. Você ganha 1 cupom por se cadastrar no Big Tester. Atualmente você possui",
    "ds.p1.subTitle.pt2": "pontos",
    "ds.p1.subTitle.pt3":
      "no Big Tester. Você pode trocar seus pontos por Números da Sorte. Cada Número da Sorte equivale a 50 pontos. Ou seja, você trocar 50 pontos do Big Tester por um Número da Sorte. Quanto mais Números da Sorte tiver, mais chances tem de ganhar o sorteio! Boa sorte!",

    "ds.p2.title": "Como saberei se fui sorteado?",
    "ds.p2.subTitle":
      "O Big Tester entrará em contato contigo via telefone e/ou e-mail que constam em seu cadastro, avisando que você foi o grande sortudo! Portanto mantenha seus contatos sempre atualizados.",

    "ds.p3.title": "O sorteio é legalizado?",
    "ds.p3.subTitle":
      "Claro! Nosso sorteio é regulamentado pela Caixa Econômica Federal. Número de certificado: 04.004907/2019. Confira o Regulamento e Certificado dos sorteios.",

    "ds.rules": "Regulamento",
    "ds.certificated": "Certificado",

    "ds.follow.title": "Acompanhe seus números da sorte",
    "ds.follow.tableTitle.pt1": "Cupons para",
    "ds.follow.tableTitle.pt2": "Sorteio em",
    "ds.follow.tableDescription": "Descrição",
    "ds.follow.tableNumbers": "Nº do Cupom",
    "ds.follow.tableDate": "Data",
    "ds.follow.tableStatus": "Status",
    "ds.follow.nothing": "Você ainda não tem nenhum cupom desse sorteio.",

    "ds.info":
      "O sorteio é somente para usuários brasileiros maiores de 18 anos.",
    "ds.coupons.table.title": "Cupons para {prize} - Sorteio em {date}",
    "ds.coupons.table.chip.text": "{qty} cupons",

    "ds.swal.ok.text": "Pontos trocados por Cupom com sucesso!",
    "ds.swal.notOk.text": "Não foi possível trocar seus pontos.",
    "ds.swal.insuficientPoints.text": "Você não possui pontos o suficiente para realizar esta troca.",
    "ds.fakePrize.drawingDate": " aguarde a definição do próximo sorteio",
    "ds.fakePrize.name": " O próximo ganhador pode ser você! ",
    //

    //
    // BlogScreen
    "bs.classifications": "categorias",
    //

    //
    // RankingScreen
    "rs.title": "Ranking",
    "rs.titleText": "Lorem Ipsum",
    "rs.1Place": "1º Lugar",
    "rs.1Place.text": "Lorem Ipsum",
    "rs.2Place": "2º Lugar",
    "rs.2Place.text": "Lorem Ipsum",
    "rs.3Place": "3º Lugar",
    "rs.3Place.text": "Lorem Ipsum",

    "rs.section2.title": "Saia na frente agora mesmo!",
    "rs.section2.warningTitle": "Aviso",
    "rs.section2.warningText": "lorem Ipsum",
    "rs.section2.position": "Posição",
    "rs.section2.name": "Nome",
    "rs.section2.sended": "Convites enviados",

    "rs.subTitle1": "Últimos ganhadores",
    "rs.subTitle2": "Regras e regulamentos",
    "rs.card.text1": "Lorem Ipsum",
    "rs.card.text2": "Lorem Ipsum",
    "rs.card.text3": "Lorem Ipsum",
    "rs.card.text4": "Lorem Ipsum",
    //

    //
    // InviteFriendsScreen
    "ifs.title": "Convidar amigos",
    "ifs.subTitle1":
      "Compartilha com o máximo de amigos nas redes sociais e veja seus pontos chegando*!",
    "ifs.subTitle2.pt1": "São ",
    "ifs.subTitle2.pt2": "10 pontos",
    "ifs.subTitle2.pt3": "para cada amigo convidado*!",
    "ifs.footnotes":
      "*Os pontos só serão creditados em sua conta quando seu amigo se cadastrar através do link compartilhado e confirmar o e-mail cadastrado no Big Tester. Caso contrário, os pontos não serão creditados.",
    "ifs.copyLink": "Copiar Link",
    "ifs.share": "Compartilhar meu link",
    "ifs.shareSuccess": "Compartilhado com sucesso!",
    "ifs.LinkshareSuccess": "O link foi copiado para sua área de transferência!",
    "ifs.copied": "Link copiado para área de transferência",
    "ifs.card1": "Quanto mais amigos se cadastrarem pelo seu convite",
    "ifs.card2": "Mais pontos você ganha e ainda participa do ranking mensal!",
    //

    //
    // MyAccountScreen
    "mas.title": "Minha conta",
    "mas.emailNotConfirmed.title":
      "Seu e-mail não foi confirmado. Confira sua caixa de entrada.",
    "mas.emailNotConfirmed.text":
      "Não recebeu o e-mail? Confira sua caixa de spam.",
    "mas.emailNotConfirmed.btn": "reenviar e-mail",
    "mas.emailNotConfirmed.sent":
      "Email de confirmação enviado para sua conta de email.",

    "mas.section1.title": "Perfil",
    "mas.section1.subTitle":
      "Algumas informações podem estar visiveis para outras pessoas que estejam usando o Bigtester.",
    "mas.section1.name": "Nome",
    "mas.section1.phone": "Celular",
    "mas.section1.cpf": "Informe seu CPF",
    "mas.section1.birthday": "Data de nascimento",
    "mas.section1.birthdayPlaceholder": "Nascimento",
    "mas.section1.sex": "Sexo",
    "mas.section1.mas": "Masculino",
    "mas.section1.fem": "Feminino",
    "mas.section1.btn": "salvar dados do perfil",

    "mas.section2.title": "Alterar senha",
    "mas.section2.nowPassword": "Senha atual",
    "mas.section2.newPassword": "Nova senha",
    "mas.section2.newPasswordAgain": "Confirmar nova senha",
    "mas.section2.btn": "alterar senha",

    "mas.section3.title": "Localização",
    "mas.section3.subTitle":
      "Informações essenciais para enviar seus propdutos até sua casa!",
    // "mas.section3.cep":"CEP",
    "mas.section3.cepPlaceholder": "Código CEP",
    "mas.section3.cep": "CEP",
    "mas.section3.street": "Rua",
    "mas.section3.number": "Número",
    "mas.section3.add": "Complemento",
    "mas.section3.district": "Bairro",
    "mas.section3.city": "Cidade",
    "mas.section3.state": "Estado",
    "mas.section3.btn": "salvar localização",

    "mas.section3.2.title": "Interesses",
    "mas.section3.2.subTitle":
      "Seus interesses personalização suas formas de ganhar pontos. Não deixe de marcar!",
    "mas.section3.2.btn": "Salvar interesses",

    "mas.section4.title": "Suas avaliações",
    "mas.section4.subTitle": "Confira suas avaliações abaixo",
    "mas.section4.noAval": "Você ainda não fez nenhuma avaliação.",

    "mas.section5.title": "Cancelar inscrição",
    "mas.section5.btn": "eu quero me descadastrar no big tester  ",
    "mas.upload.fail-message": "Não foi possivel fazer o upload. Tente novamente.",
    "mas.birthdate.parse.fail-message": "Erro data de nascimento, favor checar o campo correspondente no formulário!",
    "mas.birthdate.invalid-age": "Idade inválida <span style='color:#f27474'>{age}</span> anos, favor verifique a data de nascimento novamente o campo data de nascimento!",
    "mas.dispatch.session-update": "{section} atualizado com sucesso!",
    "mas.dispath.session-update.fail-message": "Não foi possivel atualizar seu {section}. Tente novamente mais tarde.",
    "mas.dispatch.zip-code.notFound-message": "Cep não encontrado!",
    //
    //

    //
    //  PointsScreen
    "pointsScreen.title": "Meus pontos",
    "pointsScreen.section1.title": "Saldo",
    "pointsScreen.section1.subTitle.pt1": "Você tem ",
    "pointsScreen.section1.subTitle.pt2": " pontos!",
    "pointsScreen.section1.btn1": "troque pontos",
    "pointsScreen.section1.btn2": "missões",
    "pointsScreen.section1.btn3": "campanhas",
    "pointsScreen.section1.btn4": "cupons",

    "pointsScreen.section2.title": "Extrato de pontos",
    "pointsScreen.section2.col1": "Descrição",
    "pointsScreen.section2.col2": "Valor",
    "pointsScreen.section2.col3": "Data",
    "pointsScreen.section2.col4": "Expiração",

    "pointScreen.btn": "carregar mais registros",
    //

    //
    //  FollowScreen
    "follows.title": "Siga e Ganhe Pontos",
    "follows.titleText":
      "Siga esses perfis no instagram e ganhe ainda mais pontos! Os pontos serão computados após a adesão da oferta contida na missão. Caso haja problemas em computar seus pontos, entre em contato via e-mail ou WhatsApp enviando um print comprovando a adesão à missão.",
    "follows.accomplished": "Cumpridas",
    "follows.btn1": "pontos registrados",
    "follows.btn2": "ganhar",
    "follows.points": "pontos",
    "follow.instagram": "Acompanhe o Big Tester no Instagram",
    "follow.visitus": "Visitar perfil",
    //

    //
    //  Lucky Number
    "ln.title": "Número da Sorte",
    "ln.subtitle": "Concorra a incríveis prêmios através do resgate de Números da Sorte!",

    //
    //  PrivacyScreen
    privacy: "POLÍTICA DE PRIVACIDADE E CONDIÇÕES GERAIS",
    "privacy.title.1": "1. ÂMBITO:",
    "privacy.text.1":
      "Atendendo que a Adclick Marketing do Brasil Ltda exerce a sua atividade empresarial na área de campanhas de marketing de permissão. Nesse sentido, a empresa apresenta a sua política de privacidade enunciando: as formas de recolha; de utilização dos dados pessoais; as medidas de salvaguarda; e segurança adotadas no seu tratamento. Respeitando sempre a opção escolhida pelos usuários do site.",

    "privacy.title.2": "2. RECOLHA DE DADOS:",
    "privacy.text.2":
      "A Adclick Marketing do Brasil Ltda recolhe dados pessoais, tais como nome, endereço, emails, números de telefone, endereço IP, dados demográficos e sobre estilos de vida, através de formulários online, questionários, promoções de produtos e serviços, passatempos. Para os fins que adiante são indicados, os dados pessoais, podem ser guardados quer na sua forma inidentificável, quer de forma agregada. Além disso, a fim de poder levar eficazmente a efeito diversas transações comerciais, a Adclick Marketing do Brasil Ltda poderá recolher dados pessoais, quer de pessoas individuais, quer de clientes, quer de potenciais clientes através de questionários, pedidos de informação, ou quando se contratam, adquirem, subscrevem ou registam certos produtos e serviços. Incluindo demonstrações gratuitas, ofertas especiais ou participação em concursos ou passatempos. Os dados recolhidos são adequados, pertinentes e não excessivos em relação ao âmbito, finalidades e serviços determinados, explícitos e legítimos da Adclick Marketing do Brasil Ltda.",

    "privacy.title.3": "3. PATROCINADORES:",
    "privacy.text.3.1":
      "A Adclick Marketing do Brasil Ltda realiza constantemente passatempos de recolha de dados para as suas bases de campanhas de marketing de permissão.",
    "privacy.text.3.2":
      "Alguns destes passatempos são patrocinados por empresas, a quem os dados do usuário são transmitidos. Este processo é muito transparente e visível. O usuário dará sempre o seu consentimento expresso e individual de partilhar os seus dados com cada patrocinador, através de uma página criada para o efeito e após ter conhecimento da política de privacidade de cada patrocinador. Poderá no devido momento do processo de inscrição escolher os patrocinadores com quem concorda partilhar ou não partilhar os seus dados.",
    "privacy.text.3.3":
      "Todos os participantes cadastrados também compartilham os dados com a Caloga, patrocinadora do Big Tester situada em 170 rue Raymond Losserand, 75014 Paris, França. A política de privacidade da Caloga está disponível em:",
    "privacy.text.link": "http://caloga.br.com/html/dperso.php",
    "privacy.text.3.4":
      "Atuando como encarregado do tratamento do gerenciamento de envios publicitários Leadsolution Inteligencia Digital, S.L, com CIF B98816499 e domicílio social em Valencia Avenida Corte Valencianas no 26, Espanha.",

    "privacy.title.4": "4. COMO SERÃO UTILIZADOS OS DADOS?",
    "privacy.text.4":
      "A informação proporcionada poderá ser utilizada pela Adclick Marketing do Brasil Ltda, bem como pelos seus parceiros comerciais e clientes (para os quais o usuário tenha dado consentimento), para lhe enviar informação sobre os seus produtos e serviços, adaptando as comunicações às suas necessidades e interesses. Além destes fins, os seus dados pessoais poderão ser utilizados para:",
    "privacy.li.4.1":
      "Abordagem via telefone para oferta de produtos e serviços dos parceiros",
    "privacy.li.4.2": "Desenvolvimento, teste e ensaios de produtos",
    "privacy.li.4.3": "Realização de estudos e análises de mercado",
    "privacy.li.4.4": "Comercialização de produtos e serviços",
    "privacy.li.4.5": "Envio de mensagens publicitárias",
    "privacy.li.4.6":
      "Otimização de campanhas publicitárias, baseado em clusterização.",

    "privacy.title.5":
      "5. DIREITOS DE ACESSO, RETIFICAÇÃO, CANCELAMENTO E OPOSIÇÃO:",
    "privacy.text.5":
      "O usuário poderá exercer os seus direitos de acesso, retificação, cancelamento e oposição no que diz respeito aos seus dados pessoais. O exercício dos direitos deverá realizar-­se através de qualquer meio que o usuário considere pertinente:",
    "privacy.li.5.1":
      "Através dos links de cancelamento de serviço presentes em todas as comunicações enviadas via email;",
    "privacy.li.5.2": "Por email: sair@beeleads.com.br, com o assunto remover;",
    "privacy.li.5.3":
      "Por correio: Adclick Marketing do Brasil Ltda, Rua Sergipe, 475, 5º andar, São Paulo/SP. Contato telefônico: +55 (11) 43715300.",

    "privacy.title.6": "6. COOKIES:",
    "privacy.text.6":
      "Em todos os websites da Adclick Marketing do Brasil Ltda, são utilizados cookies, que permitem melhorar o início da sua sessão. Se voltar a visitar este website, o cookie reconhece que já esteve nesse website. Além disto, em todos os sites de passatempos da Adclick Marketing do Brasil Ltda, é recolhido o IP do usuário/participante de forma a poder detectar possíveis fraudes ou comportamentos ilegítimos.",

    "privacy.title.7": "7. SEGURANÇA E ARMAZENAMENTO DOS SEUS DADOS:",
    "privacy.text.7.1":
      "A Adclick Marketing do Brasil Ltda investe fortemente para manter a segurança dos seus sites e sistemas e para impedir o acesso aos seus dados pessoais por quaisquer pessoas que não estejam autorizadas a fazê­lo.",
    "privacy.text.7.2":
      "Não obstante, a Internet não é um meio de comunicação 100% seguro e por isso a Adclick Marketing do Brasil Ltda não pode garantir a segurança de qualquer informação que nos envie via Web. A Adclick Marketing do Brasil Ltda também não se responsabiliza por perdas ou danos causados ao usuário ou a outras partes como consequência da perda da confidencialidade da dita informação.",

    "privacy.title.8":
      "8. OUTROS WEBSITES E LIMITES DE RESPONSABILIDADE DA ADCLICK MARKETING DO BRASIL LTDA:",
    "privacy.text.8.1":
      "Os websites da Adclick Marketing do Brasil Ltda, podem conter ligações a outros websites que estão fora do controle da Adclick Marketing do Brasil Ltda e que não estão cobertos por esta política de privacidade. No caso de o usuário ter acesso a outros sites utilizando essas ligações, terá de ter atenção que esses sites poderão eventualmente solicitar e recolher dados pessoais, de acordo com a respectiva política de privacidade, que poderá ser diferente da nossa.",
    "privacy.text.8.2":
      "É importante também referir que os sites da Adclick Marketing do Brasil Ltda poderão pontualmente proporcionar links a outros sites criados e geridos por outras organizações. Uma vez que esses sites não são propriedade nossa, não podemos garantir a qualidade, adequação ou funcionamento dos mesmos, e a nossa relação com os ditos sites não supõe um aval nem dos produtos e serviços vendidos ou anunciados, nem de qualquer informação presente nos sites em questão. Recomendamos fortemente, que tenha muita atenção e que tenha também em conta que sob nenhuma circunstância a Adclick Marketing do Brasil Ltda será responsável, direta ou indiretamente, de qualquer dano ou perda causados, ou supostamente causados, ao usuário ou familiares em relação ao uso dos conteúdos, bens ou serviços disponíveis em qualquer um desses websites.",

    "privacy.title.9": "9. TRANSFERÊNCIA DE ATIVOS",
    "privacy.text.9":
      "No normal de curso da atividade da Adclick Marketing do Brasil Ltda, poderão ocorrer alterações a nível das participações sociais e de controle da Adclick Marketing do Brasil Ltda, nas empresas subsidiárias, participadas ou afiliadas ou eventualmente em áreas de negócio do grupo. Em qualquer destas situações os dados pessoais poderão ser considerados ativos transmissíveis, desde que sejam respeitados os requisitos e autorizações legalmente necessárias para a transmissão. O mesmo acontecerá no caso de a Adclick Marketing do Brasil Ltda ou alguma parte ou setor de atividade ou das empresas que formam o grupo, ou empresas associadas, participadas ou subsidiárias serem compradas, situações em que os dados pessoais farão parte dos ativos a serem transferidos desde que sejam respeitados os requisitos e autorizações legalmente necessárias para a sua transmissão.",

    "privacy.title.10": "10. POLÍTICA ANTI­SPAM",
    "privacy.text.10.1":
      "A Adclick Marketing do Brasil Ltda preocupa­-se seriamente com o spamming. Para provar a nossa dedicação a um ambiente de internet sem spam, instalamos uma política e procedimentos que asseguram a integridade dos nossos sistemas e das nossas mensagens. Assim sendo, se algum subscritor nosso ou participante de um passatempo se envolver num ato de spamming, nós terminaremos imediatamente e sem aviso prévio a sua conta ou participação, devida à nossa política de anti­spam. A Adclick Marketing do Brasil Ltda e seus sites associados, sempre fizeram e continuaram a fazer tudo o que for necessário dentro das nossas políticas e procedimentos, para manter o status de uma organização limpa de spam, dentro da comunidade da internet. Nós acreditamos verdadeiramente que organizações como a nossa, incluindo ASP’s, ESP’s e ISP’s, têm a responsabilidade de assegurar que os seus clientes e usuários se empenham e utilizam práticas de email responsáveis e respeitáveis.",
    "privacy.subtitle.10": "O que é o spamming?",
    "privacy.text.10.2":
      "O spamming consiste não só em enviar um email a alguém desconhecido, mas também pode ser feito através da colocação de mensagens em newsgroups, fóruns, classificados ou chats. Excluem­ se os casos em que se insere o convite ao serviço/passatempo na assinatura dos comentários e/ou anúncios.",

    "privacy.title.11":
      "11. POLÍTICA ANTI­SPAM DA ADCLICK MARKETING DO BRASIL LTDA",
    "privacy.text.11.1":
      "Apesar de uma parte substancial da atividade da Adclick Marketing do Brasil Ltda seja a organização de passatempos e consequentemente incentive os seus participantes a divulgar os mesmos entre os amigos e conhecidos, É TOTAL E INEQUIVOCAMENTE CONTRA QUALQUER FORMA DIRETA OU INDIRETA DE UTILIZAÇÃO DE SPAMMING. Assim sendo, recomendamos aos nossos membros e participantes que só enviem emails a pessoas que conhecem e NUNCA a pessoas que desconhecem.",
    "privacy.text.11.2":
      "Se sente que um participante ou membro dos nossos sites, lhe enviou spam ou tem questões sobre a nossa política de spam, por favor contate o nosso departamento de Spam Abuse na seguinte direção de email: abuse@beeleads.com.br indicando-­nos toda a informação que puder. Em seguida tomaremos imediatamente as medidas adequadas, sendo uma delas a expulsão imediata do membro do nosso serviço, como medida mínima.",

    "privacy.title.12": "12. ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE:",
    "privacy.text.12":
      "A Adclick Marketing do Brasil Ltda reserva o direito de a qualquer momento atualizar ou rever a política de privacidade enunciada neste documento a fim de ajustar eventuais alterações legislativas e outros condicionalismos.",

    "privacy.title.13": "13. CONCORDÂNCIA COM ESTA POLÍTICA DE PRIVACIDADE:",
    "privacy.text.13":
      "Entende­-se que o usuário aceita as condições desta política de privacidade ao introduzir os seus dados em qualquer um dos sites da propriedade da Adclick Marketing do Brasil Ltda. O preenchimento dos formulários supõe o consentimento por parte do usuário do tratamento automatizado e utilização dos dados de acordo com as condições de privacidade descritas neste documento.",
    //

    //
    //  TermsScreen
    terms: "TERMOS E CONDIÇÕES GERAIS ADCLICK",
    "terms.title.1": "1. DESCRIÇÃO DO SERVIÇO:",
    "terms.text.1.1":
      "A Adclick, S. A., com sede na Rua Sergipe, 475, 5˚ andar, Higienópolis - São Paulo, SP – Brasil - CEP: 01.243-001 - CNPJ: 14.314.669/0001-62 (doravante designada por‘’Adclick’’) é uma empresa que, de entre outras atividades, se dedica à geração de audiências segmentadas, envolvidas com mensagens, meios e marcas e que, por recurso a estratégias criativas, à produção de conteúdo relevante e ao know-how digital, inter relaciona-se com o seu utilizador ou cliente e constrói relações entre pessoas e marcas.",
    "terms.text.1.2":
      "Desta forma, quando visita os nossos websites - tanto o institucional como os das marcas Adclick - encontrará informação relacionada com a oferta comercial da Adclick, enquanto sociedade prestadora de serviços de marketing digital ou, por outro lado, encontrará diversos artigos, de publicação original, livre e isenta e que tem os seus conteúdos orientados pelos mais altos padrões de critério e rigor, alicerçados em fontes de informação confiáveis, sólidas e consistentes.",
    "terms.text.1.3":
      "Nesses websites são publicados diariamente conteúdos úteis e relevantes para ajudar os utilizadores em várias temáticas, tais como: Cultura, Entretenimento e Lazer, Saúde, Bem-Estar e Alimentação, Ciência, Tecnologia e Telecomunicações, Bancos, Financeiras e Seguros, Educação, Formação e Emprego, Imobiliário, Turismo e Viagens, Entidades Sem Fins Lucrativos, Energia, entre outros.",
    "terms.text.1.4":
      "A Adclick preocupa-se com os interesses dos seus clientes e dos seus utilizadores e é guiada pela transparência e clareza da informação.",
    "terms.text.1.5":
      "No mesmo sentido, a Adclick tem configurada uma estratégia digital, que muito poderá ajudar no negócio dos nossos clientes, e que utiliza para si própria, na medida em que alicerça o seu desenvolvimento de negócio nos diversos modelos de marketing digital que queremos que compreenda existirem antes de navegar pelos nossos websites, são eles:",
    "terms.text.1.6":
      "Em qualquer destes momentos, só cederá e interagirá com as mesmas se entender deve fazê-lo. Se o fizer, os seus dados e o seu perfil converter-se-ão num contato, integrando apenas a Base de Dados da Adclick, que se encontra com medidas de segurança e garantias adequadas, conforme descrito em nossa Política de Privacidade.",
    "terms.text.1.7":
      "Caso tenha consentido de forma clara, livre e inequívoca para tanto, poderá ver-lhe sugeridos conteúdos do seu interesse ou produtos relacionados, tanto via Email marketing, como via Sms marketing, Chamada IVR, ou Chamada Call center tanto pela Adclick, como por empresas parceiras, com o seu segmento se para tanto tiver claramente identificado, se para tanto tiver, igualmente e em separado, consentido.",
    "terms.text.1.8":
      "Relembramos que, de acordo com a nossa Política de Privacidade (que permanece disponível para consulta neste link) poderá retirar o seu consentimento a todo tempo, tanto efetuando unsubscribe, como enviando um email ou carta para os endereços disponibilizados na Política de Privacidade referida acima.",
    "terms.text.1.9":
      "Igualmente, se permanecerem dúvidas acerca do funcionamento do presente ou de qualquer um dos websites da Adclick poderá utilizar os contatos aí mencionados para ver as mesmas esclarecidas.",
    "terms.text.1.10":
      "Se alguma informação proveniente do utilizador for falsa ou inexata, a Adclick reserva-se o direito de cancelar a subscrição e dar por terminados os direitos do mesmo a utilizar o Serviço.",
    "terms.li.1.1":
      "Conteúdo Patrocinado - A Adclick, em cada um dos seus websites, publica conteúdo patrocinado, de acordo com os critérios editoriais definidos e com a pertinência e interesse da audiência. Todo o conteúdo patrocinado publicado pela Adclick é identificado como tal.",
    "terms.li.1.2": `Display Advertising – este é um modelo de publicidade online, assente na utilização de criatividades visuais, como caixas de publicidade, fotos e vídeos, que são colocados ao longo dos websites da Adclick e que são estrategicamente organizados por temas relacionados com os que habitualmente pesquisa. Estes visam comunicar uma proposta de valor de determinada marca ou de determinada campanha publicitária relacionada com os interesses que, pela tecnologia de cookies que tem armazenados no seu computador, aceita que possamos conhecer.
    Com esta personalização, a Adclick publicita, assim, marcas e campanhas, ajudando a que estes anúncios sejam mais notados e que, por serem apresentados apenas às pessoas cujo interesse relacionado já se encontra mapeado, a satisfação do utilizador maior.`,
    "terms.li.1.3": `Geração de Leads -Em determinadas situações, ao navegar nos websites da Adclick, poderá ser
    confrontado com diversas interações, que procuram promover a recolha dos seus dados, como: Chatbots, Push Notifications e/ou Formulários web e questionários que, com maior ou menor capacidade de se adaptar aos seus interesses, lhe pedirão determinados dados pessoais (como o nome, idade, gênero, email, telefone, etc).`,

    "terms.title.2": "2. LIMITAÇÃO DA RESPONSABILIDADE:",
    "terms.text.2.1":
      "O Utilizador aceita expressamente assumir exclusivamente todo e qualquer risco proveniente da utilização dos serviços ou a partilha de dados pessoais referidos supra de forma ilícita ousem autorização do seu titular.",
    "terms.text.2.2":
      "A Adclick não se responsabiliza pela impossibilidade de uso, a interrupção de negócios, nem pelos danos diretos ou indiretos, especiais, acidentais, ou consequentes de qualquer tipo de negligência (incluindo a perda de benefícios).",
    "terms.text.2.3":
      "A Adclick não garante que o serviço responda aos requisitos do Utilizador, nem que o serviço não seja interrompido, nem que seja seguro em redes abertas, oportuno ou isento de erros, como tampouco assegura os resultados que se obtenham da utilização do Serviço ou a exatitude ou fiabilidade da informação obtida através do serviço. Igualmente, não garante a correção de defeitos no Serviço.",
    "terms.text.2.4":
      "Os websites, emailings, push notifications, aplicações de chat e/ ou sms enviados pela Adclick podem remeter para hiperligações a outros websites - de terceiros - que estão fora do controle da Adclick, não sendo por si controlados.",
    "terms.text.2.5":
      "No caso de o utilizador aceder a outros websites utilizando essas ligações, deverá ter atenção que esses websites poderão, eventualmente, solicitar e recolher dados pessoais, de acordo com a respetiva política de privacidade dos mesmos, que poderá ser diferente da Adclick e sobre a qual a Adclick não terá qualquer responsabilidade.",

    "terms.title.3": "3. PROIBIÇÃO DE REVENDA OU USO COMERCIAL DOS SERVIÇOS:",
    "terms.text.3.1":
      "A utilização dos Serviços é unicamente para Utilizador e Clientes (Patrocinadores) da Adclick.Os Utilizadores e Patrocinadores aceitam não ceder nem fazer nenhum tipo de uso comercial do Serviço sem o expresso consentimento da Adclick.",

    "terms.title.4": "4. CONDUTA DO UTILIZADOR:",
    "terms.text.4.1":
      "O Utilizador é o único responsável pelo conteúdo das transmissões derivativas da utilização do serviço. A utilização do serviço, por parte do Utilizador, estará submetida à legislação aplicável a nível nacional e internacional.",
    "terms.text.4.2": "O Utilizador compreende e aceita:",
    "terms.li.4.1":
      "Não utilizar o Serviço com fins ilícitos, nem proibidos neste documento;",
    "terms.li.4.2":
      "Não interferir nos sistemas de redes ligados ao Serviço, nem os desarticular;",
    "terms.li.4.3": `Acatar todos os regulamentos, disposições e procedimentos dos sistemas de
    redes ligados ao serviço de campanhas de marketing de permissão.`,
    "terms.text.4.3": `A Adclick poderá, segundo o seu próprio critério, dar por terminado os seus serviços de forma imediata, se a conduta de algum Utilizador não for de acordo com estes Termos e Condições.`,

    "terms.title.5": "5. INDENIZAÇÃO:",
    "terms.text.5":
      "O Utilizador aceita indenizar e exonerar de toda a responsabilidade a Adclick, as suas subsidiárias, afiliadas, diretores e trabalhadores/colaboradores, de qualquer reclamação ou processo, incluindo honorários razoáveis despendidos com Advogados, como consequência do uso indevido dos serviços, bem como pela infração por parte do Utilizador do estabelecido no presente acordo.",

    "terms.title.6":
      "6. PARTICIPAÇÃO OU ADESÃO A CAMPANHAS PUBLICITÁRIAS DE TERCEIROS:",
    "terms.text.6":
      "O Utilizador, se escolher manter correspondência com Terceiros, como marcas cujos produtos sejam apresentados nos websites da Adclick deverá ter consciente que qualquer entrega de mercadorias, ou fornecimento de serviços, bem como o pagamento das mesmas ou qualquer outros termos, condições e garantias relacionadas com tais correspondências irão estabelecer-se unicamente entre o Utilizador e o Patrocinador. A Adclick não assume responsabilidade, nem obrigação alguma derivada de tal correspondência ou participação.",

    "terms.title.7": "7. MODIFICAÇÕES NAS CONDIÇÕES DO SERVIÇO:",
    "terms.text.7.1":
      "Em virtude das especificidades dos websites da Adclick - e a fim de poder prestar o serviço mais adequado a cada momento - esta poderá modificar os Termos e Condições, considerando-se o Utilizador notificado das mesmas, mediante a comunicação das alterações de sua versão num dos nossos websites, de tal modo que o Utilizador tenha conhecimento dos mesmos.",
    "terms.text.7.2":
      "A utilização ininterrupta dos websites da Adclick, por parte do Utilizador, constituirá uma ratificação do presente documento, com as modificações e alterações que se tenham introduzido.",

    "terms.title.8": "8. MODIFICAÇÕES NO SERVIÇO:",
    "terms.text.8.1":
      "Pelas mesmas razões anteriormente mencionadas, a Adclick reserva-se o direito de modificar ou interromper qualquer serviço seu, no todo ou em parte, tendo enviado ou não notificação ao Utilizador e Leitor.",
    "terms.text.8.2":
      "A Adclick não será responsável perante o Utilizador ou terceiros por ter exercido o seu direito de modificar ou interromper os seus serviços.",

    "terms.title.9": "9. TÉRMINO DA RELAÇÃO:",
    "terms.text.9.1":
      "Tanto o Utilizador como a Adclick poderão dar por finalizado os Serviços a qualquer momento, sem pré-aviso, com ou sem justificação, e tal decisão será imediatamente efetivada.",
    "terms.text.9.2":
      "Caso o Utilizador não concorde com alguma cláusula dos Termos e Condições do presente acordo ou qualquer das suas modificações, ou ainda no caso de não estar satisfeito com os serviços disponibilizados e melhor aqui explicados, deverá:",
    "terms.text.9.3":
      "No momento do pedido, os serviços cessarão, após prazo razoável para a conclusão desse processo, o que no caso de exercício de direitos poderá colher um prazo de 30 dias, ou 60 em casos de especial complexidade.",
    "terms.li.9.1": "Interromper a utilização dos serviços;",
    "terms.li.9.2": "Cancelar a sua subscrição dos serviços;",
    "terms.li.9.3": "Notificar a Adclick da sua intenção.",

    "terms.title.10": "10. PROPRIEDADE INTELECTUAL E DIREITOS DE AUTOR",
    "terms.text.10": `Todo o conteúdo deste website, tal como: fotografias, desenhos, gráficos, gravuras, ícones, textos, software, nomes comerciais, marcas, logotipos ou quaisquer outros sinais distintivos suscetíveis de utilização industrial e comercial, é da propriedade da Adclick ou de terceiros que autorizaram devidamente a sua inclusão neste website e é protegido por Direitos de Autor e Direitos Conexos, e Direitos da Propriedade Industrial, ao abrigo das leis Portuguesas e da União Europeia, convenções internacionais e outras leis.
      O Utilizador não poderá reproduzir, modificar, transformar, desmontar, realizar engenharia reversa, distribuir, alugar, prestar, colocar à disposição ou permitir o acesso ao público, por meio de qualquer forma de comunicação pública de nenhum dos elementos referidos no parágrafo anterior. O Utilizador deverá utilizar os materiais, elementos e informação a que aceda através deste website unicamente para as suas necessidades pessoais, sendo expressamente proibida a exploração comercial, direta ou indireta, dos mesmos, salvo mediante autorização prévia e por escrito da Adclick ou, consoante os casos, dos terceiros titulares dos direitos de propriedade intelectual e industrial.`,

    "terms.title.11": "11. FORO:",
    "terms.text.11":
      "O presente acordo irá reger-se pela Lei Brasileira, submetendo-se as partes, em caso de litígio, aos Juízes e Tribunais da Comarca de São Paulo.",
    //


    // DESCADASTRAR
    "unsubs.title" : "Descadastrar? Poxa... Foi algo que dissemos?",
    "unsubs.text" : "Tem certeza que deseja perder todos os seus pontos até aqui e abandonar a gente? Se houver alguma dúvida, você pode tirá-la através do ",
    "unsubs.faq" : "FAQ",

    "unsubs.placeholder" : "Confirme seu e-mail",
    "unsubs.submit" : "descadastrar",

    "unsubs.error.emailDoesntMatch" : "O e-mail informado não coincide com o e-mail do cadastro!",
    "unsubs.success" : "conta removida com sucesso!",
    "unsubs.error" : "Não foi possível remover a sua conta nesse momento, por favor tente novamente mais tarde!",
    //

    //FAQ
    "faq.title" : "Perguntas Frequentes",
    
    "faq.question.1" : "Como acumular pontos?",
    "faq.question.2" : "Onde eu retiro as amostras?",
    "faq.question.3" : "É realmente de graça?",
    "faq.question.4" : "Como eu faço para receber amostras na minha casa?",
    "faq.question.5" : "Já me cadastrei, mas nunca ganhei",
    "faq.question.6" : "Como faço para me descadastrar?",
    "faq.question.7" : "Não consigo me cadastrar, o que devo fazer?",
    "faq.question.8" : "Problemas com login e cadastro?",
    "faq.question.9" : "Meu pontos não aumentam, o que devo fazer?",

    "faq.answer.1" : "Você consegue pontos: (a) Fazendo o cadastro; (b) Respondendo as perguntas de consumo e comportamento no site; (c) Completando seu cadastro no nosso site; (d) Compartilhando os produtos no seu Facebook",
    "faq.answer.2" : "Os produtos são enviados via correio para a sua casa. Após a solicitação de troca de um produto, a equipe do Big Tester entra em contato para confirmar seu endereço e depois fazem o envio.",
    "faq.answer.3" : "SIM!!! Todas as amostras são enviadas totalmente de graça. Você não tem custo nenhum.",
    "faq.answer.4" : "Para receber amostras na sua casa, é muito simples: você se cadastra no nosso site, acumula o maior numero de pontos possíveis, solicita a troca da amostras desejada e nós enviamos para sua casa.  ",
    "faq.answer.5" : "Para receber as amostras em casa, não basta se cadastrar. É necessário acumular pontos e solicitar a troca do produto desejado no site.",
    "faq.answer.6" : "Para se descadastrar, acesse: bigtester.com.br/descadastrar",
    "faq.answer.7" : "Caso não consiga terminar o seu cadastro, exclua os cookies e os cachês do seu navegador e tente novamente para finalizar o cadastro.",
    "faq.answer.8" : "Verifique se está inserindo todas as informações de forma correta. Não é permitido ''espaço'' após a última letra ou número no campo de cadastro.",
    "faq.answer.9" : "Certifique-se que você fez todos os processos para ganhar pontos (como descrito na primeira cláusula).",
    //

    // not found
    "notFound.title" : "Ops...",
    "notFound.subtitle" : "Parece que não há nada aqui.",
    "notFound.backMessage" : "Que tal voltar à ",
    "notFound.backLink" : "página inicial",
    "notFound.errorMessage" : "Erro: 404",
    //

  },
};
