import React, { useState } from "react";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";

import Product from "../../../components/home/products/Product";

import styled from "styled-components";
import SearchField from "../../../components/common/SearchField";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
import ApiService from "../../../helpers/ApiService";
import PaginationControler from "../../../components/common/PaginationControler";
import Analytics from "../../../helpers/AnalyticsService";
import translate from "../../../locales/translate";
import { useHistory } from "react-router-dom";
import ScrollTo from "scroll-to-element";
import { FormattedMessage } from "react-intl";

const amounts = [12, 24, 48, 96];

const HistoryButton = styled(Button)`
  background-color: var(--primary) !important;
  color: #fff;
  &:hover {
    background-color: var(--primary-hover) !important;
  }
`;

const RegulationContainer = styled.div`
  background-color: #ffffff;
  padding: 40px;
  margin: 64px;
  @media screen and (max-width: 960px) {
    padding: 16px;
    margin: 0;
  }
`;

const List = ({ categories = [] }) => {
  const [products, setProducts] = useState([]);
  // const [tags, setTags] = useState([])
  const [amount, setAmount] = useState(12);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const matches = useMediaQuery("(max-width:960px)");
  const [filter, setFilter] = useState("");
  const history = useHistory();

  const getProducts = () => {
    ApiService.get("products", {
      limit: amount,
      skip: (page - 1) * amount,
      search: filter,
      // category: categories.join(","),
    }).then(({ data }) => {
      setProducts(data.products);
      setTotal(data.count);
      // setTags(data.category)
    });
  };

  const goToHistoryPage = () => {
    history.push("/produtos-historico");
  };

  const handleFilter = (value) => {
    setFilter(value);
    new Analytics().registerEvent({
      category: "Products",
      action: "Search",
      label: value,
    });
  };

  const scrollToRegulation = () => {
    ScrollTo("#regulation", {
      duration: 1000,
      offset: -120,
    });
  };

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, categories, page, amount]);

  return (
    <GridContainer container>
      <Grid container>
        <Grid item xs={12} md={10}>
          <BtHowItWorks video="https://www.youtube.com/watch?v=Z63yQZ4IHJ0" />
          <Typography paragraph variant="h4" className="dark--text text-bold">
            {translate("ps.title")}
          </Typography>
          <Typography
            variant="body2"
            className="dark--text"
            style={{ marginBottom: 16 }}
          >
            {translate("ps.title2")}{" "}
            <span
              className="text-bold"
              style={{ cursor: "pointer" }}
              onClick={scrollToRegulation}
            >
              {translate("ps.regulation")}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
          <FormattedMessage id="ps.search-field.placeholder">
            {
              (searchFieldPlaceHolder) => (
                <SearchField
                  variant="outlined"
                  placeholder={searchFieldPlaceHolder}
                  fullWidth={matches}
                  onChange={handleFilter}
                />
              )
            }
          </FormattedMessage>
          
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 16 }}>
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
          // text={translate("ps.products")}
        />
        {products.map((p, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <Product item={p} small={1} />
            </Grid>
          );
        })}
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
        />
      </Grid>
      <Grid container spacing={1} style={{ backgroundColor: "#f0f0f0" }}>
        <Grid item sm={1}></Grid>
        <Grid item sm={10} xs={12}>
          <RegulationContainer id="regulation">
            <Typography variant="h6" className="dark--text text-bold">
              {translate("ps.regulation")}
            </Typography>
            <Typography variant="body2" className="dark--text text-bold">
              {translate("ps.text.title")}
            </Typography>
            <Typography paragraph variant="body2" className="dark--text">
              {translate("ps.text.01")}
            </Typography>
            <Typography variant="body2" className="dark--text">
              {translate("ps.text.02")}
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" className="dark--text">
                  {translate("ps.text.02.01")}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" className="dark--text">
                  {translate("ps.text.02.02")}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" className="dark--text">
                  {translate("ps.text.02.03")}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" className="dark--text">
                  {translate("ps.text.02.04")}
                </Typography>
              </li>
            </ul>
            <Typography variant="body2" className="dark--text">
              {translate("ps.text.03.01")}{" "}
              <span className="text-bold">{translate("ps.text.03.email")}</span>
              {translate("ps.text.03.02")}
            </Typography>
            <br />
            <HistoryButton onClick={goToHistoryPage}>
              {translate("lp.product.exchange.histBtn")}
            </HistoryButton>
          </RegulationContainer>
        </Grid>
        <Grid item sm={1}></Grid>
      </Grid>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  background-color: white;
  padding: 24px;
  margin: unset;
`;

export default List;
