import React from 'react';
import { Card, Grid, Button } from '@material-ui/core';
import styled from 'styled-components'

const OptionCard = styled(Card)`
    padding: 8px;
    padding-left: 24px;
    transition: all 1s !important;
    /* background-color: ${props => props.selected ? 'var(--success)' : props.positive ? 'rgba(156,204,101, 0.3)' : ''} !important; */
    /* color: ${props => props.selected ? 'white' : ''} !important; */
`

const KeyButton = styled(Button)`
    min-width: unset !important;
    width: 35px;
    height: 35px;
    border: 1px solid lightgrey !important;
    box-shadow: 0 0 10px lightgrey !important;
    background-color: ${props => props.selected ? 'lightgrey' : ''} !important;
    * {
        color: var(--darktext) !important;
    }
`

const CampaignOption = ({ option = {}, button, selected = false, updateSelected }) => {
    return (
        <OptionCard
            style={{ backgroundColor: selected ? 'var(--success)' : option.positive ? 'rgba(156,204,101, 0.3)' : '' }}
            // positive={option.positive}
            selected={selected}
            elevation={2}
            className="dark--text cursor"
            onClick={() => updateSelected({ button, option })}>
            <Grid container spacing={2} style={{ alignItems: 'center', padding: 12 }}>
                <Grid item xs={3}>
                    <KeyButton size="small" disabled selected={selected}>
                        {button}
                    </KeyButton>
                </Grid>
                <Grid item>
                    {option.text}
                </Grid>
            </Grid>
        </OptionCard>
    );
}

export default CampaignOption;