/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from "react-hook-form";
import InputCode from './InputCode';

const BoxContainer = styled(Box)`
    margin: 0 40px;
    padding: 32px;
    background-color: #FAFAFA;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    @media screen and (max-width: 960px) {
        margin: 0;
    }
`;
const CustomButton = styled(Button)`
    width: 100%;

    &:disabled {
        background-color: var(--lighttext) !important;
    }
`;

const CodeContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const NumberBox = () => {
    const [loaded, setLoaded] = React.useState(false);
    const { handleSubmit, register, errors, setError } = useForm();
    const [arrInputs] = React.useState([]);
    const CodeSize = 4;
    
    let numberInputs = [];
    for(let i=0; i<CodeSize; i++) {
        numberInputs.push(i);
    }

    const renderInputs = (row) => {
        return <InputCode id={`code${row}`} onChange={changeInput} register={register} disabled={loaded} />
    };
    const changeInput = (e) => {
        let id = e.currentTarget.id.split("code");
        let idNumber = parseInt(id[1]);

        if(e.type === "change" && e.currentTarget.value !== "_"){
            verifyInput(e.currentTarget);
            if (idNumber < (CodeSize - 1)) {
                document.getElementById(`code${(idNumber + 1)}`).focus();
            }
        }
        else if(e.type === "change" && e.currentTarget.value === "_") {
            saerchAndDelete(e.currentTarget)
        }
    };

    const saerchAndDelete = (currentInput) => {
        let position = arrInputs.indexOf(currentInput);
        if(~position) arrInputs.splice(position, 1);
    };

    const verifyInput = (currentInput) => {
        if(arrInputs.length !== CodeSize) {
            let position = arrInputs.indexOf(currentInput);
            if(position < 0) {
                arrInputs.push(currentInput);
                setLoaded(arrInputs.length === CodeSize)
            }
        }
    }

    const handleCodeRescue = async () => {
        
    };

    return(
        <div>
            <BoxContainer>
                <form id="submit-code">
                    <Typography variant="body1" align="center" className="dark--text text-bold">
                        Ouviu o código na Nativa FM hoje?
                    </Typography>
                    <Typography variant="body1" align="center" className="dark--text">
                        Preencha abaixo o Código Premiado ouvido hoje
                    </Typography>
                    <CodeContainer>
                        {numberInputs.map(renderInputs)}
                    </CodeContainer>
                    <CustomButton
                        disabled={!loaded}
                        className="success"
                        size="large"
                        type="submit"
                        onClick={handleCodeRescue}
                    >
                        Cadastrar código
                    </CustomButton>
                </form>
            </BoxContainer>
        </div>
    );
}

export default NumberBox;