import React from 'react';
import DefaultLayout from '../../layout/default';
import ProductsHistoryPageComponent from './components/ProductsHistoryPage';

const ProductsPageHistory = (props) => {
    return (
        <DefaultLayout>
            <ProductsHistoryPageComponent />
        </DefaultLayout >
    );
}

export default ProductsPageHistory;