import React from 'react';
import ScrollTo from 'scroll-to-element'

import { Grid, Button, useMediaQuery, Dialog } from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Video from './Video'
import { withRedux } from '../../helpers/redux';
import { useSelector, shallowEqual } from 'react-redux';
import translate from '../../locales/translate';

const useStore = () => {
    return useSelector(
        state => ({
            user: state.user,
            language: state.language
        }),
        shallowEqual
    )
}

const DefaultBtns = () => {
    const matches = useMediaQuery('(min-width:960px)');
    const [modal, setModal] = React.useState(false)
    const { user, language } = useStore()

    const handleModalEvent = event => {
        setModal(!modal)
    }

    const handleScrollRegister = () => {
        ScrollTo('body', {
            duration: 500
        })
    }

    return (
        <Grid item xs={12} style={{ padding: 0, textAlign: (matches ? '' : 'center') }}>
            {/* { language === 'pt-BR' && <Button
                variant="contained"
                size="large"
                className="danger white--text"
                onClick={handleModalEvent}>
                {translate('btn.howWorks')}
                <PlayCircleFilledIcon style={{ marginLeft: '12px' }} />
            </Button>}
            {!matches && <br />} */}
            {!user.id && <Button
                variant="contained"
                className="primary white--text" size="large"
                style={matches ? { marginLeft: 0 } : { marginTop: 16 }}
                onClick={handleScrollRegister}>
                {translate('btn.register')}
            </Button>}
            <Dialog open={modal} onClose={handleModalEvent} maxWidth="md" fullWidth>
                <Video close={handleModalEvent} />
            </Dialog>
        </Grid>
    );
}

export default withRedux(DefaultBtns);