import React from "react";
import styled from "styled-components";

import { Grid, Typography, Box, Hidden } from "@material-ui/core";
import Stars from "../common/Stars";

const ImageArea = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    border-radius: 50%;
  }
`;

const StarBox = styled(Box)`
  display: flex;
  margin-top: 8px;
  margin-bottom: 4px;
  position: relative;
  align-items: center;
`;

// const NoImageBox = styled(Box)`
//   width: ${(props) => (props.simplifyComment ? "50px" : "70px")};
//   height: ${(props) => (props.simplifyComment ? "50px" : "70px")};
//   border-radius: 50%;
//   background-color: #ddd;
//   border: 1px solid grey;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   & * {
//     font-size: ${(props) =>
//       props.simplifyComment ? "35px" : "45px"} !important;
//     color: grey;
//   }
// `;

const Comment = ({ item, imageClass, simplifyComment, noScore, key }) => {
  const simplifyText = (text) => {
    return text.substr(0, 200) + (text.length > 200 ? "..." : "");
  };

  return item.id ? (
    <Grid container spacing={4} style={{ padding: 16 }} key={key}>
      <Hidden smDown>
        <ImageArea
          item
          md={2}
          style={{
            paddingLeft: imageClass ? 0 : "",
            paddingRight: imageClass ? 0 : "",
          }}
        >
          {/* {console.log("items " + JSON.stringify(item.owner))} */}
          {/*item.owner.avatar ? (
                        <img src={item.owner.avatar} alt={item.owner.name} width="100%" style={imageClass} />
                    ) : (
                            <NoImageBox {...simplifyComment}>
                                <Icon>person</Icon>
                            </NoImageBox>
                    )*/}
        </ImageArea>
      </Hidden>
      <Grid item xs={12} md={10}>
        <Typography variant="h5" className="text-bold dark--text">
          {item && item.owner && item.owner.fullName}
        </Typography>
        {!noScore && (
          <StarBox id={item.id + "stars"}>
            <Stars rating={item.rating} max={5} />
            <Typography
              variant="body2"
              className="dark--text"
              style={{ position: "absolute", right: 0 }}
            >
              {item.date}
            </Typography>
          </StarBox>
        )}
        <Typography
          variant={simplifyText ? "caption" : "body2"}
          className="dark--text"
        >
          {simplifyComment ? simplifyText(item.comment) : item.comment}
        </Typography>
      </Grid>
      {!imageClass && (
        <Grid item xs={12}>
          <hr style={{ borderColor: "lightgrey", opacity: 0.4 }} />
        </Grid>
      )}
    </Grid>
  ) : (
    <div></div>
  );
};

export default Comment;
