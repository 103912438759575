import React from "react";
import translate from "../../../locales/translate";
import { useHistory } from "react-router-dom";
import ApiService from "../../../helpers/ApiService";
import Extract from "../../../components/statements/Extract";
// Redux
import { withRedux } from "../../../helpers/redux";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Formatter from "../../../helpers/Formatter";
// Components
import {
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  CircularProgress,
} from "@material-ui/core";
// Icons
import Icon from '@mdi/react';
import { mdiGift, mdiRocketLaunch, mdiAlertDecagram } from '@mdi/js';

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user,
      paginatino: state.pagination,
    }),
    shallowEqual
  );
};

const pointOptions = [
  {
    text: translate("pointsScreen.section1.btn1"),
    icon: mdiGift,
    page: "/produtos",
  },
  {
    text: translate("pointsScreen.section1.btn2"),
    icon: mdiRocketLaunch,
    page: "/missoes",
  },
  {
    text: translate("pointsScreen.section1.btn3"),
    icon: mdiAlertDecagram,
    page: "/campanhas-patrocinadas",
  },
  // {
  //   text: translate("pointsScreen.section1.btn4"),
  //   icon: {
  //     font: null,
  //     type: "local_offer",
  //   },
  //   page: "/cupons",
  // },
];

const MyPointsPage = () => {
  const {
    user: { wallet },
  } = useStore();

  const [statements, setStatements] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const redirect = (page) => {
    history.push(page);
  };

  const getStatements = async () => {
    setLoading(true);

    try {
      const {
        data: { statement, count },
      } = await ApiService.get("/wallet/statement", {
        skip: (page - 1) * 10,
        limit: 10,
      });

      const formattedStatements = statement.map((dat) =>
        Formatter.formatStatement(dat)
      );

      setTotal(count);
      setStatements([...statements, ...formattedStatements]);
    } catch (error) {
      alert(error);
    }

    setLoading(false);
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  React.useEffect(() => {
    getStatements();
    changePagination("myPoints");
    changeHelmet([{ name: "myPoints", content: "myPoints content" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={4}
        justify="center"
        style={{ backgroundColor: "white", padding: 18 }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" className="dark--text text-bold">
            {translate("pointsScreen.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Card className="dark--text">
            <CardContent>
              <Typography variant="h5" paragraph className="text-bold">
                {translate("pointsScreen.section1.title")}
              </Typography>
              <Typography variant="body1">
                {translate("pointsScreen.section1.subTitle.pt1")}
                {wallet.balance}
                {translate("pointsScreen.section1.subTitle.pt2")}
              </Typography>
              <Grid
                container
                spacing={2}
                style={{ margin: "18px 0", width: "100%" }}
              >
                {pointOptions.map((opt, index) => (
                  <Grid item xs={12} md={4} key={`option-btn-${index}`}>
                    <Button
                      size="large"
                      fullWidth
                      className="primary"
                      onClick={() => {
                        redirect(opt.page);
                      }}
                    >
                      <Icon path={opt.icon} style={{ marginRight: 12, height: '1.5rem' }} />
                      {opt.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10}>
          <Card className="dark--text">
            <CardContent>
              <Typography variant="h5" paragraph className="text-bold">
                {translate("pointsScreen.section2.title")}
              </Typography>
              <Extract statements={statements} pages={page} />
              {total > page * 10 && (
                <Button
                  fullWidth
                  size="large"
                  className="primary"
                  disabled={loading}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    translate("pointScreen.btn")
                  )}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRedux(MyPointsPage);
