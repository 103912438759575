import React from 'react';
import { Box, Zoom } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

const Stars = ({ rating, max, clickable, onSelect }) => {
    const [visible, setVisible] = React.useState(true)
    const boxId = 'boxstar-' + Math.ceil(Math.random() * 1000)

    const showStars = () => {
        let el = document.getElementById(boxId)
        if (window && el) setVisible((window.pageYOffset + window.innerHeight) >= (el.offsetTop))
    }

    React.useEffect(() => {
        window.addEventListener('scroll', showStars)
        showStars()

        return function cleanUp() {
            window.removeEventListener('scroll', showStars)
        }
    })

    const handleClick = (star) => {
        if (clickable) onSelect(star)
    }

    return (
        <Box id={boxId} style={{ display: 'inline' }}>
            {[...Array(max)].map((s, i) => (
                <Zoom in={visible} style={{ transitionDelay: (i * 200) + 'ms' }} key={i}>
                    <StarIcon
                        className={clickable ? 'cursor' : ''} 
                        style={{ color: (((i + 1) <= rating) ? 'var(--secondary)' : 'lightgrey') }}
                        onClick={() => handleClick(i + 1)}
                    />
                </Zoom>
            ))}
        </Box>
    );
}

export default Stars;