import React from 'react';
import styled from 'styled-components'
import { Grid, Typography, Hidden } from '@material-ui/core';
import translate from '../../locales/translate'; 

const StatementRow = styled(Grid)`
  padding: 12px 12px;
  margin-bottom: 12px;
  /* background-color: ${props => props.state === 'positive' ? 'rgba(104,159,56, 0.4)' : 'rgba(246,181,193, 0.3)'}; */
  /* background-color: ${props => props.expired ? 'rgba(0,0,0, 0.1)' : ''}; */
  border-bottom: 1px solid lightgrey;
  border-radius: 3px;
  position: relative;
  opacity: ${props => props.expired ? '0.5' : '1'};
  transition: all 0.5s;
  & .MuiGrid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    * {
      text-align: center;
    }
    @media only screen and (max-width: 960px) {
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      justify-content: flex-start;
      * {
        text-align: left;
      }
    }
  }
  & .MuiTypography-root {
    text-decoration: ${props => props.expired ? 'line-through' : ''};
  }
  &:hover {
    box-shadow: 0px 0px 5px grey;
    cursor: pointer;
  }
`

const Statement = ({ statement = {}, index }) => {
  
  const verifyBackgroundColor = () => {
    if(statement.expired){
      return 'rgba(0,0,0, 0.1)';
    } else if(statement.amount > 0) {
      return 'rgba(104,159,56, 0.4)';
    } else {
      return 'rgba(246,181,193, 0.3)';
    }
  }
  return (
    <StatementRow
      container
      style={{ backgroundColor: verifyBackgroundColor() }}
      // state={statement.amount > 0 ? 'positive' : 'negative'}
      // expired={statement.expired}
    >
      <Hidden mdUp>
        <Grid item xs={4}>
          <Typography variant="body2" className="text-bold">
            {translate("pointsScreen.section2.col1")}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={8} md={3}>
        <Typography variant="body2">
          {statement.description}
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={4}>
          <Typography variant="body2" className="text-bold">
            {translate("pointsScreen.section2.col2")}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={8} md={3}>
        <Typography variant="body2">
          {statement.amount} pontos {statement.expired ? '(expirado)' : ''}
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={4}>
          <Typography variant="body2" className="text-bold">
            {translate("pointsScreen.section2.col3")}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={8} md={3}>
        <Typography variant="body2">
          {statement.createdAt}
        </Typography>
      </Grid>
      <Hidden mdUp>
        <Grid item xs={4}>
          <Typography variant="body2" className="text-bold">
            {translate("pointsScreen.section2.col4")}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={8} md={3}>
        <Typography variant="body2">
          {statement.amount > 0 ? statement.expireAt : '-'}
        </Typography>
      </Grid>
    </StatementRow>
  );
}

export default Statement;