import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ApiService from "../helpers/ApiService";

// Language Setup
const checkLangBrowser = navigator.language || navigator.userLanguage;
let langBrowser;

if (checkLangBrowser) {
  if (checkLangBrowser === "pt-BR" || "pt") {
    langBrowser = "pt-BR";
  } else if (
    checkLangBrowser === "en" ||
    "en-US" ||
    "en-ie" ||
    "en-GB" ||
    "el"
  ) {
    langBrowser = "en-US";
  }
} else {
  langBrowser = "en-US";
}
//

// langBrowser = 'en-ie'

const initialState = {
  isLogged: false,
  user: {},
  token: null,
  alert: {
    color: "",
    message: "",
  },
  blog: {
    textFilter: null,
    tagsSelecteds: [],
  },
  // language: langBrowser,
  language: 'pt-BR',
  pagination: "start",
  helmetContent: [], 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      ApiService.updateToken(action.token);
      return { ...state, token: action.token };
    case "SET_USER":      
      return { ...state, user: action.user };      
    case "ALERT":
      return { ...state, alert: action.alert };
    case "CLOSE_ALERT":
      return { ...state, alert: { color: "", message: "" } };
    case "UPDATE_BLOG_FILTER":
      return { ...state, blog: { ...state.blog, textFilter: action.filter } };
    case "UPDATE_BLOG_TAGS":
      return {
        ...state,
        blog: { ...state.blog, tagsSelecteds: action.selected },
      };
    case "UPDATE_LANGUAGE":
      return { ...state, language: action.language };
    case "UPDATE_PAGINATION":
      return { ...state, pagination: action.pagination };
    case "UPDATE_HELMET":
      return { ...state, helmetContent: action.helmetContent };
    default:
      return state;
  }
};

export const initializeStore = (preloadedState = initialState) => {
  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  );
};
