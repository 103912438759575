import React from "react";
import { useDispatch} from "react-redux";
import { Button, Grid } from '@material-ui/core';
// import ReactCountryFlag from "react-country-flag";
import translate from '../../../locales/translate';
import styled from 'styled-components';

const StyledContainer = styled.div`
    margin-left: auto;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 10px 5px;
`

const StyledButton = styled(Button)`
    padding: .5rem;

    & span{
        max-height: 10px;
    }
`

const StyledP = styled.p`
    margin-left: .5rem;
    text-transform: initial;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.01071em;
`

const StyledSubTitle = styled.p`
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
    padding-left: 15px;
`

const Flags = props => {
  const dispatch = useDispatch();

  const changeLanguage = (language) => {
    dispatch({type: 'UPDATE_LANGUAGE', language: 'pt-BR'})
  }



  return(
    <StyledContainer>
        <Grid row="true">
            <StyledSubTitle>{translate("footer.language")}</StyledSubTitle>
        </Grid>
        <Grid row="true">
            <StyledButton
            onClick={ () => changeLanguage("en-US")}
            >
                {/* <ReactCountryFlag countryCode="US" svg style={{width: '1em', height: '1em',}} title="US" /> */}
                <StyledP>English</StyledP>
            </StyledButton>
        </Grid>
        <Grid row="true">
            <StyledButton
            onClick={() => changeLanguage("es")}
            >
                {/* <ReactCountryFlag countryCode="ES" svg style={{width: '1em', height: '1em',}} title="ES" /> */}
                <StyledP>Español</StyledP>
            </StyledButton>  
        </Grid>
        <Grid row="true">
            <StyledButton
            onClick={() => changeLanguage("pt-BR")}
            >
                {/* <ReactCountryFlag countryCode="BR" svg style={{width: '1em', height: '1em',}} title="BR" /> */}
                <StyledP>Português (BR)</StyledP>
            </StyledButton>  
        </Grid>
    </StyledContainer>
)};

export default Flags;