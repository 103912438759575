import React from 'react';
import DefaultLayout from '../../layout/default';
import CampaignPageListing from './components/PageListing';

const Campanhas = () => {

    return (
        <DefaultLayout>
            <CampaignPageListing />
        </DefaultLayout>
    );
}

export default Campanhas;