import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MissionCard from '../../../components/missions/Card';
import translate from '../../../locales/translate';

const FixedMissions = () => {
    return (
        <Grid container spacing={2} style={{ marginTop: 18 }}>
            <Grid item xs={12}>
                <Typography variant="caption" className="dark--text">
                    {translate('ss.subtitle.fixedmissions')}
                </Typography>
                <hr></hr>
            </Grid>
            <Grid item xs={12} md={4}>
                <MissionCard mission={createMissions(1)} />
            </Grid>
            <Grid item xs={12} md={4}>
                <MissionCard mission={createMissions(2)} />
            </Grid>
            <Grid item xs={12} md={4}>
                <MissionCard mission={createMissions(3)} />
            </Grid>
        </Grid>
    );
}

const createMissions = (index) => {
    var mission = {
        active: 1,
        points: translate("ss.earn.more.points.button.text"),
        target: "_self"
    };

    switch (index) {
        case 1:
            mission['image'] = "https://blds-bigtesterv2.s3.amazonaws.com/images/missions/perfil01092020.jpg";
            mission['link'] = "/perfil";
            mission['name'] = translate('ss.name.fixedmission1');
        break;
        case 2:
            mission['image'] = "https://blds-bigtesterv2.s3.amazonaws.com/images/missions/ig01092020.jpg";
            mission['link'] = "/perfil";
            mission['name'] = translate('ss.name.fixedmission2');
        break;
        case 3:
            mission['image'] = "https://blds-bigtesterv2.s3.amazonaws.com/images/missions/gratis01092020.jpg";
            mission['link'] = "/ofertas";
            mission['name'] = translate('ss.name.fixedmission3');
        break;
        default:
            mission.active = 0;
    }
    return mission;
}

export default FixedMissions;