import React from 'react';
import ScrollTo from 'scroll-to-element'

import { Grid, Typography, Button } from '@material-ui/core';
import translate from '../../../../locales/translate';
import List from './List'

import styled from 'styled-components'

const GridContainer = styled(Grid)`
    padding: 48px !important;
    min-height: 80vh;
    margin: unset;
    @media screen and (max-width: 960px) {
        padding: 24px !important;
        width: 80%;              
        margin: 0 auto;
        .MuiCardMedia-root {
            background-size: cover;
        }
    }
`

const Products = () => {
    const handleScrollRegister = () => {
        ScrollTo('body', {
            duration: 500
        })
    }

    return (
        <GridContainer container id="productsTop">
            <Grid item xs={12}>
                <Typography variant="caption" className="dark--text">
                    Bigtester
                </Typography>
                <Typography variant="h3" className="dark--text" style={{ fontWeight: 700 }}>
                    {translate('lp.product.title')}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 36, marginBottom: 36 }}>
                <List />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" className="secondary dark--text" onClick={() => { handleScrollRegister() }}>
                    {translate('lp.product.btn')}
                </Button>
            </Grid>
        </GridContainer>
    );
}

export default Products;