/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DefaultLayout from "../../layout/default";
import { Grid, Container, Typography } from "@material-ui/core";
import NewMissions from "./components/NewMissions";
import FixedMissions from "./components/FixedMissions";
import ActionCard from "./components/ActionCard";
import Product from "../../components/home/products/Product";
import Pixel from "../../components/common/Pixel";
import ApiService from "../../helpers/ApiService";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { withRedux } from "../../helpers/redux";
import translate from "../../locales/translate";
// import BtHowItWorks from "../../components/common/BtHowItWorks";

import { 
  mdiRocketLaunch, 
  mdiAlertDecagram, 
  mdiAccountMultiple,
  mdiTag
} from '@mdi/js';

const actions = [
  {
    title: translate("ss.dashboard.card1"),
    icon: mdiRocketLaunch,
    color: "var(--primary)",
    page: "/missoes",
  },
  {
    title: translate("ss.dashboard.card2"),
    icon: mdiAlertDecagram,
    color: "var(--success-hover)",
    page: "/campanhas-patrocinadas",
  },
  {
    title: translate("ss.dashboard.card3"),
    icon: mdiAccountMultiple,
    color: "var(--danger)",
    page: "/convites",
  },
  {
    title: translate("ss.dashboard.card4"),
    icon: mdiTag,
    color: "lightgrey",
    page: "/ofertas",
  },
];

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};

const Inicio = () => {
  const [products, setProduct] = React.useState([]);
  const [pixel, setPixel] = React.useState(false);
  const { user } = useStore();
  const dispatch = useDispatch();

  const getProducts = () => {
    ApiService.get("/products", {
      limit: 4,
    }).then(({ data }) => {
      setProduct(data.products);
      setPixel(true);
    });
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  React.useEffect(() => {
    getProducts();
    changePagination("start");
    changeHelmet([{ name: "start", content: "start content" }]);

    // alertDaily()
  }, []);

  return (
    <DefaultLayout>
      <Grid container style={{ backgroundColor: "white" }}>
        <Container maxWidth="lg">
          <Grid container style={{ marginTop: 24, marginBottom: 24 }}>
            <Grid item xs={12} md={12}>
              {/* <BtHowItWorks video="https://www.youtube.com/watch?v=BqMUEEMMS2U" /> */}
              <Typography variant="h4" className="dark--text">
                {translate("ss.title")} {user.fullName}
              </Typography>
            </Grid>
            <NewMissions />
            <Grid item xs={12} style={{ marginTop: 32, marginBottom: 16 }}>
              <Typography variant="caption" className="dark--text">
                {translate("ss.subtitle.dashboard")}
              </Typography>
              <hr></hr>
            </Grid>
            <Grid container spacing={2} justify="center">
              {actions.map((action, index) => (
                <Grid item xs={6} md={2} key={index}>
                  <ActionCard action={action} />
                </Grid>
              ))}
              <Grid item xs={12} />
              {products.map((product) => (
                <Grid item xs={12} md={3} key={product.id}>
                  <Product item={product} small height="auto" />
                </Grid>
              ))}
            </Grid>
            <FixedMissions />
            {pixel && <Pixel />}
          </Grid>
        </Container>
      </Grid>
    </DefaultLayout>
  );
};

export default withRedux(Inicio);
