import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import LoginLayout from "../../../layout/login";
import translate from "../../../locales/translate";
import styled from 'styled-components';
import NumberBox from './NumberBox';
import Paper from '@material-ui/core/Paper';

const YouTubeContainer = styled.div`
    position: relative;
    padding-bottom: 56% !important;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object, 
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    } 
`;

const CustomTable = styled(Table)`
    min-width: 650px;
`;

const CustomTableCell = styled(TableCell)`
    color: var(--darktext);
`;

const LuckyNumberID = () => {
    return(
        <LoginLayout>
            <Container maxWidth="lg">
            <Grid container spacing={2} style={{ paddingTop: 24, paddingBottom: 42 }}>
                <Grid item md={12} xs={12}>
                    <Typography variant="h4" className="dark--text text-bold">
                        {translate("ln.title")}
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <YouTubeContainer>
                        <iframe
                        title="youtube"
                        src={`https://youtube.com/embed/1234565432`}
                        frameBorder="0"
                        allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </YouTubeContainer>
                </Grid>
                <Grid item md={6} xs={12}>
                    <NumberBox />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className="dark--text text-bold" align="center">
                        Você possui <span className="primary--text text-bold" style={{fontSize: "1.8rem"}}>4</span> cupons para concorrer a 01 Onix 2021 Zero km
                    </Typography>
                </Grid>
                <Grid item md={1} />
                <Grid item md={10} xs={12}>
                    <TableContainer component={Paper}>
                        <CustomTable aria-label="simple table">
                            <TableHead>
                                <TableRow style={{backgroundColor: "#fafafa"}}>
                                    <CustomTableCell>Data de cadastro</CustomTableCell>
                                    <CustomTableCell>Código</CustomTableCell>
                                    <CustomTableCell align="left">Número da Sorte</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell>01/01/2021</CustomTableCell>
                                    <CustomTableCell>2222</CustomTableCell>
                                    <CustomTableCell align="left">123.45678.910</CustomTableCell>
                                </TableRow>
                            </TableBody>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell>01/01/2021</CustomTableCell>
                                    <CustomTableCell>2222</CustomTableCell>
                                    <CustomTableCell align="left">123.45678.910</CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </CustomTable>
                    </TableContainer>
                </Grid>
            </Grid>
            </Container>
        </LoginLayout>
    );
};

export default LuckyNumberID;