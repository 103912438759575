import React from 'react'
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../themes'
import { 
  BrowserRouter as Router,
  // useHistory
 } from "react-router-dom";
import ApiService from '../helpers/ApiService';
import { withRedux } from '../helpers/redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import '../themes/main.css'
import NavBar from '../components/common/navbar';
import Footer from '../components/common/Footer';
import { Grid, CircularProgress, Box, Dialog } from '@material-ui/core';
import BtLogo from '../components/common/BtLogo';
import BtAlert from '../components/common/BtAlert';
import TopBar from '../components/campaigns/TopBar';
import Analytics from '../helpers/AnalyticsService';

const useStore = () => {
  return useSelector(
    state => ({
      user: state.user || {}
    }),
    shallowEqual
  )
}

const LoginLayout = ({ children }) => {
  const [loading, setLoading] = React.useState(true)
  const dispatch = useDispatch()
  const { user } = useStore()

  // Router.events.on('routeChangeStart', url => {
  //   setLoading(true)
  // })

  // Router.events.on('routeChangeComplete', url => {
  //   setLoading(false)
  //   const page = url.substr(1).replace(/-/g, ' ')
  //   document.title = page.charAt(0).toUpperCase() + page.substr(1) + ' - Big Tester'
  // })

  const authInitialize = async () => {
    if (!user.id) {
      const user = await ApiService.autoLogin()

      if (user) {
        dispatch({
          type: 'SET_USER',
          user,
        })
      }
    }

    makeAnalytics()
  }

  const makeAnalytics = () => {
    const analytics = new Analytics(user.id)
    analytics.registerPage(Router.route)
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (loading) setLoading(false)
    }, 2000)
    authInitialize()
  }
  // , [Router]
  )

  const Page = () => (
    <ThemeProvider theme={Theme}>
      <Grid container style={{ display: 'flex', alignItems: 'flex-start', minHeight: '100vh' }}>
        <BtAlert />
        {user.id ? <NavBar /> : <TopBar />}
        {children}
        <Footer />
      </Grid>
    </ThemeProvider>
  )

  const LoadingScreen = () => (
    <Dialog open={loading} fullScreen style={{ backgroundColor: 'transparent' }}>
      <Grid container style={{ height: '100%' }} alignItems="center" justify="center">
        <Box style={{
          position: 'absolute',
          height: 180,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
          <BtLogo type="colored" style={{ margin: 'auto' }} />
        </Box>
        <CircularProgress className="primary--text" size={180} thickness={4} />
      </Grid>
    </Dialog>
  )

  return loading ? <LoadingScreen /> : <Page />
}

export default withRedux(LoginLayout);