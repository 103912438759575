import React from 'react';
import translate from "../../../locales/translate";
import { Container, Grid, Typography } from '@material-ui/core';
// import BtHowItWorks from "../../../components/common/BtHowItWorks";
import LuckyNumberCard from "./Card";


const LuckyNumberListing = () => {

    const ReturnCard = (dataCardNumber) => {
        return(
            <Grid item md={4} xs={12}>
                <LuckyNumberCard data={dataCardNumber} />
            </Grid>
        );
    };

    let apiReturn = [{
        "id":"1",
        "title": "Teste",
        "description":"description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed eros mattis, lobortis nunc id, ultricies velit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. ",
        "active": 1,
        "image": "http://lorempixel.com/640/480",
    },{
        "id":"2",
        "title": "Teste ativo",
        "description":"description ativo",
        "active": 1,
        "image": "http://lorempixel.com/640/480"
    },{
        "id":"4",
        "title": "Teste ativo",
        "description":"description ativo",
        "active": 1,
        "image": "http://lorempixel.com/640/480"
    },
    {
        "id":"3",
        "title": "Teste2",
        "description":"description 2",
        "active": 0,
        "image": "http://lorempixel.com/640/480"
    }];
    return(
        <Container>
            <Grid container spacing={2} style={{ paddingTop: 24, paddingBottom: 42 }}>
                <Grid item md={12} xs={12}>
                    {/* <BtHowItWorks video="https://www.youtube.com/watch?v=BqMUEEMMS2U" /> */}
                    <Typography variant="h4" className="dark--text text-bold">
                        {translate("ln.title")}
                    </Typography>
                    <Typography variant="body2" className="dark--text">
                        {translate("ln.subtitle")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" className="dark--text text-bold">
                        Ativos
                    </Typography>
                </Grid>
                {apiReturn.map((cardNumber) => {
                    return cardNumber.active ? ReturnCard(cardNumber) : "";
                })}
                <Grid item xs={12}>
                    <Typography variant="h5" className="dark--text text-bold">
                        Passados
                    </Typography>
                </Grid>
                {apiReturn.map((cardNumber) => {
                    return !cardNumber.active ? ReturnCard(cardNumber) : "";
                })}
            </Grid>
        </Container>
    )
};

export default LuckyNumberListing;