import React from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import styled from "styled-components";
import { SearchZip } from "../../../helpers/zip_code";
import { withRedux } from "../../../helpers/redux";
import { useDispatch } from "react-redux";
import { States } from "../../../helpers/Contansts";
import translate from "../../../locales/translate";
import { FormattedMessage } from "react-intl";
import ScrollTo from 'scroll-to-element';

const RowContainer = styled(Grid)`
  border-bottom: 1px solid lightgrey;
  &:first-child {
    border-top: 1px solid lightgrey;
  }
  padding: 24px;
  @media screen and (max-width: 960px) {
    padding-left: 0;
    padding-right: 0;
  }
  display: flex;
  align-items: center;
`;

const Address = ({ data, style, onSave = () => {} }) => {
  const [addr, setAddr] = React.useState({ state: "AC" });
  const [postalCode, setPostalCode] = React.useState('');
  const [searching, setSearch] = React.useState(false);
  const [toggleFields, setToggleFields] = React.useState({
    city: false,
    state: false,
    neighborhood: false,
    street: false,
    complement : false,
    number: false
  });

  const dispatch = useDispatch();


  const handleAddressFormChange = ({target}) => {    
    target.name === 'postalcode' && setPostalCode(target.value)
    // o length tem que ser 9 incluindo "-"
    if (target.name === "postalcode" && target.value.length === 9) {
      setTimeout(() => {
        setSearch(true);
        // remove o - da string somente para fazer a busca no viacep
        SearchZip(target.value.replace('-','')).then( result => {
          setSearch(false);
          if (result) {
            let newData = { ...addr, [target.name]: target.value };
            newData.city = result.city;
            newData.state = result.state;
            newData.street = result.street && result.street !== '' ? result.street : '';
            newData.complement = result.complement && result.complement !== '' ? result.complement : '';
            newData.number = result.number && result.number !== '' ? result.number : '';
            newData.neighborhood = result.neighborhood && result.neighborhood !== '' ? result.neighborhood : '';
            newData.extraAddrInfo = result.street && result.street !== '' ? true : false;
            setAddr(newData);
            
            // trava ou nao os campos
            const fields = {
              city: result.city ? true : false,
              state: result.state? true: false,
              neighborhood: result.neighborhood ? true : false,
              street: newData.extraAddrInfo,
              complement : false,
              number: false
            }
            setToggleFields(fields);
          }
          if (!result.city) {
            dispatch({
              type: "ALERT",
              alert: {
                color: "var(--primary)",
                message: translate("mas.dispatch.zip-code.notFound-message"),
              },
            });
          }
        })
      }, 500);  
    } else {
      const newData = { ...addr, [target.name]: target.value };
      setAddr(newData);
    }
  }

  // const handleInput = ({ target }) => {
  //   const pattern1 = /^[0-9]{8}$/
  //   const pattern2 = /^[0-9]{5}-[0-9]{3}$/
  //   //target.value.match(/^(([0-9]{9})||([0-9]{5}-[0-9]{4}))$/)
  //   if (
  //     target.name === "postalcode" &&
  //     target.value.match(/^(([0-9]{8})||([0-9]{5}-[0-9]{3}))$/)
  //   ) {     
  //     setSearch(true);
  //     SearchZip(target.value).then((result) => {
  //       setSearch(false);
  //       if (result) {
  //         let newData = { ...addr, [target.name]: target.value };
  //         newData.city = result.city;
  //         newData.state = result.state;
  //         newData.street = newData.street && newData.street !== '' ? newData.street : result.street;
  //         newData.complement = newData.complement && newData.complement !== '' ? newData.complement : result.complement;
  //         setAddr(newData);          
  //         setToggleFields(true);
  //       }
  //       if (!result.city) {
  //         dispatch({
  //           type: "ALERT",
  //           alert: {
  //             color: "var(--primary)",
  //             message: translate("mas.dispatch.zip-code.notFound-message"),
  //           },
  //         });
  //       }
  //     });
  //   } else {
  //     const newData = { ...addr, [target.name]: target.value };
  //     if (newData.postalcode && newData.postalcode !== '')
  //       setToggleFields(true);
  //     else
  //       setToggleFields(false);

  //     setAddr(newData);
  //   }
  // };

  const handleSave = () => {
    const fields = {
      city: addr.city ? true : false,
      state: addr.state? true: false,
      neighborhood: addr.neighborhood ? true : false,
      street:  addr.street ? true : false,
      complement : false,
      number: false
    };
   
    setToggleFields(fields);
    
    const saveData = {
      ...addr,
      postalcode: addr.postalcode.replace('-', '')
    };
    
    onSave({ address: saveData }, "Endereço");
  };

  React.useEffect(() => {
    if (data.postalcode){
      let suffix = data.postalcode.substr(data.postalcode.length - 3);
      let prefix = data.postalcode.replace(suffix,'');
      let zipCode = `${prefix}-${suffix}`;
      setAddr(data);
      setPostalCode(zipCode)
      // numero e complemento nao trava
      const fields = {
        city: data.city ? true : false,
        state: data.state ? true : false,
        neighborhood: data.neighborhood ? true : false,
        street: data.extraAddrInfo, 
        complement : false,
        number: false
      }
      setToggleFields(fields);
    } 
  }, [data]);

  React.useEffect(() => {
    let myURL = window.location.href;
    if ( myURL.indexOf("atualizar-endereco") > 0 ) {
      ScrollTo('#address', {
        offset: -72,
        duration: 1000
      }).on('end', () => {
        document.getElementById("postalcode").focus();
      });
    }

  },[]);

  return (
    <Paper className="dark--text" style={{ padding: 16, ...style }} id="address">
      <input type='hidden' value='prayer' /> {/** hack disable chrome autocomplete */}
      <Grid container>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          justify="space-between"
          style={{ minHeight: 100, paddingBottom: 12 }}
        >
          <Grid item>
            <Typography variant="h6" className="text-bold">
              {translate("mas.section3.title")}
            </Typography>
            <Typography variant="body2">
              {translate("mas.section3.subTitle")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.cep")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormattedMessage id="mas.section3.cepPlaceholder">
                {(translated) => (
                  <TextField
                    value={postalCode}
                    name="postalcode"
                    fullWidth
                    autoComplete="postalcode"
                    id="postalcode"
                    // type="number"
                    InputProps={{
                      endAdornment: <EditIcon className="primary--text" />,
                    }}
                    // resolve bug material ui textField maxlength com type='number' que não funciona
                    // detalhes: https://github.com/mui-org/material-ui/issues/5309
                    onInput = {(e) =>{                      
                      let { value } = e.target;
                      
                      if (!isNaN(value) && value.length <= 8) {                        
                        e.target.value = value;                         
                        if (value.length === 8) {
                          const suffix = value.substr(value.length - 3); 
                          const prefix = value.replace(suffix,'');
                          const zip = `${prefix}-${suffix}`; 
                          e.target.value = zip;
                        }
                      } else {
                        e.target.value = value.replace(/\D/g,'')
                      }
                                            
                    }}
                    onChange={handleAddressFormChange}
                    placeholder={String(translated)}
                    // obs deve permitir 9 char pq inclui o " - " do cep
                    inputProps={{ maxLength: 9, inputMode: "numeric"}}                    
                  />
                )}
              </FormattedMessage>
            </Grid>
            {searching && (
              <Grid item xs={12} md={1} style={{ textAlign: "center" }}>
                <CircularProgress className="primary--text" />
              </Grid>
            )}
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.street")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={addr.street || ''}
                name="street"
                fullWidth
                autoComplete="street"
                disabled={searching || toggleFields.street}
                InputProps={{
                  endAdornment: <EditIcon className={toggleFields.street ? "light--text" : "primary--text"} />,
                }}
                onChange={handleAddressFormChange}
              />
            </Grid>
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.number")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={addr.number || ''}
                name="number"
                fullWidth
                autoComplete="number"
                disabled={searching || toggleFields.number}
                InputProps={{
                  endAdornment: <EditIcon className={toggleFields.number ? "light--text" : "primary--text"} />,                                                   
                }}
                inputProps={{
                  maxLength: 6
                }}                
                onChange={handleAddressFormChange}
              />
            </Grid>
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography
                variant="body2"
                className="text-bold"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {translate("mas.section3.add")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={addr.complement || ''}
                name="complement"
                fullWidth
                autoComplete="complement"
                disabled={searching || toggleFields.complement}
                InputProps={{
                  endAdornment: <EditIcon className={toggleFields.complement ? "light--text" : "primary--text"} />,
                }}
                onChange={handleAddressFormChange}
              />
            </Grid>
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.district")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                value={addr.neighborhood || ''}
                name="neighborhood"
                fullWidth
                autoComplete="neighborhood"
                disabled={searching || toggleFields.neighborhood}
                InputProps={{
                  endAdornment: <EditIcon className={toggleFields.neighborhood ? "light--text" : "primary--text"} />,
                }}
                onChange={handleAddressFormChange}
              />
            </Grid>
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.city")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
            {/*searching*/}
              <TextField
                value={addr.city || ''}
                name="city"
                fullWidth
                autoComplete="city"
                disabled={toggleFields.city}                
                InputProps={{
                  endAdornment: <EditIcon className={toggleFields.city ? "light--text" : "primary--text"} />,
                }}
                onChange={handleAddressFormChange}
              />
            </Grid>
          </RowContainer>
          <RowContainer container>
            <Grid item xs={4} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("mas.section3.state")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="state"
                value={addr.state}
                fullWidth
                disabled={toggleFields.state}
                onChange={handleAddressFormChange}
              >
                {States.map((state) => (
                  <MenuItem key={`state-${state.value}`} value={state.value}>
                    {state.text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </RowContainer>
        </Grid>
      </Grid>
      <Button
        fullWidth
        className="success"
        size="large"
        style={{ marginTop: 24 }}
        onClick={handleSave}
      >
        {translate("mas.section3.btn")}
      </Button>
    </Paper>
  );
};

export default withRedux(Address);
