import React from 'react'
import DefaultLayout from '../../layout/default';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BgError404 from '../../img/bg-error-404.svg';
import translate from "../../locales/translate";

const Section = styled.section`
  width: 100%;
  max-width: 900px;
  margin: 1rem auto;
  padding: 2rem;
  min-height: 60vh;
  background: url(${props => props.bg}) no-repeat right center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  br {display: none;}

  @media (max-width: 767px) {
    background: none;
    width: auto;
    br {display: block;}
  }
`;

const StyledLink = styled(Link)`
  color: var(--darktext);
  border-bottom: 2px solid var(--darktext);
  transition: all 200ms;
  &:hover {
    color: var(--primary);
  border-bottom: 2px solid var(--primary);
  }
`;

const NotFoundPage = () => {
  return (
    <DefaultLayout >
      <Section bg={BgError404}>
        <div>
          <Typography variant="h2" className="dark--text text-bold" gutterBottom="true">
            {translate('notFound.title')}
          </Typography>
          <Typography variant="h4" className="dark--text text-bold" gutterBottom="true"> 
            {translate('notFound.subtitle')}
          </Typography>
          <Typography variant="h5" className="dark--text" gutterBottom="true"> 
            {translate('notFound.backMessage')} <br />
            <StyledLink to="/inicio">
              {translate('notFound.backLink')}
            </StyledLink>
            ?
          </Typography>

          <Typography variant="body1" className="dark--text">
            {translate('notFound.errorMessage')}
          </Typography>
        </div>
      </Section>
    </DefaultLayout>
  )
}

export default NotFoundPage;
