// import ReactGA from 'react-ga'
// import ApiService from './ApiService';

class Analytics {
  // constructor(userID) {
  //   ReactGA.initialize(process.env.ANALYTICS_ID || 'G-H53VRZVPJD', {
  //     debug: true,
  //     titleCase: false,
  //     gaOptions: {
  //       userId: userID || ApiService.user.id || 0
  //     }
  //   })
  // }

  registerPage(page) {    
    // ReactGA.pageview(page)
  }

  registerEvent(eventData) {    
    // ReactGA.event(eventData)
  }
}

export default Analytics