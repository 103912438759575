import React from 'react';
import { Grid, Container, Button, Dialog } from '@material-ui/core';
import BtLogo from '../common/BtLogo';
import Login from '../auth';
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

const CloseBtn = styled(Button)`
    position: fixed !important;
    width: 30px !important;
    height: 36px !important;
    top: 8px;
    right: 8px;
    color: white;
    border-radius: 50% !important;
`

const TopBar = () => {
  const [modal, setModal] = React.useState(false)
  let history = useHistory();

  const handleModalEvent = () => {
    setModal(!modal)
  }
  

  return (
    <Grid container>
      <Grid item xs={12} style={{ backgroundColor: 'var(--primary)', height: 100 }}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" style={{ height: 100 }}>
            <Grid item xs={6}>
              <BtLogo />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button size="large" style={{ color: 'white', marginRight: 32 }} onClick={() => { history.push('/') }}>
                ir para home
              </Button>
              <Button className="success" size="large" onClick={() => { handleModalEvent() }}>
                Entrar
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
          <CloseBtn size="small" onClick={handleModalEvent}>
            <CloseIcon className="white--text" />
          </CloseBtn>
          <Login reload={false} close={() => { setModal(false) }} />
        </Dialog>
      </Grid>
    </Grid >
  );
}

export default TopBar;