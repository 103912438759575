import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ActionCard from '../dashboard/ActionCard';
import translate from '../../locales/translate';

const actions = [
    {
        title: translate('card1.title'),
        description: translate('card1.text'),
        icon: {
            font: null,
            type: 'new_releases'
        },
        color: 'var(--mission-box1)',
        page: '/campanhas-patrocinadas'
    },
    {
        title: translate('card2.title'),
        description: translate('card2.text'),
        icon: {
            font: null,
            type: 'people'
        },
        color: 'var(--mission-box2)',
        page: '/convites'
    },
    {
        title: translate('card3.title'),
        description: translate('card3.text'),
        icon: {
            font: null,
            type: 'local_offer'
        },
        color: 'var(--mission-box3)',
        page: '/ofertas'
    },
    {
        title: translate('card4.title'),
        description: translate('card4.text'),
        icon: {
            font: null,
            type: 'question_answer'
        },
        color: 'var(--mission-box4)',
        page: '/perfil'
    }
]

const GainMore = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: 24 }}>
                <Typography variant="caption" className="dark--text">
                    {translate('cards.majorTitle')}
                </Typography>
                <hr></hr>
            </Grid>
            {actions.map((action, index) => (
                <Grid item xs={12} md={3} key={index}>
                    <ActionCard action={action} extended />
                </Grid>
            ))}
        </Grid>
    );
}

export default GainMore;