import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import styled from 'styled-components';
import Login from './Login';
import translate from '../../locales/translate';
import CloseIcon from '@material-ui/icons/Close';

const CloseBtn = styled(Button)`
    position: fixed !important;
    width: 30px !important;
    height: 36px !important;
    top: 8px;
    right: 8px;
    color: white;
    border-radius: 50% !important;
`

const LoginContainer = styled.div`
    margin: 24px;
`
const LoginBtn = styled(Button)`
    margin-left: 20px !important;
    color: white !important;
`
const LoginText = styled.span`
    color: white !important;
`

const HaveLogin = () => {
    const [modal, setModal] = useState(false);

    const handleModalEvent = event => {
        setModal(!modal)
    }

    const close = () => {
        setModal(false);
    }

    return (
        <LoginContainer>
            <LoginText>
                {translate("lp.login.havelogin")}
            </LoginText>
            <LoginBtn
                variant="contained"
                size="small"
                className="success"
                onClick={handleModalEvent}>
                {translate('lp.navbar.btn')}
            </LoginBtn>
            <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
                <CloseBtn size="small" onClick={handleModalEvent}>
                    <CloseIcon className="white--text" />
                </CloseBtn>
                <Login close={close}/>
            </Dialog>
        </LoginContainer>
    );
}

export default HaveLogin;