import React from "react";
import { Breadcrumbs, Link } from "@material-ui/core";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  & li:not(:last-child) a {
    color: var(--primary) !important;
  }
  & li:last-child a {
    text-decoration: none !important;
    color: var(--darktet) !important;
    cursor: inherit;
  }
`;

// Props "auto" = Gerar breadcrumbs pela URL que o componente se encontra.
// Não recomendado em caso de página com ID (ex: produtos/2).
// Em caso de ID, recomendado usar a opção customizada pela props "items"

const BigTesterBreadcrumb = ({
  items = [{ text: "", page: "" }],
  auto = false,
}) => {
  const router = useLocation();
  const [autoLinks, setAutoLinks] = React.useState([]);

  const makeBreakcrumbs = () => {
    setAutoLinks(
      router.pathname
        .split("/")
        .filter((r) => r && r !== "/")
        .map((r, index, original) => ({
          text: r[0].toUpperCase() + r.substr(1),
          page: "/" + original.filter((o, i) => i <= index).join("/"),
        }))
    );
  };

  React.useEffect(() => {
    if (auto) makeBreakcrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <StyledBreadcrumbs separator=">">
      {auto
        ? autoLinks.map((link, index) => (
            <Link href={link.page} key={`link-${index}`}>
              {link.text}
            </Link>
          ))
        : items.map((item, index) => (
            <Link href={item.page} key={`link-${index}`}>
              {item.text}
            </Link>
          ))}
    </StyledBreadcrumbs>
  );
};

export default BigTesterBreadcrumb;
