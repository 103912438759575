import React from 'react';
import DefaultLayout from '../../../layout/default';
import MarketPageComponent from '../../../components/products/market/MarketPageComponent';

const ProductsPageEvaluate = () => {
    return (
        <DefaultLayout>
            <MarketPageComponent />
        </DefaultLayout>
    );
}

export default ProductsPageEvaluate;