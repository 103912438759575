/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { withRedux } from "../../helpers/redux";
import ApiService from "../../helpers/ApiService";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import translate from "../../locales/translate";
import * as translations from "../../locales/text";
import Swal from "sweetalert2";
import Pixel from "../../components/common/Pixel";
import "./register.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { ToastContainer, toast, cssTransition } from 'react-toastify';
import { parseFullName } from 'parse-full-name';

import CloseIcon from '@material-ui/icons/Close';

import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  CircularProgress,
  Dialog,
  DialogContent,
  Snackbar,
  makeStyles,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import styled from "styled-components";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Analytics from "../../helpers/AnalyticsService";
import validator from "validar-telefone";

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root{
    background-color: #dd0033;
  }
`;

const Title = styled(Typography)`
  color: var(--darktext);
  font-weight: 700;
  @media screen and (max-width: 960px) {
    font-size: 18;
  }
`;

const StyledForm = styled.form`
  .MuiFormHelperText-root {
    color: var(--danger) !important;
  }

  .MuiTypography-caption {
    color: var(--danger)!important;
  }

  @media screen and (max-width: 960px) {
    min-width: 100vw;

    div:first-child {
      /* max-width: 90%; */
    }
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl{
    #birthdate {
      color: transparent !important;
    }
  }

  label[data-shrink="true"]~.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl {
    #birthdate {
      color: var(--darktext) !important;
    }
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.Mui-focused {
    #birthdate {
      color: var(--darktext) !important;
    }
  }
`;

const StyledText = styled(Typography)`
  color: var(--darktext);
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  @media screen and (max-width: 768px) {
    padding-bottom: 12px;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};

const Register = ({subdomain, emailDefault = ''}) => {
  const dispatch = useDispatch();
  const { language } = useStore();
  const { handleSubmit, register, errors, setError, clearErrors,setValue } = useForm();
  // const router = useRouter()
  const [loading, setLoading] = useState(false);
  const [lgpdChecked, setLgpdChecked] = useState(false);
  const [lgpdWarnMessage, setLgpdWarnMessage] = useState("");
  const [pixel, setPixel] = useState(false);
  const [pixelUrl, setPixelUrl] = useState('');
  const [userWantsContact, setUserWantsContact] = useState(false);
  const [minDataFormatada, setMinDataFormatada] = useState();
  const [maxDataFormatada, setMaxDataFormatada] = useState();
  let history = useHistory();
  const searchParams = useLocation().search;

  const [userAlreadyExistsInV1, setUserAlreadyExistsInV1] = useState(false);

  var RegisterInputTranslation = {
    errBirthday: translations.default[language]["lp.form.errBithday"],
    invalidFullName: translations.default[language]["lp.form.invalidFullName"],
    invalidBirthday: translations.default[language]["lp.form.invalidBirthday"],
    invalidCellphone:
      translations.default[language]["lp.form.invalidCellphone"],
    errCellphone: translations.default[language]["lp.form.errCellphone"],
    notPossible: translations.default[language]["lp.form.notPossible"],
    invalidEmail: translations.default[language]["lp.form.invalidEmail"],
    invalidCamp: translations.default[language]["lp.form.invalidCamp"],
    obrCamp: translations.default[language]["lp.form.obrCamp"],
    RegistrationSuccessMessageTitle:
      translations.default[language][
        "lp.form.successfullyRegisterMessageTitle"
      ],
    RegistrationSuccessMessage:
      translations.default[language]["lp.form.successfullyRegisterMessage"],
    dateParseError:
      translations.default[language]["lp.form.dateParseErrorMessage"],
    emailAlredyInUse: translations.default[language]["lp.form.emailAlredyInUse"],
    emailError: translations.default[language]["lp.form.emailError"],
    emailAlreadyExistsInLegacyV1: translations.default[language]["lp.form.register.error.409"]
  };

  var CustomformValuesErrors = [];

  const ageCalculator = () => {
    var minData = new Date();
    var maxData = new Date();
    var majority = language === 'en-US' ? 21 : 18
    // console.log(majority)
    minData.setFullYear(minData.getFullYear() - 100);
    maxData.setFullYear(maxData.getFullYear() - majority);
    minData.setHours(0,0,0,0);
    maxData.setHours(0,0,0,0);

    const calcMonth = (d) => {
      if (d.getMonth() >= 10) {
        return d.getMonth() + 1;
      } else {
        return "0" + (d.getMonth() + 1);
      }
    };

    let tempMinData =
      minData.getFullYear() +
      "-" +
      calcMonth(minData) +
      "-" +
      minData.getDate();
    let tempMaxData =
      maxData.getFullYear() +
      "-" +
      calcMonth(maxData) +
      "-" +
      maxData.getDate();

    setMinDataFormatada(tempMinData);
    setMaxDataFormatada(tempMaxData);
  };

  const urlParams = () => {
    let removeQuestionMark = searchParams.replace('?', '');
    let tempParams = removeQuestionMark.split('&');
    let objParams = {};
    tempParams.map((param) => {
      let splitParam = param.split('=');
      objParams[splitParam[0]] = splitParam[1];
    });
    Object.assign(objParams,{ language });
    //console.log('parametros',objParams)
    return(objParams)
  }

  const notify = (message,type) => {
    const msgId = 'messageId-1';
    const Slide = cssTransition({
      enter: 'zoomIn',
      exit: 'zoomOut',
    });

    if (type === 'default') {
      return (
        toast(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: msgId,
        })
      );
    } else {
      return (
        toast[type](message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          toastId: msgId,
        })
      );
    }
  };

  const handleRegister = async (user) => {
    if (!lgpdChecked) {
      setLoading(false);
      setLgpdWarnMessage(translate("lp.form.notice.terms"));
      setTimeout(() => {
        setLgpdWarnMessage("");
      }, 3500);
      return false;
    }

    dayjs.extend(utc);
  
    // eslint-disable-next-line no-unreachable
    let brazilianPhoneNumberValidation = 0;
    let date = new Date();
    try {
      if (language === "en-US") {
        let year = user.birthdate.split('/')[2];
        let month = user.birthdate.split('/')[0];
        let day = user.birthdate.split('/')[1];
        date = dayjs(`${year}-${month}-${day}`).utc().format()
        console.log(date)
      } else {
        date = dayjs(user.birthdate).utc().format();
      }
    } catch(e) {
      setError("birthdate", {
        type: "manual",
        message: RegisterInputTranslation.dateParseError,
      });
      CustomformValuesErrors.push(RegisterInputTranslation.dateParseError);
    }  
   

    setLoading(true);
    try {
      user.birthdate = dayjs(date).toDate().getTime();
      let majority = language === "en-US" ? 21 : 18;
      let userAge = new Date().getFullYear() - dayjs(date).toDate().getFullYear();
      if (userAge < majority && userAge > 0) {
        CustomformValuesErrors.push(
          RegisterInputTranslation.errBirthday + `${majority}`
        );
      } else if (userAge < majority && userAge <= 0) {
        CustomformValuesErrors.push(RegisterInputTranslation.invalidBirthday + ` - ${userAge}`);
      } else if (userAge > 100 ) {
        CustomformValuesErrors.push(RegisterInputTranslation.invalidBirthday);
      }

      // brazilian phone number validation
      if (language === "pt-BR") {
        brazilianPhoneNumberValidation = validator(user.cellphone, {
          permitirCelular: true,
        });
        if (brazilianPhoneNumberValidation === false) {
          setError("cellphone", {
            type: "manual",
            message: RegisterInputTranslation.invalidCellphone,
          });
          CustomformValuesErrors.push(
            RegisterInputTranslation.invalidCellphone
          );
        }
      }

      if (CustomformValuesErrors.length > 0) {
        CustomformValuesErrors.forEach((error) =>
          Swal.fire({
            icon: "error",
            text: error,
          })
        );
        throw new Error(CustomformValuesErrors);
      }
      user = {
        ...user,
        ...subdomain,
        userWantsContact
      };
       // tenta validar nome do usuario
      const userName = parseFullName(user.fullName);
      if (!userName.first || userName.last.length < 0) {
        setError("fullName", {
          type: "manual",
          message: RegisterInputTranslation.invalidFullName,
        });     
        CustomformValuesErrors.push(RegisterInputTranslation.invalidFullName);      
        setLoading(false);
        document.getElementById('fullName').focus();      
        return false;
      } 

      await ApiService.post("users/create", {
        ...user,
        params: urlParams(),
      }).then(( { data: { token, pixel, imgUrlPixel }, status }) => {

        //saltar Pixel de RPL
        if(pixel){
          setPixel(true);
          setPixelUrl(imgUrlPixel)
        }

       let title= RegisterInputTranslation.RegistrationSuccessMessageTitle;
       let text =  RegisterInputTranslation.RegistrationSuccessMessage;
        if(status === 204){
          title = 'Sucesso!';
          text = translate("lp.form.greetings");
        }else{
          title = RegisterInputTranslation.RegistrationSuccessMessageTitle;
          text = RegisterInputTranslation.RegistrationSuccessMessage;
        }
        // Swal.fire({
        //   title,
        //   text,
        //   confirmButtonColor: "var(--whatsapp-hover)",
        //   icon: "success",
        // }).then(
        (async () => {
            new Analytics().registerEvent({
              category: "Leads",
              action: "Register",
              label: user.email,
            });

            ApiService.updateToken(token);

            const userLogin = {
              email: user.email,
              cellphone: user.cellphone,
            };

            await ApiService.tryLogin(userLogin);
            await ApiService.autoLogin().then(() => {
              // setTimeout(() => {
                history.push("/sucesso");
              // }, 1500);
            });

            setLoading(false);
          }
        )();
      });
    } catch (error) {
      let httpError = JSON.stringify(error);

      if (httpError.includes("400")) {
        CustomformValuesErrors.push(RegisterInputTranslation.notPossible);
      }

      if (httpError.includes("422")) {
        Swal.fire({
          title: '',
          text: RegisterInputTranslation.emailError,
          icon: 'error'
        });
        
        document.getElementById('email').focus();
      }

      if ( httpError.includes("409") ) {
        setUserAlreadyExistsInV1(true);
      }

      setLoading(false);

      dispatch({
        type: "ALERT",
        alert: {
          color: "var(--danger)",
          message: RegisterInputTranslation.notPossible,
        },
      });
    }

  };
  const handleChangeValidateMail = async (email) => {
    let re = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let validEmail = re.exec(email);
    if (validEmail) {
        await ApiService.post("validate-email", {
            email,
          })
          .then((response) => {
            // console.log(response.status)
            if (response && response.message && response.message.includes("406")) {
              notify(RegisterInputTranslation.invalidEmail,'error');
              document.getElementById('email').focus();
            }
          })
          .catch(error => {
            if (error && error.response && error.response.status && error.response.status === 422) {
              notify(RegisterInputTranslation.emailAlredyInUse,'warn');
              CustomformValuesErrors.push(RegisterInputTranslation.emailAlredyInUse);
              setError('email', {
                type: 'manual',
                message: RegisterInputTranslation.emailAlredyInUse
              });
            }

          });

    } else {
      CustomformValuesErrors.push(RegisterInputTranslation.invalidEmail);
    }
  };

  React.useEffect(() => {
    ageCalculator();
    //urlParams()
  }, []);

  return (
    <StyledForm id="register" onSubmit={handleSubmit(handleRegister)}>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        className='message'
      />

      <Card
        elevation={10}
        spacing={1}
        style={{ maxWidth: "90%", marginLeft: "auto", marginRight: "auto" }}
      >
        <CardContent style={{ marginBottom: "12px" }}>
          <Title variant="h5" align="center" className="cardTitle" paragraph>
            {translate("lp.form.title")}
          </Title>
          <Grid container justify="center" spacing={4}>
            <Grid
              item
              sm={10}
              xs={12}
              style={{ paddingTop: ".4rem", paddingBottom: ".4rem" }}
            >
              <FormattedMessage id="lp.form.name">
                {(translateName) => (
                  <TextField
                    label={translateName}
                    fullWidth
                    id="fullName"
                    name="fullName"
                    inputRef={register({ required: true })}
                    helperText={
                      errors.fullName &&
                      RegisterInputTranslation.invalidFullName
                    }
                    disabled={loading}
                  />
                )}
              </FormattedMessage>
            </Grid>
            {/* <Grid item xs={10}>
              <FormattedMessage id="lp.form.lastname">
                {(translateLastName) => (
                  <TextField
                    label={translateLastName}
                    fullWidth
                    name="lastName"
                    inputRef={register({ required: true })}
                    helperText={errors.lastName && RegisterInputTranslation.invalidCamp}
                    disabled={loading}
                  />
                )}
              </FormattedMessage>
            </Grid> */}
            <Grid
              item
              sm={10}
              xs={12}
              style={{ paddingTop: ".4rem", paddingBottom: ".4rem" }}
            >
              <TextField
                label="E-mail"
                fullWidth
                id="email"
                name="email"
                type="email"
                inputRef={register({
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                helperText={
                  errors.email && errors.email.message 
                  ? errors.email.message 
                  : (errors.email && RegisterInputTranslation.invalidEmail)
                }
                disabled={loading}
                onBlur={(event) => handleChangeValidateMail(event.target.value)}
                defaultValue={emailDefault}
              />
            </Grid>
            <Grid
              item
              sm={5}
              xs={6}
              style={{ paddingTop: ".4rem", paddingBottom: ".4rem" }}
            >
              {language === "pt-BR" ? (
                <FormattedMessage id="lp.form.phone">
                  {(cellphone) => (
                    <InputMask mask="(99) 99999-9999" disabled={loading}>
                      {(inputProps) => (
                        <TextField
                          disabled={loading}
                          label={cellphone}
                          fullWidth
                          name="cellphone"
                          inputRef={register({
                            required: true,
                            pattern: /^\([1-9]{2}\)\s[0-9]{5}-[0-9]{4}$/,
                          })}
                          inputProps={{ inputMode: "numeric" }}
                          helperText={
                            errors.cellphone &&
                            RegisterInputTranslation.invalidCellphone
                          }
                        />
                      )}
                    </InputMask>
                  )}
                </FormattedMessage>
              ) : (
                <FormattedMessage id="lp.form.phone">
                  {(cellphone) => (
                    <InputMask mask="(999) 999-9999" disabled={loading}>
                      {(inputProps) => (
                        <TextField
                          disabled={loading}
                          label={cellphone}
                          fullWidth
                          name="cellphone"
                          inputRef={register({
                            required: true,
                            pattern: /^\([1-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                          })}
                          inputProps={{ inputMode: "numeric" }}
                          helperText={
                            errors.cellphone &&
                            RegisterInputTranslation.invalidCellphone
                          }
                        />
                      )}
                    </InputMask>
                  )}
                </FormattedMessage>
              )}
            </Grid>
            <Grid
              item
              sm={5}
              xs={6}
              style={{ paddingTop: ".4rem", paddingBottom: ".4rem" }}
            >
              {language === "en-US" ? (
                <FormattedMessage id="lp.form.birthday">
                  {(birthdate) => (
                    <InputMask mask="99/99/9999" maskPlaceholder="mm/dd/yyyy" disabled={loading} onChange={({target})=> {
                      let dateStrArr = target.value.split("/");
                      let year = dateStrArr[2];
                      let month = dateStrArr[0];
                      let day = dateStrArr[1];
                      let dateStr = `${year}-${month}-${day}`;
                      if (year && year.charAt(0) !== '_' && year.charAt(3) !== '_') { 
                        dayjs.extend(utc);
                        let date = dayjs(dateStr).utc();                   
                        let min = dayjs(minDataFormatada).utc();
                        let max = dayjs(maxDataFormatada).utc();
                        // console.log(date.toDate(),'data en us')
                        if (
                          (date.toDate().getTime() < min.toDate().getTime()) ||
                          (date.toDate().getTime() > max.toDate().getTime()) ||
                          parseInt(month,10) > 12 || parseInt(day,10) > 31
                        ) {
                          setError("birthdate", {
                            type: "manual",
                            message: RegisterInputTranslation.invalidBirthday
                          });    
                        } else {                                             
                          clearErrors("birthdate");
                        }
                      } 
                    }}>
                      {(inputProps) => (
                        <TextField
                          disabled={loading}
                          label={birthdate}
                          fullWidth
                          name="birthdate"
                          placeHolder={inputProps.maskPlaceholder}
                          defaultValue=''
                          inputRef={register({
                            required: true,
                            // pattern: /^((0[1-9])|([1-2][0-9])|(3[0-1]))\/((0[1-9])|(1[0-2]))\/((19[0-9]{2})|(20[0-1][0-9]))$/
                            //pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                          })}
                          inputProps={{ inputMode: "numeric"}}  
                          InputProps={{
                            inputProps: {
                              min: minDataFormatada,
                              max: maxDataFormatada,
                            },
                          }}                                         
                          helperText={
                            errors.birthdate &&  errors.birthdate.message 
                            ? errors.birthdate.message 
                            : (errors.birthdate && RegisterInputTranslation.obrCamp)
                          }
                        />
                      )}
                    </InputMask>
                  )}
                </FormattedMessage>
              ) : (
                <FormattedMessage id="lp.form.birthday">
                  {(birthdate) => (
                    // <InputMask mask="99/99/9999" disabled={loading}>
                    //   {(inputProps) => (
                    <TextField
                      id="birthdate"
                      disabled={loading}
                      label={birthdate}
                      fullWidth
                      name="birthdate"
                      type="date"
                      inputRef={register({
                        required: true,
                        // pattern: /^((0[1-9])|([1-2][0-9])|(3[0-1]))\/((0[1-9])|(1[0-2]))\/((19[0-9]{2})|(20[0-1][0-9]))$/
                        //pattern: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/g,
                      })}
                      inputProps={{ inputMode: "numeric", placeholder: " " }}
                      InputProps={{
                        inputProps: {
                          min: minDataFormatada,
                          max: maxDataFormatada,
                        },
                      }}
                      onChange = {({target}) => {
                        // console.log(target.value)
                        if (target.value.length === 10 && target.value.charAt(0) !== '_' && target.value.charAt(3) !== '_' ) {
                          dayjs.extend(utc);                          
                          let date = dayjs(target.value).utc();
                          let min = dayjs(minDataFormatada).utc();
                          let max = dayjs(maxDataFormatada).utc();
                          if((date.toDate().getTime() < min.toDate().getTime()) || (date.toDate().getTime() > max.toDate().getTime())) {                           
                            setError("birthdate", {
                              type: "manual",
                              message: RegisterInputTranslation.invalidBirthday
                            });                            
                          }else  {
                            // console.log(maxDiff, date.toDate().getTime(), max.toDate().getTime())
                            // console.log(date.toDate(), max.toDate())
                            clearErrors("birthdate")
                          }                          
                        }
                      }}
                      helperText={
                        errors.birthdate &&  errors.birthdate.message &&  errors.birthdate.message !== "" 
                        ? errors.birthdate.message 
                        : (errors.birthdate && RegisterInputTranslation.obrCamp)
                      }
                    />                   
                                        
                    //   )}
                    // </InputMask>
                  )}
                </FormattedMessage>                  
              )}
            </Grid>

            {/* Password input removed to use only cell phone instead. */}
            {/* Commented on for future use if needed. */}
            {/* <Grid item xs={10}>
                            <TextField
                                label="Senha"
                                fullWidth
                                name="password"
                                inputRef={register({
                                    required: true
                                })}
                                helperText={errors.password && RegisterInputTranslation.invalidCamp}
                                type="password"
                                disabled={loading}
                            />
                        </Grid> */}

            <Grid
              item
              sm={10}
              xs={12}
              style={{
                textAlign: "center",
                paddingTop: ".6rem",
                paddingBottom: ".4rem",
              }}
            >
              <RadioGroup row style={{ display: "block" }}>
                <FormattedMessage id="lp.form.fem">
                  {(fem) => (
                    <FormControlLabel
                      value="F"
                      control={
                        <Radio
                          color="primary"
                          name="gender"
                          inputRef={register({ required: true })}
                          disabled={loading}
                        />
                      }
                      label={fem}
                      style={{ color: "#455a64" }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="lp.form.masc">
                  {(masc) => (
                    <FormControlLabel
                      value="M"
                      control={
                        <Radio
                          color="primary"
                          name="gender"
                          inputRef={register({ required: true })}
                          disabled={loading}
                        />
                      }
                      label={masc}
                      style={{ color: "#455a64" }}
                    />
                  )}
                </FormattedMessage>
              </RadioGroup>
              <Typography variant="caption">
                {errors.gender && RegisterInputTranslation.obrCamp}
              </Typography>
            </Grid>
            <Grid
              item
              sm={10}
              xs={12}
              style={{
                textAlign: "left",
                paddingTop: ".1rem",
                paddingBottom: ".1rem",
              }}
            >
              <FormGroup>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="checkedI"
                      onChange={() => {
                        setLgpdChecked(!lgpdChecked);
                      }}
                    />
                  }
                  label={
                    <StyledText variant="body1">
                      {translate("lp.form.terms1")}
                      <a href="/terms" target="_blank" rel="noreferrer">
                        {translate("lp.form.terms2")}
                      </a>
                      {translate("lp.form.terms3")}
                      <a
                        href="/privacy-and-terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translate("lp.form.terms4")}
                      </a>
                      .
                    </StyledText>
                  }
                ></StyledFormControlLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      name="userWantsContact"
                      onChange={(e) => {
                        setUserWantsContact(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <StyledText variant="body1">
                      {translate("lp.form.terms5")}.
                    </StyledText>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <Grid container alignItems="center" justify="center">
          <Typography variant="body1" style={{textAlign: "center"}}>
            <p style={{color:"var(--instagram-hover)"}}>
              { lgpdWarnMessage && lgpdWarnMessage !== "" && lgpdWarnMessage }
            </p>
          </Typography>       
        </Grid>
        <Button
          fullWidth
          variant="contained"
          size="large"
          className="secondary dark--text"
          type="submit"
          disabled={loading || lgpdWarnMessage !== ""}
        >
          {loading ? (
            <StyledProgress size={30} thickness={7} />
          ) : (
            translate("lp.form.btn")
          )}
          {pixel && <Pixel value={pixelUrl}/>}
        </Button>
      </Card>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={userAlreadyExistsInV1}
        message={RegisterInputTranslation.emailAlreadyExistsInLegacyV1}
        onClose={() => setUserAlreadyExistsInV1(false)}
        action={
          <CloseIcon onClick={() => setUserAlreadyExistsInV1(false)} style={{ cursor: 'pointer'}}>
            Fechar
          </CloseIcon>
        }
      />
    </StyledForm>
  );
};

const StyledProgress = styled(CircularProgress)`
  color: var(--darktext) !important;
`;

export default withRedux(Register);
