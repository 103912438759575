import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import styled from "styled-components";
import Formatter from "../../../helpers/Formatter";
import Analytics from "../../../helpers/AnalyticsService";
import translate from "../../../locales/translate";

const CustomCard = styled(Card)`
  position: relative;
  cursor: pointer;
`;

const OfferCard = ({ offer }) => {
  const handleOpenOffer = () => {
    new Analytics().registerEvent({
      category: "Offers",
      action: "Offer click",
      label: offer.id,
    });

    window.open(offer.link, "_blank");
  };

  return (
    <CustomCard>
      <CardMedia image={offer.image} style={{ height: 150 }} />
      <CardContent style={{ textAlign: "center", position: "relative" }}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} className="dark--text">
            <Typography
              variant="body1"
              align="center"
              className="dark--text "
              style={{
                height: 40,
                display: "table-cell",
                verticalAlign: "middle",
                width: "100vw",
                fontWeight: "bold",
              }}
            >
              {offer.title}
            </Typography>
            {offer.description && (
              <Typography variant="body2" align="center" style={{minHeight: 50}}>
                {Formatter.simplifyText(offer.description, 100)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Button
        fullWidth
        className="primary"
        style={{
          margin: "auto",
          textDecoration: "none",
          borderRadius: "0 0 4px 4px",
        }}
        onClick={handleOpenOffer}
      >
        <AddIcon style={{ marginRight: 8 }} />
        {translate("btn.knowMore")}
      </Button>
    </CustomCard>
  );
};

export default OfferCard;
