import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Grid, Typography, IconButton, Fade } from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Rating from "./Rating";
import ApiService from "../../../helpers/ApiService";
import translate from "../../../locales/translate";

const ArrowArea = styled(Grid)`
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    & button {
      position: absolute;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }
`;

const LastRatings = () => {
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);

  const handlePageChange = (page) => {
    if (page + 1 > list.length) setPage(0);
    else if (page < 0) setPage(list.length - 1);
    else setPage(page);
  };

  const getRatings = () => {
    ApiService.get("/last-ratings", {
      limit: 6,
    }).then(({ data }) => {
      setList(
        data.map((dat) => ({
          ...dat,
          date: new Date(dat.createdAt).toLocaleDateString("pt-BR"),
        }))
      );
    });
  };

  useEffect(() => {
    getRatings();

    let n = setTimeout(() => {
      handlePageChange(page + 1);
    }, 5000);

    return () => {
      clearTimeout(n);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastRatingsContent = () => (
    list.length > 0 ? 
    (
      <Grid
      container
      spacing={2}
      style={{
        backgroundColor: "white",
        padding: 24,
        margin: "unset",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h5"
          className="text-bold dark--text"
          style={{ marginBottom: 8 }}
        >
          {translate("pd.title.lastProducts")}
        </Typography>
      </Grid>
      <ArrowArea item xs={1} md>
        <IconButton
          style={{ margin: "auto" }}
          className="left"
          onClick={() => handlePageChange(page - 1)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
       </ArrowArea>
        <Grid item xs={10}>
          {list.map((item, index) => {
            return index === page ? (
              <Fade in={true} key={index} timeout={2000}>
                <div>
                  <Rating item={item} />
                </div>
              </Fade>
            ) : (
              ""
            );
          })}
        </Grid>
        <ArrowArea item xs={1} md>
          <IconButton
            style={{ margin: "auto" }}
            className="right"
            onClick={() => handlePageChange(page + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </ArrowArea>
      </Grid>
    ) :
    (
      <br/>
    )

    
  );

  return lastRatingsContent();
};

export default LastRatings;
