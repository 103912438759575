import React, { useState } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { Box, Typography, Button, Dialog } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Video from '../../../components/common/Video';
import translate from '../../../locales/translate';
import styled from 'styled-components'

const TopTitle = styled(Typography)`
    color: white;
    z-index: 1;
`

const HowItWorksBtn = styled(Button)`
    color: white !important;
    font-weight: 700;
`

const useStore = () => {
    return useSelector(
        state => ({
            language: state.language
        }),
        shallowEqual
    )
}

const Title = ({ text = '' }) => {
    const [modal, setModal] = useState(false)    
    const { language } = useStore()
   
    const handleModalEvent = event => {
        setModal(!modal)
    }

    return (
        <Box>
            <TopTitle variant="h3" paragraph align="center" style={{ fontWeight: 700 }}>
                {language === 'pt-BR' && (text || 'O maior site de teste de produtos da América Latina')}
                {(language === 'es' || language === 'en-US') &&  translate('lp.text')}
            </TopTitle>
            {/* { language === 'pt-BR' && <><Box align="center">
                <HowItWorksBtn
                    variant="contained"
                    size="large"
                    className="danger"
                    onClick={handleModalEvent}>
                    {translate('lp.btn.howWork')}
                    <PlayCircleFilledIcon style={{ marginLeft: '12px' }} />
                </HowItWorksBtn>
            </Box>
            <Dialog open={modal} onClose={handleModalEvent} maxWidth="md" fullWidth>
                <Video close={handleModalEvent} />
            </Dialog></>} */}
        </Box>
    );
}

export default Title;