import React, { useEffect, useState } from 'react'
import DefaultLayout from '../../layout/default';
import Layout from '../../layout/blank';
import TopMenu from '../TermsPage/components/top-menu-disconnected/Topmenu';
import { useSelector, shallowEqual } from "react-redux";
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import translate from "../../locales/translate";

//material ui
import { Typography } from '@material-ui/core';
import Questions from './components/Questions';
import FaqItem from './components/Faq';

const Section = styled.section`
  max-width: 1100px;
  width: 100%;
  padding: 1%.5rem;
  min-height: 100vh;
  margin: auto;

  .title {
    margin: 2rem 0;
  }

  @media (max-width: 600px) {
    max-width: 90vw;
    overflow-x: hidden;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};


const FaqPage = () => {
  const { user } = useStore();
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (user && Object.keys(user).length  === 0 && user.constructor === Object) {
      setIsLogged(false)
    } else {
      setIsLogged(true)
    }
  }, [user, isLogged]);

  const faqList = () => {
    return(
      <Grid item xs={12}>
        <Section>
          <Typography variant="h4" className="text-bold dark--text title" paragraph align="center">
            {translate("faq.title")}
          </Typography>

          {Questions.map((question, index) => (
            <FaqItem 
              key={index}
              title={question.title}
              content={question.content}
              isTable={question.isTable}
              headers={question.headers}
              data={question.data}
              index={index}
            />
          ))}
        </Section>
      </Grid>
    );
  };

  return (
    <>
      {isLogged ? (
        <DefaultLayout>
          {faqList()}
        </DefaultLayout>
      ) : (
        <Layout>
          <TopMenu/>
          {faqList()}
        </Layout>
      )}
    </>
  )
}

export default FaqPage;
