import React, { useEffect } from "react";

import { Grid, Container } from "@material-ui/core";

import Details from "../../components/products/Details";
import Ratings from "../../components/products/Ratings";
import ExtraProducts from "../../components/common/product/ExtraProducts";
import LastRatings from "../../components/common/product/LastRatings";
import DefaultLayout from "../../layout/default";
import { useParams } from "react-router-dom";
import ApiService from "../../helpers/ApiService";

const ProductID = () => {
  const router = useParams();
  const [product, setProduct] = React.useState({});
  const [alreadyHave, setAlreadyHave] = React.useState(false);

  const getProduct = () => {
    ApiService.get(`/products/${router.id}`).then(({ data }) => {
      setProduct({
        ...data,
        comments: data.comments.map((comment) => ({
          ...comment,
          date: new Date(comment.createdAt).toLocaleDateString("pt-BR"),
        })),
      });
      document.title = data.name + " - Big Tester";
    });
  };

  const checkIfHave = () => {
    ApiService.get("products/get-user-exchanged-products ").then(({ data }) => {
      // Checks if already have the product, if yes return true and set that to state
      data.find((e) => e.name === product.name)
        ? setAlreadyHave(true)
        : setAlreadyHave(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (router.id) getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    checkIfHave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <DefaultLayout>
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth="lg" style={{ padding: 0 }}>
            <Details product={product} haveTheProduct={alreadyHave} />
            <Ratings
              product={product}
              user={ApiService.user}
              update={getProduct}
            />
            <ExtraProducts category={product.category} />
            <LastRatings />
          </Container>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

export default ProductID;
