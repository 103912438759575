import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";

import Winners from "../Winners";

import styled from "styled-components";
import ApiService from "../../../helpers/ApiService";
import MarketList from "./List";
import Stores from "./Stores";

const StyledContainer = styled(Container)`
  min-height: 50vh;
  padding: unset !important;
  & #lastwinners {
    margin: auto;
  }
`;

const MarketPageComponent = () => {
  const [stores, setStores] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);

  const getStores = () => {
    ApiService.get("/stores")
      .then(({ data }) => {
        setStores(data);
      })
      .catch((err) => {
        console.log("Requisição da API com erro");
      });
  };

  const handleStoreSelected = (storeID) => {
    if (selectedStores.includes(storeID)) {
      let tmp = [...selectedStores];
      const idx = selectedStores.findIndex(
        (category) => category.id === storeID
      );
      tmp.splice(idx, 1);
      setSelectedStores(tmp);
    } else {
      setSelectedStores([...selectedStores, storeID]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStores();
    document.title = "Avalie produto e ganhe pontos! - BigTester";
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledContainer maxWidth="lg">
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={3} style={{ zIndex: 2, position: "relative" }}>
              <Stores
                stores={stores}
                selected={selectedStores}
                onSelect={handleStoreSelected}
              />
            </Grid>
            <Grid item xs={12} md={9} style={{ zIndex: 1 }}>
              <MarketList stores={selectedStores} />
              <Winners />
            </Grid>
          </Grid>
        </StyledContainer>
      </Grid>
    </Grid>
  );
};

export default MarketPageComponent;
