import React from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useSelector, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import ApiService from "../../helpers/ApiService";
import Analytics from "../../helpers/AnalyticsService";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import translate from "../../locales/translate";
import InputMask from "react-input-mask";

// const RegisterLink = styled(Typography)`
//   &:hover {
//     color: var(--primary);
//     text-decoration: underline;
//     cursor: pointer;
//   }
// `;

const CardTitle = styled(Typography)`
  color: var(--darktext);
  font-weight: 700;
`;

const StyledLink = styled.a`
  color: var(--darktext);
  &:hover {
    color: var(--primary);
    text-decoration: underline;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};

const Login = ({
  reload = true,
  changeMode = () => {},
  updateUser = () => {},
  close = () => {},
  handleForgotPhone = async () => {},
  forceReload = () => {}
}) => {
  const { handleSubmit, register, errors } = useForm();

  const [loginError, setLoginError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [switchTo, setSwitchTo] = React.useState(false);
  const [message, setMessage] = React.useState('');
 

  let history = useHistory();
  const { language } = useStore();

  const handleLogin = async (login) => {
    ///console.log(login, "dados login");
    if (login.cellphone && login.cellphone !== "") {
      setLoading(true);
      try {
        await ApiService.tryLogin(login);
        //console.log(login, "login");

        const user = await ApiService.autoLogin();

        new Analytics().registerEvent({
          category: "Leads",
          action: "Login",
          label: user.id,
        });

        if (reload) {
          history.push("/missoes");
        } else {
          updateUser(user);
          close();
        }
      } catch (error) {
        setLoginError(true);
      }
      setLoading(false);
    } else {
      setLoading(true);
      language && Object.assign(login, { language });
      changeMode("forgotPhone");      
      const returnValue = await handleForgotPhone(login);
      setMessage( 
        returnValue === 200 ? 
        translate("lp.login.forgotPhone.success") : 
        (returnValue === 204 ? translate("lp.login.forgotPhone.warning") : 
        (returnValue === 500 && translate("lp.login.forgotPhone.error")))
      );
      setTimeout(() => {
        setMessage("");
        if(returnValue === 200) {
          setSwitchTo(!switchTo);  
          setTimeout(() => {
            forceReload(true);
          }, 1500)                          
        }
      }, 3500);      
      setLoading(false);      
    }

    
  };

  const formDinamicContent = () => {
    const defaultFormContent = () => (
      <Grid item xs={12} style={{ marginBottom: 32, marginTop: 32 }}>
        <Grid item xs={12} style={{ marginTop: 12 }}>
          <CardTitle variant="body1" align="center">
            {translate("lp.login.cardTitle1")}
            <br />
            {translate("lp.login.cardTitle2")}
          </CardTitle>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="E-mail"
            fullWidth
            disabled={loading}
            name="email"
            type="email"
            helperText={errors.email && translate("lp.login.forgotPhone.warning")}
            inputRef={register({
              required: true,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormattedMessage id="lp.login.cellphone">
            {(t) => (
              <InputMask mask="(99) 99999-9999" disabled={loading}>
                {(inputProps) => (
                  <TextField
                    disabled={loading}
                    label={t}
                    fullWidth
                    name="cellphone"
                    inputRef={register({
                      required: true,
                      pattern: /^\([1-9]{2}\)\s[0-9]{5}-[0-9]{4}$/,
                    })}
                    inputProps={{ inputMode: "numeric" }}
                  />
                )}
              </InputMask>
            )}
          </FormattedMessage>
        </Grid>
        {loginError && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="caption" className="danger--text text-bolde">
              {translate("lp.login.wrong")}
            </Typography>
          </Grid>
        )}
      </Grid>
    );

    const forgotPhoneFormContent = () => (
      <Slide direction="right" in={true}>
        <Grid item xs={12} style={{ marginBottom: 32, marginTop: 32 }}>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <CardTitle variant="body1" align="center">
              {translate("lp.login.forgotPhone")}
              <br />
              {translate("lp.login.forgotPhone.message")}              
            </CardTitle>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              label="E-mail"
              fullWidth
              disabled={loading}
              name="email"
              type="email"
              helperText={errors.email && translate("lp.login.forgotPhone.warning")}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </Grid>
        </Grid>
      </Slide>
    );

    return switchTo ? forgotPhoneFormContent() : defaultFormContent();
  };

  const handleSwitchForm = () => {
    setSwitchTo(!switchTo);
  };

 
  return (
    <DialogContent>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            {formDinamicContent()}
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginBottom: 12 }}
            >
              <Typography variant="body1" paragraph style={{color: 'var(--danger)'}}>
                {message && message !== '' && message}  
              </Typography><br/>
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <StyledLink href onClick={handleSwitchForm}>
                {switchTo
                  ? translate("lp.login.forgotPhone.link.alternative")
                  : translate("lp.login.forgotPhone.link")}
              </StyledLink>
            </Grid>
            <FormattedMessage
              id={
                switchTo ? "lp.login.enterBtn.alternative" : "lp.login.enterBtn"
              }
            >
              {(t) =>
                
                  <Button
                    fullWidth
                    variant="contained"
                    className="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={25} style={{ color: "white" }} />
                    ) : (
                      t
                    )}
                  </Button>
                
              }
            </FormattedMessage>
          </Grid>
          <Grid item xs={12}>
            {/* <RegisterLink
              variant="body2"
              align="center"
              paragraph
              onClick={() => {
                changeMode("register");
              }}
            >
              {translate("lp.login.noRegisterQuestion")}
            </RegisterLink> */}
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );
};

export default Login;
