import React from "react";

import { Grid, Typography } from "@material-ui/core";
import translate from "../../../../locales/translate";
import DefaultBtns from "../../../../components/common/DefaultBtns";

const Texts = () => {
  return (
    <Grid container spacing={2} className="dark--text">
      <Grid item xs={12}>
        <Typography variant="h5">{translate("lp.lastW.subTitle.1")}</Typography>
        <Typography variant="h3" display="inline" className="text-bold">
          {translate("lp.lastW.subTitle.2")}
        </Typography>
        <Typography variant="h5" display="inline">
          {translate("lp.lastW.subTitle.3")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{translate("lp.lastW.text")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" className="text-bold">
          {translate("lp.lastW.subnum.1")}
        </Typography>
        <Typography variant="body1">
          {translate("lp.lastW.subtext.1")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DefaultBtns />
      </Grid>
    </Grid>
  );
};

export default Texts;
