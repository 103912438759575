import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const FaqWrapper = styled.div`
  height: auto;
  min-height: ${props => props.questionHeight+'px'}; // deixar como a altura da typografia escolhida pra pergunta
  margin: 1rem 0;
  padding: 2rem;
  background: #fff;
  border-radius: 0.5rem;
  position: relative;
  border: 2px solid ${props => props.opened ? 'var(--secondary)' : 'transparent'};
  transition: all 300ms;
  cursor: pointer;

  color: #333;

  &:hover {
    border: 2px solid var(--secondary);
    background: #fff;
  }

  .question {
    position: absolute;
    top: ${props => props.opened ? '1rem' : '2rem'};
    font-size: ${props => props.opened ? '1rem' : '1.2rem'};
    transition: all 300ms;
    border-bottom: 3px solid ${props => props.opened ? 'var(--secondary)' : 'transparent'}
  }
  .answer {
    margin-top: ${props => props.opened ? Number(props.questionHeight)+'px' : 0};
    opacity: ${props => props.opened ? 1 : 0};
    height: ${props => props.opened ? 'auto' : 0};
    line-height: 1.4;
    transition: all 300ms;
  }

  @media (max-width: 600px) {
    width: auto;

    .question {
      font-size: ${props => props.opened ? '1rem' : '1.1rem'};
      margin-right: 2rem;
    }

    .answer {
      margin-top: ${props => props.opened ? Number(props.questionHeight)+16+'px' : 0};
    }
  }
`;

const FaqItem = ({title, content, isTable = false, headers = [], data = []}, index) => {

  const [ isFaqActive, setIsFaqActive ] = React.useState(false);
  const [ questionHeight, setQuestionHeight ] = React.useState(0);

  React.useEffect( ()=>{
    const questionDivHeight = document.querySelector(`[index='${index}']`).offsetHeight;
    setQuestionHeight(questionDivHeight);
  }, [index]); 

  return (
    <FaqWrapper opened={isFaqActive} questionHeight={questionHeight} onClick={() => setIsFaqActive( isFaqActive => !isFaqActive )}>
      <div className="question" index={index}>
        {title}
      </div>
      {!isTable && 
        <div className="answer">
          {ReactHtmlParser(content)}
        </div>
      }
      {isTable && headers.length > 0 && data.length > 0 && (
        <div className="answer">
          {ReactHtmlParser(content)}
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                      <TableCell className="bold-text" key={index}>
                        {header}
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((line, index) => (
                    <TableRow key={index}>
                      <TableCell>{line.qty}</TableCell>
                      { line.qty === '-' ? (
                          <TableCell>
                            <center>{line.desc}</center>
                          </TableCell>
                        ) : (
                          <TableCell>
                            {line.desc}
                          </TableCell>
                        )
                      }
                      { line.value && <TableCell>{line.value}</TableCell> }
                      { line.total && <TableCell>{line.total}</TableCell> }
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>      
      )}
    </FaqWrapper>
  )
}

export default FaqItem;
