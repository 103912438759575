/* eslint-disable no-useless-escape */
import React, { useEffect } from "react";
import DefaultLayout from "../../layout/default";
import { Container, Grid, Hidden, Typography } from "@material-ui/core";
import ShareCard from "../../components/common/ShareCard";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { withRedux } from "../../helpers/redux";
import copy from "copy-to-clipboard";
import Analytics from "../../helpers/AnalyticsService";
import translate from "../../locales/translate";
import Box from "@material-ui/core/Box";
import BtHowItWorks from "../../components/common/BtHowItWorks";

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
      pagination: state.pagination,
    }),
    shallowEqual
  );
};

const Convites = () => {
  const { user } = useStore();
  const dispatch = useDispatch();

  const shareMessage =
    "Faça seu cadastro no Big Tester e ganhe produtos de graça!!! ";
  const shareLink = `${
    process.env.REACT_APP_APP_URL || "https://bigtester.com.br"
  }?invite_code=${user.referralKey}`;

  const handleShare = (social) => {
    if (!process.env.REACT_APP_APP_URL) return;
    
    new Analytics().registerEvent({
      category: "Invites",
      action: `Generate invite for ${social}`,
      label: user.id,
    });
    switch (social) {
      case "whatsapp":
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
          )
        ) {
          //window.open(`https://wa.me/?text=${encodeURIComponent(shareMessage)}${shareLink}`, '__blank');
          window.open(
            `whatsapp://send?text=${encodeURIComponent(
              shareMessage + shareLink
            )}`,
            "sharer",
            'data-action="share/whatsapp/share"'
          );
        } else {
          window.open(
            `https://web.whatsapp.com/send?text=${encodeURIComponent(
              shareMessage + shareLink
            )}`,
            "__blank"
          );
        }
        break;
      case "facebook":
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
          )
        ) {
          window.open(
            `fb-messenger://share?link=${encodeURIComponent(
              shareLink
            )}&app_id=${encodeURIComponent("1779104839040637")}`
          );
          
        } else {
          console.log(shareLink);
          window.open(`https://www.facebook.com/sharer/sharer.php?
                    &u=${shareLink}
                    &quote=${encodeURIComponent(shareMessage)}`);
        }
        break;
      case "twitter":
        window.open(
          `https://twitter.com/share?url=${shareLink}&text=Ganhe produtos grátis com o Big Tester! `,
          "__blank"
        );
        break;
      case "instagram":
        window.open(`https://instagram.com/big_tester/`, "__blank");
        break;
      case "share":
        if (navigator.share) {          
          navigator
            .share({
              title: shareMessage,
              url: shareLink,
            })
            .then(() => {
              dispatch({
                type: "ALERT",
                alert: {
                  color: "var(--primary)",
                  message: translate("ifs.shareSuccess"),
                },
              });
            });
        } else {
          // caso navegador não suporte api navigator.share 
          // principalmente em dispositivos móveis o bigtester
          // copia o link para área de transferência e emite aviso 
          const el = document.createElement('textarea');
          el.value = shareLink;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          dispatch({
            type: 'ALERT',
            alert: {
              color: 'var(--primary)',
              message: translate("ifs.LinkshareSuccess"),              
            }
          })
        }
        break;
      default:
        copy(shareLink);
        dispatch({
          type: "ALERT",
          alert: {
            color: "var(--primary)",
            message: translate("ifs.copied"),
          },
        });
        break;
    }
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  useEffect(() => {
    changePagination("invite");
    changeHelmet([{name: "invite", content: "invite content"}]);
  });

  return (
    <DefaultLayout>
      <Container
        maxWidth="lg"
        className="dark--text"
        style={{ backgroundColor: "white" }}
      >
        <Grid
          container
          spacing={2}
          style={{ paddingBottom: 42, paddingTop: 24 }}
        >
          <Grid item xs={12}>
            <BtHowItWorks video="https://www.youtube.com/watch?v=7AopQ3-GNqQ" />
            <Typography variant="h4" className="dark--text text-bold">
              {translate("ifs.title")}
            </Typography>
            <Typography variant="body2" className="dark--text">
              {translate("ifs.subTitle1")}
            </Typography>
            <Typography variant="body2">
              {translate("ifs.subTitle2.pt1")}{" "}
              <b>{translate("ifs.subTitle2.pt2")}</b>{" "}
              {translate("ifs.subTitle2.pt3")}
            </Typography>
          </Grid>
          <Hidden xsUp>
            <Grid item xs={12}>
              <ShareCard type="share" shareLink={() => handleShare("share")} />
            </Grid>
          </Hidden>
          <Grid
            container
            item
            xs={12}
            sm={8}
            justify="center"
            alignItems="center"
          >
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/img/inviteFriends.svg"}
              style={{ width: "60%" }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={4}>
              <ShareCard
                type="whatsapp"
                shareLink={() => handleShare("whatsapp")}
              />
              <ShareCard
                type="facebook"
                shareLink={() => handleShare("facebook")}
              />
              <ShareCard
                type="twitter"
                shareLink={() => handleShare("twitter")}
              />
              <ShareCard
                type="instagram"
                shareLink={() => handleShare("instagram")}
              />
              <ShareCard shareLink={() => handleShare("link")} />
            </Grid>
          </Hidden>

          <Hidden smUp>
            <ShareCard type="share" shareLink={() => handleShare("share")} />
          </Hidden>

          <Grid item xs={12}>
            <Typography variant="caption" className="light--text">
              <Box textAlign="center">{translate("ifs.footnotes")}</Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
};

export default withRedux(Convites);
