import axios from "axios";

export const SearchZip = async (zip) => {
  // eslint-disable-next-line no-useless-escape
  const formattedZip = zip.replace(/\-/g, "");

  try {
    const { data } = await axios.get(
      `https://viacep.com.br/ws/${formattedZip}/json/`
    );
    return {
      postalcode: data.cep,
      street: data.logradouro,
      number: "",
      complement: data.complemento,
      city: data.localidade,
      state: data.uf,
      neighborhood: data.bairro
    };
  } catch (error) {
    return {
      postalcode: zip,
      street: "",
      number: "",
      complement: "",
      city: "",
      state: "",
    };
  }
};
