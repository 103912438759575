import React from 'react';
// import { useHistory } from "react-router-dom";
// import Head from 'next/head';

import { Grid, Box, CircularProgress, Fade, Zoom } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../themes';
import '../themes/main.css';
import BtLogo from '../components/common/BtLogo';
import Analytics from '../helpers/AnalyticsService';

const HomeLayout = ({ children }) => {
  // let history = useHistory();

  const [loading, setLoading] = React.useState(false)

  // history.events.on('routeChangeStart', url => {
  //   setLoading(false)
  // })

  // history.events.on('routeChangeComplete', () => {
  //   setLoading(true)
  // })

  React.useEffect(() => {
    setTimeout(() => {
      if (!loading) setLoading(true)
    }, 2000);
    
    new Analytics().registerPage('home')
  })

  const Loading = () => (
    <Zoom in={!loading} timeout={500}>
      <Grid
        container
        style={{
          height: '100vh',
          alignItems: 'center',
        }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box style={{
            position: 'absolute',
            height: 180,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}>
            <BtLogo type="colored" style={{ margin: 'auto' }} />
          </Box>
          <CircularProgress className="primary--text" size={180} thickness={4} />
        </Grid>
      </Grid>
    </Zoom>
  )

  const Page = () => (
    <ThemeProvider theme={Theme}>
      <Fade in={loading} timeout={1000}>
        <Box>
          {children}
        </Box>
      </Fade>
    </ThemeProvider>
  )

  return loading ? <Page /> : <Loading />
}

export default HomeLayout;