import React from "react";
import styled from "styled-components";
import { Card, Grid, Typography, Box } from "@material-ui/core";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { useHistory } from "react-router-dom";

import Comment from "../../products/Comment";

const RatingCard = styled(Card)`
  color: var(--darktext);
  cursor: pointer;
  @media screen and (max-width: 960px) {
    padding: 8px;
  }
`;

const HighlightBox = styled(Box)`
  &&& {
    color: darkorange !important;
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;
    margin-top: 8px;
    height: 16px;
    @media screen and (max-width: 960px) {
      float: none;
      margin-bottom: 12px;
      margin-top: 0;
      padding-top: 0;
    }
  }
`;

const TextArea = styled(Grid)`
  @media screen and (max-width: 960px) {
    padding-top: 0 !important;
  }
`;

const imageClass = {
  alignSelf: "start",
  width: "100%",
};

const Rating = ({ item }) => {
  let history = useHistory();

  return (
    <RatingCard
      elevation={4}
      onClick={() => {
        history.push(`/produtos/${item.product.id}`);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <img
            src={item.product.image}
            alt={item.product.name}
            width="100%"
            height="100%"
          />
        </Grid>
        <TextArea item xs={12} md={8} style={{ padding: 24 }}>
          {item.product.highlight && (
            <HighlightBox>
              <WhatshotIcon style={{ marginRight: 8 }} />
              <Typography variant="body2" className="text-bold">
                LANÇAMENTO
              </Typography>
            </HighlightBox>
          )}
          <Typography variant="h6" className="text-bold dark--text">
            {item.product.name}
          </Typography>
          <Comment item={item} imageClass={imageClass} simplifyComment />
        </TextArea>
      </Grid>
    </RatingCard>
  );
};

export default Rating;
