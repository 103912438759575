/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DefaultLayout from "../../layout/default";
import { Container, Grid, Typography } from "@material-ui/core";
import Profile from "./components/Profile";
import Address from "./components/Address";
import Interests from "./components/Interests";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { withRedux } from "../../helpers/redux";
import ApiService from "../../helpers/ApiService";
import Analytics from "../../helpers/AnalyticsService";
import EmailWarning from "./components/EmailWarning";
import Unregister from "./components/Unregister";
import translate from "../../locales/translate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user,
      pagination: state.pagination,
      language: state.language
    }),
    shallowEqual
  );
};

const MinhaConta = () => {
  var { user, language } = useStore();
  const dispatch = useDispatch();

  const [interests, setInterests] = React.useState([]);
  

  const getInterests = async () => {
    await ApiService.get("interests").then(({ data }) => {
      setInterests(data);
    });
  };

  const saveProfile = async (data, section) => {

    if (section === "Endereço") {
      await ApiService.patch('/my-account',data)
      .then((response) => {
        if (response.status === 200) {
          const { address } = response.data;
          user.address = address;                           
        }
      }).catch(e => console.log(e));
    }

    if (section === "Interesses") {
      await ApiService.patch('/my-account',data)
      .then(async (response) => {
        if (response.status === 200) {
          const { tags } = response.data;
          user.tags = tags;          
        }
      }).catch(e => console.log(e));
    }

    if (section === "Perfil") {
      await ApiService.patch('/my-account',data)
      .then(async (response) => {
        if (response.status === 200) {
          delete data.cellphone; // o campo esta desativado para edicao por isso não há necessidade desse atributo
          delete data.email; // o campo esta desativado para edicao por isso não há necessidade desse atributo
          
          user = { 
            ...user, // mantem o wallet e outras info
            ...data, // se usuario mudar sexo/idade/nome sobreescreve aqui
            birthdate: dayjs(response.data.birthdate).utc().format("DD/MM/YYYY") // formata data de aniversario
          };               
          dispatch({
            type: "SET_USER",
            user,
          });           
          //window.location.reload();                         
          new Analytics().registerEvent({
            category: "Profile",
            action: "Updated profile",
            label: user.id,
          });
        }
      }).catch(e => console.log(e));
    }

    if (section === 'Perfil-Avatar') {           
      // macete para recarregar o avatar no botão da navbar
      window.location.href = "#bottom";
      window.location.href = "#top";         
      new Analytics().registerEvent({
        category: "Profile",
        action: "Updated profile",
        label: user.id,
      });        
    }

    try {
      dispatch({
        type: "ALERT",
        alert: {          
          color: "var(--success)",
          message: language === 'pt-BR' ? translate("mas.dispatch.session-update", {section}) : translate("mas.dispatch.session-update"),          
        },
      });      
    } catch (err) {      
      dispatch({
        type: "ALERT",
        alert: {
          color: "var(--danger)",
          message: language === 'pt-BR' ? translate("mas.dispath.session-update.fail-message", {section}) : translate("mas.dispath.session-update.fail-message"),          
        },
      });
    }

    return;
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  React.useEffect(() => {
    getInterests();
    changePagination("myAccount");
    changeHelmet([{name: "myAccount", content: "myAccount content"}]);  
    window.scrollTo(0,100);
    setTimeout(() => {
      window.scrollTo(0,0);
    },300);      
  }, [ user,user.tags, user.avatar]);

  return (
    <DefaultLayout>
      {user && user.id && (
        <Container
          maxWidth="lg"
          id="top"
          style={{ backgroundColor: "white", position: "relative" }}
        >
          <Grid
            container
            justify="center"
            spacing={2}
            style={{ paddingTop: 24, paddingBottom: 42 }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" className="dark--text text-bold">
                {translate("mas.title")}
              </Typography>
            </Grid>
            {!user.confirm && <EmailWarning />}
            <Grid item xs={12} md={8}>
              <Profile user={user} onSave={saveProfile} />
              {/* <ChangePassword /> */}
              {user.address && (
                <Address
                  data={user.address}
                  style={{ marginTop: 32 }}
                  onSave={saveProfile}
                />
              )}
              {/**changed in*/}
              <Interests
                items={interests}
                userSelect={user.tags}
                style={{ marginTop: 32 }}
                onSave={saveProfile}
              />
              {/* <Ratings data={user.comments} style={{ marginTop: 32 }} /> */}
            </Grid>
            <Grid item xs={12} md={8} id="bottom">
              <Unregister />
            </Grid>
          </Grid>
        </Container>
      )}
    </DefaultLayout>
  );
};

export default withRedux(MinhaConta);
