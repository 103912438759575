import React from 'react';
import { Card, Grid, Button } from '@material-ui/core';
import styled from 'styled-components'

const OptionCard = styled(Card)`
    padding: 8px;
    padding-left: 24px;
    transition: all 1s !important;
    background-color: ${props => props.positive ? 'var(--success)' : ''} !important;
    color: ${props => props.positive ? 'white' : ''} !important;
`;

const KeyButton = styled(Button)`
        min-width: unset !important;
        width: 35px;
        height: 35px;
        border: 1px solid lightgrey !important;
        /* box-shadow: 0 0 10px lightgrey !important; */
        background-color: ${props => props.positive ? 'white' : ''} !important;
        * {
            color: var(--darktext) !important;
        }
    `

const Option = ({ option = {}, button, selected = false, updateSelected }) => {

    return (
        <OptionCard
            {...option}
            elevation={2}
            className="dark--text cursor"
            onClick={() => updateSelected({ button, option })}>
            <Grid container spacing={2} style={{ alignItems: 'center', padding: 12 }}>
                <Grid item xs={3}>
                    <KeyButton {...option} size="small" disabled>
                        {button}
                    </KeyButton>
                </Grid>
                <Grid item>
                    {option.text}
                </Grid>
            </Grid>
        </OptionCard>
    );
}

export default Option;