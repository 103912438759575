import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ApiService from '../../helpers/ApiService';
// import Analytics from '../../helpers/AnalyticsService';

const Title = styled(Typography)`
  color: var(--darktext);
  font-weight: 700;
  @media screen and (max-width: 960px) {
    font-size: 18;
  }
`;

const Forgot = () => {
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);

  const handleRecover = async (user) => {
    setLoading(true);
    try {
      await ApiService.get('/reset-password', user)
    } catch (error) {
      // Do nothing
    }
    setCompleted(true);
    setLoading(false);
  };

  return (
    <form id="register" onSubmit={handleSubmit(handleRecover)}>
      <Card elevation={10}>
        <CardContent style={{ marginBottom: "12px" }}>
          <Title variant="h5" align="center" className="cardTitle" paragraph>
            Coloque seu e-mail que te enviaremos o link de recuperação de senha
          </Title>
          <Grid container justify="center" spacing={2}>
            {completed ? (
              <Grid item xs={10}>
                <Typography
                  variant="body2"
                  align="center"
                  className="primary--text"
                >
                  E-mail enviado. Confira sua caixa de entrada e de spam.
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={10}>
                <TextField
                  label="E-mail"
                  fullWidth
                  name="email"
                  inputRef={register({
                    required: true,
                    // eslint-disable-next-line no-useless-escape
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  helperText={errors.email && "Email inválido"}
                  disabled={loading || completed}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Button
          fullWidth
          variant="contained"
          size="large"
          className={completed ? "" : "secondary dark--text"}
          type="submit"
          disabled={loading || completed}
        >
          {loading ? <StyledProgress size={30} thickness={7} /> : "enviar"}
        </Button>
      </Card>
    </form>
  );
};

const StyledProgress = styled(CircularProgress)`
  color: var(--darktext) !important;
`;

export default Forgot;
