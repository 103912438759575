import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import styled from "styled-components";
import Analytics from "../../../helpers/AnalyticsService";
import ApiService from "../../../helpers/ApiService";
import translate from "../../../locales/translate";
import "../../StartPage/components/customStyles/card.mobile.css";

const CustomCard = styled(Card)`
  position: relative;
  cursor: pointer;
`;

const CardLabel = styled(Box)`
  position: absolute;
  top: 30px;
  right: -38px;
  min-width: 150px;
  font-size: 12px;
  background-color: var(--danger);
  padding: 4px;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transform: rotate(45deg);
`;

const CardPoints = styled(Box)`
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 12px;
  background-color: var(--secondary);
  padding: 4px 8px;
  text-align: center;
  font-weight: bold;
  color: var(--darktext);
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
  z-index: ${(props) => (props.disabled ? "1" : "")};
`;

const CampainImage = styled(CardMedia)`
  height: 150px;
  width: 100%;
  filter: ${(props) => (props.disabled ? "grayscale(100%)" : "")};
  -webkit-filter: ${(props) => (props.disabled ? "grayscale(100%)" : "")};
`;

const MissionCard = ({ mission, disabled }) => {
  const missionPoints = mission.points;
  const buttonsTextContent = {
    get: translate("ms.card.button.text.gain", { points: missionPoints }),
    points: translate("ms.card.button.text.points", { points: missionPoints }),
  };

  const btnTextResolver = () => {
    if (disabled) return buttonsTextContent.points;
    else return buttonsTextContent.get;
  };

  /**
   * replaced by btnTextResolver [cristiano]
   * const resolveButtonText = () => {
    if (disabled) return `${mission.points} pontos registrados`;
    else{
        
        return `ganhar ${mission.points} pontos`;
    }
  };*/

  const handleOpenPage = () => {
    ApiService.post("/missions/accept", {
      missionId: mission.id,
    }).then(() => {
      if (mission.pixel) {
        let missionPixel = mission.pixel;
        // caso n funcione descomentar essa linha e comentar as demais
        //$("body").append(missionPixel); 
        const elm = document.createElement('img'); 
        elm.setAttribute('id', 'pixel')
        elm.setAttribute('name', 'pixel');
        elm.setAttribute('src', missionPixel);
        elm.style.width = '1px';
        elm.style.height = '1px';
        elm.style.display = 'none'; 
        elm.style.pointerEvents = 'none';       
        document.body.appendChild(elm);
        // let test = document.getElementById('pixel').src; // obtem o valor do pixel no img
        // window.alert(test);  
      }
    });

    new Analytics().registerEvent({
      category: "Missions",
      action: "Access link",
      label: mission.id,
    });

    window.open(mission.link, "_blank");
   
  };

  return (
    <div
      href=" "
      onClick={() => {
        handleOpenPage();
      }}
    >
      <CustomCard elevation={2} className="Mission-card">
        {mission.recommended && (
          <CardLabel>{translate("card.recommended")}</CardLabel>
        )}
      <CardPoints
        className={disabled ? "disabled" : ""}
        disabled={disabled}
      >
        {mission.points}
        {' '}
        {translate('ds.p1.subTitle.pt2')}
      </CardPoints>
        <CampainImage
          disabled={disabled}
          image={mission.image}
          title={mission.title}
        />
        <CardContent style={{padding: 8}}>
          <Typography
            variant="body2"
            align="center"
            className="dark--text"
            style={{height: 40, display: 'table-cell', verticalAlign: 'middle', width: '100vw'}}
          >
            {mission.name}
          </Typography>
        </CardContent>
        <Button
          fullWidth
          disabled={disabled}
          className={disabled ? "disabled" : "primary"}
          style={{borderRadius: "0 0 4px 4px"}}
        >
          {btnTextResolver()}
        </Button>
      </CustomCard>
    </div>
  );
};

export default MissionCard;
