import React from "react";

import { Grid, Typography, Box, Hidden } from "@material-ui/core";
import translate from "../../../../locales/translate";

import styled from "styled-components";

const GridContainer = styled(Grid)`
  background-color: white;
  margin: unset;
  padding: 24px;
  height: auto;
  max-height: 180vh;
  position: relative;
  @media screen and (min-width: 960px) {
    max-height: 650px;
    padding: 48px 48px 24px 48px;
  }
`;

const WinnersBox = styled(Box)`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 52%;
  right: 0;
  background-image: url("/img/bg_dir.jpg");
  z-index: 1;
`;

const GridTitle = styled(Grid)`
  z-index: 10;
  @media screen and (max-width: 960px) {
    margin-bottom: 24px;
  }
`;

const WhoWeAre = () => {
  return (
    <GridContainer
      container
      className="winners-root"
      id="who-we-are"
    >
      <Hidden smDown>
        <WinnersBox className="winners-box" />
      </Hidden>
      <Typography variant="caption" className="dark--text" >
        Bigtester
      </Typography>
      <GridTitle container item xs={12} className="winners-title">
        <Grid item xs={12} md={5}>
          <Typography
            variant="h3"
            className="dark--text"
            style={{ fontWeight: 700, marginBottom: 12 }}
          >
            {translate("lp.whoWeAre.title")}
          </Typography>
          <Typography variant="body1" className="dark--text">
            {translate("lp.whoWeAre.text")}
          </Typography>
        </Grid>
        <Grid item md={1} />
        <Grid
          container
          item
          xs={12}
          md={6}
          justify="center"
          alignItems="center"
        >
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/img/landingPage/purchaseImg.svg"}
            style={{ width: "80%" }}
          />
        </Grid>
      </GridTitle>
    </GridContainer>
  );
};

export default WhoWeAre;
