import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";
import ApiService from "../../../helpers/ApiService";
import Analytics from "../../../helpers/AnalyticsService";
import translate from "../../../locales/translate";
// Redux
import { withRedux } from "../../../helpers/redux";
import { useDispatch } from "react-redux";
// Components
import {
  Grid,
  Container,
  LinearProgress,
  Slide,
  Typography,
  Fade,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import Icon from '@mdi/react';
import { mdiTrophyVariant } from '@mdi/js';
import FlowCard from "./Card";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
import { useHistory } from "react-router-dom";


const Progress = styled(LinearProgress)`
  background-color: lightgrey !important;
`;

const PageLayout = styled(Grid)`
  background-image: url("/src/img/perfil_bg.jpeg");
  background-size: cover;
`;

const StyledProgress = styled(Progress)`
  & .MuiLinearProgress-barColorPrimary {
    background-color: var(--secondary) !important;
  }
`;

const shake = keyframes`
  0% {
    transform: none;
  }
  20% {
    transform: translateX(4px);
  }
  40% {
    transform: translateX(-8px);
  }
  60% {
    transform: translateX(8px);
  }
  80% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(8px);
  }
`;

const PointDisplay = styled.div`
  transition: all 0.5s !important;
  position: absolute;
  left: calc(${(props) => props.position}% - 20px);
  margin-top: 8px;
  color: ${(props) =>
    props.progress >= props.position ? "var(--secondary)" : "rgba(0,0,0, 0.4)"};
  font-weight: ${(props) => (props.progress >= props.position ? "900" : "")};
  animation: ${(props) => (props.progress >= props.position ? shake : "")} 0.5s
    linear;
  /* font-size: ${(props) =>
    props.progress >= props.position ? "12px" : "12px"}; */

  @media (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
      user: state.user,
    }),
    shallowEqual
  );
};

const FlowPageComponent = () => {
  const [completed, setCompleted] = React.useState(0);
  const [update, setUpdate] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [flows, setFlows] = React.useState([]);
  const [stepPercentage, setStepPercentage] = React.useState(10);
  const [progressionPonts, setProgressionPoints] = React.useState([]);
  const { language, user } = useStore();
  const matches = useMediaQuery('(min-width:960px)');
  const history = useHistory();
 
  const dispatch = useDispatch();


  const updateWallet = async (balance) => {
    user.wallet.balance = balance;
    dispatch({ type: 'SET_USER', user });
    window.location.href = '#top';
  };

  // questionQty total of flow questions (in case of need calc question percentual value)
  const handleApply = async (flow, selected, questionQty) => {
    try {
      const response = await ApiService.post("/resposta", {
        flowId: flow.id,
        answers: selected,
        send: flow.sendHive,
        affId: user.affiliate,
        percent: completed + stepPercentage,
      });
      
      new Analytics().registerEvent({
        category: "Flow",
        action: "Answer",
        label: flow.id,
      });

      if (step < flows.length) {
        const newBalance = response.data.balance;
        updateWallet(newBalance);
        setStep(step + 1);
        setCompleted(completed + stepPercentage);
      }
    } catch (error) {
      dispatch({
        type: "ALERT",
        alert: {
          color: "var(--warning)",
          message:
            "Não foi possível salvar sua resposta. Se o problema se repetir, contate o suporte.",
        },
      });
    }
  };

  const getOffers = async () => {
    await ApiService.get("flow/questions").then(({ data }) => {
      let userCreationDate = new Date(user.createdAt).toLocaleDateString();
      let today = new Date().toLocaleDateString();
      let flows = [];
      if (userCreationDate === today || user.extra[0].firstLogin) {
        flows = data.flow
          .filter((flow) => {
            return flow.flowType === 1 || flow.flowType === 0;
          })
          .sort((flowA, flowB) => {
            return flowB.flowType - flowA.flowType;
          });
      } else {
        flows = data.flow
          .filter((flow) => {
            return flow.flowType === 2 || flow.flowType === 0;
          })
          .sort(function (flowA, flowB) {
            return flowB.flowType - flowA.flowType;
          });
      }
      setFlows(flows);
      setProgressionPoints(data.ruler.values);
      if (data.userProgress) {
        setStepPercentage((100 - data.userProgress.flowPercent) / flows.length);
        setCompleted(data.userProgress.flowPercent);
      } else {
        setStepPercentage(100 / flows.length);
      }
    });
  };

  const endMessage = () => {
    return(
      <Grid iem xs={12} md={8} style={{ textAlign: "center" }}>
        <Typography variant="h4" paragraph>
          {translate("cons.end.title1")}
          <br></br>
          {translate("cons.end.title2")}
        </Typography>
        {(step === flows.length && step !== 0) ? (
          <Typography variant="body1">
            {translate("cons.end.redirect")}
          </Typography>
          ) : (
          <Typography variant="body1">
            {translate("cons.end.subTitle")}
          </Typography>
        )}
      </Grid>
    );
  }
  const redirectToMissions = () => {
    setTimeout(function() {
      history.push("/missoes");
    }, 5 * 1000);
  }

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };
  
  React.useEffect(() => {
    getOffers();
    switch (language) {
      case "en-US":
        document.title = "Consumer profile - BigTester";
        break;
      case "es":
        document.title = "Perfil de consumidor - BigTester";
        break;
      case "pt-BR":
        document.title = "Perfil de consumo - BigTester";
        break;
      default:
    }
    changePagination("cons");
    changeHelmet([{name: "flow", content: "flow content"}]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (completed >= 100) {
      new Analytics().registerEvent({
        category: "Flow",
        action: "Completed",
        label: ApiService.user.id,
      });

      setUpdate(true);
    }
  }, [completed]);

  React.useEffect(() => {}, [update]);
  
  const Score = ({ item = {} }) => (
    <PointDisplay position={item.percents} progress={completed}>
      +{item.value}
    </PointDisplay>
  );

  return (
    <PageLayout container className="dark--text" id="top">      
      <Container maxWidth="lg" style={{ marginTop: 24, marginBottom: 42 }}>
        <Grid container justify="center">
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              className="dark--text"
              align="center"              
              style={{ marginBottom: 16 }}
            >
              {translate("cons.init.title")}
            </Typography>
          </Grid>
          <Grid item xs={9} md={6} style={{ position: "relative" }}>
            <StyledProgress
              variant="determinate"
              value={completed >= 100 ? 100 : completed}
            />
            {progressionPonts.map((pp, index) => (
              <Score item={pp} key={`score-${index}`} />
            ))}
            <Icon
              path={mdiTrophyVariant}
              style={{
                width: "2rem",
                color:
                  completed >= 100 ? "var(--secondary-hover)" : "lightgrey",
                position: "absolute",
                right: -48,
                top: -12,
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            alignContent="center"
            alignItems="center"
            style={{ textAlign: "center" }}
          >
            <Box my={4}>
              <BtHowItWorks
                video="https://www.youtube.com/watch?v=a3x94HV7T6E"
                position="center"
                buttonFull
              />
            </Box>
          </Grid>
        </Grid>
        {flows.length === 0 ? (
          <Fade in={true} timeout={2000}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ minHeight: matches ? "66vh" : "56vh" }}
            >
              {endMessage()}
            </Grid>
          </Fade>
        ) : ( 
          step < flows.length ? (
            <Grid container justify="center" style={{ minHeight: matches ? "66vh" : "56vh" }}>
              <Grid item xs={12} />
              {flows.map(
                (flow, index) =>
                  index === step && (
                    <Slide key={index} in={true} direction="left">
                      <Grid item xs={12} md={10} lg={8}>
                        {/* {flow.flowType} */}
                        <FlowCard flow={flow} apply={handleApply} />
                      </Grid>
                    </Slide>
                  )
              )}
            </Grid>
          ) : (
            <Grid
              container
              justify="center"
              id="bottom"
              alignItems="center"              
              style={{ minHeight: matches ? "66vh" : "56vh" }}
            >
              {endMessage()}
              {redirectToMissions()}
            </Grid>
          )
        )}
      </Container>      
    </PageLayout>
  );
};

export default withRedux(FlowPageComponent);
