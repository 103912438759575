import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import translate from "../../../locales/translate";

const WarningGrid = styled(Grid)`
  text-align: center;
  border-radius: 5px;
  padding: 16px 0 !important;
`;

const ResendButton = styled(Button)`
  color: white !important;
  border-color: white !important;
`;

const EmailWarning = () => {
  const [clicked, setClicked] = React.useState(false);

  const handleOnClick = () => {
    setClicked(true);
    alert("Enviar Email");
  };

  return (
    <WarningGrid item xs={12} md={8} className="danger">
      <Typography variant="h6" className="text-bold white--text" paragraph>
        {translate("mas.emailNotConfirmed.title")}
      </Typography>
      {!clicked && (
        <>
          <Typography variant="body2" style={{ marginBottom: 8 }}>
            {translate("mas.emailNotConfirmed.text")}
          </Typography>
          <ResendButton
            size="large"
            variant="outlined"
            onClick={() => handleOnClick()}
          >
            {translate("mas.emailNotConfirmed.btn")}
          </ResendButton>
        </>
      )}
      {clicked && (
        <>
          <Typography variant="body2" style={{ marginBottom: 8 }}>
            {translate("mas.emailNotConfirmed.sent")}
          </Typography>
        </>
      )}
    </WarningGrid>
  );
};

export default EmailWarning;
