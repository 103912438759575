import axios from "axios";
import cookies from "js-cookie";
import S3 from "aws-s3";
import dayjs from "dayjs";

class ApiService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL || "https://api.bigtester.us/";
    this.token = cookies.get("auth_token");
    this.defaultAxios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    });
    this.user = {};
    this.defaultAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error)
        if (error && error.response.status === 401) {
          window.location.replace("/");
        } else if (error.response.status === 406) {
          return error;
        }
        return Promise.reject(error);
      }
    );
  }

  async tryLogin(loginData) {
    try {
      const {
        data: { token },
      } = await axios.post(this.baseUrl + "users/login", loginData);
      this.updateToken(token);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  }

  async autoLogin(force = false) {
    if (this.token) {
      try {
        if (!this.user.id || force) {
          const {
            data: { user, comments },
          } = await axios.get(this.baseUrl + "users/auth", {
            headers: {
              authorization: `Bearer ${this.token}`,
            },
          });

          this.user = {
            ...user,
            comments,
            address: user.address || {},
            birthdate: dayjs(user.birthdate).format("DD/MM/YYYY"),
          };
        }
        return Promise.resolve(this.user);
      } catch (error) {
        return Promise.reject(false);
      }
    }
  }

  updateToken(token) {
    cookies.set("auth_token", token);
    this.token = token;
    this.defaultAxios.defaults.headers["authorization"] = `Bearer ${token}`;
    this.autoLogin(true);
  }

  async disableFirstUserLogin() {
    if (this.user && this.user.extra[0].firstLogin) {
      console.log(this.user.extra[0]);
      let extra = {
        ...this.user.extra[0],
        firstLogin: false,
      };
      try {
        const { data } = await this.patch(`/my-account`, { extra: [extra] });
        console.log("usuario fazendo logoff", data);
      } catch (error) {
        console.log(error);
      }
    }
  }

  async logout() {
    await this.disableFirstUserLogin();
    cookies.remove("auth_token");
    this.token = null;
    this.defaultAxios.defaults.headers["authorization"] = "";
    this.user = {};
  }

  async get(suffix, params) {
    return await this.defaultAxios.get(suffix, { params });
  }

  async post(suffix, params) {
    return await this.defaultAxios.post(suffix, params);
  }

  async delete(suffix, params) {
    return await this.defaultAxios.delete(suffix, params);
  }

  async patch(suffix, params) {
    return await this.defaultAxios.patch(suffix, params);
  }

  async sendS3({ source, file, fileName = null }) {
    const config = {
      bucketName: "blds-bigtesterv2",
      dirName: `images/${source}`,
      region: "sa-east-1",
      accessKeyId: "AKIATPCOXN76DMRMYXRO",
      secretAccessKey: "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",
    };

    const S3Client = new S3(config);

    const timestamp = new Date().getTime();
    let formattedName = "";

    if (fileName) {
      formattedName = `${fileName.replace(/ /g, "")}${timestamp}`;
    } else {
      formattedName = `${file.name.split(".")[0]}${timestamp}`;
    }

    try {
      return await S3Client.uploadFile(file, formattedName);
    } catch (error) {
      return { error };
    }
  }

  async removeFileOnS3({ filename = null, source = null }) {
    const config = {
      bucketName: "blds-bigtesterv2",
      dirName: `images/${source}`,
      region: "sa-east-1",
      accessKeyId: "AKIATPCOXN76DMRMYXRO",
      secretAccessKey: "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",
    };
    
    const S3Client = new S3(config);

    try {
      return await S3Client.deleteFile(filename);
    } catch (error) {
      return { error };
    }
  }
}

export default new ApiService();
