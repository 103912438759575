import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import styled from "styled-components";
import translate from "../../../locales/translate";

const RowContainer = styled(Grid)`
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  padding: 12px;
`;

const CheckboxLabel = styled(FormControlLabel)`
  & .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

const Interests = ({
  items = {},
  userSelect = [],
  style = {},
  onSave = () => {},
}) => {
  const [selected, setSelected] = React.useState(null);

  const formatSelected = () => {
    let tmp = {};
    userSelect.forEach((s) => {
      tmp = { ...tmp, [s.id]: true };
    });
    setSelected(tmp);
  };

  const handleCheckBox = ({ target }) => {
    setSelected({ ...selected, [target.value]: !selected[target.value] });
  };

  const handleSave = () => {
    const IDS = Object.keys(selected);

    let selectedIDS = [];
    IDS.forEach((id) => {
      if (selected[id]) selectedIDS.push(parseInt(id));
    });

    onSave({ tags: selectedIDS }, "Interesses");
  };

  React.useEffect(() => {
    formatSelected();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelect]);

  return (
    <Paper className="dark--text" style={{ padding: 16, ...style }}>
      <Grid container>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          justify="space-between"
          style={{ minHeight: 100, paddingBottom: 12 }}
        >
          <Grid item>
            <Typography variant="h6" className="text-bold">
              {translate("mas.section3.2.title")}
            </Typography>
            <Typography variant="body2">
              {translate("mas.section3.2.subTitle")}
            </Typography>
          </Grid>
        </Grid>
        {selected && (
          <RowContainer container>
            {items.map((item) => (
              <Grid
                item
                xs={6}
                md={4}
                key={item.id}
                style={{ position: "relative" }}
              >
                <CheckboxLabel
                  style={{ maxWidth: "100%" }}
                  control={
                    <Checkbox
                      checked={selected[item.id]}
                      // defaultChecked={userSelect.includes(item.id)}
                      onChange={handleCheckBox}
                      value={item.id}
                      color="primary"
                    />
                  }
                  label={item.name}
                />
              </Grid>
            ))}
          </RowContainer>
        )}
      </Grid>
      <Button
        size="large"
        className="success"
        fullWidth
        style={{ marginTop: 24 }}
        onClick={handleSave}
      >
        {translate("mas.section3.2.btn")}
      </Button>
    </Paper>
  );
};

export default Interests;
