import React from 'react';
import DefaultLayout from '../../layout/default';
import DrawPageComponent from './components/PageComponent';

const Draw = () => {
    return (
        <DefaultLayout>
            <DrawPageComponent />
        </DefaultLayout >
    );
}

export default Draw;