import React from "react";
import { List, ListItem, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { mdiAccount } from '@mdi/js';
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import translate from "../../../locales/translate";
import Icon from '@mdi/react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const ProfileImage = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 30px;
  margin-right: 16px;
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};

const MobileList = ({ buttons = [], logout = () => {} }) => {
  const { user } = useStore();

  return (
    <List style={{ paddingTop: 0, overflowX: "hidden", }}>
      <Link to="/minha-conta" key="minha-conta">
        <ListItem
          key="key_minha-conta"
          button
          className="secondary dark--text text-bold"
          style={{
            minWidth: 250,
            textDecoration: "none",
            // margin: 8,
            height: 75,
            margin: "unset",
          }}
        >
          {/*|| "https://cdn.pixabay.com/photo/2017/01/23/19/40/woman-2003647_960_720.jpg" */}
          <Box>
           { user.avatar ? <ProfileImage src={user.avatar } /> : <Icon path={mdiAccount} title="userprofilePic" size={2}/>}
          </Box>
          <Box style={{ flex: 1 }}>
            <Typography
              className="dark--text text-bold"
              variant="h6"
              style={{ lineHeight: 1.2, textTransform: "capitalize" }}
            >
              {user.fullName || ""}
            </Typography>
            <Box
              className="dark--text"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MonetizationOnIcon
                style={{ fontSize: 18, marginRight: 4 }}
              />
              <Typography variant="caption" className="dark--text">
                <span>{user.wallet ? user.wallet.balance : "0"} {translate("follows.points")}</span>
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </Link>
      <Link to="meus-pontos" key="meus-pontos">
        <ListItem
          key="key_meus-pontos"
          button
          className="white--text text-bold"
          style={{
            minWidth: 250,
            textDecoration: "none",
            margin: 8,
            height: 50,
          }}
        >
          <AccountBalanceWalletIcon
            style={{ marginRight: 16, fontSize: 20, width: 25 }}
          />
          {translate("nav.click.mypoints")}
        </ListItem>
      </Link>
      {buttons.map((btn, index) => {
        return (
          <Link to={btn.page} key={index}>
            <ListItem
              key={btn.key}
              button
              className="white--text text-bold"
              style={{
                minWidth: 250,
                textDecoration: "none",
                margin: 8,
                height: 50,
              }}
            >
              <Icon
                path={btn.icon}
                style={{ marginRight: 16, fontSize: 20, width: 25 }}
              />
              {btn.text}
            </ListItem>
          </Link>
        );
      })}
      <ListItem
        key="logout"
        button
        className="text-bold"
        style={{
          minWidth: 250,
          textDecoration: "none",
          height: 50,
          color: "#FFF",
          backgroundColor: "var(--danger)",
        }}
        onClick={logout}
      >
        <ExitToAppIcon
          style={{ marginRight: 16, fontSize: 20 }}
        />
        {translate("nav.quit")}
      </ListItem>
    </List>
  );
};

export default MobileList;
