/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import GainMore from "../../../components/common/GainMore";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
import OfferList from "./List";
import ApiService from "../../../helpers/ApiService";
import PaginationControler from "../../../components/common/PaginationControler";
import styled from "styled-components";
import translate from "../../../locales/translate";
import { useDispatch } from "react-redux";

const TitleGrid = styled(Grid)`
  align-items: center;
  position: relative;
`;

const OfferPageListing = () => {
  const amounts = [8, 16, 32, 64];

  const [offers, setOffers] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [amount, setAmount] = React.useState(8);
  const [total, setTotal] = React.useState(40);
  const dispatch = useDispatch();

  const getOffers = (filter = "") => {
    ApiService.get("ofertas", {
      limit: amount,
      skip: (page - 1) * amount,
      title: filter,
    }).then(async ({ data }) => {
      //console.log(data.offers)
      setOffers(data.offers);      
      setTotal(data.count);
    });
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  React.useEffect(() => {
    getOffers();
    changePagination("free");
    changeHelmet([{ name: "offers", content: "offers content" }]);
  }, [page, amount]);

  return (
    <Container maxWidth="lg" style={{ backgroundColor: "white" }}>
      <Grid container style={{ paddingTop: 16, paddingBottom: 42 }} spacing={2}>
        <TitleGrid item xs={12} className="dark--text" style={{ padding: '16px 8px' }}>
          <BtHowItWorks video="https://www.youtube.com/watch?v=3K6V68_C59I" />
          <Typography variant="h4" className="dark--text text-bold">
            {translate("fs.title")}
          </Typography>
          <Typography variant="body2">{translate("fs.titleText")}</Typography>
          <Typography variant="body2">
            {translate("fs.titleText.02")}
          </Typography>
        </TitleGrid>
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Ofertas" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
          searchField
          onSearchChange={(filter) => getOffers(filter)}
        />
        <OfferList offers={offers} />
        <Grid item xs={12}>
          <GainMore />
        </Grid>
      </Grid>
    </Container>
  );
};

export default OfferPageListing;
