import React from "react";

// import Topmenu from '../top-menu-disconnected/Topmenu' //Ok
import { Container, Grid } from "@material-ui/core";
import styled from "styled-components";
// import Formatter from '../../../../helpers/Formatter';
import translate from "../../../../locales/translate";

// import { Container } from './styles';
const MainContent = styled(Grid)`
  position: relative !important;
  background-color: #ffffff;
  padding: 15px 40px;
  margin-top: 10px;
  color: #546e7a;

  a:hover {
    cursor: pointer;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: rgb(41, 182, 246);
  }

  a:active {
    text-decoration: none;
  }

  h2 {
    margin-top: 40px;
  }
`;

const privacy = () => {
  return (
    <Container>
      <MainContent>
        <h1>{translate("privacy")}</h1>
        <h2>{translate("privacy.title.1")}</h2>
        <p>{translate("privacy.text.1")}</p>

        <h2>{translate("privacy.title.2")}</h2>
        <p>{translate("privacy.text.2")}</p>

        <h2>{translate("privacy.title.3")}</h2>
        <p>{translate("privacy.text.3.1")}</p>
        <p>{translate("privacy.text.3.2")}</p>
        <p>
          {translate("privacy.text.3.3")}
          <a href=" http://caloga.br.com/html/dperso.php">
            {translate("privacy.text.link")}
          </a>
        </p>
        <p>{translate("privacy.text.3.4")}</p>

        <h2>{translate("privacy.title.4")}</h2>
        <p>{translate("privacy.text.4")}</p>

        <ul>
          <li>{translate("privacy.li.4.1")}</li>
          <li>{translate("privacy.li.4.2")}</li>
          <li>{translate("privacy.li.4.3")}</li>
          <li>{translate("privacy.li.4.4")}</li>
          <li>{translate("privacy.li.4.5")}</li>
          <li>{translate("privacy.li.4.6")}</li>
        </ul>

        <h2>{translate("privacy.title.5")}</h2>
        <p>{translate("privacy.text.5")}</p>
        <ol>
          <li>{translate("privacy.li.5.1")}</li>
          <li>{translate("privacy.li.5.2")}</li>
          <li>{translate("privacy.li.5.3")}</li>
        </ol>

        <h2>{translate("privacy.title.6")}</h2>
        <p>{translate("privacy.text.6")}</p>

        <h2>{translate("privacy.title.7")}</h2>
        <p>{translate("privacy.text.7.1")}</p>
        <p>{translate("privacy.text.7.2")}</p>

        <h2>{translate("privacy.title.8")}</h2>
        <p>{translate("privacy.text.8.1")}</p>
        <p>{translate("privacy.text.8.2")}</p>

        <h2>{translate("privacy.title.9")}</h2>
        <p>{translate("privacy.text.9")}</p>

        <h2>{translate("privacy.title.10")}</h2>
        <p>{translate("privacy.text.10.1")}</p>

        <h3>{translate("privacy.subtitle.10")}</h3>
        <p>{translate("privacy.text.10.2")}</p>

        <h2>{translate("privacy.title.11")}</h2>
        <p>{translate("privacy.text.11.1")}</p>
        <p>{translate("privacy.text.11.2")}</p>

        <h2>{translate("privacy.title.12")}</h2>
        <p>{translate("privacy.text.12")}</p>

        <h2>{translate("privacy.title.13")}</h2>
        <p>{translate("privacy.text.13")}</p>
      </MainContent>
    </Container>
  );
};

export default privacy;
