import { LOCALES } from "../locales";

export default {
  [LOCALES.ESPANOL]: {
    //FirstScreen LandingPage
    // Navbar
    "lp.navbar.winners": "probadores",
    "lp.navbar.who": "quiénes somos",
    "lp.navbar.how": "cómo funciona",
    "lp.navbar.btn": "entrar",

    // Login
    "lp.login.cardTitle1": "¿Ya registrado? ",
    "lp.login.cardTitle2": " ¡Accede y sigue ganando puntos!",
    "lp.login.cellphone": "Mobile",
    "lp.login.wrong": "Correo electrónico o teléfono móvil no válido",
    "lp.login.enterBtn": "Iniciar sesión",
    "lp.login.noRegisterQuestion":
      "¿No tienes registro? ¡Haz clic aquí y hazlo ahora mismo!",
    "lp.login.havelogin": "¿Ya registrado?",
    "lp.login.enterBtn.alternative": "Enviar",
    "lp.login.forgotPhone.link":"Olvidé mi número",
    "lp.login.forgotPhone.link.alternative":"Regresar",
    "lp.login.forgotPhone": "¿Olvidaste tu número de teléfono?",
    "lp.login.forgotPhone.message": "¡Ingrese su correo electrónico y le enviaremos un mensaje!",
    "lp.login.forgotPhone.success": "correo electrónico enviado con éxito, revise su bandeja de entrada!",
    "lp.login.forgotPhone.warning": "¡el correo electrónico informado no es válido!",
    "lp.login.forgotPhone.error": "Lo siento, pero no pudimos enviar el correo electrónico en este momento. Vuelve a intentarlo más tarde.",

    // Botones
    "lp.btn.howWork": "cómo funciona",
    "lp.btn.register": "registro",
    "lp.btn.want": "Quiero",
    "lp.btn.viewMore": "ver más productos",

    // Destacar
    "lp.text": "El mayor sitio de pruebas de productos en América Latina",

    // Formulario de registro
    "lp.form.title": "¡Reciba productos gratis para probar en casa!",
    "lp.form.name": "Nombre Completo",
    "lp.form.lastname": "Apellido",
    "lp.form.phone": "Mobile",
    "lp.form.birthday": "Nacimiento",
    "lp.form.masc": "Hombre",
    "lp.form.fem": "Femenino",
    "lp.form.btn": "regístrese",

    "lp.form.invalidFullName": "por favor ingrese el nombre completo!",
    "lp.form.errBithday": "edad inválida la edad mínima debe ser: ",
    "lp.form.invalidBirthday": "fecha de nacimiento no válida",
    "lp.form.invalidCellphone": "teléfono no válido",
    "lp.form.errCellphone": "número de teléfono no válido",
    "lp.form.notPossible":
      "No pudimos crear su cuenta. Verifique sus datos y vuelva a intentarlo",
    "lp.form.invalidEmail": "¡Correo electrónico no válido!",
    "lp.form.invalidCamp": "Campo no válido",
    "lp.form.obrCamp": "Campo obligatorio",
    "lp.form.successfullyRegisterMessageTitle": "Éxito",
    "lp.form.successfullyRegisterMessage":
      "Gracias por registrarse, recibirá un correo electrónico de confirmación en su bandeja de entrada, por favor revíselo. Es importante destacar que en el primer inicio de sesión de cada día recibirás 20 puntos para usar en el sistema, así que no pierdas esta oportunidad y ¡buena suerte!",
    "lp.form.dateParseErrorMessage":
      "Error de fecha, verifique que la fecha ingresada en el formulario sea correcta.",
    "lp.form.terms1": "Acepto los ",
    "lp.form.terms2": "Términos de compromiso ",
    "lp.form.terms3": "y la ",
    "lp.form.terms4": "Política de Privacidad",
    "lp.form.terms5":
      "Acepto recibir noticias y nuevos productos BigTester en mi correo electrónico",
    "lp.form.emailAlredyInUse": "El correo electrónico que ingresó ya está en uso, ¡intente iniciar sesión!",
    "lp.form.emailError": "El correo electrónico informado ya está en uso, ¡no es posible continuar!",
    "lp.form.notice.terms": "Antes de continuar, es necesario aceptar los términos de contratación y la política de privacidad.",
    "lp.form.greetings": "Es genial tenerte con nosotros nuevamente. ¡Encontramos su registro en nuestra base de datos y ya hemos acreditado sus puntos! Es importante destacar que en el primer inicio de sesión de cada día recibirás 20 puntos para usar en el sistema, así que no pierdas esta oportunidad y ¡buena suerte!",
    "lp.form.register.error.409": "Correo electrónico ya registrado en la versión anterior de BigTester. Intente iniciar sesión",

    // HowWorks
    "lp.how.title": "¿Cómo funciona?",
    "lp.how.subtitle1": "1. Inscríbete",
    "lp.how.text1":
      "Al registrarse totalmente gratis, el Gran Probador le ofrece productos y ofertas increíbles!",
    "lp.how.subtitle2": "2. Crea tu perfil",
    "lp.how.text2":
      "¡Se ve bien en la foto! Personaliza tu perfil y asegúrate de que los productos gratuitos lleguen a tu casa",
    "lp.how.subtitle3": "3. Intercambie sus puntos",
    "lp.how.text3":
      "Estar activo en el Gran Probador. ¡Cuantos más puntos ganes, más productos podrás comercializar! ¡Hay cientos de productos y todavía tienes el sorteo! No te lo pierdas!",

    // Quienes somos
    "lp.whoWeAre.title": "Quiénes somos",
    "lp.whoWeAre.text": `The Big Tester es una plataforma digital totalmente interactiva, donde permite al usuario tener una experiencia única de probar productos o servicios de forma gratuita o participar en selecciones y sorteos de forma segura. Además, es un escaparate que permite la intermediación entre marca / producto al consumidor final, brindándole siempre beneficios, como campaña promocional, productos gratuitos, y servicios para pruebas exclusivas o descuentos.`,

    // iPhone
    "lp.iphone.title":
      "Además de probar los productos en casa, compita por tres iPhones al año!",
    "lp.iphone.text": `Cada año, el Gran Probador dibuja 3 iPhones! Si te inscribes con el Gran Probador,
                            tienes acceso a los cupones del sorteo para cada iPhone. Cuanto más interactúes con el Gran Probador,
                            aprovechando campañas, misiones, respondiendo sobre tu perfil de consumo, invitando a tus amigos y participando
                            de los rankings, más puntos que acumulas, para que puedas cambiarlos por más cupones. Cuantos más cupones tengas,
                            ¡cuantas más oportunidades tengas de ganar el iPhone! ¿Perderás esa oportunidad?`,

    // Los últimos ganadores
    "lp.lastW.title": "Últimos probadores",
    "lp.lastW.subTitle.1": "Ya estamos",
    "lp.lastW.subTitle.2": "7,7 millones",
    "lp.lastW.subTitle.3": " de los probadores",
    "lp.lastW.text":
      "Miles de probadores se inscriben cada mes! Hay millones de puntos distribuidos, miles de cupones generados para las rifas y cientos de productos que se envían a los hogares de la gente. ¿A qué esperas para convertirte en probador?",
    "lp.lastW.subnum.1": "Más de mil millones",
    "lp.lastW.subtext.1": "de puntos ganados",
    "lp.lastW.subnum.2": "1 Bi",
    "lp.lastW.subtext.2": "de puntos ganados",
    "lp.lasW.drawNotice":"esperando el sorteo",

    //winnersCustom
    "lp.lastCustom.title": "Únete a los ganadores",
    "lp.lastCustom.subTitle.1": "Haz lo que ellos hacen",
    "lp.lastCustom.subTitle.2": "Únete al equipo de campeones",

    // Productos
    "lp.product.title": "Productos que puedes probar",
    "lp.product.point": "puntos",
    "lp.product.btn": "ver más productos",
    "lp.product.exchange.histBtn": "ver su historial de intercambio",
    "lp.product.exchanged.title": "{uname}, este es su historial de productos",
    "lp.product.exchaged.btBack": "volver a la pantalla de productos",

    //Contato
    "lp.contact.title": "Entre em contacto!",
    "lp.contact.socialmedia": "O ven a las redes sociales",
    "lp.contact.errors.name": "Nombre inválido",
    "lp.contact.errors.email": "Ingrese un correo electrónico válido",
    "lp.contact.errors.phone": "Ingrese un número de teléfono válido",
    "lp.contact.errors.message": "Su mensaje debe contener de 5 a 500 caracteres",
    "lp.contact.success.title": "¡Éxito!",
    "lp.contact.success.text": "Su información de contacto ha sido enviada con éxito, ¡espere nuestro contacto!",
    "lp.contact.message": "Nos pondremos en contacto con usted pronto",

    // Puestos
    "lp.posts.title": "últimos posts",

    // Cookies
    "lp.cookies":
      "Utilizamos cookies para proporcionar los servicios y características que se ofrecen en nuestro sitio web y para mejorar nuestra experiencia de usuario.",
    "lp.cookies.link": "Sepa mas",
    "lp.cookies.button": "Aceptar!",

    // Contacto
    "contact.title": "Contacto",
    "contact.invalidCamp": "Campo no válido",
    "contact.googleMaps":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.5731282722813!2d-46.66061278448496!3d-23.547850966979794!2m3!1f0!2f0!3i02432!7i10824! 4f13.1! 3m3! 1m2! 1s0x94ce577f1cc235b5% 3A0x46efa75fcd46396b! 2sStreet% 20Sergipe% 2C% 20475% 20-% 20 Consola% C3% A7% C3% A3o% 2C% 20S% C3% A3o% 20Paulo% 20 2001243-001! 5e0! 3m2! 1sen! 2sbr! 4v1577735236092! 5m2! 1sen! 2sbr ",
    "contact.street":
      "Rua Sergipe, 475, 5 ° piso, Higienópolis - São Paulo, SP - Brasil",
    "contact.cep": "CEP: 01.243-001",
    "contact.number": "+55 (11) 4371-5300",
    "contact.leaveMessage": "Deja tu mensaje",
    "contact.namePlaceholder": "Nombre",
    "contact.phonePlaceholder": "Teléfono",
    "contact.messagePlaceholder": "Mensaje",
    "contact.button": "Enviar",

    // Para todos
    perPágina: "por página",
    puntos: "puntos",

    // Botones
    "btn.iWant": "Yo quiero",
    "btn.howWorks": "cómo funciona",
    "btn.register": "registro",
    "btn.knowMore": "saber más",
    "btn.knowMore.disabled": "cerrado",
    "btn.sendOffert": "enviar oferta",
    "btn.tradePoints": "puntos de intercambio",
    "btn.inviteAmigos": "invite a amigos",
    "btn.seeRanking": "ver ranking",

    "cards.majorTitle": "Obtén más puntos",

    // GainMorePoints | Tarjetas cerca de Footer
    "cartas.TítuloMayor": "Consigue más puntos",
    "card1.title": "Patrocinado",
    "card1.text": "Participar en campañas de prueba de productos",
    "card2.title": "Invita a tus amigos",
    "card2.text": "¡Invita a tus amigos y gana puntos",
    "card3.title": "Ofertas",
    "card3.text": "Aprovecha estas ofertas exclusivas",
    "card4.title": "Perfil del consumidor",
    "card4.text": "Responde a las preguntas sobre tu comportamiento de consumo",
    "card.recommended": "Recomendado",
    "card.participating": "Participativo",

    // Pie de página
    "footer.language": "Idiomas",
    "footer.iconsText": "Disfruta de nuestras redes sociales",
    "footer.privacyTitle": "Política de privacidad | FAQ",
    "footer.termsTitle": "Términos de Compromiso",
    "footer.privacyText":
      "©Adclick Ltda - Todos los derechos reservados. Este sitio es propiedad de Adclick Ltda, único responsable de su contenido, y está destinado a los residentes de Brasil.",
    "footer.warning":
      "Imágenes sólo para ilustración. BigTester es responsable de la distribución del 100% de los productos ofrecidos en el sitio. El Gran Probador es el único responsable de la compra y distribución de los premios. Los fabricantes y/o distribuidores de los productos ofrecidos como premios no organizan ni participan en la política comercial del sitio web. Participe gratuitamente, acumule puntos y canjee sus puntos por los productos ofrecidos",
    "footer.contact.title": "Póngase en contacto",
    "footer.contact.text.01": "Soy una empresa",
    "footer.contact.text.02": "Soy un tester",

    // Navbar
    "nav.mission": "misiones",
    "nav.campaign": "patrocinado",
    "nav.cons": "consumo",
    "nav.aval": "evaluar productos",
    "nav.product": "cambia puntos",
    "nav.free": "gratis",
    "nav.follow": "sigue y gana + puntos",
    "nav.draw": "dibujar",
    "nav.invite": "invite a sus amigos",
    "nav.click.myaccount": "mi cuenta",
    "nav.click.mypoints": "mis puntos",
    "nav.points": "puntos",
    "nav.quit": "salir",

    "nav.avatar.menu.account": "Mi cuenta",
    "nav.avatar.menu.mypoints": "Mis puntos",
    "nav.avatar.menu.mycupons": "Mis cupones",
    "nav.avatar.menu.exit": "Salir",

    // Navbar bottom Mobile
    "nav.mobile.start": "inicio",
    "nav.mobile.points": "misiones",
    "nav.mobile.products": "productos",
    "nav.mobile.draw": "dibujar",
    "nav.mobile.campaigns": "campañas",
    "nav.mobile.follow": "influencers",

    //
    // StartScreen | Beginning
    "ss.title": "Hola, ",
    "ss.btn.pt1": "ganiar ",
    "ss.btn.pt2": " puentos",
    "ss.btn.pt2.alt": " pts",
    "ss.subtitle.missions": "Nuevas misiones",
    "ss.subtitle.dashboard": "Dashboard",
    "ss.dashboard.card1": "Misiones",
    "ss.dashboard.card2": "Campañas patrocinadas",
    "ss.dashboard.card3": "Invita a tus amigos",
    "ss.dashboard.card4": "Ofertas",
    "ss.subtitle.fixedmissions": "Ganar más puntos",
    "ss.name.fixedmission1": "Responde algunas preguntas y gana puntos",
    "ss.name.fixedmission2": "Siga en las redes sociales y puntúe",
    "ss.name.fixedmission3": "Haga clic aquí y obtenga productos gratis",
    //

    //
    // MissionsScreen
    "ms.title": "Misiones",
    "ms.titleText": "¡Completa las misiones y acumula muchos puntos!",
    "ms.text.01": "Aviso:",
    "ms.text.02":
      "Los puntos se contabilizarán una vez que se haya sumado la oferta contenida en la misión.",
    "ms.text.03":
      "Si hay problemas en el cómputo de sus puntos, contáctenos vía e-mail o WhatsApp enviando una impresión que acredite la adhesión a la misión.",
    "ms.text.04":
      "Aquí puedes realizar misiones y ganar puntos y luego canjearlos por productos.",
    "ms.text.05":
      "¡Cada semana hay nuevas misiones con ofertas exclusivas para probadores y nunca dejas de acumular puntos!",
    "ms.detailTitle.pt1": "Usted tiene ",
    "ms.detailTitle.pt2": "misiones disponibles",
    "ms.otherMissions": "Otras misiones",
    "ms.btn.pt1": "ganar",
    "ms.btn.pt2": "puntos",
    "ms.subTitle1": "Libros",
    "ms.subTitle2": "Películas",
    // "ms.subTitle2": "Baby",
    // "ms.subTitle4": "Industrial",
    "ms.subTitle4": "Electrónica",
    "ms.card.button.text.gain": "gana {points} puntos",
    "ms.card.button.text.points": "{points} puntos registrados",
    //

    //
    // CampaignScreen
    "cs.title": "Campañas patrocinadas",
    "cs.titleText":
      "Al solicitar campañas patrocinadas, puede ser seleccionado para recibir productos en su casa y también ganar muchos puntos!",
    "cs.campaignPerPage": "Campañas por página:",
    "cs.subTitle": "Gana más puntos",
    "cs.csOver": "¡La campaña ha terminado!",
    "cs.csOverWhen": "La campaña finalizó el:",
    "cs.otherCampaigns": "ver otras campañas",
    "cs.winners": "Ganadores",
    "cs.active": "Activo",
    "cs.over": "Cerrado",
    //

    //
    // CampaignScreen - Detalle
    "cs.detail.period": "Periodo de la campaña",
    "cs.detail.start": "Inicio",
    "cs.details.end": "Final",
    "cs.detail.patro": "Esta campaña está patrocinada por",
    "cs.detail.patro.after": "Esta campaña fue patrocinada por",
    "cs.detail.social": "Me gusta en las redes sociales",
    "cs.detail.btn1": "ya participando",
    "cs.detail.btn2": "aplicar",
    "cs.detail.btn3": "conoce a los ganadores",
    "cs.detail.btn4": "candidatos aún no seleccionados",
    "cs.detail.btn5": "Actualizar mis datos",
    "cs.detail.noaddress": "Para postularse a las campañas, es necesario que sus datos, como CPF y dirección, estén registrados en el Big Tester!",
    "cs.btn.continue": "continuar",
    "cs.continueText": "O presione enter",
    "cs.inputText": "Tu respuesta",
    "cs.watch": "Mira el video para confirmar tu suscripción",
    "cs.reseller.title": "¡Disfrute y aumente sus posibilidades de ganar registrándose con estos socios!",
    "cs.reseller.skip": "Quiero dejar pasar esta oportunidad...",
    "cs.end.mission": "¿Quiere tener más probabilidades de ser seleccionado? Completa las misiones y aumenta tus posibilidades",
    "cs.end.title": "¡Ha completado la campaña!",
    "cs.end.subTitle1": "Sus",
    "cs.end.subTitle2":
      "puntos se acreditarán tan pronto como la campaña los haga disponibles.",
    "cs.end.text1":
      "Se ha enviado la confirmación de su solicitud en su correo electrónico. Verifique que nuestro correo electrónico no haya sido depositado en su casilla de correo no deseado o basura. Es muy importante que revise este y otros correos electrónicos BigTester para aprovechar toda la experiencia de la campaña ",
    "cs.end.text2":
      "Si su aplicación es seleccionada, le enviaremos un mensaje en este mismo correo electrónico. ¡Así que estad atentos!",
    "cs.end.btn": "Ver más campañas",
    "cs.end.btn2": "¡cambia tus puntos ahora!",
    "cs.end.addressTitle": "¡Atención probador!",
    "cs.end.addressText": "En su registro, ¿esta dirección es correcta?",
    "cs.end.addressTextNoAddress1": "No tiene una dirección registrada en Big Tester.",
    "cs.end.addressTextNoAddress2": "Para recibir el producto que está solicitando, debe completar su dirección completa en el área Mi cuenta o haciendo clic en el botón a continuación.",
    "cs.end.addressButton": "actualizar dirección",
    "cs.detail.titleshare":
      "Cuando esté seleccionado, no olvide anunciar en las redes sociales",
    "cs.detail.descriptionshare1":
      "Es genial recibir regalos y productos en casa, ¿no? Entonces, cuando lleguen tus productos, publica en Instagram una foto con el hashtag #bigtester en modo público y etiquetando a @big_tester, ¿qué te parece? ¡En esta misión esperamos que tome fotos increíbles con su producto! ¡Puede ser en el automóvil, en la universidad, en casa, en el trabajo y en cualquier otro lugar que desee! No olvides registrarte en una foto enojada, con derecho a muchos clics!",
    "cs.detail.descriptionshare2":
      "¡Te invitamos a evaluar el producto de la campaña y darnos tu opinión sobre lo recibido! ¡Grabe historias en Instagram dando testimonio y contando lo que piensa del empaque, el producto y su experiencia en general! ¡No olvide marcar nuestro perfil @big_tester, que podemos volver a publicar en nuestro perfil! ¡Tus comentarios son extremadamente importantes!",
    "cs.btn.confirm": "confirmar aplicación",
    "cs.legacy.table.name": "Nombre",
    "cs.legacy.table.email": "Email",
    //
    //
    // ConsumptionScreen / FlowScreen
    "cons.init.title": "¡Responde estas preguntas y gana muchos puntos!",
    "cons.end.title1": "¡Felicitaciones!",
    "cons.end.title2": "¡Ha completado todas las preguntas disponibles!",
    "cons.end.subTitle": "Pronto, te traeremos más preguntas y puntos",
    "cons.end.redirect": "Serás redirigido a Misiones en unos segundos...",
    //

    //
    // ProductScreen
    "ps.title": "Productos para que los evalúe",
    "ps.title2":
      "Consulta la lista de productos para canjear por puntos y evaluar. En caso de duda consulte el",
    "ps.regulation": "Reglamento",
    "ps.products": "productos",
    "ps.classifications": "CATEGORIAS",
    "ps.winnersTitle": "últimos probadores",
    "ps.dispatchSuccess":
      "Su Producto se enviará pronto, esté atento a su correo electrónico.",
    "ps.dispatchFail": "No pudimos realizar el cambio. ¡Contáctanos!",
    "ps.new": "RELEASE",
    "ps.trade": "cambiar ahora",
    "ps.buy": "comprar ahora",
    "product.toast": "¡Producto intercambiado correctamente!",
    "ps.text.title": "¡Probador!",
    "ps.text.01": `Con el fin de mejorar su comodidad y sortear los constantes problemas con las entregas, la logística y la huelga postal intermitente, le informamos que la mayoría de los productos a cambio son productos virtuales y, por ello, sus imágenes son meramente ilustrativas y son solo sugerencias para intercambios`,
    "ps.text.02": `Entonces:`,
    "ps.text.02.01": `En el acto de cambiar el producto virtual, nuestros profesionales se comunicarán con usted dentro de los 10 días hábiles por correo electrónico y / o WhatsApp para confirmar sus datos.`,
    "ps.text.02.02": `Una vez realizado el contacto, el producto virtual se enviará en su correo electrónico dentro de los 20 días hábiles y no se enviará ningún producto físico en caso de cambio por productos virtuales.`,
    "ps.text.02.03": `Puede elegir libremente cómo utilizar los valores de las tarjetas virtuales intercambiadas aquí.`,
    "ps.text.02.04": `Algunos de los productos digitales son acumulativos y se puede utilizar más de un vale para comprar su producto en el sitio web del proveedor.`,
    "ps.text.03.01": `Si tiene alguna pregunta sobre un intercambio, comuníquese con nosotros por correo electrónico`,
    "ps.text.03.email": `instagram @ bigtester.com.br`,
    "ps.text.03.02": `. Estaremos encantados de responder.`,
    //

    //ProductDetail
    "pd.exchangeSuccess": "producto intercambiado correctamente",
    "pd.exchangeSuccessDetail":
      "¡Nos comunicaremos con usted en un plazo de 48 horas para confirmar!",
    "pd.exchangeError": "¡Algo salió mal!",
    "pd.Products": "Productos",
    "pd.title.avaliate": "calificaciones y comentarios",
    "pd.btn.avaliate": "evaluar también",
    "pd.title.otherProducts": "otros productos de esta categoría",
    "pd.title.lastProducts": "últimos productos revisados",
    "pd.swal.ok.title": "¡Producto intercambiado correctamente!",
    "pd.swal.ok.text":
      "¡Dentro de 48 horas nos comunicaremos contigo para confirmar!",
    "pd.swal.notOk.title": "Hubo un error",
    "pd.swal.notOk.text": "Consulte al equipo de BigTester",
    "pd.access": "Acceso",
    "pd.productOwned": "Producto digital adquirido",
    "pd.productVoucher.detail.message": "Al canjear sus puntos por este producto recibirá un enlace proporcionado por uno de nuestros socios donde podrá obtener más información sobre cómo obtener el producto físico",
    "pd.exchange.prompt.message": "Está a punto de canjear {price} puntos por el producto {product}. ¿De verdad quieres hacer el cambio?",
    "pd.exchange.prompt.btn-confirm": "Sí",
    "pd.exchange.prompt.btn-cancel": "No",
    "pd.exchange.prompt.fail-message": "¡No pudimos hacer el cambio en este momento!",
    "pd.exchange.quantity-error.prompt.message": "Lo siento, pero el producto {product} no tiene ninguna cantidad disponible para intercambiar <span style='color:var(--instagram-hover);'> {quantity} </span> unidades. ¡Prueba con otro producto!",
    "pd.exchange.insufficient-points.prompt.message": "Lo siento, pero no tienes suficientes puntos para intercambiar, <span style='color:var(--instagram-hover);'> {balance} </span> de <span style='color:var(--primary);'> {price} </span> pts!",
    "pd.evaluate.dialog.title": "¿Qué les pareció el producto?",
    "pd.evaluate.dialog.stars.amount": "de 1 a 5",
    "pd.evaluate.dialog.textfield.placeholder": "Describe un poco lo que piensas de este producto ...", 
    "pd.evaluate.dialog.button.send": "Enviar opinión", 
    "pd.evaluate.dialog.bottom-message.error": "Debe especificar una calificación, si desea cancelar su evaluación, haga clic en el botón cerrar (esquina superior derecha de este cuadro de diálogo)",
    //

    // FreeScreen
    "fs.title": "Libre",
    "fs.titleText":
      "¡Obtén productos totalmente gratis! Acumula puntos y canjea por más productos, los que están disponibles en la sección de productos de la web, ¡ya que los de esta sección son gratis!",
    "fs.titleText.02":
      "All services and deliveries available below are the sole responsibility of the manufacturer.",
    "fs.sendOffer": "enviar oferta",
    "fs.searchPlaceholder": "Busca aquí...",
    "fs.offertsPerPage": "Ofertas por página:",
    "fs.search-field.placeholder": "Busca aquí...",
    "fs.pagination.of": "de cada",
    "ps.search-field.placeholder": "Buscar",
    //

    //
    // DrawingScreen
    "ds.title": "Sorteo",
    "ds.subTitle1":
      "¡El Gran Probador siempre dibuja para ti! Es muy simple participar!",
    "ds.subTitle2": "Es tuyo",
    "ds.subTitle3": "números de la suerte",
    "ds.when.Text1": "El sorteo se realizará el día",
    "ds.when.Text2": "¡Cuantos más cupones, más posibilidades de ganar!",
    "ds.when.Text3": "Switch",
    "ds.when.Text4": "50 puntos",
    "ds.when.Text5": "por ",
    "ds.when.Text6": "1 número de la suerte",
    "ds.btnTrade": "canjear 50 puntos por 1 cupón",
    "ds.btnGoToHome": "Volver a la página de inicio",

    "ds.p1.title": "¿Cómo obtener los números de la suerte?",
    "ds.p1.subTitle.pt1":
      "El número de la suerte es su cupón de la suerte. Gana un cupón por registrarse con el Gran Probador. Actualmente tienes uno",
    "ds.p1.subTitle.pt2": "puntos",
    "ds.p1.subTitle.pt3":
      "no Big Tester. Puedes cambiar tus puntos por Lucky Numbers. Cada número de la suerte es igual a 50 puntos. Es decir, cambias 50 puntos del Gran Probador por un Número de la Suerte. ¡Cuantos más números de la suerte tengas, más oportunidades tendrás de ganar el sorteo! ¡Buena suerte!",

    "ds.p2.title": "¿Cómo sabré si estoy dibujado?",
    "ds.p2.subTitle":
      "El Gran Probador se pondrá en contacto con usted a través del teléfono y/o correo electrónico que figura en su registro, haciéndole saber que usted fue el afortunado! Así que mantén tus contactos siempre actualizados",

    "ds.p3.title": "¿Es legal el dibujo?",
    "ds.p3.subTitle":
      "¡Claro! Nuestra rifa está regulada por la Caixa Econômica Federal. Número de certificado: 04.004907/2019. Revise las reglas y el certificado de dibujos",

    "ds.rules": "Regulamento",
    "ds.certificated": "Certificado",

    "ds.follow.title": "Rastrea tus números de la suerte",
    "ds.follow.tableTitle.pt1": "Cupones para",
    "ds.follow.tableTitle.pt2": "Draw on",
    "ds.follow.tableDescripción": "Descripción",
    "ds.follow.tableNumbers": "Número de cupón",
    "ds.follow.tableDate": "Date",
    "ds.follow.tableStatus": "Status",
    "ds.follow.nothing": "Todavía no tienes un cupón para ese sorteo",

    "ds.info": "El sorteo es solo para usuarios brasileños mayores de 18 años.",

    "ds.swal.ok.text": "¡Puntos canjeados con éxito por cupones!",
    "ds.swal.notOk.text": "No se han podido canjear tus puntos",
    "ds.swal.insuficientPoints.text":
      "No tienes suficientes puntos para realizar este intercambio.",
    "ds.fakePrize.drawingDate": " espera la definición del próximo sorteo",
    "ds.fakePrize.name": " ¡El próximo ganador podrías ser tú! ",
    //

    //
    // BlogScreen
    "bs.classifications": "categorías",
    //

    //
    // RankingScreen
    "rs.title": "Ranking",
    "rs.titleText": "Lorem Ipsum",
    "rs.1Place": "1º Lugar",
    "rs.1Place.text": "Lorem Ipsum",
    "rs.2Place": "2º Lugar",
    "rs.2Place.text": "Lorem Ipsum",
    "rs.3Place": "3º Lugar",
    "rs.3Place.text": "Lorem Ipsum",

    "rs.section2.title": "Saia na frente agora mesmo!",
    "rs.section2.warningTitle": "Aviso",
    "rs.section2.warningText": "lorem Ipsum",
    "rs.section2.position": "Posição",
    "rs.section2.name": "Nome",
    "rs.section2.sended": "Convites enviados",

    "rs.subTitle1": "Últimos ganhadores",
    "rs.subTitle2": "Regras e regulamentos",
    "rs.card.text1": "Lorem Ipsum",
    "rs.card.text2": "Lorem Ipsum",
    "rs.card.text3": "Lorem Ipsum",
    "rs.card.text4": "Lorem Ipsum",
    //

    //
    // InviteFriendsScreen
    "ifs.title": "Invita a tus amigos",
    "ifs.subTitle1":
      "Comparta con tantos amigos en las redes sociales y vea sus puntos venir*!",
    "ifs.subTitle2.pt1": "Son ",
    "ifs.subTitle2.pt2": "10 puntos",
    "ifs.subTitle2.pt3": "por cada amigo invitado*!",
    "ifs.footnotes":
      "*Estos puntos solo se agregarán a su cuenta cuando su amigo se registre a través de su enlace compartido y confirme el correo electrónico registrado con Big Tester. De lo contrario, los puntos no se acreditarán en su cuenta.",
    "ifs.copyLink": "Copiar URL",
    "ifs.share": "Compartir mi URL",
    "ifs.shareSuccess": "Compartido con éxito!",
    "ifs.LinkshareSuccess": "El enlace se ha copiado en su portapapeles!",
    "ifs.copied": "link copiado al portapapeles",
    "ifs.card1": "Cuantos más amigos se inscriban en su invitación",
    "ifs.card2":
      "¡Más puntos que ganar y aún así participar en el ranking mensual!",
    //

    //
    // MyAccountScreen
    "mas.title": "Mi cuenta",
    "mas.emailNotConfirmed.title":
      "Su correo electrónico no ha sido verificado. Revise su bandeja de entrada.",
    "mas.emailNotConfirmed.text":
      "¿No recibiste el correo electrónico? Revisa tu casilla de correo no deseado",
    "mas.emailNotConfirmed.btn": "reenviar correo electrónico",
    "mas.emailNotConfirmed.sent":
      "Correo electrónico de confirmación enviado a su cuenta de correo electrónico.",

    "mas.section1.title": "Perfil",
    "mas.section1.subTitle":
      "Alguna información puede ser visible para otras personas que usan Bigtester.",
    "mas.section1.name": "Nombre",
    "mas.section1.phone": "Móvil",
    "mas.section1.cpf": "informe a su cpf",
    "mas.section1.birthday": "Fecha de nacimiento",
    "mas.section1.birthdayPlaceholder": "Nacimiento",
    "mas.section1.sex": "Sexo",
    "mas.section1.mas": "Hombre",
    "mas.section1.fem": "Mujer",
    "mas.section1.btn": "guardar datos de perfil",

    "mas.section2.title": "Cambiar contraseña",
    "mas.section2.nowPassword": "Contraseña actual",
    "mas.section2.newPassword": "Nueva contraseña",
    "mas.section2.newPasswordAgain": "Confirmar nueva contraseña",
    "mas.section2.btn": "cambiar contraseña",

    "mas.section3.title": "Ubicacion",
    "mas.section3.subTitle":
      "Informacion esencial para enviar sus productos a su casa!",
    "mas.section3.cepPlaceholder": "Código postal",
    "mas.section3.cep": "CEP",
    "mas.section3.street": "Calle",
    "mas.section3.number": "Número",
    "mas.section3.add": "Suplemento",
    "mas.section3.district": "Vecindario",
    "mas.section3.city": "Ciudad",
    "mas.section3.state": "Estado",
    "mas.section3.btn": "guardar ubicacion",

    "mas.section3.2.title": "Intereses",
    "mas.section3.2.subTitle":
      "Tus intereses personalizan tus formas de ganar puntos. ¡Asegúrate de anotar!",
    "mas.section3.2.btn": "Guardar intereses",

    "mas.section4.title": "Sus calificaciones",
    "mas.seccion4.subTitulo": "Revise sus calificaciones abajo",
    "mas.section4.noAval": "No has hecho ninguna evaluación todavía.",

    "mas.section5.title": "Anular la suscripción",
    "mas.section5.btn": "Quiero ser dado de baja en el big tester.",
    "mas.upload.fail-message": "No se pudo cargar. Inténtalo de nuevo.",
    "mas.birthdate.parse.fail-message": "Error de fecha de nacimiento, por favor marque el campo correspondiente en el formulario.",
    "mas.birthdate.invalid-age": "Edad no válida <span style ='color:#f27474'>{age}</span> años. ¡Vuelve a comprobar tu fecha de nacimiento en el campo de fecha de nacimiento!",
    "mas.dispatch.session-update": "actualizado con éxito!",
    "mas.dispath.session-update.fail-message": "No se pudo actualizar. Vuelve a intentarlo más tarde.",
    "mas.dispatch.zip-code.notFound-message": "¡Zip no encontrado!",
    //

    //
    //  PointsScreen
    "pointsScreen.title": "Mis puntos",

    "pointsScreen.section1.title": "Equilibrio",
    "pointsScreen.section1.subTitle.pt1": "¡Tienes ",
    "pointsScreen.section1.subTitle.pt2": " puntos!",
    "pointsScreen.section1.btn1": "puntos de intercambio",
    "pointsScreen.section1.btn2": "misiones",
    "pointsScreen.section1.btn3": "campañas",
    "pointsScreen.section1.btn4": "cupones",

    "pointsScreen.section2.title": "Extracto de puntos",
    "pointsScreen.section2.col1": "Descripción",
    "pointsScreen.section2.col2": "Valor",
    "pointsScreen.section2.col3": "Fecha",
    "pointsScreen.section2.col4": "Vencimiento",

    "pointScreen.btn": "cargar más registros",
    //
    //

    //
    //  FollowScreen
    "follows.title": "Siga y gane puntos",
    "follows.titleText":
      "¡Sigue estos perfiles en Instagram y gana aún más puntos! Los puntos se contabilizarán una vez que se haya sumado la oferta contenida en la misión. Si hay problemas en el cómputo de sus puntos, contáctenos vía correo electrónico o WhatsApp enviando una impresión que acredite la adhesión a la misión.",
    "follows.accomplished": "Consumado",
    "follow.btn1": "puntos registrados",
    "follow.btn2": "ganar",
    "follow.points": "puntos",
    "follow.instagram": "Sigue al BigTester en Instagram",
    "follow.visitus": "Visitar perfil",
    //

    //
    // Pantalla de privacidad
    privacidad: "POLÍTICA DE PRIVACIDAD Y CONDICIONES GENERALES",
    "privacy.title.1": "1. ALCANCE:",
    "privacy.text.1": `Dado que Adclick Marketing do Brasil Ltda desarrolla su actividad empresarial en el área de campañas de marketing de permisos. En este sentido, la empresa presenta su política de privacidad indicando: las formas de recolección; uso de datos personales; salvaguarda; y seguridad adoptada en su tratamiento. Siempre respetando la opción elegida por los usuarios del sitio.`,

    "privacy.title.2": "2. RECOPILACIÓN DE DATOS:",
    "privacy.text.2":
      "Adclick Marketing do Brasil Ltda recopila datos personales, como nombre, dirección, correos electrónicos, números de teléfono, dirección IP, datos demográficos y de estilo de vida, a través de formularios en línea, cuestionarios, promociones de productos y servicios, pasatiempos. los fines indicados a continuación, los datos personales, pueden ser almacenados ya sea en forma no identificable o en forma agregada.Además, para poder llevar a cabo de manera efectiva diversas transacciones comerciales, Adclick Marketing do Brasil Ltda puede recopilar datos personales , ya sea de particulares, clientes o potenciales clientes a través de cuestionarios, solicitudes de información, o al contratar, comprar, suscribir o registrar determinados productos y servicios, incluyendo demostraciones gratuitas, ofertas especiales o participación en concursos o pasatiempos. Los datos recabados son adecuados, relevantes y no excesivos en relación al alcance, finalidades y servicios determinados, explícitos y de Adclick Marketing do Brasil Ltda. ",

    "privacy.title.3": "3. PATROCINADORES:",
    "privacy.text.3.1":
      "Adclick Marketing do Brasil Ltda constantemente realiza pasatiempos de recolección de datos para sus bases de campañas de marketing de permisos",
    "privacy.text.3.2":
      "Algunas de estas aficiones son patrocinadas por empresas, a las que se les transmiten los datos del usuario. Este proceso es muy transparente y visible. El usuario siempre dará su consentimiento expreso e individual para compartir sus datos con cada patrocinador, a través de una página creada a tal efecto y previo conocimiento de la política de privacidad de cada patrocinador. Podrás, en el momento oportuno del proceso de registro, elegir los patrocinadores con los que te comprometes a compartir o no tus datos. ",
    "privacy.text.3.3":
      "Todos los participantes registrados también comparten los datos con Caloga, patrocinador de Big Tester ubicado en 170 rue Raymond Losserand, 75014 París, Francia. La política de privacidad de Caloga está disponible en:",
    "privacy.text.link": "http://caloga.br.com/html/dperso.php",
    "privacy.text.3.4":
      "Actuando a cargo de la gestión de los elementos publicitarios de Leadsolution Inteligencia Digital, S.L, con CIF B98816499 y domicilio social en Valencia Avenida Corte Valencianas no 26, España.",

    "privacy.title.4": "4. ¿CÓMO SE UTILIZARÁN LOS DATOS?",
    "privacy.text.4":
      "La información proporcionada puede ser utilizada por Adclick Marketing do Brasil Ltda, así como por sus socios comerciales y clientes (para lo cual el usuario ha dado su consentimiento), para enviar información sobre sus productos y servicios, adaptando las comunicaciones a sus necesidades. e intereses. Además de estos fines, sus datos personales pueden utilizarse para: ",
    "privacidad.li.4.1":
      "Aproximación vía telefónica para ofrecer productos y servicios de socios",
    "privacy.li.4.2": "Desarrollo, prueba y prueba de productos",
    "privacy.li.4.3": "Realización de estudios y análisis de mercado",
    "privacy.li.4.4": "Comercialización de productos y servicios",
    "privacy.li.4.5": "Envío de mensajes publicitarios",
    "privacidad.li.4.6":
      "Optimización de campañas publicitarias, basadas en clustering.",

    "privacy.title.5":
      "5. DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN:",
    "privacy.text.5":
      "El usuario podrá ejercitar sus derechos de acceso, rectificación, cancelación y oposición respecto de sus datos personales. El ejercicio de los derechos deberá realizarse a través de cualquier medio que el usuario considere pertinente:",
    "privacidad.li.5.1":
      "A través de los enlaces de cancelación del servicio presentes en todas las comunicaciones enviadas por correo electrónico;",
    "privacy.li.5.2":
      "Por correo electrónico: exit@beeleads.com.br, con el asunto a eliminar;",
    "privacidad.li.5.3":
      "Por correo: Adclick Marketing do Brasil Ltda, Rua Sergipe, 475, 5to piso, São Paulo / SP. Teléfono de contacto: +55 (11) 43715300.",

    "privacy.title.6": "6. COOKIES:",
    "privacy.text.6":
      "En todos los sitios web de Adclick Marketing do Brasil Ltda se utilizan cookies, que le permiten mejorar el inicio de su sesión. Si vuelve a visitar este sitio web, la cookie reconoce que ya ha estado en ese sitio web. Además, en todos los sitios de pase tiempo de Adclick Marketing do Brasil Ltda, la IP del usuario / participante es recolectada con el fin de detectar posible fraude o comportamiento ilegítimo. ",

    "privacy.title.7": "7. SEGURIDAD Y ALMACENAMIENTO DE SUS DATOS:",
    "privacy.text.7.1":
      "Adclick Marketing do Brasil Ltda invierte fuertemente para mantener la seguridad de sus sitios y sistemas y para evitar el acceso a sus datos personales por parte de cualquier persona que no esté autorizada para hacerlo",
    "privacy.text.7.2":
      "Sin embargo, Internet no es un medio de comunicación 100% seguro y, por lo tanto, Adclick Marketing do Brasil Ltda no puede garantizar la seguridad de la información que nos envíe a través de la Web. Adclick Marketing do Brasil Ltda tampoco se hace responsable de las pérdidas. o daños causados ​​al usuario oa terceros como consecuencia de la pérdida de la confidencialidad de dicha información. ",

    "privacy.title.8":
      "8. OTROS SITIOS WEB Y LÍMITES DE RESPONSABILIDAD DE ADCLICK MARKETING DO BRASIL LTDA:",
    "privacy.text.8.1":
      "Los sitios web de Adclick Marketing do Brasil Ltda, pueden contener enlaces a otros sitios web que están fuera del control de Adclick Marketing do Brasil Ltda y que no están cubiertos por esta política de privacidad. En caso de que el usuario tenga acceso a otros sitios web utilizando estos enlaces, debe tener en cuenta que estos sitios pueden eventualmente solicitar y recopilar datos personales, de acuerdo con su política de privacidad, que puede ser diferente a la nuestra. ",
    "privacy.text.8.2":
      "También es importante notar que los sitios de Adclick Marketing do Brasil Ltda ocasionalmente pueden proporcionar enlaces a otros sitios creados y administrados por otras organizaciones. Dado que estos sitios no son de nuestra propiedad, no podemos garantizar su calidad, idoneidad o funcionamiento, y nuestra relación con dichos sitios no implica un aval ni de los productos y servicios vendidos o publicitados, ni de ninguna información presente en los sitios en cuestión. Le recomendamos encarecidamente que preste mucha atención y además tenga en cuenta que bajo ninguna circunstancia Adclick Marketing do Brasil Ltda será responsable, directa o indirectamente, de cualquier daño o perjuicio causado, o supuestamente causado, al usuario o familia en relación con el uso de los contenidos, bienes o servicios disponibles en cualquiera de estos sitios web. ",

    "privacy.title.9": "9. TRANSFERENCIA DE ACTIVOS",
    "privacy.text.9":
      "En el curso normal de los negocios de Adclick Marketing do Brasil Ltda, puede haber cambios en la participación y control de Adclick Marketing do Brasil Ltda, en empresas subsidiarias, afiliadas o afiliadas o eventualmente en las áreas de negocio del grupo. En estas situaciones, los datos personales pueden ser considerados activos transmisibles, siempre que se respeten los requisitos y autorizaciones legalmente necesarios para la transmisión. Lo mismo ocurrirá en el caso de Adclick Marketing do Brasil Ltda o cualquier parte o sector de actividad o de las empresas que forman el grupo, o empresas asociadas, subsidiarias o subsidiarias a adquirir, situaciones en las que los datos personales serán parte de los activos a transferir siempre que se respeten los requisitos y autorizaciones legalmente necesarios para su transmisión. ",

    "privacy.title.10": "10. POLÍTICA ANTISPAM",
    "privacy.text.10.1":
      "Adclick Marketing do Brasil Ltda está seriamente preocupado por el spam. Para demostrar nuestra dedicación a un entorno de Internet libre de spam, hemos instalado una política y procedimientos que garantizan la integridad de nuestros sistemas y mensajes. Por lo tanto, si alguno de nuestros suscriptores o participante en un hobby se involucra en un acto de spam, cancelaremos su cuenta o participación inmediatamente, sin previo aviso, debido a nuestra política anti-spam. Adclick Marketing do Brasil Ltda y sus sitios asociados siempre han hecho y continúan haciendo todo todo lo que se requiera dentro de nuestras políticas y procedimientos para mantener el estado de una organización libre de spam dentro de la comunidad de Internet. Creemos sinceramente que organizaciones como la nuestra, incluidos ASP, ESP e ISP, tienen la responsabilidad de garantizar que sus clientes y usuarios se involucran y utilizan prácticas de correo electrónico responsables y respetables. ",
    "privacy.subtitle.10": "¿Qué es el spam?",
    "privacy.text.10.2":
      "El spam consiste no solo en enviar un correo electrónico a un desconocido, sino que también se puede realizar mediante la publicación de mensajes en grupos de noticias, foros, clasificados o chats. Excluyendo los casos en los que la invitación al servicio / hobby se inserta en la firma de los comentarios y / o anuncios. ",

    "privacy.title.11":
      "11. ADCLICK MARKETING DO BRASIL LTDA'S ANTISPAM POLICY",
    "privacy.text.11.1":
      "Aunque una parte sustancial de la actividad de Adclick Marketing do Brasil Ltda es la organización de pasatiempos y consecuencias Aliente encarecidamente a sus participantes a que los revelen entre amigos y conocidos, ES TOTAL Y DESIGUALABLE CONTRA CUALQUIER FORMA DIRECTA O INDIRECTA DE USO DE SPAMMING. Por lo tanto, recomendamos que nuestros miembros y participantes solo envíen correos electrónicos a personas que conocen y NUNCA a personas que no conocen. ",
    "privacy.text.11.2":
      "Si cree que un participante o miembro de nuestros sitios le ha enviado spam o tiene preguntas sobre nuestra política de spam, comuníquese con nuestro departamento de Abuso de Spam en la siguiente dirección de correo electrónico: abuse@beeleads.com.br indicando todo información que podamos. Luego tomaremos de inmediato las medidas oportunas, una de las cuales es la expulsión inmediata del afiliado de nuestro servicio, como medida mínima. ",

    "privacy.title.12": "12. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD:",
    "privacy.text.12":
      "Adclick Marketing do Brasil Ltda se reserva el derecho en cualquier momento de actualizar o revisar la política de privacidad establecida en este documento con el fin de ajustar cualquier cambio legislativo y otras restricciones.",

    "privacy.title.13": "13. ACUERDO CON ESTA POLÍTICA DE PRIVACIDAD:",
    "privacy.text.13":
      "Se entiende que el usuario acepta las condiciones de esta política de privacidad al ingresar sus datos en cualquiera de los sitios propiedad de Adclick Marketing do Brasil Ltda. La cumplimentación de los formularios implica el consentimiento del usuario para el procesamiento y uso automatizado de los datos de de acuerdo con las condiciones de privacidad descritas en este documento. ",
    //

    // DESCADASTRAR
    "unsubs.title" : "¿Darse de baja? Wow ... ¿Eso fue algo que dijimos?",
    "unsubs.text" : "¿Estás seguro de que quieres perder todos tus puntos hasta ahora y dejarnos? Si tiene alguna pregunta, puede hacerla a través de las ",
    "unsubs.faq" : "preguntas frecuentes",

    "unsubs.placeholder" : "Confirme su email",
    "unsubs.submit" : "darse de baja",

    "unsubs.error.emailDoesntMatch" : "¡El correo electrónico ingresado no coincide con el correo electrónico del registro!",
    "unsubs.success" : "cuenta eliminada con éxito!",
    "unsubs.error" : "No pudimos eliminar su cuenta en este momento. Vuelva a intentarlo más tarde.",
    //

    //FAQ
    "faq.title" : "Preguntas Frecuentes",
    
    "faq.question.1" : "¿Cómo acumular puntos?",
    "faq.question.2" : "¿Dónde tomo las muestras?",
    "faq.question.3" : "¿Es realmente gratis?",
    "faq.question.4" : "Como eu faço para receber amostras na minha casa?",
    "faq.question.5" : "¿Cómo recibo muestras en mi domicilio?",
    "faq.question.6" : "¿Cómo me doy de baja?",
    "faq.question.7" : "No puedo registrarme, ¿qué debo hacer?",
    "faq.question.8" : "¿Problemas con el inicio de sesión y el registro?",
    "faq.question.9" : "Mis puntos no aumentan, ¿qué debo hacer?",

    "faq.answer.1" : "Obtienes puntos al: (a) Registrarse; (b) Responder a las preguntas de consumo y comportamiento en el sitio web; (c) completar su registro en nuestro sitio web; (d) Compartir los productos en su Facebook.",
    "faq.answer.2" : "Los productos se envían por correo a su domicilio. Después de solicitar un cambio de producto, el equipo de Big Tester se pone en contacto para confirmar su dirección y luego enviarla.",
    "faq.answer.3" : "¡¡¡SÍ!!! Todas las muestras se envían de forma totalmente gratuita. No tiene ningún costo.",
    "faq.answer.4" : "Recibir muestras en tu domicilio es muy sencillo: te registras en nuestra web, acumula la mayor cantidad de puntos posibles, solicita el intercambio de las muestras deseadas y te las enviamos a tu domicilio.",
    "faq.answer.5" : "Para recibir las muestras en casa, no basta con registrarse. Es necesario acumular puntos y solicitar el canje del producto deseado en el sitio web.",
    "faq.answer.6" : "Para darse de baja, visite: bigtester.com.br/descadastrar",
    "faq.answer.7" : "Si no puede completar su registro, elimine las cookies y las cachés de su navegador y vuelva a intentar completar el registro.",
    "faq.answer.8" : "Asegúrese de ingresar toda la información correctamente. No se permite 'espacio' después de la última letra o número en el campo de registro.",
    "faq.answer.9" : "Asegúrese de haber realizado todos los procesos para ganar puntos (como se describe en la primera cláusula).",
    //

    // not found
    "notFound.title" : "Ops...",
    "notFound.subtitle" : "Parece que aquí no hay nada.",
    "notFound.backMessage" : "¿Qué tal volver a la ",
    "notFound.backLink" : "página de inicio",
    "notFound.errorMessage" : "Error 404",
    //
  },
};
