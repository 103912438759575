import React, { useState, useEffect } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, Hidden, IconButton, Dialog, Card, CardContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const RootBox = styled(Box)`
    height: 100%;
    padding: 16px;
    background-color: #ECEFF1;
    border-left: 1px solid #B0BEC5;
    border-right: 1px solid #B0BEC5;
    z-index: 5;
    @media screen and (max-width: 960px) {
        height: auto;
        padding: unset;
        background-color: unset;
        border: none;
        position: fixed;
        bottom: 12px;
        right: 12px;
    }
`

const CategoryBtn = styled(IconButton)`
    z-index: 10 !important;
`

const CloseBtn = styled(IconButton)`
    position: absolute !important;
    right: 6px;
    top: 6px;
`

const StyledForm = styled(FormGroup)`
    margin-top: 16px;
    & .label-active .MuiFormControlLabel-label {
        color: var(--primary);
    }
`

const Stores = ({
    stores = [],
    selected = [],
    onSelect = () => { } }) => {

    const [modal, setModal] = useState(false)

    useEffect(() => {
        const hashevent = window.addEventListener('hashchange', (hash) => {
            const tmp = hash.newURL.split('#')
            if (!tmp[1]) setModal(false)
        })

        return () => window.removeEventListener('hashchange', hashevent)
    })

    const handleCheckbox = id => event => {
        onSelect(id)
    }

    // const handleExtra = (id) => event => {
    //     this.onExtraSelect(id)
    // }

    const Checkboxes = () => {
        return (
            <StyledForm>
                {stores.map(c => {
                    return (
                        <FormControlLabel
                            key={c.id}
                            control={
                                <Checkbox
                                    color="primary"
                                    onChange={handleCheckbox(c.id)}
                                    checked={selected.includes(c.id)}
                                />
                            }
                            label={c.name}
                            className={(selected.includes(c.id) ? 'label-active' : 'label')}
                        />
                    )
                })}
            </StyledForm>
        )
    }

    // const ExtraFilters = () => {
    //     return (<div style={{ marginTop: 24 }}>
    //         <Typography variant="caption" className="dark--text text-bold">
    //             {this.extraFilter.title}
    //         </Typography>
    //         <StyledForm>
    //             {this.extraFilter.options.map(option => {
    //                 return (
    //                     <FormControlLabel
    //                         key={option.id}
    //                         control={
    //                             <Checkbox
    //                                 color="primary"
    //                                 onChange={handleExtra(option.id)}
    //                                 checked={this.extraFilter.selecteds.includes(option.id)}
    //                             />
    //                         }
    //                         label={option.name}
    //                         className={(this.extraFilter.selecteds.includes(option.id) ? 'label-active' : 'label')}
    //                     />
    //                 )
    //             })}
    //         </StyledForm>
    //     </div>
    //     )
    // }

    const handleModal = (event) => {
        setModal(!modal)
    }

    return (
        <RootBox>
            <Hidden smDown>
                <Typography variant="caption" className="dark--text text-bold">
                    LOJAS
                </Typography>
                <Checkboxes />
            </Hidden>
            <Hidden mdUp>
                <Link href="#categorias">
                    <CategoryBtn className="secondary" onClick={() => handleModal(true)}>
                        <Icon path={mdiFilter} />
                    </CategoryBtn>
                </Link>
                <Dialog open={modal} fullScreen onClose={() => handleModal(false)}>
                    <Card elevation={0} style={{ position: 'relative' }}>
                        <Link href="">
                            <CloseBtn onClick={() => handleModal(false)}>
                                <CloseIcon />
                            </CloseBtn>
                        </Link>
                        <CardContent>
                            <Typography variant="body2" className="dark--text">
                                LOJAS
                            </Typography>
                            <Checkboxes />
                        </CardContent>
                    </Card>
                </Dialog>
            </Hidden>
        </RootBox>
    );
}

export default Stores;