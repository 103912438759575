import React from 'react';

import Layout from '../../layout/blank'
import Privacy from './components/privacy/Privacy';
import Topmenu from './components/top-menu-disconnected/Topmenu'

// import { Container } from './styles';

function privacy() {
  return (
    <Layout>
      <Topmenu />
      <Privacy />
    </Layout>
  );
}

export default privacy;