import React, { useState } from "react";
import {
  Zoom,
  Paper,
  Grid,
  Typography,
  Box,
  Hidden,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Avatar,
} from "@material-ui/core";
import { Smile } from "../../../../img/icons";
import UserDefault from "../../../../img/icons/userdefault.svg";

import styled from "styled-components";


const PaperBox = styled(Box)`
  ${(props) => props.styling}
  height: 100% !important;
  position: relative;
`;

const WinnerPaper = styled(Paper)`
  max-width: 200px;
  padding: 24px;
  min-height: 136px;
  position: relative;

  @media screen and (max-width: 960px) {
    max-width: "unset";
  }
`;

const SmileIcon = styled(Smile)`
  position: absolute;
  width: 35px !important;
  right: 25px;
  transform: rotate(${(Math.floor(Math.random() * 90) - 45).toString()}deg);
  &.pink path {
    fill: var(--danger);
  }
  &.green path {
    fill: var(--success);
  }
  &.blue path {
    fill: var(--primary);
  }
`;

const UserPaper = ({
  styling,
  winner,
  visible,
  delayed,
  smileColor,
  hideSmile
}) => {
  const [modal, setModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <PaperBox styling={styling}>
      {winner && (
        <Zoom
          in={visible}
          style={{ transitionDelay: delayed ? "300ms" : "100ms" }}
        >
          <WinnerPaper elevation={10}>
            <Hidden smDown>
              <SmileIcon className={smileColor} />
            </Hidden>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Avatar
                  src={winner.picture}
                  alt={winner.user.fullName}
                  style={{ width: 80, height: 80 }}
                >
                  <img src={UserDefault} alt={winner.user.fullName} />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{winner.user.fullName}</Typography>
                {/* <Typography variant="body2">{winner.location}</Typography> */}
                {/* <Button
                  variant="contained"
                  className="danger white--text"
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  + detalhes
                </Button> */}
              </Grid>
            </Grid>
          </WinnerPaper>
        </Zoom>
      )}

      <Dialog
        open={modal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Ganhador</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="p" fontWeight="fontWeightBold">
                Órgão de análise:{" "}
              </Typography>
              <br />
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                Concurso:{" "}
              </Typography>
              <br />
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                Data Concurso:{" "}
              </Typography>
              <br />
              <br />
              <br />

              <Typography variant="p" fontWeight="fontWeightBold">
                Resultado do Concurso:{" "}
              </Typography>
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                1º.{" "}
              </Typography>
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                2º.{" "}
              </Typography>
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                3º.{" "}
              </Typography>
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                4º.{" "}
              </Typography>
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                5º.{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="p" fontWeight="fontWeightBold">
                Número sorteado:{" "}
              </Typography>
              <br />
              <br />
              <Typography variant="p" fontWeight="fontWeightBold">
                Cumpom selecionado:{" "}
              </Typography>
              <br />
              <br />
              <Button
                style={{ color: "blue" }}
                onClick={() => {
                  setDetailModal(!detailModal);
                }}
              >
                <Typography variant="p" fontWeight="fontWeightBold">
                  Entenda o cupom selecionado
                </Typography>
              </Button>
            </Grid>
            {detailModal && (
              <Grid>
                <br />
                <Typography variant="p">
                  Será identificado o número da Série, por meio da utilização
                  dos algarismos da dezena simples do 1º, 2º, 3º e 4 º prêmios,
                  lidos de cima para baixo:
                </Typography>
                <br />
                <br />
                <Grid container alignItems={"center"} justify={"center"}>
                  <Grid item>
                    <Table>
                      <TableBody style={{ textAlign: "center" }}>
                        <TableRow>
                          <TableCell>1º Prêmio</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            3
                          </TableCell>
                          <TableCell>1</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>2º Prêmio</TableCell>
                          <TableCell>6</TableCell>
                          <TableCell>7</TableCell>
                          <TableCell>8</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            4
                          </TableCell>
                          <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>3º Prêmio</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            2
                          </TableCell>
                          <TableCell>3</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>4º Prêmio</TableCell>
                          <TableCell>3</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>7</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            5
                          </TableCell>
                          <TableCell>4</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>5º Prêmio</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>5</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item>
                    <i
                      style={{ color: "red", fontSize: "42px" }}
                      class="fas fa-arrow-down"
                    ></i>
                  </Grid>
                </Grid>
                <br />
                <br />
                <Typography variant="p">
                  Conforme o exemplo acima, a Série identificada seria a 3425.
                </Typography>
                <br />
                <br />
                <Typography variant="p">
                  Após, será identificado o número de ordem de referência para a
                  Série identificada, por meio da utilização dos algarismos da
                  unidade simples do 1º ao 5º prêmio, lidos de cima para baixo:
                </Typography>
                <br />
                <br />
                <Grid container alignItems={"center"} justify={"center"}>
                  <Grid item>
                    <Table>
                      <TableBody style={{ textAlign: "center" }}>
                        <TableRow>
                          <TableCell>1º Prêmio</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>3</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            1
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>2º Prêmio</TableCell>
                          <TableCell>6</TableCell>
                          <TableCell>7</TableCell>
                          <TableCell>8</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            2
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>3º Prêmio</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>2</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            3
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>4º Prêmio</TableCell>
                          <TableCell>3</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>7</TableCell>
                          <TableCell>5</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            4
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>5º Prêmio</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>4</TableCell>
                          <TableCell>1</TableCell>
                          <TableCell style={{ backgroundColor: "yellow" }}>
                            5
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item>
                    <i
                      style={{ color: "red", fontSize: "42px" }}
                      class="fas fa-arrow-down"
                    ></i>
                  </Grid>
                </Grid>
                <br />
                <br />
                <Typography variant="p">
                  Conforme o exemplo acima, o número de ordem identificado seria
                  o 12.345, cujo titular, da Série 3425, fará jus ao prêmio.
                </Typography>
                <br />
                <br />
                <Typography variant="p">
                  Caso o número de Série apurado conforme a regra esteja fora do
                  intervalo de Séries distribuído (0000 a 9999), dever-se-á ser
                  considerado contemplada a série de número imediatamente
                  superior e, somente na falta deste, o número imediatamente
                  inferior.
                </Typography>
                <br />
                <br />
                <Typography variant="p">
                  Na hipótese em que o número de ordem apurado não tenha sido
                  distribuído a nenhum participante, o prêmio será entregue ao
                  portador do número de ordem imediatamente superior distribuído
                  ou, na falta deste, ao imediatamente inferior distribuído,
                  sendo que esta busca continuará a partir do número de ordem
                  apurado, até que se encontre o número inferior imediatamente
                  distribuído.
                </Typography>
                <br />
                <br />
                <br />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </PaperBox>
  );
};

export default UserPaper;
