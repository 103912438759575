import React, { useState } from 'react';
import { Grid, Button, Dialog, Hidden, IconButton, SwipeableDrawer, List, ListItem, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import Login from '../../../components/auth';
import translate from '../../../locales/translate';
import { useHistory } from "react-router-dom";
import BtLogo from '../../../components/common/BtLogo';
import MenuIcon from '@material-ui/icons/Menu';

const AppBarContainer = styled(Grid)`
    position: absolute;
    top: 12px;
    align-items: center;
    max-width: 100vw;
    overflow-x: hidden;
    margin-bottom: 20px;
    @media screen and (min-width: 1280px) {
        top: 24px;
    }

    @media screen and (max-width: 426px) {
        position: unset;
        padding-top: 12px;
        align-self: flex-start;
    }
`
const ButtonBar = styled(Grid)`
    text-align: right;
    padding-right: unset !important;
    margin-right: -10px;
    .btn {
        margin-left: 20px;
        color: white;
        font-weight: 700;
    }
`
const LoginBtn = styled(Button)`
    margin-left: 20px !important;
    color: white !important;
`

const CloseBtn = styled(Button)`
    position: fixed !important;
    width: 30px !important;
    height: 36px !important;
    top: 8px;
    right: 8px;
    color: white;
    border-radius: 50% !important;
`

const buttons = [
    // {
    //     text: translate("nav.draw"),
    //     page: '/sorteio-detalhes'
    // },
    {
        text: translate('lp.navbar.how'),
        page: '#como-funciona',
    },
    {
        text: translate('lp.navbar.who'),
        page: '#who-we-are',
    },
    {
        text: translate('lp.navbar.winners'),
        page: '#ganhadores',
    }
]

const Appbar = ({ logo }) => {
    const [modal, setModal] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const history = useHistory();

    const handleModalEvent = event => {
        setModal(!modal)
    }

    const handleDrawer = (state) => {
        setDrawer(state)
    }

    const handleClick = (page) => {
        if(!page.includes('/sorteio-detalhes')) {
            let scroll = require('scroll-to-element')
            scroll(page)
            setDrawer(false)
        } else {
            history.push(page);
        }
    }

    return (
        <AppBarContainer container spacing={2} justify="center">
            <Hidden mdUp>
                <Grid item style={{ padding: 8, flexGrow: 1, paddingLeft: 32 }}>
                    {logo && <img src={logo} alt="logo" style={{ height: '30px' }}/>}
                    {!logo && <BtLogo type="default" height="30px" /> }
                </Grid>
                <Grid item style={{ alignItems: 'flex-end' }}>
                    <IconButton style={{ color: 'white' }} onClick={() => handleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                </Grid>
                <SwipeableDrawer className="Appbar-mobile-drawer" anchor="right" open={drawer} onClose={() => handleDrawer(false)} onOpen={() => handleDrawer(true)}>
                    <List>
                        {buttons.map((btn, index) => {
                            return (
                                <ListItem
                                    button
                                    key={index}
                                    className="white--text text-bold"
                                    style={{ minWidth: 250, minHeight: 50 }}
                                    onClick={() => handleClick(btn.page)}>
                                    {btn.text}
                                </ListItem>
                            )
                        })}
                        <ListItem
                            button
                            className="white--text text-bold success"
                            style={{ minWidth: 250, minHeight: 50 }}
                            onClick={handleModalEvent} >
                            {translate('lp.navbar.btn')}
                        </ListItem>
                    </List>
                </SwipeableDrawer>
            </Hidden>
            <Hidden smDown>
                <Container maxWidth="lg">
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            {logo && <img src={logo} alt="logo" style={{ height: '40px' }}/>}
                            {!logo && <BtLogo type="default" height="40px" /> }
                        </Grid>
                        <ButtonBar item xs={6}>
                            {buttons.map((btn, index) => (
                                <Button className="btn" key={index} onClick={() => handleClick(btn.page)}>
                                    {btn.text}
                                </Button>
                            ))}
                            <LoginBtn
                                variant="contained"
                                size="large"
                                className="success"
                                onClick={handleModalEvent}>
                                {translate('lp.navbar.btn')}
                            </LoginBtn>
                        </ButtonBar>
                    </Grid>
                </Container>
            </Hidden>
            <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
                <CloseBtn size="small" onClick={handleModalEvent}>
                    <CloseIcon className="white--text" />
                </CloseBtn>
                <Login />
            </Dialog>
        </AppBarContainer>
    );
}

export default Appbar;