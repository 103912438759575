import React, { useState } from 'react';
import { Grid, Button, Dialog, Hidden, IconButton, SwipeableDrawer, List, ListItem, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Login from '../../../../components/auth';
import translate from '../../../../locales/translate';
import { useHistory } from "react-router-dom";

const AppBarContainer = styled(Grid)`
    position: relative;
    align-items: center;
    overflow-x: hidden;
    background-color: rgb(41, 182, 246);
    padding:15px;
    @media screen and (min-width: 1280px) {
    }
`
const ButtonBar = styled(Grid)`
    text-align: right;
    padding-right: unset !important;
    margin-right: -10px;
    .btn {
        margin-left: 20px;
        color: white;
        font-weight: 700;
    }
`
const LoginBtn = styled(Button)`
    margin-left: 20px !important;
    color: white !important;
`

const CloseBtn = styled(Button)`
    position: fixed !important;
    width: 30px !important;
    height: 36px !important;
    top: 8px;
    right: 8px;
    color: white;
    border-radius: 50% !important;
`

const buttons = [
  {
    text: translate('ds.btnGoToHome'),
    page: '/',
  },
  
]

const Appbar = () => {
  const [modal, setModal] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const history = useHistory();

  const handleModalEvent = event => {
    setModal(!modal)
  }

  const handleDrawer = (state) => {
    setDrawer(state)
  }

  const handleClick = (page) => {
    history.push(page); 
    setDrawer(false)
  }

  return (
    <AppBarContainer container spacing={2} justify="center">
      <Hidden mdUp>
        <Grid item style={{ padding: 8, flexGrow: 1, paddingLeft: 32 }}>
          <Link to='/'>
            <img src="/img/bt_logos/bt_logo.png" alt="Big Tester" height="25px" />
          </Link>
        </Grid>
        <Grid item style={{ alignItems: 'flex-end' }}>
          <IconButton style={{ color: 'white' }} onClick={() => handleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <SwipeableDrawer className="Appbar-mobile-drawer" anchor="right" open={drawer} onClose={() => handleDrawer(false)} onOpen={() => handleDrawer(true)}>
          <List>
            {buttons.map((btn, index) => {
              return (
                <ListItem
                  button
                  key={index}
                  className="white--text text-bold"
                  style={{ minWidth: 250, minHeight: 50 }}
                  onClick={() => handleClick(btn.page)}>
                  {btn.text}
                </ListItem>
              )
            })}
            <ListItem
              button
              className="white--text text-bold success"
              style={{ minWidth: 250, minHeight: 50 }}
              onClick={handleModalEvent} >
              {translate('lp.navbar.btn')}
            </ListItem>
          </List>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Link to="/">
                <img src="/img/bt_logos/bt_logo.png" alt="Big Tester" height="40px" />
              </Link>
            </Grid>
            <ButtonBar item xs={6}>
              {buttons.map((btn, index) => (
                <Button className="btn" key={index} onClick={() => handleClick(btn.page)}>
                  {btn.text}
                </Button>
              ))}
              <LoginBtn
                variant="contained"
                size="large"
                className="success"
                onClick={handleModalEvent}>
                {translate('lp.navbar.btn')}
              </LoginBtn>
            </ButtonBar>
          </Grid>
        </Container>
      </Hidden>
      <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
        <CloseBtn size="small" onClick={handleModalEvent}>
          <CloseIcon className="white--text" />
        </CloseBtn>
        <Login />
      </Dialog>
    </AppBarContainer>
  );
}

export default Appbar;