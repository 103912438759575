import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import translate from "../../../locales/translate";

import "./animation.css";
import "./drawer.css";

import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Hidden,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  Grid,
  Box,
} from "@material-ui/core";

import styled from "styled-components";
import BtLogo from "../BtLogo";
import ProfileMenu from "./ProfileMenu";
import MobileList from "./MobileList";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { withRedux } from "../../../helpers/redux";
import ApiService from "../../../helpers/ApiService";

import { 
  mdiRocketLaunch, 
  mdiAlertDecagram, 
  mdiAccountMultiple,
  mdiTrophy,
  mdiForum,
  mdiGift,
  mdiStar,
  mdiHeart
} from '@mdi/js';
import NavBtn from "./NavBtn";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from '@material-ui/icons/Menu';

const FloatPrice = styled(Box)`
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  pointer-events: all;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  :hover {
    background-color: var(--primary-hover);
  }
`;
const FloatPriceMobile = styled(Box)`
  position: absolute;
  right: 0;
  top: 56px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  padding: 4px 8px;
  border-radius: 0 0 0 4px;
  background-color: var(--primary);
`;
const ButtonBar = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: 2px;
  top: ${(props) => (props.expanded ? "" : "")};
  width: 100%;
`;

const StyledAppbar = styled(AppBar)`
  box-shadow: none !important;
  background-color: var(--primary) !important;
`;

const StyledContainer = styled(Container)`
  
`;

const mainButtons = [
  // {
  //   text: "cupons",
  //   page: "/cupons",
  //   icon: {
  //     font: "fa",
  //     type: "fas fa-barcode",
  //   },
  // },
  // {
  //   text: "blog",
  //   page: "/blog",
  //   icon: {
  //     font: "fa",
  //     type: "fas fa-newspaper",
  //   },
  // },
  {
    text: translate("nav.invite"),
    page: "/convites",
    icon: mdiAccountMultiple,
  },
];

const secondaryButtons = [
  {
    color: "mission",
    text: translate("nav.mission"),
    icon: mdiRocketLaunch,
    page: "/missoes",
    key: "missoes",
  },
  {
    color: "campaign",
    text: translate("nav.campaign"),
    icon: mdiAlertDecagram,
    page: "/campanhas-patrocinadas",
    key: "campanhas-patrocinadas",
  },
  {
    color: "cons",
    text: translate("nav.cons"),
    icon: mdiForum ,
    page: "/perfil",
    key: "perfil",
  },
  {
    color: "product",
    text: translate("nav.product"),
    icon: mdiGift,
    page: "/produtos",
    key: "produtos",
  },
  {
    color: "free",
    text: translate("nav.free"),
    icon: mdiStar,
    page: "/ofertas",
    key: "ofertas",
  },
  // only disabled
  // {
  //   color: "draw",
  //   text: translate("nav.draw"),
  //   icon: {
  //     font: null,
  //     type: "emoji_events",
  //   },
  //   page: "/sorteio",
  // },
  {
    color: "draw",
    text: translate("nav.draw"),
    icon: mdiTrophy,
    page: "/sorteio",
  },
  {
    color: "follow",
    text: translate("nav.follow"),
    icon: mdiHeart,
    page: "/seguir",
    key: "seguir",
  },
];

const secondaryButtonsMobile = [
  {
    text: translate("nav.mission"),
    icon: mdiRocketLaunch,
    page: "/missoes",
    key: "missoes",
  },
  {
    text: translate("nav.campaign"),
    page: "/campanhas-patrocinadas",
    icon: mdiAlertDecagram,
  },
  {
    text: translate("nav.cons"),
    page: "/perfil",
    icon: mdiForum,
  },
  {
    text: translate("nav.product"),
    icon: mdiGift,
    page: "/produtos",
    key: "produtos",
  },
  {
    text: translate("nav.free"),
    page: "/ofertas",
    icon: mdiStar,
  },
  // only disabled
  // {
  //   text: translate("nav.draw"),
  //   icon: {
  //     font: null,
  //     type: "emoji_events",
  //   },
  //   page: "/sorteio",
  // },
  {
    color: "draw",
    text: translate("nav.draw"),
    icon: mdiTrophy,
    page: "/sorteio",
  },
  {
    color: "follow",
    text: translate("nav.follow"),
    icon: mdiHeart, //color: red
    page: "/seguir",
    key: "seguir",
  },
  // {
  //   text: "blog",
  //   page: "/blog",
  //   icon: {
  //     font: "fa",
  //     type: "fas fa-newspaper",
  //   },
  // },
  {
    text: translate("nav.invite"),
    page: "/convites",
    icon: mdiAccountMultiple,
  },
];

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
      language: state.language,
      pagination: state.pagination,     
    }),
    shallowEqual
  );
};

const NavBar = () => {
  const mobile = useMediaQuery("(max-width:960px)");
  const laptop = useMediaQuery("(max-width: 1366px)"); // em telas laptop o tamanho dos botões impede a correta visualização do logo então o uso desta consulta proporciona comportamento normal
  const maximumWidthToShowDesktopButtons = useMediaQuery("(min-width: 1200px)"); // largura ate onde menu exibe normalmente
  const { user, language, pagination } = useStore();
  const dispatch = useDispatch();
  let history = useHistory();
  //const [text, setText] = React.useState(0)
  const [expanded, setExpanded] = useState(true);
  const [drawer, setDrawer] = useState(false);

  const updatePageOffset = () => {
    setExpanded(window.pageYOffset < 250);
  };

  const handleLogout = () => {
    ApiService.logout();
    history.push("/");
    dispatch({
      type: "SET_USER",
      user: {},
    });
  };

  useEffect(() => {
    // if (!maximumWidthToShowDesktopButtons || mobile) {
    if (mobile) {
      setExpanded(false);
    } else {
      window.addEventListener("scroll", updatePageOffset);

      return function cleanUp() {
        window.removeEventListener("scroll", updatePageOffset);
      };
    }
  }, [mobile, maximumWidthToShowDesktopButtons]);

  // Event Handlers
  const handleDrawer = (state) => {
    setDrawer(state);
  };

  const toPage = (page) => {
    history.push(page);
  };

  React.useEffect(() => {
   window.scrollTo(0,100);
   setTimeout(() => {
     window.scrollTo(0,0)
   }, 100);
  }, [user, user.wallet.balance])

  return (
    <StyledContainer style={{ height: mobile ? "60px" : "100px" }}>
      <StyledAppbar
        style={{ height: expanded ? "100px" : "60px" }}
        position="fixed"
        className={
          (mobile ? "" : "appbar ") +
          (expanded && maximumWidthToShowDesktopButtons ? "expanded" : "")
        }
      >
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Toolbar
            style={{
              height: "100%",
              minHeight: 60,
              alignItems: "flex-start",
              marginTop: 8,
              padding: 0,
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ flex: 1 }}>
                  <a href="/inicio" className="cursor">
                    <BtLogo
                      type="default"
                      style={{
                        zIndex: 10,
                        width: laptop ? "140px" : "150px",
                        height: laptop ? "auto" : "auto",
                      }}
                    />
                  </a>
                </div>
                {/**smDown */}
                <Hidden smDown>
                  {expanded && maximumWidthToShowDesktopButtons &&
                    mainButtons.map((btn, index) => {
                      return language === "en-US" && btn.text === "blog" ? (
                        " "
                      ) : (
                        <Link to={btn.page} key={index}>
                          <Button
                            key={`btn_${index}`}
                            className="primary white--text"
                            style={{
                              textDecoration: "none",
                              marginRight: 4,
                              marginLeft: 4,
                            }}
                          >
                            {btn.text}
                          </Button>
                        </Link>
                      );
                    })}
                  {maximumWidthToShowDesktopButtons && (
                    <ProfileMenu expanded={expanded} logout={handleLogout} />
                  )}
                </Hidden>

                {!maximumWidthToShowDesktopButtons && (
                  <>
                    {/**mdUP */}
                    <IconButton
                      className="white--text"
                      onClick={() => handleDrawer(true)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <SwipeableDrawer
                      className="mobile-drawer"
                      anchor="right"
                      open={drawer}
                      onClose={() => handleDrawer(false)}
                      onOpen={() => handleDrawer(true)}
                    >
                      <MobileList
                        buttons={secondaryButtonsMobile}
                        logout={handleLogout}
                      />
                    </SwipeableDrawer>
                  </>
                )}
              </Grid>

              {maximumWidthToShowDesktopButtons && (
                <>
                  {/**smDown */}
                  <ButtonBar item xs={12} id="button-bar" style={{ position: expanded ? "relative" : "absolute"}} >
                    {expanded && (
                      <FloatPrice
                        onClick={() => {
                          history.push("/meus-pontos");
                        }}
                        style={{ fontSize: laptop ? "14px" : "" }}
                      >
                        <MonetizationOnIcon 
                          style={{
                            marginRight: laptop ? 2 : 4,
                            fontSize: laptop ? "14px" : "1rem",
                          }}
                        />

                        <span
                          className="text-bold"
                          style={{ fontSize: laptop ? "14px" : "1rem", marginLeft: 4 }}
                        >
                          {user.wallet ? <b>{user.wallet.balance}</b> : "0"}{" "}
                          {translate("ss.btn.pt2")}
                        </span>
                      </FloatPrice>
                    )}
                    <Grid
                      container
                      justify="flex-end"
                      style={{ marginRight: 150 }}
                    >
                      <Grid item>
                        {secondaryButtons.map((btn, index) => {
                          return btn.color === pagination ? (
                            <NavBtn
                              key={index}
                              className="primary white--text"
                              style={{
                                fontSize: laptop ? "14px" : "0.9rem",
                                background:
                                  "linear-gradient(45deg, #29aae9 0%,#29aae9 100%)",
                              }}
                              icon={btn.icon}
                              text={btn.text}                        
                              onClick={() => toPage(btn.page)}
                            />
                          ) : (
                            <>
                              <NavBtn
                                key={index}
                                className="primary white--text"
                                style={{
                                  fontSize: laptop ? "12px" : "1rem",
                                  marginLeft: laptop ? 2 : 3,
                                  marginRight: laptop ? 2 : 3,
                                }}
                                icon={btn.icon}
                                onClick={() => toPage(btn.page)}
                                text={btn.text}
                              />
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </ButtonBar>
                </>
              )}
            </Grid>
          </Toolbar>
        </Container>
        <Hidden mdUp>
          <FloatPriceMobile>
            <MonetizationOnIcon style={{ marginRight: laptop ? 2 : 4, fontSize: laptop ? "12px" : "1rem", }} />
            <span className="text-bold" style={{ fontSize: laptop ? "12px" : "1rem", marginLeft: 4 }} >
              {user.wallet ? <b>{user.wallet.balance}</b> : "0"}{" "}
              {translate("ss.btn.pt2")}
            </span>
          </FloatPriceMobile>
        </Hidden>
      </StyledAppbar>
    </StyledContainer>
  );
};

export default withRedux(NavBar);
