import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import InputMask from "react-input-mask";

const InputCustom = styled(TextField)`
    max-width: 50px;
    margin: 16px 4px;
    .MuiInputBase-input {
        font-size: 2rem;
        text-align: center;
        padding: 8px;
    }
    .MuiOutlinedInput-input {
        background-color: white;
    }
`;

const InputCode = (props) => {

    return(
        <>
            {(
                <InputMask mask="9" onChange={props.onChange}>
                    {(inputProps) => (
                    <InputCustom
                        id={props.id}
                        variant="outlined"
                        name={props.id}
                        inputRef={props.register({
                            required: true,
                            pattern: /^\([1-9]{2}\)\s[0-9]{5}-[0-9]{4}$/,
                        })}
                        inputProps={{ inputMode: "numeric" }}
                    />
                    )}
                </InputMask>
            )}
        </>
    );
}
export default InputCode;