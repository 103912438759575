import React from "react";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { withRedux } from "../../../helpers/redux";
import translate from "../../../locales/translate";
// import ApiService from '../../../helpers/ApiService';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const ProfileImage = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 30px;
  margin-left: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: var(--primary) !important;
  height: 50px;
  &:hover {
    background-color: var(--primary-hover) !important;
    color: white !important;
  }
`;
const LogoutItem = styled(MenuItem)`
  height: 50px;
  color: var(--danger) !important;
  &:hover {
    background-color: var(--danger-hover) !important;
    color: white !important;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};

const menuItemTranslations = {
  myaccountLabel: translate("nav.avatar.menu.account"),
  mypointsLabel: translate("nav.avatar.menu.mypoints"),
  mycupons: translate("nav.avatar.menu.mycupons"),
  exitLabel: translate("nav.avatar.menu.exit"),
};

const menuItems = [
  {
    text: menuItemTranslations.myaccountLabel,
    icon: "person",
    page: "/minha-conta",
  },
  {
    text: menuItemTranslations.mypointsLabel,
    icon: "monetization_on",
    page: "/meus-pontos",
  },
  // {
  //   text: menuItemTranslations.mycupons,
  //   icon: "card_giftcard",
  //   page: "/cupons",
  // },
];

const ProfileMenu = ({ expanded, logout = () => {} }) => {
  const [menu, setMenu] = React.useState(false);
  const { user } = useStore();
  // const dispatch = useDispatch()
  let history = useHistory();

  const handleMenu = (event) => {
    setMenu(event.currentTarget);
  };
  const handleClose = () => {
    setMenu(null);
  };

  const handleChangePage = (page) => {
    history.push(page);
  };

  const ScoreBtn = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      &nbsp;
      <MonetizationOnIcon style={{ marginRight: 6, fontSize: 18 }} />
      <span>
        <b>{user.wallet ? user.wallet.balance : "0"}</b>{" "}
        {translate("ss.btn.pt2.alt")}
      </span>
    </div>
  );

  const UserName = () => (
    <Typography
      variant="body1"
      className="text-bold"
      style={{ textTransform: "capitalize" }}
    >
      {user.fullName || ""}
    </Typography>
  );

  return (
    <div>
      <Button
        className="primary white--text"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenu}
        style={{ zIndex: 5, marginLeft: 4 }}
      >
        {expanded ? <UserName /> : <ScoreBtn />}
        {user.avatar ? (
          <ProfileImage src={user.avatar} />
        ) : (
          <PersonIcon style={{ marginLeft: 8 }} />
        )}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left",
        // }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {menuItems.map((item, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => handleChangePage(item.page)}
          >
            {item.icon === 'person' && <PersonIcon style={{ marginRight: 12 }} />}
            {item.icon === 'monetization_on' && <MonetizationOnIcon style={{ marginRight: 12 }} />}
            {item.text}
          </StyledMenuItem>
        ))}
        <LogoutItem className="danger--text" onClick={logout}>
          <ExitToAppIcon style={{ marginRight: 12 }} />
          {menuItemTranslations.exitLabel}
        </LogoutItem>
      </Menu>
    </div>
  );
};

export default withRedux(ProfileMenu);
