import React from "react";
import { Paper, Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import translate from "../../../locales/translate";

const UnregisterButton = styled(Button)`
  background-color: #eee !important;
  color: var(--darktext) !important;
  &:hover {
    background-color: #ddd !important;
  }
`;

const Unregister = () => {
  let history = useHistory();

  return (
    <Paper style={{ padding: 16 }} className="dark--text">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" paragraph>
            <b>{translate("mas.section5.title")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <UnregisterButton
            fullWidth
            onClick={() => {
              history.push("/descadastrar");
            }}
          >
            {translate("mas.section5.btn")}{" "}
          </UnregisterButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Unregister;
