/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ApiService from "../../../helpers/ApiService";
import {
  Container,
  Grid,
  Button,
  Tabs,
  Tab,
  Fade,
  Typography,
  Hidden,
} from "@material-ui/core";
// import Selo from '../../../../publicselo.png'
import ScrollToElement from "scroll-to-element";
import styled from "styled-components";
import Swal from "sweetalert2";
import LuckyNumbersTable from "./LuckyNumbersTable";
import { withRedux } from "../../../helpers/redux";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Formatter from "../../../helpers/Formatter";
import translate from "../../../locales/translate";
import * as translations from "../../../locales/text";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
// import { faBlackberry } from "@fortawesome/free-brands-svg-icons";

const StyledTab = styled(Tab)`
  & * {
    text-transform: capitalize !important;
  }
`;

// const DrawingDate = styled(Typography)`
//   padding: 8px 24px;
//   margin-top: 8px !important;
//   color: black;
//   font-weight: 700 !important;
//   display: inline-block;
//   border-radius: 5px;
// `;

const TradeButton = styled(Button)`
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  padding: 0 16px !important;
  /* width: 200px; */
  height: 50px;
  /* max-width: 90vw; */

  @media screen and (max-width: 960px) {
    margin-bottom: 32px !important;
    width: 100%;
  }
`;

const PrizeImageGrid = styled(Grid)`
  padding: 0 24px !important;
  & img {
    border: 5px solid white;
    box-shadow: 0 0 10px grey;
  }
  @media screen and (max-width: 960px) {
    padding: 0 !important;
  }
`;

const InfoDestaque = styled.strong`
  color: black;
  background-color: var(--secondary-hover);
  padding: 4px 8px;
  border-radius: 4px;
`;

const DocGrid = styled(Grid)`
  border: 1px solid lightgray;
`;

const docs = [
  {
    name: translate("ds.rules"),
    url:
      "https://blds-bigtesterv2.s3-sa-east-1.amazonaws.com/regulamentos/2021_Regulamento_0202100526.pdf",
  },
  {
    name: translate("ds.certificated"),
    url:
      "https://blds-bigtesterv2.s3-sa-east-1.amazonaws.com/regulamentos/2021_Certificado_autorizacao_040117222021.pdf",
  },
];

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
      language: state.language,
    }),
    shallowEqual
  );
};

// dados fake apenas para exibir mensagens de espera pelo próximo sorteio
const fakePrize = {
  createdAt: 1613485495980,
  drawingDate: translate('ds.fakePrize.drawingDate'),
  endDate: 1633996800000,
  id: 0,
  name: translate('ds.fakePrize.name'),
  picture: "http://lorempixel.com/640/480", // checar com o Mateus se a imagem ser aleatória fixa
  startDate: 1617816407716,
  subdomain: 1,
  updatedAt: 1613485495980,
  winner: null
};

const DrawPageComponent = () => {
  const [prize, setPrize] = React.useState({});
  const [docTabs, setDocTabs] = React.useState(0);
  const [luckyNumbers, setLuckyNumbers] = React.useState([]);
  const [disableTradeButton, setDisableTradeButton] = React.useState(false);
  const dispatch = useDispatch();

  const { user, language } = useStore();
  var SwaltextTranslation = {
    textOkValue: translations.default[language]["ds.swal.ok.text"],
    textNotOkValue: translations.default[language]["ds.swal.notOk.text"],
    textInsuficientPoints:
      translations.default[language]["ds.swal.insuficientPoints.text"],
  };

  const getPrize = () => {
    ApiService.get("/raffle/running").then(({ data }) => {
      // caso não haja sorteios o objeto data chega como nulo então desabilitamos o botão de troca 
      // e exibimos mensagens de espera para que o usuário fique ciente de que ainda não há sorteio
      if (!data) {
        setDisableTradeButton(true);
        setPrize(fakePrize); // mostra mensagem de aguarde o prox sorteio
      } else {
        setDisableTradeButton(false);
        setPrize({
          ...data,
          startDate: new Date(data.startDate).toLocaleDateString("pt-BR"),
          endDate: new Date(data.endDate).toLocaleDateString("pt-BR"),
          drawingDate: new Date(data.drawingDate).toLocaleDateString("pt-BR"),
        });
      }
    });
    ApiService.get("/luckynumbers", {
      skip: 0,
      limit: 100,
    }).then(({ data: { luckyNumbers } }) => {
      setLuckyNumbers(luckyNumbers.map((l) => Formatter.formatLuckyNumber(l)));
      //let vc = vouchers.map(v => Formatter.formatLuckyNumber(v));
      //console.log(vc);
    });
  };

  const handleTradePoints = () => {
    ApiService.post("/luckynumbers")
      .then(({ data }) => {
        if (data === "Inssuficient Points.") {
          Swal.fire({
            text: SwaltextTranslation.textInsuficientPoints,
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          setLuckyNumbers([...luckyNumbers, Formatter.formatLuckyNumber(data)]);

          Swal.fire({
            text: SwaltextTranslation.textOkValue,
            icon: "success",
            confirmButtonText: "Ok",
          });
          getPrize();

          ApiService.autoLogin(true).then((user) => {
            dispatch({
              type: "SET_USER",
              user,
            });
          });
        }

        // ScrollToElement("#vouchers", {
        //   duration: 500,
        //   offset: -25,
        // });
      })
      .catch((err) => {
        Swal.fire({
          text: SwaltextTranslation.textNotOkValue,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const handleTabsChange = (event, newValue) => {
    setDocTabs(newValue);
    setTimeout(() => {
      ScrollToElement("#doctabs");
    }, 500);
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPrize();
    changePagination("draw");
    changeHelmet([{ name: "draw", content: "draw content" }]);
  }, []);

  const luckyNumbersTableTitle = {
    text: translate("ds.coupons.table.title", {
      prize: prize.name,
      date: prize.drawingDate,
    }),
  };

  return (
    <Container maxWidth="lg">
      <Grid container justify="center" style={{ padding: "32px 0" }}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <div className="dark--text" style={{ width: "100%" }}>
              <Hidden mdUp>
                <BtHowItWorks video="https://www.youtube.com/watch?v=9B628qwzZqQ" />
              </Hidden>
              <Typography variant="h4" className="dark--text text-bold">
                {translate("ds.title")}
              </Typography>
              {/* <Typography variant="body2">
                {translate("ds.subTitle1")}
              </Typography> */}
              <Typography variant="body1" paragraph>
                {translate("ds.subTitle2")}{" "}
                <strong style={{ color: "black" }}>
                  {luckyNumbers.length} {translate("ds.subTitle3")}
                </strong>
                <br />
                {translate("ds.when.Text1")}{" "}
                <InfoDestaque>{prize.drawingDate || "aguardando novo sorteio"}</InfoDestaque>
              </Typography>
              <Typography variant="body2" style={{ marginTop: 18 }}>
                {translate("ds.when.Text2")}
                <br></br>
                {translate("ds.when.Text3")} <b>{translate("ds.when.Text4")}</b>{" "}
                {translate("ds.when.Text5")} <b>{translate("ds.lucky")}</b>
              </Typography>
              <TradeButton className={disableTradeButton ? 'default' : 'success'} onClick={handleTradePoints} disabled={disableTradeButton}>
                {translate("ds.btnTrade")}
              </TradeButton>
            </div>
          </Grid>
        </Grid>
        <PrizeImageGrid item xs={12} md={6}>
          <Hidden mdDown>
            <BtHowItWorks video="https://www.youtube.com/watch?v=9B628qwzZqQ" />
          </Hidden>
          <Typography variant="h5" paragraph className="dark--text text-bold">
            {prize.name}
          </Typography>
          <img
            src={prize.picture}
            width="100%"
            alt={prize.name}
            style={{ maxWidth: "95%" }}
          />
        </PrizeImageGrid>
        <Grid
          id="vouchers"
          item
          xs={12}
          style={{ marginTop: 42, marginBottom: 24 }}
        >
          <Typography variant="h5" className="text-bold dark--text">
            {translate("ds.follow.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <LuckyNumbersTable luckyNumbers={luckyNumbers} title={luckyNumbersTableTitle.text} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 48 }}>
          <Grid container>
            <div className="dark--text">
              <Typography variant="body2" paragraph>
                <b style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                  {translate("ds.p1.title")}
                </b>
                <br></br>
                {translate("ds.p1.subTitle.pt1")}{" "}
                <b style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                  {user && user.wallet ? user.wallet.balance : 0}{" "}
                  {translate("ds.p1.subTitle.pt2")}{" "}
                </b>
                {translate("ds.p1.subTitle.pt3")}
              </Typography>
              <Typography variant="body2" paragraph>
                <b style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                  {translate("ds.p2.title")}
                </b>
                <br></br>
                {translate("ds.p2.subTitle")}
              </Typography>
              <Typography variant="body2" paragraph>
                <b style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                  {translate("ds.p3.title")}
                </b>
                <br></br>
                {translate("ds.p3.subTitle")}
              </Typography>
              <Typography variant="body2" paragraph>
                <b style={{ fontWeight: "bolder", fontSize: "1.1rem" }}>
                  {translate("ds.info")}
                </b>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <DocGrid item xs={12} id="doctabs">
          <Tabs
            value={docTabs}
            indicatorColor="primary"
            onChange={handleTabsChange}
            style={{ padding: "0 12px" }}
          >
            {docs.map((doc, index) => (
              <StyledTab key={`tab${index}`} index={`dob-tab-${index}`} label={doc.name} />
            ))}
          </Tabs>
          {docs.map((doc, index) => {
            return index === docTabs ? (
              <Fade in={true} key={`doc-${index}`} timeout={500}>
                <div style={{ height: "90vh", paddingTop: 8 }}>
                  <iframe
                    src={doc.url}
                    width="100%"
                    height="100%"
                    title={doc.name}
                  />
                </div>
              </Fade>
            ) : (
              ""
            );
          })}
        </DocGrid>
      </Grid>
    </Container>
  );
};

export default withRedux(DrawPageComponent);
