import React, { useState, useEffect } from 'react';

import { Grid, Zoom } from '@material-ui/core';

import Product from './Product'
// import { array } from 'prop-types';
import ApiService from '../../../../helpers/ApiService';

const List = ({ alwaysVisible }) => {
    const [visible, setVisible] = useState(false)
    const [products, setProducts] = useState([])

    const isVisible = () => {
        let el = document.getElementById('productsTop')
        if (window && el) setVisible((window.pageYOffset + window.innerHeight) >= (el.offsetTop + 200))
    }

    const getProducts = () => {
        ApiService.get('/products', {
            limit: 8
        }).then(({ data }) => {
            setProducts(data.products)
        })
    }

    useEffect(() => {
        getProducts()
        if (alwaysVisible) setVisible(true)
        else {
            window.addEventListener('scroll', isVisible)

            return function cleanUp() {
                window.removeEventListener('scroll', isVisible)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container spacing={2} justify="center">
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {products.map((p, index) => {
                        return (
                            <Zoom in={visible} key={index} style={{ transitionDelay: ((index * 100) + 'ms') }}>
                                <Grid item xs={12} md={3}>
                                    <Product item={p} />
                                </Grid>
                            </Zoom>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default List;