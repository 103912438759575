import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
//Importando Páginas
import "./themes/main.css";
import LandingPage from "./pages/LandingPage";

import Inicio from "./pages/StartPage";

import Campanhas from "./pages/CampaignsPage";
import CampaignID from "./pages/CampaignsPage/[slug]";
import CampanhaIdParticipar from "./pages/CampaignsPage/[slug]/participar";
import CampaignWinners from "./pages/CampaignsPage/components/CampaignWinners";
import CampaignLegacy from "./pages/CampaignsPage/[slug]/legacy";

import Convites from "./pages/InvitePage";
import Ofertas from "./pages/OffersPage";

import Perfil from "./pages/UserPage";
import Missoes from "./pages/MissionsPage";
// import CouponsPage from "./pages/CuponsPage";

import MinhaConta from "./pages/MyAccountPage";
import MeusPontos from "./pages/MyPointsPage";

import ProductsPage from "./pages/ProductsPage";
import ProductsPageHistory from "./pages/ProductsPage/productsHistory";
import ProductID from "./pages/ProductsPage/[id].js";
import ProductsPageEvaluate from "./pages/ProductsPage/avaliar";

// Draw disabled
import Draw from "./pages/DrawPage";
// import DrawDetails from "./pages/DrawDetailsPage";
// import Blog from "./pages/BlogPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import FollowPage from "./pages/FollowPage";

import LuckyNumber from "./pages/LuckyNumberPage";
import LuckyNumberID from "./pages/LuckyNumberPage/[slug]";

import ConfirmEmail from "./pages/EmailConfirmPage";

import UnregisterPage from "./pages/UnregisterPage";

import FaqPage from "./pages/FaqPage";
import NotFoundPage from "./pages/NotFoundPage";
import SuccessPage from "./pages/SuccessPage";
import NewSubdomainPage from "./pages/NewSubdomainPage";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>

        <Route path="/inicio">
          <Inicio />
        </Route>

        <Route path="/campanhas-patrocinadas">
          <CampanhasRouter />
        </Route>

        <Route path="/convites">
          <Convites />
        </Route>
        
        <Route path="/ofertas">
          <Ofertas />
        </Route>

        <Route path="/perfil">
          <Perfil />
        </Route>

        <Route path="/missoes">
          <Missoes />
        </Route>
        {/* <Route path="/cupons">
          <CouponsPage />
        </Route> */}

        <Route path="/minha-conta">
          <MinhaConta />
        </Route>
        <Route path="/meus-pontos">
          <MeusPontos />
        </Route>

        <Route path="/produtos">
          <ProductsRouter />
        </Route>
        <Route path="/produtos/avaliar">
          <ProductsPageEvaluate />
        </Route>

        <Route exact path="/produtos-historico">
          <ProductsPageHistory />
        </Route>

        {/* Draw only disabled */}
        <Route path="/sorteio">
          <Draw />
        </Route>

        {/* <Route path="/sorteio-detalhes">
          <DrawDetails />
        </Route> */}

        <Route path="/numero-da-sorte">
          <LuckyNumberRouter />
        </Route>

        {/* <Route path="/blog">
          <Blog />
        </Route> */}

        <Route path="/privacy-and-terms">
          <PrivacyPage />
        </Route>
        <Route path="/terms">
          <TermsPage />
        </Route>

        <Route path="/seguir">
          <FollowPage />
        </Route>
        <Route path="/confirmar-email">
          <ConfirmEmail />
        </Route>

        <Route path="/descadastrar">
          <UnregisterPage/>
        </Route>

        <Route path="/faq">
          <FaqPage/>
        </Route>

        <Route path="/sucesso">
          <SuccessPage />
        </Route>

        <Route path="/newsubdomain">
          <NewSubdomainPage />
        </Route>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};

function CampanhasRouter() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id/legacy`}>
        <CampaignLegacy />
      </Route>
      <Route path={`${match.path}/:id/ganhadores`}>
        <CampaignWinners />
      </Route>
      <Route path={`${match.path}/:id/participar`}>
        <CampanhaIdParticipar />
      </Route>
      <Route path={`${match.path}/:id`}>
        <CampaignID />
      </Route>
      <Route path={match.path}>
        <Campanhas />
      </Route>
    </Switch>
  );
}

function ProductsRouter() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <ProductID />
      </Route>
      <Route path={match.path}>
        <ProductsPage />
      </Route>
    </Switch>
  );
}

function LuckyNumberRouter() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <LuckyNumberID />
      </Route>
      <Route path={`${match.path}`}>
        <LuckyNumber />
      </Route>
    </Switch>
  );
}

export default Routes;
