class Formatter {

    simplifyText(text, max) {
        return text.substr(0, max) + (text.length > max ? '...' : '')
    }

    parseToDate(date) {
        return new Date(date)
    }
    parseToNumber(date) {
        return date.getTime()
    }

    formatCampaign(campaign) {
        const now = new Date()
        now.setHours(0, 0, 0, 0);

        const newStartDate = new Date (campaign.startDate);
        newStartDate.setHours(0, 0, 0, 0);
        
        const newEndDate = new Date (campaign.endDate);
        newEndDate.setHours(0, 0, 0, 0);

        let active = false
        // if (now > campaign.startDate && now < campaign.endDate) active = true
        if (now >= newStartDate && now < newEndDate) active = true

        return {
            ...campaign,
            endDate: this.parseToDate(campaign.endDate).toLocaleDateString('pt-BR'),
            startDate: this.parseToDate(campaign.startDate).toLocaleDateString('pt-BR'),
            active
        }
    }

    formatStatement(statement) {
        const now = new Date()
        const createdAt = new Date(statement.createdAt)
        const expireAt = new Date(statement.expireAt)

        return {
            ...statement,
            createdAt: `${createdAt.toLocaleDateString('en-US')} ${createdAt.toLocaleTimeString()}`,
            expireAt: `${expireAt.toLocaleDateString('en-US')} ${expireAt.toLocaleTimeString()}`,
            expired: (now > expireAt) && (statement.amount > 0)
        }
    }

    formatLuckyNumber(luckyNumber) {
        return {
            ...luckyNumber,
            createdAt: new Date(luckyNumber.createdAt).toLocaleDateString('pt-BR')
        }
    }
}

export default new Formatter()