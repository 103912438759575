import React from 'react';
// import translate from "../../../locales/translate";
import { Card, CardContent, CardMedia, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '48%',
    },
    description: {
        overflow: 'hidden',
        maxLines: 2,
        maxHeight: 40,
        position: "relative",
        "&&:before": {
            content: '"..."',
            position: "absolute",
            right: 0,
            bottom: 0,
        },
        "&&:after": {
            content: '""',
            position: "absolute",
            right: 0,
            width: "1em",
            height: "1em",
            marginTop: "0.2em",
            background: "white"
        }
    },
    customCard: {
        minHeight: 304,
    }
}));


const LuckyNumberListing = (props) => {
    const classes = useStyles();
    const data = props.data;
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Link to={`numero-da-sorte/${data.id}`} style={{textDecoration: "none"}}>
                        <Card className={classes.customCard}>
                            <CardMedia
                                className={classes.media}
                                image={data.image}
                                title={data.title}
                            />
                            <CardContent>
                                <Typography variant="h6" className="dark--text">
                                    {data.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={`dark--text ${classes.description}`}
                                >
                                    {data.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
};

export default LuckyNumberListing;