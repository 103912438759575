import React from "react";
import { Grid, Typography, Chip } from "@material-ui/core";
import styled from "styled-components";
import { LuckyNumberStatuses, LuckyNumbersTypes, LuckyNumbersTypesUS, LuckyNumbersStatusesUS } from "../../../helpers/Contansts";
import translate from "../../../locales/translate";
import { useSelector, shallowEqual } from "react-redux";

const HeaderLine = styled(Grid)`
  background-color: var(--primary);
  color: white;
  padding: 18px 8px !important;
`;

const LuckyNumberCell = styled(Grid)`
  border: 1px solid lightgray !important;
  padding: 24px 12px !important;
  color: var(--darktext);
`;

//const voucherHeaders = ["Descrição", "Nº do Cupom", "Data", "Status"];
const tableHeaders = {
  br: ["Descrição", "Nº do Cupom", "Data", "Status"],
  en: ["Description", "Coupon Nº", "Date", "Status"],
};

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};

const LuckyNumbersTable = ({ luckyNumbers = [], title = "" }) => {
  const { language } = useStore();

  const luckyNumberChipText = translate("ds.coupons.table.chip.text", {
    qty: luckyNumbers.length,
  });
  return (
    <Grid container>
      {}
      <Grid item xs={12}>
        <HeaderLine container alignItems="center" style={{ padding: "12px 0" }}>
          <Grid item xs={8}>
            <Typography variant="body1" className="text-bold">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Chip label={luckyNumberChipText} />
          </Grid>
        </HeaderLine>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {language === "en-US"
            ? tableHeaders.en.map((header, index) => (
                <LuckyNumberCell item xs={3} key={`lucky-number-header-${index}`}>
                  <Typography variant="body2" className="dark--text">
                    <b>{header}</b>
                  </Typography>
                </LuckyNumberCell>
              ))
            : tableHeaders.br.map((header, index) => (
                <LuckyNumberCell item xs={3} key={`header-header-${index}`}>
                  <Typography variant="body2" className="dark--text">
                    <b>{header}</b>
                  </Typography>
                </LuckyNumberCell>
              ))}
        </Grid>
        {luckyNumbers.map((luckyNumber, index) => (
          <Grid container key={`grid-${index}`}>
            <LuckyNumberCell item xs={3} key={`luckynumber1-${index}`}>
              { language === 'en-US' ? LuckyNumbersTypesUS[luckyNumber.type - 1] : LuckyNumbersTypes[luckyNumber.type - 1]}
            </LuckyNumberCell>
            <LuckyNumberCell item xs={3} key={`luckynumber2-${index}`}>
              {("0000" + luckyNumber.lot).slice(-4)}.
              {("00000" + luckyNumber.code).slice(-5)}
            </LuckyNumberCell>
            <LuckyNumberCell item xs={3} key={`luckynumber3-${index}`}>
              {luckyNumber.createdAt}
            </LuckyNumberCell>
            <LuckyNumberCell item xs={3} key={`luckynumber4-${index}`}>
              { language === 'en-US' ? LuckyNumbersStatusesUS[luckyNumber.status] : LuckyNumberStatuses[luckyNumber.status]}
            </LuckyNumberCell>
          </Grid>
        ))}
        {luckyNumbers.length === 0 && (
          <Grid container>
            <LuckyNumberCell item xs={12} style={{ textAlign: "center" }}>
              <b>{translate("ds.follow.nothing")}</b>
            </LuckyNumberCell>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LuckyNumbersTable;
