/* eslint-disable array-callback-return */
import React from "react";
import axios from 'axios';
import DefaultLayout from "../../layout/default";
import { Grid, Container, Typography, Card, Box, Avatar, Button, CardMedia } from "@material-ui/core";
import ApiService from "../../helpers/ApiService";
// import FollowCard from "./components/Card";
import MissionCard from "../MissionsPage/components/Card";
import CarouselSocialMedia from "./components/Carousel";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import BtHowItWorks from "../../components/common/BtHowItWorks";
import translate from "../../locales/translate";
import FollowersPackageSection from "./components/FollowersPackageSection";
import _ from 'lodash';

// const PostCard = styled(Card)`
//   width: 100%;
//   height: 80%;

//   a:hover {
//     opacity: 0.5;
//   }

//   img {
//     width: 100%;
//     height: 100%;
//   }
// `;

const InstagramContainer = styled(Box)`
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  padding: 32px;

`;
const CustomAvatar = styled(Avatar)`
  border: 1px solid #e0e0e0;
  width: 100%;
  height: unset;
`;

const InstagramMedia = styled(Card)`
  width: 100%;
  height: 300px;

  @media screen and (max-width: 960px) {
    height: 90px;
  }
`;

const FollowPage = () => {
  const [missions, setMissions] = React.useState([]);
  const [otherMissions, setOtherMissions] = React.useState([]);
  const [myMissions, setMyMissions] = React.useState([]);
  const [instagramPosts, setInstagramPosts] = React.useState([]);
  const [instagramData, setInstagramData] = React.useState({});
  const [missionTags, setMissionTags] = React.useState([])
  // const [total, setTotal] = React.useState(40);
  const dispatch = useDispatch();

  const getMissions = async () => {
    await ApiService.get("missions").then(({ data }) => {      
      let allMissionFollowpage = [];
      data.missions.map((currentMission) => {        
        currentMission.tag.followPage && allMissionFollowpage.push({
          tag: currentMission.tag.name,
          data: currentMission,
        });
      });
      // remove items com nomes duplicados do array
      let filterdArr = _.uniqBy(allMissionFollowpage, 'data.name');     
      setMissions(filterdArr);
      let allTags = [];
      allMissionFollowpage.map((currentTag) => {
        !allTags.includes(currentTag.tag) && allTags.push(currentTag.tag);
      });
      
      setMissionTags(allTags);
      
      const tagTmp = data.tags.map((t) => t.name);
      setOtherMissions(
        data.missions.filter((miss) => !tagTmp.includes(miss.tag.name))
      );

      getMyMissions(allMissionFollowpage);
    });
  };

  const getMyMissions = (currentMissions = []) => {
    ApiService.get("/my-missions").then(({ data }) => {
      if( currentMissions.length > 0 ) {
        let myMissionsTemp = [];
        let callbackMyMissions = data.map((dat) => dat.mission);

        callbackMyMissions.map((currentMyMission) => {
          currentMissions.map((mission) => {
            currentMyMission === mission.data.id && myMissionsTemp.push(currentMyMission);
          });
        });
        setMyMissions(myMissionsTemp);
      }
      else {
        setMyMissions(data.map((dat) => dat.mission));
      }
    });
  };

  const getInstagramPosts = () => {
    const fbid = '17841408802549277';
    const instagramToken = 'IGQVJYUVg4NEtZANzE0OGZASLVJnMUpmZAFozZAVZAJY2RMREgtaDdxbndFNXd0LTNlMEp4ZAmN3ZAUU2Rkc3RFVkelQ2S3hmYnc2eGZArb0NyTThLTGJQM0hGRGdFS2tuTzAzTG4yQ3J0WkRGQzJpNzBFc2xiSQZDZD';
    ApiService.get(
      `https://graph.instagram.com/${fbid}/media?fields=username,media_type,media_url,permalink,thumbnail_url,caption&access_token=${instagramToken}`,
      // "https://graph.instagram.com/17841401308600520/media?fields=media_type,media_url,permalink,thumbnail_url&access_token=IGQVJVVzZALeS0tVWxyQV9lVklsU2FMVHo3eExULVZAJR2F1WGFEbDNDQ3VVdjB1cVMwa2NuQ0NEN0pxYnZARb3liY2pRNjNpT2FRanVZANElDQ3ZAfcHdtRm9NempWY2FWQUlLdk9YYl85bzE2WHpGdElQSwZDZD"
    ).then(({ data }) => {
      setInstagramPosts(data.data);

      if( data.data !== undefined ){
        const instagramUsername = data.data[0].username;
        axios.get(`https://www.instagram.com/${instagramUsername}/?__a=1`).then(({ data }) => {
          let igUser = {
            username: instagramUsername,
            full_name: data.graphql.user.full_name,
            profile_pic_url: data.graphql.user.profile_pic_url,
          }
          setInstagramData(igUser);
        }).catch((error) => {
          console.log('Erro na requisição do Instagram:',error);
        });
      }
    }).catch((err) => {
      console.log('Erro na requisição do Graph do Instagram:', err);
    });

  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  const verifyTotal = () => {
    let totalTemp = 0;
    let myMissionsTemp = 0;
    myMissions.map((currentMyMission) => {
      missions.map((currentMission) => {
        if (currentMyMission === currentMission.data.id ) {
          myMissionsTemp++;
          return "";
        }
      });
    });
    totalTemp = missions.length - myMissionsTemp;
    return totalTemp;
  }

  React.useEffect(() => {
    getMissions();
    getInstagramPosts();
    changePagination("follow");
    changeHelmet([{name: "follow", content: "follow content"}]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Container maxWidth="lg" style={{ backgroundColor: "white" }}>
        {/* <CarouselSocialMedia /> */}
        <Grid
          container
          spacing={2}
          style={{ paddingBottom: 42, paddingTop: 24 }}
        >
          <Grid item xs={12}>
            <BtHowItWorks video="https://www.youtube.com/watch?v=cXgDVvVJ3-c" />
            <Typography variant="h4" className="dark--text text-bold">
              {translate("follows.title")}
            </Typography>
            <Typography variant="body2" className="dark--text">
              {translate("follows.titleText")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CarouselSocialMedia />
          </Grid>
          <Grid item xs={12}>
            <Card style={{ padding: 12 }} elevation={2} className="dark--text">
              {translate("ms.detailTitle.pt1")}
              <span className="primary--text text-bold">
                {" "}
                {verifyTotal()}{" "}
              </span>
              {translate("ms.detailTitle.pt2")}
            </Card>
          </Grid>

          <Grid item xs={12}>
            <FollowersPackageSection />
          </Grid>

          <Grid item xs={12}>
            {missionTags.map((tag, index) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    key={`mission-tag-${index}`}
                  >
                    <Grid item xs={12} style={{ marginTop: '16px' }}>
                      <Typography variant="h6" className="text-bold dark--text">
                        {tag}
                      </Typography>
                      <hr></hr>
                    </Grid>
                    {missions.map((mission, i) => {
                      if(mission.tag === tag) {
                        return !myMissions.includes(mission.data.id) ? (
                          <Grid
                            item
                            xs={12}
                            md={3}
                            key={`mission-${i}`}
                          >
                            <MissionCard
                              mission={mission.data}
                              disabled={myMissions.includes(mission.data.id)}
                            />
                          </Grid>
                        ):('');
                      }
                    })}
                  </Grid>
                );
              })
            }
          </Grid>
          {myMissions.length > 0 && (
            <Grid item xs={12} md={12} style={{marginTop: 32}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className="text-bold dark--text">
                    {translate("follows.accomplished")}
                  </Typography>
                  <hr></hr>
                </Grid>
                {missions.map((mission, i) => {
                  return myMissions.includes(mission.data.id) ? (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      key={`mission-${i}`}
                    >
                      <MissionCard
                        mission={mission.data}
                        disabled={myMissions.includes(mission.data.id)}
                      />
                    </Grid>
                  ):('');
                })}
              </Grid>
            </Grid>
          )}
          {otherMissions.length > 0 && (
            <Grid item xs={12}>
              <Grid container style={{ margin: "42px 0" }}>
                <Grid item xs={12} style={{ marginBottom: 12 }}>
                  <Typography variant="h6" className="text-bold dark--text">
                    {translate("ms.otherMissions")}
                  </Typography>
                  <hr></hr>
                </Grid>
                {otherMissions.map((other, index) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    key={`other-mission-${index}`}
                    style={{ padding: "0 8px", marginBottom: 16 }}
                  >
                    <MissionCard
                      mission={other}
                      disabled={myMissions.includes(other.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {instagramPosts.length > 0 && (
            <Grid item xs={12} md={12} style={{marginTop: 32}}>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className="text-bold dark--text">
                    {translate("follow.instagram")}
                  </Typography>
                  <hr></hr>
                </Grid>
                <Grid item xs={12} md={1} />
                <Grid item xs={12} md={10}>
                  <InstagramContainer >
                    <Grid container spacing={2}>
                      {Object.keys(instagramData).length > 0 && (
                        <>
                          <Grid item xs={3} md={2}>
                            <CustomAvatar alt={instagramData.username} src={instagramData.profile_pic_url} />
                          </Grid>
                          <Grid item xs={9} md={10}>
                            <Typography variant="h6" className="text-bold dark--text">
                              {instagramData.full_name}
                            </Typography>
                            <Typography variant="body1" className="dark--text">
                              @
                              {''}
                              {instagramData.username}
                            </Typography>
                            <Button
                              size="small"
                              className="primary"
                              style={{ paddingRight: 8, paddingLeft: 8, marginTop: 8 }}
                              href={`https://www.instagram.com/${instagramData.username}`}
                              target='_blank'
                            >
                              {translate("follow.visitus")}
                            </Button>
                          </Grid>
                        </>
                      )}
                      {instagramPosts.map((post, index) =>
                        index < 9 ? (
                          <Grid item xs={4} md={4} key={index}>
                            <InstagramMedia>
                              <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                                <CardMedia style={{height: '100%'}}
                                  image={post.media_url}
                                  title={post.caption}
                                />
                              </a>
                            </InstagramMedia>
                          </Grid>
                        ) : ('')
                      )}
                    </Grid>
                  </InstagramContainer>
                </Grid>
                <Grid item xs={12} md={1} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </DefaultLayout>
  );
};

export default FollowPage;
