import React from 'react';

import { Grid, Typography, Box, Hidden } from '@material-ui/core';
import translate from '../../../../locales/translate';
import Texts from './Texts'
import People from './People'

import styled from 'styled-components'

const GridContainer = styled(Grid)`
    background-color: white;
    margin: unset;
    padding: 24px;
    height: auto;
    max-height: 180vh;
    position: relative;
    /* margin-bottom: 50px; */
    @media screen and (min-width: 960px) {
        max-height: 650px;
        height: 650px;
        padding: 48px;
    }
`

const WinnersBox = styled(Box)`
    position: absolute;
    bottom: 0;
    top: 0;
    left: 52%;
    right: 0;
    background-image: url('/img/bg_dir.jpg');
    z-index: 1;
`

const GridTitle = styled(Grid)`
    @media screen and (max-width: 960px) {
        margin-bottom: 24px;
    }
`

const PeopleBG = styled(Grid)`
    background-image: url('/img/bg_esq.jpg');
    background-repeat: space;
`

const TextBG = styled(Grid)`
    padding-left: 16px;
    z-index: 2;
    @media screen and (min-width: 960px) {
        padding-left: 48px !important;
    }
`

const Winners = () => {
    return (
        <GridContainer container className="winners-root" id="ganhadores">
            <Hidden smDown>
                <WinnersBox className="winners-box" />
            </Hidden>
            <GridTitle item xs={12} className="winners-title">
                <Typography variant="caption" className="dark--text">
                    Bigtester
                </Typography>
                <Typography variant="h3" className="dark--text" style={{ fontWeight: 700 }}>
                    {translate("lp.lastW.title")}
                </Typography>
            </GridTitle>
            <PeopleBG item xs={12} md={6}>
                <People />
            </PeopleBG>
            <Hidden smDown>
                <TextBG item xs={12} md={6}>
                    <Texts />
                </TextBG>
            </Hidden>
        </GridContainer>
    );
}

export default Winners;