import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import GainMore from "../../../components/common/GainMore";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
import CampainCard from "./Card";
import ApiService from "../../../helpers/ApiService";
import Formatter from "../../../helpers/Formatter";
// import PaginationControler from '../../../components/common/PaginationControler';
import translate from "../../../locales/translate";
import { useDispatch } from "react-redux";

const CampaignPageListing = () => {
  // const amounts = [6, 12, 18, 24]

  const [campaigns, setCampaigns] = React.useState([]);
  const [myCampaigns, setMyCampaigns] = React.useState([]);
  const [campaignsLegacy, setCampaignsLegacy] = React.useState([]);
  const [amount] = React.useState(100);
  const [page] = React.useState(1);
  const dispatch = useDispatch();
  // const [total, setTotal] = React.useState(40)

  const getCampaigns = () => {
    ApiService.get("/campaigns", {
      limit: amount,
      skip: (page - 1) * amount,
    }).then(({ data }) => {
      setCampaigns(data.campaigns.map((dat) => Formatter.formatCampaign(dat)));
      // setTotal(data.count)
    });

    ApiService.get("/my-campaigns").then(({ data }) => {
      setMyCampaigns(data.map((d) => (d.campaign ? d.campaign : d)));
    });

    ApiService.get("/campaigns/legacy").then(({ data }) => {
      setCampaignsLegacy(data);
    });
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  const futureCampaigns = (campaignStartDate) => {
    let today = new Date();
    let compareToday = `${today.getDate()}/${parseInt(today.getMonth()+1)}/${today.getFullYear()}`;
    return (campaignStartDate > compareToday);
  }

  React.useEffect(() => {
    getCampaigns();
    changePagination("campaign");
    changeHelmet([{name: "campaign", content: "campaign content"}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, amount]);

  return (
    <Container maxWidth="lg" style={{ backgroundColor: "white" }}>
      <Grid container spacing={2} style={{ paddingTop: 24, paddingBottom: 42 }}>
        <Grid item xs={12}>
          <BtHowItWorks video="https://www.youtube.com/watch?v=ARgjiGhvGFA" />
          <Typography variant="h4" className="dark--text text-bold">
            {translate("cs.title")}
          </Typography>
          <Typography variant="body2" className="dark--text">
            {translate("cs.titleText")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <PaginationControler
            {...{ page, amount, total, amounts, objectName: 'Campanhas' }}
            onAmountChange={(value) => { setPage(1); setAmount(value); }}
            onPageChange={(value) => { setPage(value) }}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className="dark--text text-bold">
            {translate("cs.active")}
          </Typography>
        </Grid>
        {
          // eslint-disable-next-line array-callback-return
          campaigns.map((campaign) => {
            if (campaign.active)
              return (
                <Grid item xs={12} md={4} key={campaign.id}>
                  <CampainCard
                    campaign={campaign}
                    disabled={myCampaigns.includes(campaign.slug)}
                  />
                </Grid>
              );
          })
        }
        <Grid item xs={12} style={{ paddingTop: 36 }}>
          <Typography variant="h5" className="dark--text text-bold">
            {translate("cs.over")}
          </Typography>
        </Grid>
        {
          // eslint-disable-next-line array-callback-return
          campaigns.map((campaign) => {
            if (!campaign.active && !futureCampaigns(campaign.startDate))
              return (
                <Grid item xs={12} md={4} key={campaign.id}>
                  <CampainCard
                    campaign={campaign}
                    disabled={myCampaigns.includes(campaign.id)}
                  />
                </Grid>
              );
          })
        }
        { campaignsLegacy.map((campaign) => {
            return(
              <Grid item xs={12} md={4} key={campaign.id}>
                  <CampainCard
                    campaign={campaign}
                    disabled={true}
                    legacy={true}
                  />
                </Grid>
            );
          }) 
        }
        <GainMore />
      </Grid>
    </Container>
  );
};

export default CampaignPageListing;
