import React from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import HtmlParse from "react-html-parser";
import { useSelector, shallowEqual } from "react-redux";
import ApiService from "../../../helpers/ApiService";
import Formatter from "../../../helpers/Formatter";
import { withRedux } from "../../../helpers/redux";
import Login from "../../../components/auth/Login";
import Analytics from "../../../helpers/AnalyticsService";
import translate from "../../../locales/translate";

// Components
import LoginLayout from "../../../layout/login";
import {
  Grid,
  Container,
  Typography,
  Button,
  Hidden,
  Dialog
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// social media icons
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};

// const CardTitle = styled(Typography)`
//     color: var(--darktext);
//     font-weight: 700;
// `

const CloseBtn = styled(Button)`
  position: fixed !important;
  width: 30px !important;
  height: 36px !important;
  top: 8px;
  right: 8px;
  color: white;
  border-radius: 50% !important;
`;

const EmbedVideo = styled.div`
  position: relative;
  padding-bottom: 56.25% !important;
  height: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CampaignID = () => {
  const router = useParams();
  const history = useHistory();
  const { user } = useStore();
  const [campaign, setCampaign] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [joined, setJoined] = React.useState(false);

  const handleModalEvent = () => {
    setModal(!modal);
  };

  const getCampaign = () => {
    ApiService.get(`/campaigns/${router.id}`)
      .then(({ data }) => {
        setCampaign(Formatter.formatCampaign(data));
        document.title = data.title + " - Big Tester";
      })
      .catch((err) => {
        if (!!ApiService.token) history.push("/campanhas-patrocinadas");
        else history.push("/");
      });
  };

  const getMyCampagins = () => {
    ApiService.get("/my-campaigns")
      .then(({ data }) => {
        const found = data.find((d) => d === router.id);
        if (!found) setJoined(false);
      })
      .catch((err) => {
        setJoined(false);
      });
  };

  const handleRegister = () => {
    if (user.id) {
      history.push(`/campanhas-patrocinadas/${router.id}/participar`);
      new Analytics().registerEvent({
        category: "Campaigns",
        action: "Join",
        label: campaign.slug,
      });
    } else {
      setModal(true);
    }
  };

  const isHexadecimal = (hexValue) => {
    let hexRe = /[0-9A-Fa-f]{6}/g;
    return (hexRe.test(hexValue));
  }

  const handleLoginClose = () => {
    setModal(false);
  };

  const sendToWinners = () => {
    return history.push(campaign.slug + `/ganhadores`);
  }
  const sendToMyAccount = () => {
    return history.push('/minha-conta#atualizar-endereco');
  }

  const validateAddressFields = (fields) => {
    let hasFields = [];
    // eslint-disable-next-line array-callback-return
    fields.map( field => {
      hasFields.push(field in user.address && user.address[field].length > 0);
    });
    return hasFields.indexOf(false) < 0;
  }

  const validateCPF = () => {
    return (user.cpf > 0);
  }

  const userHasAddressAndCPF = () => {
    if ( Object.keys(user.address).length > 0 ) {
      // Campos de endereço: city / complement / extraAddrInfo / neighborhood / number / postalcode / state / street
      let mandatoryAddress = ['city', 'number', 'state', 'street'];
      return (validateAddressFields(mandatoryAddress) && validateCPF());
    } else {
      return false;
    }
  }

  const validateCTA = () => {
    if( campaign ) {
      let ctaVariables = {};
      ctaVariables.link = handleRegister;
      ctaVariables.address = true;

      if( user.address !== undefined && !userHasAddressAndCPF() ) {
        ctaVariables.className = "darktext";
        ctaVariables.isDisable = false;
        ctaVariables.text = translate("cs.detail.btn5");
        ctaVariables.link = sendToMyAccount;
        ctaVariables.address = false;
      }
      else {
        // Se a data está válida e ainda não é candidato
        if( campaign.active && !joined ) {
          ctaVariables.className = "success";
          ctaVariables.isDisable = false;
          ctaVariables.text = translate("cs.detail.btn2");
        }
        // Se a data está válida e já é candidato
        else if( campaign.active && joined ) {
          ctaVariables.className = "disabled";
          ctaVariables.isDisable = true;
          ctaVariables.text = translate("cs.detail.btn1");
        }
        // Se a data não é válida mais e já é está selecionados os candidatos
        if( !campaign.active && campaign.winners ) {
          if(campaign.winners.length > 0) {
            ctaVariables.className = "success";
            ctaVariables.isDisable = false;
            ctaVariables.text = translate("cs.detail.btn3");
            ctaVariables.link = sendToWinners;
          }
          // Se a data não é válida mais e ainda não foram selecionados os candidatos
          else {
            ctaVariables.className = "disabled";
            ctaVariables.isDisable = true;
            ctaVariables.text = translate("cs.detail.btn4");
          }
        }
      }
      return(
        <>
          { !ctaVariables.address && (
            <Typography variant="body2" className="dark--text" paragraph>
              {translate("cs.detail.noaddress")}
            </Typography>
          ) }
          <Button
            size="large"
            style={{ paddingLeft: 64, paddingRight: 64 }}
            className={ctaVariables.className}
            disabled={ctaVariables.isDisable}
            onClick={ctaVariables.link}
          >
            {ctaVariables.text}
          </Button>
        </>
      );
    }
  };

  React.useEffect(() => {
    if (router.id) {
      getCampaign();
      getMyCampagins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <LoginLayout>
      <Grid container style={{ backgroundColor: `${(campaign.backgroundColor !== undefined && isHexadecimal(campaign.backgroundColor)) ? '#' : ''}${campaign.backgroundColor}` }}>
        <Container maxWidth="lg">
          <Grid
            container
            style={{ backgroundColor: "white", paddingBottom: 24 }}
          >
            <Grid item xs={12}>
              <img
                src={campaign.image}
                alt={campaign.title}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={8} style={{ padding: 24 }}>
              <Typography
                variant="h4"
                className="dark--text text-bold"
                paragraph
              >
                {campaign.title}
              </Typography>
              <div style={{ textAlign: "center", marginBottom: 24 }}>
                {validateCTA()}
              </div>
              <div>
                <Typography variant="body1" className="dark--text" paragraph>
                  {HtmlParse(campaign.description)}
                </Typography>
              </div>
              <div
                style={{ margin: "24px 0", textAlign: "center", width: "100%" }}
              >
                {campaign.video && !joined && (
                  <EmbedVideo>
                    <iframe
                      id="youtube-player"
                      type="text/html" 
                      title={campaign.title}
                      src={`https://youtube.com/embed/${campaign.video}`}
                      frameBorder="0"
                      allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </EmbedVideo>
                )}
              </div>
              <div style={{ marginBottom: 32 }}>
                <Typography variant="body1" className="dark--text" paragraph>
                  {HtmlParse(campaign.descriptionProduct)}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="h6"
                  className="dark--text text-bold"
                  paragraph
                >
                  {translate("cs.detail.titleshare")}
                </Typography>
                <Typography variant="body1" className="dark--text" paragraph>
                  {translate("cs.detail.descriptionshare1")}
                </Typography>
                <Typography variant="body1" className="dark--text" paragraph>
                  {translate("cs.detail.descriptionshare2")}
                </Typography>
              </div>
              <div style={{ textAlign: "center", marginTop: 42 }}>
                {validateCTA()}
              </div>
            </Grid>
            <Grid item xs={12} md={3} style={{ paddingTop: 24, margin: 15 }}>
              <Typography variant="h6" className="text-bold dark--text">
                {translate("cs.detail.period")}
              </Typography>
              <Grid container spacing={1} style={{ marginTop: 12 }}>
                <Grid item xs={6}>
                  <Typography variant="body1" className="text-bold dark--text">
                    {translate("cs.detail.start")}
                  </Typography>
                  <Typography variant="body1" className="dark--text">
                    {campaign.startDate}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" className="text-bold dark--text">
                    {translate("cs.details.end")}
                  </Typography>
                  <Typography variant="body1" className="dark--text">
                    {campaign.endDate}
                  </Typography>
                </Grid>
                <Hidden lgDown>
                  <Grid item xs={12} style={{ textAlign: "center", marginTop: 24 }}>
                    {validateCTA()}
                  </Grid>
                </Hidden>
              </Grid>
              <Typography
                variant="body1"
                className="dark--text"
                align="center"
                style={{ marginTop: 32, marginBottom: 16 }}
              >
                {translate("cs.detail.patro")}
              </Typography>
              {campaign.sponsor && (
                <div style={{ textAlign: "center" }}>
                  <img
                    alt=""
                    src={campaign.imageSponsor}
                    style={{ maxWidth: "100%", maxHeight: 200 }}
                  />
                  <Typography variant="body1" className="dark--text">
                    {campaign.sponsor}
                  </Typography>
                </div>
              )}
              {campaign.urlSocial && (
                <Hidden smDown>
                  <div
                    style={{ marginTop: 24, textAlign: "center" }}
                    className="dark--text"
                  >
                    <Typography variant="body2" className="text-bold">
                      {translate("cs.detail.social")}
                    </Typography>
                    {campaign.urlSocial.facebook && (
                      <a
                        href={`https://facebook.com/${campaign.urlSocial.facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--facebook)",
                        }}
                      >
                        <FacebookIcon
                          fontSize="large"
                          style={{ padding: 4 }}
                        />
                      </a>
                    )}
                    {campaign.urlSocial.instagram && (
                      <a
                        href={`https://instagram.com/${campaign.urlSocial.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--instagram-hover)",
                        }}
                      >
                        <InstagramIcon
                          fontSize="large"
                          style={{ padding: 4 }}
                        />
                      </a>
                    )}
                    {campaign.urlSocial.twitter && (
                      <a
                        href={`https://twitter.com/${campaign.urlSocial.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--twitter)",
                        }}
                      >
                        <TwitterIcon
                          fontSize="large"
                          style={{ padding: 4 }}
                        />
                      </a>
                    )}
                    {campaign.urlSocial.pinterest && (
                      <a
                        href={`https://br.pinterest.com/${campaign.urlSocial.pinterest}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--youtube)",
                        }}
                      >
                        <PinterestIcon
                          fontSize="large"
                          style={{ padding: 4 }}
                        />
                      </a>
                    )}
                    {campaign.urlSocial.youtube && (
                      <a
                        href={`https://youtube.com/channel/${campaign.urlSocial.youtube}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "var(--youtube)",
                        }}
                      >
                        <YouTubeIcon
                          fontSize="large"
                          style={{ padding: 4 }}
                        />
                      </a>
                    )}
                  </div>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
        <CloseBtn size="small" onClick={handleModalEvent}>
          <CloseIcon className="white--text" />
        </CloseBtn>
        <Login
          reload={false}
          close={() => {
            handleLoginClose();
          }}
        />
      </Dialog>
    </LoginLayout>
  );
};

export default withRedux(CampaignID);
