import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  Grid,
  Container,
  Hidden,
  Typography,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Link } from "react-router-dom";
// import { useSelector, shallowEqual } from "react-redux";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CountUp from 'react-countup';
import styled from "styled-components";

import { withRedux } from "../../helpers/redux";
import ApiService from "../../helpers/ApiService";
import translate from "../../locales/translate";

import Appbar from "./components/Appbar.js"; //Ok
import HomeLayout from "../../layout/home";
import Title from "./components/Title"; //Ok
import Register from "../../components/auth/Register"; //Ok
import HaveLogin from "../../components/auth/HaveLogin"; //Ok
import HowItWorks from "./components/how-it-works"; //Ok
import Awards from "./components/awards"; //Ok
import Winners from "./components/winners"; //Ok
import Products from "./components/products"; //Ok
import Footer from "../../components/common/Footer"; //Ok
import WhoWeAre from "./components/who-we-are";
import Contact from "./components/contact";

const StyledContainer = styled(Grid)`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  max-height: 300vh;
  align-items: center;
  background-image: url(${(props) => props.image || "/img/main_bg.jpg"});
  background-size: cover;
  .MuiGrid-root {
    z-index: 2;
  }
  @media screen and (max-width: 426px) {
    height: unset;
    padding-bottom: 32px;
  }
`;

const BGMask = styled.div`
  min-height: 150vh;
  position: absolute;
  width: 100vw;
  background-color: ${(props) => props.color || "rgb(41, 182, 246)"};
  opacity: ${(props) => props.opacity || "0.7"};
  z-index: 0;

  @media screen and (max-width: 426px) {
    height: 140vh;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};

const LandingPage = () => {
  const matches = useMediaQuery("(max-width:426px)");
  const isLargeScreen = useMediaQuery("(min-width: 1900px)");
  const [homeData, setHomeData] = React.useState({});
  const totalUsers = 8536879;
  const { language } = useStore();

  const getHomeData = async () => {
    try {
      await ApiService.get("/subdomains").then((data) => {
        // data returns also headers and details about request
        // data.data returns information about subdomain
        let subdomainData = data.data;
        //console.log('data: ',data)

        if (subdomainData.id) setHomeData(subdomainData);
      });
    } catch (error) {
      console.log("No subdomain");
    }
  };

  const titleCounter = () => {
    return (
      <CountUp
        start={1234567}
        end={totalUsers}
        duration={8}
        separator="."
        delay={0.6}
      />
    )
  }

  
  useEffect(() => {
    window.scrollTo(0, 0);
    getHomeData();
  }, [language]);

  useEffect(() => {}, [homeData]);

  return (
    <HomeLayout>
      {/* {window.innerWidth} */}
      <StyledContainer container spacing={0} image={homeData.picture}>
        <BGMask color={homeData.color} opacity={homeData.opacity} />
        <Appbar logo={homeData.logo} />
        <Container
          maxWidth="lg"
          style={{
            padding: "unset",
            marginTop: matches ? 0 : isLargeScreen ? 10 : 75,
          }}
        >
          <Grid
            container
            spacing={0}
            justify="space-around"
            style={{ alignItems: "center" }}
          >
            <Hidden smDown>
              <Grid item md={6}>
                {/* <Title text={homeData.slogan} /> */}
                {/* <Title text={`São mais de usuários que confiam no Big Tester!`} /> */}
                <Typography
                  variant="h3" paragraph align="center" style={{ color: "white", zIndex: 1 }}
                >
                  Mais de 
                  {" "}
                  {titleCounter()}
                  {" "}
                  pessoas já acumularam pontos para trocar por produtos!
                </Typography>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid
                item
                xs={11}
                className="white--text"
                style={{ textAlign: "center", marginBottom: 24 }}
              >
                <Typography variant={matches ? "h6" : "h5"}>
                  Mais de 
                  {" "}
                  {titleCounter()}
                  {" "}
                  pessoas já acumularam pontos para trocar por produtos!
                  {/* {homeData.slogan || translate("lp.text")} */}
                </Typography>
              </Grid>
            </Hidden>
            <Grid
              item
              container
              xs={10}
              md={6}
              spacing={0}
              justify="center"
              alignItems="center"
            >
              <Register subdomain={homeData.id} />
              <Hidden mdUp>
                <HaveLogin />
              </Hidden>
            </Grid>
            <Hidden mdUp>
              <Grid
                item
                xs={12}
                className="white--text"
                style={{
                  textAlign: "center",
                  paddingBottom: 10,
                }}
              >
                <Button
                  className="danger white--text"
                  size={matches ? "medium" : "large"}
                >
                  {translate("btn.howWorks")}
                  <PlayCircleFilledIcon
                    style={{ marginLeft: "12px" }}
                  />
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </StyledContainer>
      <HowItWorks />
      <Grid container justify="space-around" style={{ backgroundColor: "#FFF" }}>
        <Container maxWidth="lg" style={{ padding: "unset" }}>
          <WhoWeAre />
          <Winners />
        </Container>
      </Grid>
      <Grid container justify="space-around" style={{ backgroundColor: "var(--secondary)" }}>
        <Container maxWidth="lg" style={{ padding: "unset" }}>
          <Awards />
        </Container>
      </Grid>
      <Grid container justify="space-around">
        <Container maxWidth="lg" style={{ padding: "unset" }}>
          <Products />
        </Container>
      </Grid>
      <Grid container justify="space-around" style={{ backgroundColor: "#edeff0" }}>
        <Container maxWidth="lg" style={{ padding: "unset" }}>
          <Contact language={language}/>
        </Container>
      </Grid>
      <CookieConsent
        onAccept={() => {
          Cookies.set("bt-cookie-consent", true);
        }}
        cookieSecurity={true}
        location="bottom"
        buttonText={translate("lp.cookies.button")}
        cookieName="bt-cookie-consent"
        cookieValue={true}
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "13px",
          borderRadius: "4px",
          padding: "8px 16px",
        }}
        expires={150}
        debug={false}
      >
        {translate("lp.cookies")}
        <Link to="/privacy-and-terms" style={{ color: "rgb(255 212 45)" }}>
          {" "}
          <b>{translate("lp.cookies.link")}</b>
        </Link>
      </CookieConsent>
      <Footer />
    </HomeLayout>
  );
};

export default withRedux(LandingPage);
