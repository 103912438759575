import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Slide,
  Fade,
  Button,
  Card,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import CampaignQuestionCard from "./questions/Card";
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import FontIcon from "../../../components/common/FontIcon";
import ApiService from "../../../helpers/ApiService";
import translate from "../../../locales/translate";
import AddressImage from "../../../img/campaign-address.svg";
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

import HtmlParse from "react-html-parser";
import CardReseller from "./CardReseller";
import CardMission from "../../MissionsPage/components/Card";

const PageLayout = styled(Grid)`
  min-height: calc(100vh - 360px);

  /* background-color: ${(props) => props.backgroundColor}; */
  /* * {
    color: ${(props) => props.textColor};
    .option-text * {
      border-color: ${(props) => props.textColor} !important;
      color: ${(props) => props.textColor} !important;
    }
  } */
`;

const PageContainer = styled(Container)`
  padding: 32px;
  background-color: white;
`;

const TextSuccess = styled.div`
  padding-left: 24px;
  text-align: left;

  @media screen and (max-width: 960px) {
    padding-left: 0;
  }
`;

const ButtonEndCampaign = styled(Button)`
  @media screen and (max-width: 960px) {
    margin-top: 16px;
    width: 100%;
  }
`;

const AddressContainer = styled(Card)`
  margin: 32px 0;
`;

const ButtonAddress = styled(Button)`
  font-size: 12;
  padding: 4px 16px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

const EmbedVideo = styled.div`
  position: relative;
  padding-bottom: 56.25% !important;
  height: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
    }),
    shallowEqual
  );
};

const Participate = () => {
  const router = useParams();
  const history = useHistory();
  const [campaign, setCampaign] = useState({});
  const [questionCount, setQuestionCount] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [completedAnswers, setCompletedAnswers] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [pixel, setPixel] = useState(false);
  const [timeCounter, setTimeCounter] = useState(35);
  const [skipResellers, setSkipResellers] = useState(true);
  const { user } = useStore();
  
  let timerDisplay = true;

  const getCampaign = async () => {
    try {
      const found = (await ApiService.get("/my-campaigns")).data.find(
        (m) => m === router.id
      );

      if (!found) {
        const { data } = await ApiService.get(`/campaigns/${router.id}`);
        setCampaign(data);
        if (data.pixel !== "" || data.pixel !== undefined) {
          setPixel(true);
        }
        if (data.reselers.length > 0) {
          setSkipResellers(false);
        }
        document.title = data.title + " - Campanhas - Big Tester";

      } else {
        history.push("/campanhas-patrocinadas");
      }
    } catch (error) {
      history.push(`/campanhas-patrocinadas/${router.id}`);
    }
  };

  const handleSelected = async (question, answer) => {
    if (question.type !== "text") {
      const tmp = answer.map((index) => question.options[index - 1]);
      setAnswers([...answers, { question: question.question, answers: tmp }]);
    } else
      setAnswers([
        ...answers,
        { question: question.question, answers: [{ text: answer }] },
      ]);

    if (questionCount < campaign.questions.length - 1)
      setQuestionCount(questionCount + 1);
    else {
      setCompletedAnswers(true);
    }
  };
  
  const skipResellersHandler = () => {
    setSkipResellers(true);
    window.scrollTo(0,0);
  }

  const sendAsnwers = async () => {
    try {
      await ApiService.post(`/campaign/${campaign.id}/answers`, { answers });
    } catch (error) {
      console.log(error);
    }
  };

  const isHexadecimal = (hexValue) => {
    let checkHex = parseInt(hexValue, 16);
    return (checkHex.toString(16) === hexValue);
  };

  const getAddress = () => {
    return (`${user.address.street}, ${user.address.number} - ${user.address.city}/${user.address.state}`)
  };

  const verifyBackgroundColor = () => {
    let bgColor = "";
    if(campaign.backgroundColor !== undefined && isHexadecimal(campaign.backgroundColor)) {
      bgColor = "#";
    }
    bgColor = bgColor + campaign.backgroundColor;
    return bgColor;
  }

  useEffect(() => {
    if (router.id && !completedAnswers) getCampaign();
    completedAnswers &&
      skipResellers &&
        timeCounter > 0 &&
      setTimeout(() => setTimeCounter(timeCounter - 1), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, completed, completedAnswers, skipResellers, timeCounter]);

  return (
    <PageLayout
      container
      style={{ backgroundColor: verifyBackgroundColor() }}
      // backgroundColor={`${(campaign.backgroundColor !== undefined && isHexadecimal(campaign.backgroundColor)) ? '#' : ''}${campaign.backgroundColor}`}
      // textColor={campaign.textColor}
    >
      <PageContainer maxWidth="lg">
        <Grid container spacing={2} justify="center">
          {!completedAnswers && campaign.questions && (
            <Grid item xs={12} md={8}>
              {campaign.questions.map((quest, index) => (
                <Slide
                  in={index === questionCount}
                  timeout={{ enter: 1000, exit: 1000 }}
                  key={`question-${index}`}
                  direction="left"
                  style={{ display: index !== questionCount ? "none" : "" }}
                >
                  <div>
                    {index === questionCount && (
                      <CampaignQuestionCard
                        question={quest}
                        image={campaign.image}
                        apply={handleSelected}
                      />
                    )}
                  </div>
                </Slide>
              ))}
            </Grid>
          )}
          {completedAnswers && !completed && (
            <Grid item xs={12} md={10} style={{ textAlign: "center" }}>
              {!skipResellers ? (
                <Fade in={true} timeout={1000}>
                  <div>
                    <Typography variant="h5" paragraph className="text-bold dark--text">
                      {translate("cs.reseller.title")}
                    </Typography>
                    <Grid container spacing={2}>
                      {campaign.reselers.map((reseller, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <CardReseller data={reseller}/>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid container spacing={2}>
                      <Button
                        size="small"
                        className="light--text"
                        style={{ margin: "16px auto" }}
                        onClick={skipResellersHandler}
                      >
                        {translate("cs.reseller.skip")}
                      </Button>
                    </Grid>
                  </div>
                </Fade>
              ) : (
                <Fade in={true} timeout={1000}>
                  <div>
                    <Typography variant="h5" paragraph className="text-bold dark--text">
                      {translate("cs.watch")}
                    </Typography>
                    {/* <YouTube
                      videoId={campaign.video.split("v=")[1]}
                      opts={{
                        width: "80%",
                        height: "400px",
                        playerVars: {
                          autoplay: 1,
                        },
                      }}
                    /> */}
                    <EmbedVideo>
                      <iframe
                        id="youtube-player"
                        type="text/html" 
                        title={campaign.title}
                        src={`https://www.youtube.com/embed/${campaign.video}?autoplay=1&enablejsapi=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </EmbedVideo>
                    <Typography variant="body1" paragraph className="dark--text" style={{display: timerDisplay, margin: 16}}>
                      {translate("cs.detail.timer1")} {timeCounter}s
                    </Typography>
                    {timeCounter === 0 && (
                      <Button
                      size="large"
                      className="primary white--text"
                      onClick={() => {
                        setCompleted(true);
                        sendAsnwers();
                      }}
                      >
                        {translate("cs.btn.confirm")}
                      </Button>
                    )}
                  </div>
                </Fade>
              )}
            </Grid>
          )}
          {completed && timeCounter === 0 && (
            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Typography variant="h5" paragraph className="text-bold dark--text">
                    {translate("cs.end.title")}
                  </Typography>
                </Grid>
                { campaign.missionsSelected && campaign.missionsSelected.length > 0 && (
                  <Grid item md={12} xs={12}>
                    <Card elevation={3} style={{ padding: 24, margin: "24px 0 48px" }}>
                      <Typography variant="h6" paragraph className="dark--text" gutterBottom>
                        {translate('cs.end.mission')}
                      </Typography>
                      <Grid container spacing={2} justify="center">
                        {campaign.missionsSelected && campaign.missionsSelected.map((mission, index) => (
                          <Grid item xs={12} md={4}>
                            <CardMission
                              mission={mission}
                              disabled={false}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <img src={campaign.image} alt={campaign.title} style={{width: "100%"}}/>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Fade in={true} timeout={1000}>
                    <TextSuccess>
                      <Typography variant="body1" paragraph className="dark--text">
                        {translate("cs.end.subTitle1")}
                        {' '}
                        {campaign.points}
                        {' '}
                        {translate("cs.end.subTitle2")}
                      </Typography>
                      <Typography variant="body1" paragraph className="dark--text">
                        {HtmlParse(campaign.descriptionEnd)}
                      </Typography>
                      <Typography variant="body1" paragraph className="dark--text">
                        {translate("cs.end.text1")}
                        <br />
                        <br />
                        {translate("cs.end.text2")}
                      </Typography>
                      <ButtonEndCampaign
                        size="large"
                        className="primary white--text"
                        onClick={() => {
                          history.push("/campanhas-patrocinadas");
                        }}
                        style={{marginRight: 16}}
                      >
                        <FontIcon
                          icon={{ type: "new_releases" }}
                          style={{ marginRight: 12 }}
                        />
                        {translate("cs.end.btn")}
                      </ButtonEndCampaign>
                      <ButtonEndCampaign
                        size="large"
                        className="primary white--text"
                        onClick={() => {
                          history.push("/produtos");
                        }}
                      >
                        <CardGiftcardIcon
                          fontSize="small"
                          style={{ marginRight: 12 }}
                        />
                        {translate("cs.end.btn2")}
                      </ButtonEndCampaign>
                    </TextSuccess>
                  </Fade>
                </Grid>
                <Grid item xs={12} md={12} />
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={8}>
                  <AddressContainer elevation={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <img src={AddressImage} alt="Confirme seu endereço" />
                      </Grid>
                      <Grid item xs={12} md={7} style={{padding: 24, textAlign: 'left'}}>
                        <Typography variant='body1' className="dark--text text-bold" align="left" gutterBottom>
                        {translate("cs.end.addressTitle")}
                        </Typography>
                        { user.address.street ? (
                          <>
                            <Typography variant='body1' paragraph className="dark--text" align="left">
                              {translate("cs.end.addressText")}
                            </Typography>
                            <Typography variant='body1' paragraph className="dark--text" align="left">
                              <PersonPinCircleIcon fontSize="small" />
                              {getAddress()}
                            </Typography>
                          </>
                        ): (
                          <>
                            <Typography variant='body1' paragraph className="dark--text" align="left">
                              {translate("cs.end.addressTextNoAddress1")}
                            </Typography>
                            <Typography variant='body2' paragraph className="dark--text" align="left">
                              {translate("cs.end.addressTextNoAddress2")}
                            </Typography>
                          </>
                        )}
                          <ButtonAddress
                            size="small"
                            className="darktext"
                            onClick={() => {
                              history.push("/minha-conta");
                            }}
                          >
                            {translate("cs.end.addressButton")}
                          </ButtonAddress>
                      </Grid>
                    </Grid>
                  </AddressContainer>
                </Grid>
              </Grid>
              {pixel && (
                <img alt="" src={campaign.pixel} width="1" height="1" />
              )}
            </Grid>
          )}
        </Grid>
      </PageContainer>
    </PageLayout>
  );
};

export default Participate;
