import React from "react";

import { Zoom, Paper, Grid, Typography, Box, Hidden, Avatar } from "@material-ui/core";
import { Smile } from "../../../../src/img/icons";

import styled from "styled-components";

import UserDefault from "../../../img/icons/userdefault.svg";

const PaperBox = styled(Box)`
  ${(props) => props.styling}
  height: 100% !important;
  position: relative;
`;

const WinnerPaper = styled(Paper)`
  max-width: 400px;
  padding: 24px;
  min-height: 175px;
  position: relative;
`;

const SmileIcon = styled(Smile)`
  position: absolute;
  width: 35px !important;
  right: 25px;
  transform: rotate(${(Math.floor(Math.random() * 90) - 45).toString()}deg);
  &.pink path {
    fill: var(--danger);
  }
  &.green path {
    fill: var(--success);
  }
  &.blue path {
    fill: var(--primary);
  }
`;

const UserPaper = ({
  styling,
  winner,
  visible,
  delayed,
  smileColor,
}) => {
  return (
    <PaperBox styling={styling}>
      {winner && (
        <Zoom
          in={visible}
          style={{ transitionDelay: delayed ? "300ms" : "100ms" }}
        >
          <WinnerPaper elevation={10}>
            <Hidden smDown>
              <SmileIcon className={smileColor} />
            </Hidden>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Avatar
                  src={winner.picture}
                  alt={winner.user.fullName}
                  style={{ width: 120, height: 120 }}
                >
                  <img src={UserDefault} alt={winner.user.fullName} />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {winner.user ? winner.user.fullName : ""}
                </Typography>
                <Typography variant="body2">{winner.location}</Typography>
              </Grid>
            </Grid>
          </WinnerPaper>
        </Zoom>
      )}
    </PaperBox>
  );
};

export default UserPaper;
