import React from 'react';
import { Container, Grid, Hidden, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ApiService from '../../../helpers/ApiService';
import translate from '../../../locales/translate';

const CampaignImage = styled.img`
  width: 100%;
`;
const SponsorImage = styled.img`
  max-width: 200px;
`;

const CustomTableHead = styled(TableHead)`
  background-color: #ECEFF1;
  .MuiTableCell-head {
    font-weight: bold;
    color: var(--darktext);
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: #FAFAFA;
  }
  .MuiTableCell-body {
    color: var(--darktext);
  }
`;

const LegacyCampaign = () => {
  const router = useParams();
  const [campaign, setCampaign] = React.useState({});
  const urlImage = "https://images.flow-assets.com/bigtester.com.br/";

  const getCampaign = () => {
    ApiService.get(`/campaigns/legacy/${router.id}`).then(({ data }) => {
      setCampaign(data);
    });
  };

  const convertDate = (dateToConvert) => {
    if(dateToConvert) {
      let splitDate = dateToConvert.split('T');
      let dateItens = splitDate[0].split('-');
      let year = dateItens[0];
      let month = dateItens[1];
      let day = dateItens[2];

      return `${day}/${month}/${year}`;
    }
  };

  const hideEmail = (email) => {
    let splitEmail = email.split('@');
    let nameEmail = splitEmail[0].split('');
    let secretMail = nameEmail[0] + nameEmail[1] + nameEmail[2] + '*****@' + splitEmail[1];

    return secretMail;
  }

  React.useEffect(() => {
    if (router.id) {
      getCampaign();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <Grid container style={{backgroundColor: campaign.background_color}}>
      <Container style={{padding: 0, backgroundColor: "white"}}>
        <CampaignImage src={`${urlImage}${campaign.image}`} alt={campaign.title} />
        <Grid container spacing={2} style={{padding: 32}}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" className="text-bold dark--text" style={{marginBottom: 16}}>
              {campaign.title}
            </Typography>
            <Typography variant="h5" className="dark--text">
              {translate("cs.csOver")}
            </Typography>
            <Typography variant="body1" className="dark--text">
              {translate("cs.csOverWhen")} {convertDate(campaign.end_date)}
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4} align="center">
              <Typography variant="body2" className="dark--text" style={{marginBottom: 8}}>
                {translate("cs.detail.patro.after")}
                {' '}
                {campaign.sponsor}
              </Typography>
              <SponsorImage src={`${urlImage}${campaign.image_sponsor}`} alt={campaign.sponsor} />
            </Grid>
          </Hidden>
          {
            campaign.winners && campaign.winners.length > 0 ? (
              <Grid item xs={12} md={12}>
                <Typography variant="h5" className="dark--text" align="center">
                  {translate("cs.winners")}
                </Typography>
                <TableContainer component={Paper} style={{marginTop: 24}}>
                  <Table>
                    <CustomTableHead>
                      <TableRow>
                        <TableCell>{translate("cs.legacy.table.name")}</TableCell>
                        <TableCell>{translate("cs.legacy.table.email")}</TableCell>
                      </TableRow>
                    </CustomTableHead>
                    <TableBody>
                      {campaign.winners.map((campaign) => {
                        return (
                          <CustomTableRow>
                            <TableCell>{campaign.firstName}</TableCell>
                            <TableCell>{hideEmail(campaign.email)}</TableCell>
                          </CustomTableRow>
                        );
                      })}
                  </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ):("")
          }
        </Grid>
      </Container>
    </Grid>
  );
};

export default LegacyCampaign;
