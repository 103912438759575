import React from 'react';
import DefaultLayout from '../../layout/default';
import MyPointsPage from './components/Page';

const MeusPontos = () => {
  return (
    <DefaultLayout>
      <MyPointsPage />
    </DefaultLayout>
  );
}

export default MeusPontos;