import React from 'react';
import Icon from '@mdi/react';
import styled from 'styled-components';

const Btn = styled.button`
    display: inline-grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover,
    &:focus {
        outline: none;
    }
`;

const NavBtn = (props) => {
    return (
        <Btn onClick={props.onClick} style={props.style} {...props}>
            <Icon 
                path={props.icon} 
                width='24px' 
                height='24px'
            />
            {props.text}
        </Btn>
    )
}

export default NavBtn
