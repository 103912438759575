/* eslint-disable array-callback-return */
import React from "react";
import { Grid, Container, Typography, Card } from "@material-ui/core";
import MissionCard from "./Card";
import GainMore from "../../../components/common/GainMore";
import BtHowItWorks from "../../../components/common/BtHowItWorks";
import ApiService from "../../../helpers/ApiService";
import translate from "../../../locales/translate";
import { useDispatch } from "react-redux";

const PageListing = () => {
  const [missions, setMissions] = React.useState([]);
  const [otherMissions, setOtherMissions] = React.useState([]);
  const [myMissions, setMyMissions] = React.useState([]);
  const [missionTags, setMissionTags] = React.useState([]);

  const dispatch = useDispatch();

  const getMissions = () => {
    ApiService.get("missions").then(({ data }) => {
      let allMissions = [];
      data.missions.map((currentMission) => {
        !currentMission.tag.followPage &&
          allMissions.push({
            tag: currentMission.tag.name,
            data: currentMission,
          });
      });
      setMissions(allMissions);

      let allTags = [];
      allMissions.map((currentTag) => {
        !allTags.includes(currentTag.tag) && allTags.push(currentTag.tag);
      });
      setMissionTags(allTags);

      const tagTmp = data.tags.map((t) => t.name);
      setOtherMissions(
        data.missions.filter((miss) => !tagTmp.includes(miss.tag.name))
      );

      getMyMissions(allMissions);
    });
  };

  const getMyMissions = (currentMissions = []) => {
    ApiService.get("/my-missions").then(({ data }) => {
      if (currentMissions.length > 0) {
        let myMissionsTemp = [];
        let callbackMyMissions = data.map((dat) => dat.mission);

        callbackMyMissions.map((currentMyMission) => {
          currentMissions.map((mission) => {
            currentMyMission === mission.data.id &&
              myMissionsTemp.push(currentMyMission);
          });
        });
        setMyMissions(myMissionsTemp);
      } else {
        setMyMissions(data.map((dat) => dat.mission));
      }
    });
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  const verifyTotal = () => {
    let totalTemp = 0;
    let myMissionsTemp = 0;
    myMissions.map((currentMyMission) => {
      missions.map((currentMission) => {
        if (currentMyMission === currentMission.data.id) {
          myMissionsTemp++;
          return "";
        }
      });
    });
    totalTemp = missions.length - myMissionsTemp;
    return totalTemp;
  };

  React.useEffect(() => {
    getMyMissions();
    getMissions();
    changePagination("mission");
    changeHelmet([{name: "mission", content: "mission content"}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="lg" style={{ backgroundColor: "white" }}>
      <Grid container spacing={2} style={{ paddingBottom: 42, paddingTop: 24 }}>
        <Grid item xs={12}>
          <BtHowItWorks video="https://www.youtube.com/watch?v=jxnoI1Et6rc" />
          <Typography variant="h4" className="dark--text text-bold">
            {translate("ms.title")}
          </Typography>
          <Typography paragraph variant="h6" className="dark--text text-bold">
            {translate("ms.titleText")}
          </Typography>
          <Typography variant="body2" className="dark--text">
            <span className="text-bold">{translate("ms.text.01")}</span>{" "}
            {translate("ms.text.02")}
          </Typography>
          <Typography paragraph variant="body2" className="dark--text">
            {translate("ms.text.03")}
          </Typography>
          <Typography variant="body2" className="dark--text">
            {translate("ms.text.04")}
          </Typography>
          <Typography variant="body2" className="dark--text">
            {translate("ms.text.05")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ padding: 12 }} elevation={2} className="dark--text">
            {translate("ms.detailTitle.pt1")}
            <span className="primary--text text-bold"> {verifyTotal()} </span>
            {translate("ms.detailTitle.pt2")}
          </Card>
        </Grid>
        <Grid item xs={12}>
          {missionTags.map((tag, index) => {
            return (
              <Grid container spacing={2} key={`mission-tag-${index}`}>
                <Grid item xs={12} style={{ marginTop: "16px" }}>
                  <Typography variant="h6" className="text-bold dark--text">
                    {tag}
                  </Typography>
                  <hr></hr>
                </Grid>
                {missions.map((mission, i) => {
                  if (mission.tag === tag) {
                    return !myMissions.includes(mission.data.id) ? (
                      <Grid item xs={12} md={3} key={`mission-${i}`}>
                        <MissionCard
                          mission={mission.data}
                          disabled={myMissions.includes(mission.data.id)}
                        />
                      </Grid>
                    ) : (
                      ""
                    );
                  }
                })}
              </Grid>
            );
          })}
        </Grid>
        {myMissions.length > 0 && (
          <Grid item xs={12} md={12} style={{ marginTop: 32 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" className="text-bold dark--text">
                  {translate("follows.accomplished")}
                </Typography>
                <hr></hr>
              </Grid>
              {missions.map((mission, i) => {
                return myMissions.includes(mission.data.id) ? (
                  <Grid item xs={12} md={3} key={`mission-${i}`}>
                    <MissionCard
                      mission={mission.data}
                      disabled={myMissions.includes(mission.data.id)}
                    />
                  </Grid>
                ) : (
                  ""
                );
              })}
            </Grid>
          </Grid>
        )}
        {otherMissions.length > 0 && (
          <Grid item xs={12}>
            <Grid container style={{ margin: "42px 0" }}>
              <Grid item xs={12} style={{ marginBottom: 12 }}>
                <Typography variant="caption" className="dark--text">
                  {translate("ms.otherMissions")}
                </Typography>
                <hr></hr>
              </Grid>
              {otherMissions.map((other, index) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  key={`other-mission-${index}`}
                  style={{ padding: "0 8px", marginBottom: 16 }}
                >
                  <MissionCard
                    mission={other}
                    disabled={myMissions.includes(other.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <GainMore />
      </Grid>
    </Container>
  );
};

export default PageListing;
