import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";

// import Categories from './Categories'
import List from "./ProductHistoryList";

import styled from "styled-components";
import ApiService from "../../../helpers/ApiService";

const StyledContainer = styled(Container)`
  min-height: 50vh;
  padding: unset !important;
  & #lastwinners {
    margin: auto;
  }
`;

const ProductsHistoryPageComponent = () => {
  const [setCategories] = React.useState([]);
  const [selectedCategories] = React.useState([]);

  const getCategories = () => {
    ApiService.get("/categorias")
      .then(({ data }) => {
        console.log(data);
        setCategories(data);
      })
      .catch((err) => {
        console.log("Requisição da API com erro");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledContainer maxWidth="lg">
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} md={12} style={{ zIndex: 1 }}>
              <List categories={selectedCategories} />
            </Grid>
          </Grid>
        </StyledContainer>
      </Grid>
    </Grid>
  );
};

export default ProductsHistoryPageComponent;
