// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: '1. Como acumular pontos?',
    content: 'Você consegue os pontos:<br>'
    +'1. fazendo o cadastro;<br>'
    +'2. respondendo as perguntas de consumo e comportamento no site;<br>'
    +'3. completando seu cadastro no nosso site;<br>'
    +'4. compartilhando os produtos no seu Facebook.'
  },
  {
    title: '2. Onde retiro as amostras?',
    content: 'Os produtos são enviados via correio para sua casa.<br>'
    +'Após a solicitação de troca de um produto, a equipe do Big Tester entra em contato para confirmar seu endereço e para depois fazer o envio.'
  },
  {
    title: '3. É realmente de graça?',
    content: 'Sim!!!<br>'
    +'Todas as amostras são enviadas totalmente de graça. Você não tem custo nenhum.'
  },
  {
    title: '4. Como faço para receber amostras na minha casa?',
    content: 'Para receber amostras na sua casa, é muito simples: <br>'
    +'Você se cadastra no nosso site, acumula o maior número de pontos possíveis, solicita a troca das amostras desejadas e nós enviamos para sua casa.',
  },
  {
    title: '5. Já me cadastrei e nunca ganhei nada.',
    content: 'Para receber as amostras em casa, não basta se cadastrar. É necessário acumular pontos e solicitar a troca do produto desejado no site.',
  },
  {
    title: '6. Como faço para me descadastrar?',
    content: 'Para se descadastrar acesse <a href="https://www.bigtester.com.br/descadastrar" target="_blank">esse link</a>.'
  },
  {
    title: '7. Não consigo me cadastrar. O que devo fazer?',
    content: 'Caso não consiga terminar o seu cadastro, exclua os cookies e os caches do seu navegador e tente finalizar o cadastro novamente.',
  },
  {
    title: '8. Problemas com login e cadastro?',
    content: 'Verifique se está inserindo todas as informações de forma correta. Não é permitido "espaço" após a última letra ou número no campo de cadastro.',
  },
  {
    title: '9.  Meu pontos não aumentam. O que devo fazer?',
    content: 'Certifique-se que você fez todos os processos para ganhar pontos (como descrito na primeira cláusula).<br>'
    +'Caso já tenha realizado todos os procedimentos e mesmo assim os pontos não subiram, entre em contato através do e-mail instagram@bigtester.com.br.',
  },
];
