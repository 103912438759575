import React from "react";

// import Topmenu from '../top-menu-disconnected/Topmenu' //Ok
import { Container, Grid } from "@material-ui/core";
import styled from "styled-components";
// import Formatter from '../../../../helpers/Formatter';
import translate from "../../../../locales/translate";

// import { Container } from './styles';
const MainContent = styled(Grid)`
  position: relative !important;
  background-color: #ffffff;
  padding: 15px 40px;
  margin-top: 10px;
  color: #546e7a;

  a:hover {
    cursor: pointer;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: rgb(41, 182, 246);
  }

  a:active {
    text-decoration: none;
  }

  h2 {
    margin-top: 40px;
  }
`;

const Terms = () => {
  return (
    <Container>
      <MainContent>
        <h1>{translate("terms")}</h1>
        <h2>{translate("terms.title.1")}</h2>
        <p>{translate("terms.text.1.1")}</p>
        <p>{translate("terms.text.1.2")}</p>
        <p>{translate("terms.text.1.3")}</p>
        <p>{translate("terms.text.1.4")}</p>
        <p>{translate("terms.text.1.5")}</p>
        <ol>
          <li>{translate("terms.li.1.1")}</li>
          <li>{translate("terms.li.1.2")}</li>
          <li>{translate("terms.li.1.3")}</li>
        </ol>
        <p>{translate("terms.text.1.6")}</p>
        <p>{translate("terms.text.1.7")}</p>
        <p>{translate("terms.text.1.8")}</p>
        <p>{translate("terms.text.1.9")}</p>
        <p>{translate("terms.text.1.10")}</p>

        <h2>{translate("terms.title.2")}</h2>
        <p>{translate("terms.text.2.1")}</p>
        <p>{translate("terms.text.2.2")}</p>
        <p>{translate("terms.text.2.3")}</p>
        <p>{translate("terms.text.2.4")}</p>
        <p>{translate("terms.text.2.5")}</p>

        <h2>{translate("terms.title.3")}</h2>
        <p>{translate("terms.text.3.1")}</p>

        <h2>{translate("terms.title.4")}</h2>
        <p>{translate("terms.text.4.1")}</p>
        <p>{translate("terms.text.4.2")}</p>

        <ul>
          <li>{translate("terms.li.4.1")}</li>
          <li>{translate("terms.li.4.2")}</li>
          <li>{translate("terms.li.4.3")}</li>
        </ul>

        <p>{translate("terms.text.4.3")}</p>

        <h2>{translate("terms.title.5")}</h2>
        <p>{translate("terms.text.5")}</p>

        <h2>{translate("terms.title.6")}</h2>
        <p>{translate("terms.text.6")}</p>

        <h2>{translate("terms.title.7")}</h2>
        <p>{translate("terms.text.7.1")}</p>
        <p>{translate("terms.text.7.2")}</p>

        <h2>{translate("terms.title.8")}</h2>
        <p>{translate("terms.text.8.1")}</p>
        <p>{translate("terms.text.8.2")}</p>

        <h2>{translate("terms.title.9")}</h2>
        <p>{translate("terms.text.9.1")}</p>
        <p>{translate("terms.text.9.2")}</p>
        <ul>
          <li>{translate("terms.li.9.1")}</li>
          <li>{translate("terms.li.9.2")}</li>
          <li>{translate("terms.li.9.3")}</li>
        </ul>
        <p>{translate("terms.text.9.3")}</p>

        <h2>{translate("terms.title.10")}</h2>
        <p>{translate("terms.text.10")}</p>

        <h2>{translate("terms.title.11")}</h2>
        <p>{translate("terms.text.11")}</p>
      </MainContent>
    </Container>
  );
};

export default Terms;
