import React from 'react';
import DefaultLayout from '../../layout/default';
import PageListing from './components/PageListing';

const Missoes = () => {
    return (
        <DefaultLayout>
            <PageListing />
        </DefaultLayout>
    );
}

export default Missoes;