import React from "react";
import Carousel from "react-material-ui-carousel";
import CarouselItem from './CarouselItem';
import ApiService from '../../../helpers/ApiService';

const carouselInterval = 6000;
const CarouselSocialMedia = () => {
    
    const [carrouselItens, setCarouselItens] = React.useState([]);
    const getItens = () => {
        ApiService.get("follow-earn-points-card/actives").then(({ data }) => {
            setCarouselItens(data);
        });
    };
    
    React.useEffect(() => {
        getItens();
      }, []);
 
    return (
        <Carousel interval={carouselInterval}>{ carrouselItens.map( (item, i) => <CarouselItem key={i} item={item} /> ) }</Carousel>
    );
};

export default CarouselSocialMedia;