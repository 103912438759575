import React, { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Button,
  CircularProgress,
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import styled from "styled-components";
import { useForm } from "react-hook-form";
import ApiService from "../../../helpers/ApiService";
import { withRedux } from "../../../helpers/redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ReactInputMask from "react-input-mask";
import translate from "../../../locales/translate";
import { FormattedMessage } from "react-intl";
import * as translations from "../../../locales/text";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const ProfileImage = styled.img`
  border-radius: 50%;
  border: 1px solid lightgrey;
`;

const NoProfileImage = styled.div`
  background-color: lightgrey;
  border: 1px solid grey;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
`;

const PhotoUploadBtn = styled(IconButton)`
  position: absolute;
  bottom: 10px;
  left: 60px;
  float: right;
`;

const RowContainer = styled(Grid)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  &:first-child {
    border-top: 1px solid lightgrey;
  }
  padding: 24px;
  @media screen and (max-width: 960px) {
    padding-left: 0;
    padding-right: 0;
  }
`;


const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};



const Profile = ({ user = {}, onSave = () => {}, ...props }) => {
  const [genderSelect, setGender] = React.useState(user.gender || "N/A");
  const [inputImage, setInputImage] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const { handleSubmit, register, errors, setValue, setError } = useForm();
  const [uploadedImage, setUploadedImage] = React.useState(user.avatar)
  const dispatch = useDispatch();
  const { language } = useStore();
  const [ hasCPF, setHasCPF ] = useState(false);

  const handleSelect = ({ target }) => {
    setGender(target.value);
    setValue("gender", target.value);
  };

  const handleNewAvatar = () => {
    document.getElementById("user-avatar").click();
  };

  const uploadNewAvatar = async (event) => {
    try {
      setUploading(true);
      const avatar = inputImage.files[0];
      const { location } = await ApiService.sendS3({
        source: "users",
        file: avatar,
        fileName: user.fullName,
      });
      setUploading(false);
      user.avatar = location;
      setUploadedImage(location);
      if (location && location !== '') {
        document.getElementById('profile-image').src = location;
        await ApiService.patch('/my-account',{avatar: location})
        .then(({ data }) => {
          dispatch({ type: 'SET_USER', user })
          onSave(data , 'Perfil-Avatar');        
        }).catch(e => console.log(e));
      }
    } catch (err) {
      dispatch({
        type: "ALERT",
        alert: {
          color: "var(--success)",
          message: translate("mas.upload.fail-message"),
        },
      });
    }
  };

  const TextTranstaltions = {
    birthDateErrorMessage: translations.default[language]["mas.birthdate.parse.fail-message"],
    invalidUserAgeMessage: translations.default[language]["mas.birthdate.invalid-age"],
    madatoryFieldMessage: translations.default[language]["lp.form.obrCamp"]
  };

  const handleSave = (data) => {
    dayjs.extend(utc);    
    let bDate = data.birthdate.split("/").map((dat) => parseInt(dat, 10));
    let utcDate = dayjs(new Date(bDate[2],bDate[1]-1,bDate[0])).utc().format();
    let formattedUTCBirthDate = undefined;
    try {
      formattedUTCBirthDate = dayjs(utcDate).toDate();
    }catch(error) {
     Swal.fire(TextTranstaltions.birthDateErrorMessage);
      return;
    }
    let currentYear = new Date().getFullYear();
    let userBirthDateYear = formattedUTCBirthDate.getFullYear();
    let userCurrentAge = currentYear - userBirthDateYear;
    let majority = language === 'en-US' ? 21 : 18;

    let cpfNumber = String(data.cpf).replace(/\D/g, "");
    if (!hasCPF)
      data.cpf = Number(cpfNumber);//se usuario nao tem cpf seta o valor do form
    else
      delete data.cpf; // se usuario ja tem cpf nao deixa atualizar  
      //data.cpf = String(user.cpf).replace(/\D/g, "");      
    if(currentYear <= userBirthDateYear || userCurrentAge < majority || userCurrentAge > 100){
      let years = dayjs(new Date()).diff(utcDate,'year');
      setError('birthdate', {type:'manual',message:translate("lp.form.invalidBirthday")});
      //`Idade inválida <span style='color:#f27474'>${years}</span> anos, favor verifique a data de nascimento novamente o campo data de nascimento!`
      const SwalInvalidUserAge ={
        body: TextTranstaltions.invalidUserAgeMessage.replace("{age}", years)
      }
      Swal.fire({
        icon: 'error',
        html: SwalInvalidUserAge.body
      })
      return;
    }

    onSave({ 
      ...data,     
      birthdate: formattedUTCBirthDate.getTime() 
    }, "Perfil");    
  };

  React.useEffect(() => {
    register({ name: "gender" });
    setValue("gender", user.gender || "N/A");
    window.scrollTo(0,100);
    setTimeout(() => {
      window.scrollTo(0,0);
    },300);

    setHasCPF(user.cpf > 0);        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user.avatar]);

  return (
    <Paper className="dark--text" style={{ padding: 16 }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container>
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            justify="space-between"
            style={{ minHeight: 100, paddingBottom: 12 }}
          >
            <Grid item>
              <Typography variant="h6" className="text-bold">
                {translate("mas.section1.title")}
              </Typography>
              <Typography variant="body2">
                {translate("mas.section1.subTitle")}{" "}
              </Typography>
            </Grid>
            <Grid item style={{ height: 135 }}>
              <div style={{ position: "relative", height: 125, width: 125 }}>
                {user.avatar ? (
                  <ProfileImage
                    id="profile-image"
                    src={uploadedImage}
                    alt={user.fullName}
                    height="100px"
                  />
                ) : (
                  <NoProfileImage>
                    <PersonIcon style={{ fontSize: 90 }} />
                  </NoProfileImage>
                )}
                <PhotoUploadBtn
                  className="primary"
                  disabled={uploading}
                  onClick={handleNewAvatar}
                >
                  {uploading ? (
                    <CircularProgress color="#FFF" size={18} thickness={7} />
                  ) : (
                    <PhotoCameraIcon fontSize="small" />
                  )}
                </PhotoUploadBtn>
                <input
                  type="file"
                  hidden
                  name="avatar"
                  id="user-avatar"
                  accept="image/*"
                  onChange={uploadNewAvatar}
                  ref={(ref) => setInputImage(ref)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  {translate("mas.section1.name")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={user.fullName}
                  fullWidth
                  InputProps={{
                    endAdornment: <EditIcon className="primary--text" />,
                  }}
                  name="fullName"
                  inputRef={register({ required: true })}
                  helperText={errors.fullName && TextTranstaltions.madatoryFieldMessage}
                />
              </Grid>
            </RowContainer>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  {translate("mas.section1.phone")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={user.cellphone}
                  fullWidth
                  disabled
                  name="cellphone"
                  inputRef={register({ required: true })}
                  helperText={errors.cellphone && TextTranstaltions.madatoryFieldMessage}
                />
              </Grid>
            </RowContainer>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  E-mail
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  defaultValue={user.email}
                  fullWidth
                  disabled
                  name="email"
                  inputRef={register({ required: true })}
                  helperText={errors.email && TextTranstaltions.madatoryFieldMessage}
                />
              </Grid>
            </RowContainer>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  CPF
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormattedMessage id="mas.section1.cpf">
                  {(translate) => (
                    <ReactInputMask
                      mask="999.999.999-99"
                      defaultValue={(user.cpf > 0) ? user.cpf : ""}
                      disabled={hasCPF}
                    >
                      {(_inputProps) => (
                        <TextField
                          defaultValue={(user.cpf > 0) ? user.cpf : ""}
                          label={translate}
                          fullWidth
                          name="cpf"
                          disabled={hasCPF}
                          InputProps={{
                            endAdornment: (
                              <>
                                {!hasCPF && (
                                  <EditIcon className="primary--text" />
                                )}
                              </>
                            ),
                          }}
                          inputRef={register({
                            required: true,
                            pattern: /(?!(\d)\1{2}.\1{3}.\1{3}-\1{2})\d{3}\.\d{3}\.\d{3}-\d{2}/gm,
                          })}
                          inputProps={{ inputMode: "numeric" }}
                          helperText={errors.cpf &&  TextTranstaltions.madatoryFieldMessage}
                        />
                      )}
                    </ReactInputMask>
                  )}
                </FormattedMessage>      
              </Grid>
            </RowContainer>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  {translate("mas.section1.birthday")}
                </Typography>
              </Grid>
              <Grid item xs={8}>             
                <FormattedMessage id="mas.section1.birthdayPlaceholder">
                  {(translate) => (
                    <ReactInputMask
                      mask="99/99/9999"
                      defaultValue={user.birthdate}
                    >
                      {(_inputProps) => (
                        <TextField
                          defaultValue={user.birthdate}
                          label={translate}
                          fullWidth
                          name="birthdate"
                          InputProps={{
                            endAdornment: (
                              <EditIcon className="primary--text" />
                            ),
                          }}
                          inputRef={register({
                            required: true,
                            pattern: /^((0[1-9])|([1-2][0-9])|(3[0-1]))\/((0[1-9])|(1[0-2]))\/((19[0-9]{2})|(20[0-1][0-9]))$/,
                          })}
                          inputProps={{ inputMode: "numeric" }}
                          helperText={errors.birthdate &&  TextTranstaltions.madatoryFieldMessage}
                        />
                      )}
                    </ReactInputMask>
                  )}
                </FormattedMessage>                
              </Grid>
            </RowContainer>
            <RowContainer container>
              <Grid item xs={4} md={3}>
                <Typography variant="body2" className="text-bold">
                  {translate("mas.section1.sex")}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Select value={genderSelect} fullWidth onChange={handleSelect}>
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="M">{translate("mas.section1.mas")}</MenuItem>
                  <MenuItem value="F">{translate("mas.section1.fem")}</MenuItem>
                </Select>
              </Grid>
            </RowContainer>
          </Grid>
        </Grid>
        <Button
          fullWidth
          size="large"
          className="success"
          style={{ marginTop: 24 }}
          type="submit"
        >
          {translate("mas.section1.btn")}
        </Button>
      </form>
    </Paper>
  );
};

export default withRedux(Profile);
