import React from 'react';
import DefaultLayout from '../../../layout/default'
import LegacyCampaign from '../components/LegacyCampaign';

const Legacy = () => {
  return (
    <DefaultLayout>
      <LegacyCampaign />
    </DefaultLayout>
  );
}

export default Legacy;