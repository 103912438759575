import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import styled from "styled-components";
import HtmlParse from "react-html-parser";
import Formatter from "../../../helpers/Formatter";
import translate from "../../../locales/translate";

const CampainImage = styled(CardMedia)`
  height: 200px;
`;

const DisabledLabel = styled(Box)`
  transform: rotate(45deg);
  position: absolute;
  background-color: var(--primary);
  height: 20px;
  z-index: 5;
  top: 30px;
  right: -35px;
  width: 150px;
  text-align: center;
  color: white;
  box-shadow: 0px 0px 10px grey;
`;

const LowerText = styled.span`
  text-transform: lowercase;
`;

const CampainCard = ({ campaign = {}, disabled = false, legacy = false }) => {
  const urlCard = `campanhas-patrocinadas/${campaign.slug}` + (legacy ? '/legacy' : '');
  return (
    <>
      {campaign.active && !disabled ? (
        <Link
          to={
            campaign.active && !disabled
              ? urlCard
              : ""
          }
        >
          <Card
            style={{ 
              position: "relative",
              filter: !campaign.active || disabled ? "grayscale(100%)" : "",
              WebkitFilter: !campaign.active || disabled ? "grayscale(100%)" : "",
            }}
          >
            {disabled && (
              <DisabledLabel>{translate("card.participating")}</DisabledLabel>
            )}
            <CampainImage image={campaign.image} title={campaign.title} />
            <CardContent>
              <Typography variant="h6" className="dark--text">
                {campaign.title}
              </Typography>
              <Typography variant="body2" className="dark--text">
                {HtmlParse(Formatter.simplifyText(campaign.description, 160))}
              </Typography>
              <Box style={{ marginTop: 12, width: "100%", textAlign: "right" }}>
                <Button
                  className="primary"
                  size="large"
                  disabled={!campaign.active || disabled}
                >
                  {translate("btn.knowMore")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Link>
      ) : (

        <Link to={urlCard}>
          <Card
            style={{ 
              position: "relative",
              filter: !campaign.active || disabled ? "grayscale(100%)" : "",
              WebkitFilter: !campaign.active || disabled ? "grayscale(100%)" : "",
            }}
          >
            {disabled && !legacy && (
              <DisabledLabel>{translate("card.participating")}</DisabledLabel>
            )}
            <CampainImage image={legacy ? 'https://images.flow-assets.com/bigtester.com.br/' + campaign.image : campaign.image} title={campaign.title} />
            <CardContent>
              <Typography variant="h6" className="dark--text">
                {campaign.title}
              </Typography>
              <Typography variant="body2" className="dark--text">
                {!legacy ? (
                  HtmlParse(Formatter.simplifyText(campaign.description, 160))
                ) : (
                  <LowerText>
                    {Formatter.simplifyText(String(campaign.description).replace(/<[^>]*>|&nbsp;?/gm, ' '), 160)}
                  </LowerText>
                )}
              </Typography>
              <Box style={{ marginTop: 12, width: "100%", textAlign: "right" }}>
                <Button
                  className="primary"
                  size="large"
                  disabled={!campaign.active || disabled}
                >
                  {!campaign.active
                    ? translate("btn.knowMore.disabled")
                    : translate("card.participating")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Link>
      )}
    </>
  );
};

export default CampainCard;
