import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";

// import Categories from './Categories'
import List from "./List";
import Winners from "./Winners";

import styled from "styled-components";
import ApiService from "../../../helpers/ApiService";
import { useDispatch } from "react-redux";

const StyledContainer = styled(Container)`
  min-height: 50vh;
  padding: unset !important;
  & #lastwinners {
    margin: auto;
  }
`;

const ProductsPageComponent = () => {
  const [categories, setCategories] = React.useState([]);
  const dispatch = useDispatch();

  const getCategories = () => {
    ApiService.get("/categorias")
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        console.log("Requisição da API com erro", err);
      });
  };

  const changePagination = (pagination) => {
    dispatch({ type: "UPDATE_PAGINATION", pagination: pagination });
  };

  const changeHelmet = (helmetContent) => {
    dispatch({ type: "UPDATE_HELMET", helmetContent: helmetContent });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCategories();
    changePagination("product");
    changeHelmet([{ name: "product", content: "product content" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledContainer maxWidth="lg">
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} md={12} style={{ zIndex: 1 }}>
              <List categories={categories} />
              <Winners />
            </Grid>
          </Grid>
        </StyledContainer>
      </Grid>
    </Grid>
  );
};

export default ProductsPageComponent;
