import React from 'react';
import { Grid } from '@material-ui/core';
import OfferCard from './Card';

const OfferList = ({ offers = [] }) => {


    return (
        <Grid container spacing={2}>
            {offers.map(offer => (
                <Grid item xs={12} md={3} key={offer.id}>
                    <OfferCard offer={offer} />
                </Grid>
            ))}
        </Grid>
    );
}

export default OfferList;