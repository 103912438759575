import React from 'react';
import DefaultLayout from '../../../layout/default'
import Participate from '../components/Participate';

const CampanhaIdParticipar = () => {
  return (
    <DefaultLayout>
      <Participate />
    </DefaultLayout>
  );
}

export default CampanhaIdParticipar;