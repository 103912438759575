import React, { useState } from "react";

import styled from "styled-components";
import { Grid, Typography, Button } from "@material-ui/core";
import Comment from "./Comment";
import Stars from "../common/Stars";
import NewRating from "../common/product/NewRating";

import translate from "../../locales/translate";

const NewRatiingButton = styled(Button)`
  position: absolute !important;
  right: 0;
  bottom: 0;
`;

const Rattings = ({ product = {}, user = {}, update = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [commentAvalible, setCommentAvalible] = React.useState(true);

  const handleModal = () => {
    setOpen(!open);
  };

  const saveNewComment = () => {
    setOpen(false);
    update();
  };

  const checkComments = () => {
    const found = product.comments.find((c) =>
      c.owner ? c.owner.id === user.id : ""
    );
    if (found) setCommentAvalible(false);
  };

  React.useEffect(() => {
    if (product.comments && product.comments.length > 0) checkComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <Grid
      container
      style={{ backgroundColor: "white", padding: 24, paddingTop: 12 }}
    >
      <Grid item xs={12} style={{ position: "relative" }}>
        <Typography
          variant="h5"
          className="text-bold dark--text"
          style={{ marginBottom: 8 }}
        >
          {translate("pd.title.avaliate")}
        </Typography>
        <Typography
          variant="h5"
          className="dark--text"
          style={{ display: "flex", alignItems: "center" }}
          id="avarageRate"
        >
          <span style={{ marginRight: 8 }}>
            {product.stars ? product.stars.toFixed(1) : 1}
          </span>
          <Stars rating={product.stars || 0} max={5} />
          {product.comments && (
            <small style={{ marginLeft: 8 }}>
              {"(" + product.comments.length + ")"}
            </small>
          )}
        </Typography>
        <NewRatiingButton
          className={commentAvalible ? "success" : ""}
          size="large"
          disabled={!commentAvalible}
          onClick={handleModal}
        >
          {translate("pd.btn.avaliate")}
        </NewRatiingButton>
        <NewRating
          product={product}
          open={open}
          close={handleModal}
          onSaved={saveNewComment}
        />
      </Grid>
      {product.comments &&
        product.comments.map((r, index) => <Comment item={r} key={index} />)}
    </Grid>
  );
};

export default Rattings;
