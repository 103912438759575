import React from "react";
import { useParams, useHistory } from "react-router-dom";
import LoginLayout from "../../../layout/login";
import {
  Grid,
  Container,
  Typography,
  Button,
  Dialog,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Hidden,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
// import { useSelector, shallowEqual } from "react-redux";
import ApiService from "../../../helpers/ApiService";
import Formatter from "../../../helpers/Formatter";
import { withRedux } from "../../../helpers/redux";
import Login from "../../../components/auth/Login";
import translate from "../../../locales/translate";

const CloseBtn = styled(Button)`
  position: fixed !important;
  width: 30px !important;
  height: 36px !important;
  top: 8px;
  right: 8px;
  color: white;
  border-radius: 50% !important;
`;

const CustomTableHead = styled(TableHead)`
  background-color: #ECEFF1;
  .MuiTableCell-head {
    font-weight: bold;
    color: var(--darktext);
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: #FAFAFA;
  }
  .MuiTableCell-body {
    color: var(--darktext);
  }
`;

const CampaignWinners = () => {
  const router = useParams();
  const history = useHistory();
  const [campaign, setCampaign] = React.useState({});
  const [modal, setModal] = React.useState(false);

  const handleModalEvent = () => {
    setModal(!modal);
  };

  const getCampaign = () => {
    ApiService.get(`/campaigns/${router.id}`)
      .then(({ data }) => {
        setCampaign(Formatter.formatCampaign(data));
        // document.title = data.title + " - Big Tester";
      })
      .catch((err) => {
        if (!!ApiService.token) history.push("/campanhas-patrocinadas");
        else history.push("/");
      });
  };

  const handleLoginClose = () => {
    setModal(false);
  };

  const isHexadecimal = (hexValue) => {
    let hexRe = /[0-9A-Fa-f]{6}/g;
    return (hexRe.test(hexValue));
  };

  const hideEmail = (email) => {
    let splitEmail = email.split('@');
    let nameEmail = splitEmail[0].split('');
    let secretMail = nameEmail[0] + nameEmail[1] + nameEmail[2] + '*****@' + splitEmail[1];

    return secretMail;
  };

  React.useEffect(() => {
    if (router.id) {
      getCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <LoginLayout>
      <Grid container style={{ backgroundColor: `${(campaign.backgroundColor !== undefined && isHexadecimal(campaign.backgroundColor)) ? '#' : ''}${campaign.backgroundColor}` }}>
        <Container style={{padding: 0, backgroundColor: "white"}}>
          <img src={campaign.image} alt={campaign.title} style={{width: "100%"}} />
          <Grid container spacing={2} style={{padding: 32}}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" className="text-bold dark--text" style={{marginBottom: 16}}>
                {campaign.title}
              </Typography>
              <Typography variant="h5" className="dark--text">
                {translate("cs.csOver")}
              </Typography>
              <Typography variant="body1" className="dark--text">
                {translate("cs.csOverWhen")} {campaign.endDate}
              </Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={4} align="center">
                <Typography variant="body2" className="dark--text" style={{marginBottom: 8}}>
                  {translate("cs.detail.patro.after")}
                  {' '}
                  {campaign.sponsor}
                </Typography>
                <img src={campaign.imageSponsor} alt={campaign.sponsor} style={{maxWidth: 200}} />
              </Grid>
            </Hidden>
            {
              campaign.winners && campaign.winners.length > 0 ? (
                <Grid item xs={12} md={12}>
                  <Typography variant="h5" className="dark--text" align="center">
                    {translate("cs.winners")}
                  </Typography>
                  <TableContainer component={Paper} style={{marginTop: 24}}>
                    <Table>
                      <CustomTableHead>
                        <TableRow>
                          <TableCell>{translate("cs.legacy.table.name")}</TableCell>
                          <TableCell>{translate("cs.legacy.table.email")}</TableCell>
                        </TableRow>
                      </CustomTableHead>
                      <TableBody>
                        {campaign.winners.map((winner) => {
                          return (
                            <CustomTableRow>
                              <TableCell>{winner.user.fullName}</TableCell>
                              <TableCell>{hideEmail(winner.user.email)}</TableCell>
                            </CustomTableRow>
                          );
                        })}
                    </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ):("")
            }
          </Grid>
        </Container>
      </Grid>
      <Dialog open={modal} onClose={handleModalEvent} maxWidth="xs" fullWidth>
        <CloseBtn size="small" onClick={handleModalEvent}>
          <CloseIcon className="white--text" />
        </CloseBtn>
        <Login
          reload={false}
          close={() => {
            handleLoginClose();
          }}
        />
      </Dialog>
    </LoginLayout>
  );
};

export default withRedux(CampaignWinners);
