/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
// import ApiService from "../../helpers/ApiService";

const timeToEnter = 5000;

const useStyles = makeStyles({
  root: {
    width: 270,
    position: "fixed",
    bottom: "16px",
    right: "16px",
    boxShadow: "0 2px 10px 2px rgba(0,0,0,0.2)",
  },
  media: {
    height: 130,
  },
  hr: {
    height: "1px",
    border: "none",
    backgroundColor: "#e0e0e0",
    margin: 0,
  },
  title: {
    lineHeight: 1.4,
    color: "#455A64",
  },
  btnClose: {
    position: "absolute",
    top: "4px",
    right: "4px",
    backgroundColor: "#FFFFFF",
    minWidth: "22px",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.8) !important",
      color: "red",
    },
  },
});

const FeaturedBalloon = (props) => {

  const classes = useStyles();
  const [showBalloon, setBalloon] = useState("block");
  const [enterAnimation, setEnterAnimation] = useState(false);

  const closeBalloon = () => {
    setEnterAnimation(false);
    setTimeout(() => {
      setBalloon("none");
    }, 500);
  };

  setTimeout(() => {
    setEnterAnimation(true);
  }, timeToEnter);

  return (
    <Slide direction="left" in={enterAnimation} mountOnEnter unmountOnExit>
      <Card className={classes.root} style={{ display: showBalloon }}>
        {props.data.map((row, index) =>
          row.active ? (
            <CardActionArea key={index}>
              <a href={row.link} target="_blank">
                <CardMedia
                  className={classes.media}
                  image={row.image}
                  title={row.text}
                />
                <CardContent>
                  <Typography
                    className={classes.title}
                    variant="button"
                    display="block"
                    gutterBottom
                  >
                    {row.text}
                  </Typography>
                </CardContent>
              </a>
            </CardActionArea>
          ) : (
            ""
          )
        )}
        <Button className={classes.btnClose} size="small" color="primary">
          <CloseIcon fontSize="inherit" onClick={closeBalloon} />
        </Button>
      </Card>
    </Slide>
  );
};

export default FeaturedBalloon;
