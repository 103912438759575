import React from 'react';
import styled from 'styled-components'
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { withRedux } from '../../../helpers/redux';
import './animation.css'

const AlertLayout = styled.div`
  position: fixed;
  width: 100%;
  bottom: -100px;
  z-index: 11 !important;
  display: flex;
  justify-content: center;
`

const AlertBox = styled.div`
  background-color: ${props => props.color || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  box-shadow: ${props => props.color ? '0px 0px 10px grey' : ''};
  color: white;
  border-radius: 8px;
  z-index: 11 !important;
`

const useStore = () => {
  return useSelector(
    state => ({
      alert: state.alert || {}
    }),
    shallowEqual
  )
}

const BtAlert = () => {
  const { alert } = useStore()
  const dispatch = useDispatch()
  const [active, setActive] = React.useState(false)

  const handleEndAnimation = () => {
    if (active) {
      setTimeout(() => {
        setActive(false)
      }, 3000)
    } else {
      dispatch({ type: 'CLOSE_ALERT' })
    }
  }

  React.useEffect(() => {
    if (alert.message) setActive(true)
  }, [alert])

  return (
    <AlertLayout className="alert-layout" style={active ? { bottom: 15 } : {}}>
      <AlertBox color={alert.color} className={`alert-box ${active ? 'opened' : 'closed'}`} onAnimationEnd={handleEndAnimation}>
        <Typography variant="body1">
          {alert.message}
        </Typography>
      </AlertBox>
    </AlertLayout>
  );
}

export default withRedux(BtAlert);