import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Theme from "../themes";
// import { useHistory } from "react-router-dom";
import "../themes/main.css";
import Footer from "../components/common/Footer";
import { Grid, CircularProgress, Box, Dialog } from "@material-ui/core";
import BtLogo from "../components/common/BtLogo";

const BlankLayout = ({ children }) => {
  const [loading, setLoading] = React.useState(true);

  // Router.events.on('routeChangeStart', url => {
  //   setLoading(true)
  // })

  // Router.events.on('routeChangeComplete', url => {
  //   setLoading(false)
  //   const page = url.substr(1).replace(/-/g, ' ')
  //   document.title = page.charAt(0).toUpperCase() + page.substr(1) + ' - Big Tester'
  // })

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const Page = () => (
    <ThemeProvider theme={Theme}>
      <Grid
        container
        style={{
          display: "flex",
          alignItems: "flex-start",
          minHeight: "100vh",
        }}
      >
        {children}
        <Footer />
      </Grid>
    </ThemeProvider>
  );

  const LoadingScreen = () => (
    <Dialog
      open={loading}
      fullScreen
      style={{ backgroundColor: "transparent" }}
    >
      <Grid
        container
        style={{ height: "100%" }}
        alignItems="center"
        justify="center"
      >
        <Box
          style={{
            position: "absolute",
            height: 180,
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <BtLogo type="colored" style={{ margin: "auto" }} />
        </Box>
        <CircularProgress className="primary--text" size={180} thickness={4} />
      </Grid>
    </Dialog>
  );

  return loading ? <LoadingScreen /> : <Page />;
};

export default BlankLayout;
