import React from 'react';
import BtLogo from '../../components/common/BtLogo';
import { Page, Header, Container, BgColor } from './style';

const NewSubdomainLayout = ({theme, children}) => {
  return (
    <Page 
    backgroundImg={theme.bannerImage}
    textColor={theme.text}
    >
      <BgColor 
        backgroundColor={theme.backgroundColor} 
        backgroundOpacity={theme.opacity}
      >
        <Header>
          <BtLogo />
        </Header>
        <Container>
          {children}
        </Container>
      </BgColor>
    </Page>
  )
}

export default NewSubdomainLayout;
