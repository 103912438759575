import React from "react";
import translate from "../../../../locales/translate";
import { FormattedMessage } from "react-intl";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Option from "./Options";

const CampaignQuestionCard = ({
  question = {},
  image = "",
  apply = () => {},
}) => {
  const [selecteds, setSelecteds] = React.useState([]);
  const [textAnswer, setTextAnswer] = React.useState("");
  const [ready, setReady] = React.useState(false);

  const handleKeyboard = (event) => {
    const key = parseInt(event.key);

    if (
      typeof key == "number" &&
      key <= question.options.length &&
      question.type !== "text"
    ) {
      handleSelected({ button: key, option: question.options[key - 1] });
    } else if (event.key === "Enter") {
      if (question.type === "text" && textAnswer) handleNext(textAnswer);
      else if (selecteds.length > 0) handleNext(selecteds);
    }
  };

  const handleSelected = ({ button, option }) => {
    if (selecteds.includes(button)) {
      let tmp = [...selecteds];
      tmp.splice(
        tmp.findIndex((t) => t === button),
        1
      );
      setSelecteds(tmp);
    } else {
      setSelecteds([...selecteds, button]);

      // if (option.link && question.type !== 'text') window.open(option.link, '_blank')
    }

    if (question.type === "radio") handleNext([button]);
  };

  const handleNext = (option) => {
    apply(question, option || textAnswer || selecteds);
  };

  React.useEffect(() => {
    window.addEventListener("keyup", handleKeyboard);

    return () => {
      window.removeEventListener("keyup", handleKeyboard);
    };
  });

  React.useEffect(() => {
    if (selecteds.length > 0) setReady(true);
    else if (textAnswer.length >= 3) setReady(true);
    else setReady(false);
  }, [selecteds, textAnswer]);

  return (
    <Card
      elevation={0}
      className="dark--text"
      style={{ backgroundColor: "transparent" }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img src={image} alt={question.question} width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {question.question}
              </Grid>
              {question.type !== "text" ? (
                question.options.map((option, index) => (
                  <Grid item xs={12} key={`question-${option.text}-${index}`}>
                    <Option
                      button={index + 1}
                      option={option}
                      selected={selecteds.includes(index + 1)}
                      updateSelected={handleSelected}
                    />
                  </Grid>
                ))
              ) : (
                <FormattedMessage id="cs.inputText">
                  {(t) => (
                    <TextField
                      className="option-text"
                      label={t}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={5}
                      style={{ marginTop: 24 }}
                      value={textAnswer}
                      onChange={({ target }) => {
                        setTextAnswer(target.value);
                      }}
                    />
                  )}
                </FormattedMessage>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: 24,
              opacity: ready ? "1" : "0.3",
            }}
          >
            <Button
              size="large"
              className="success"
              disabled={!ready}
              onClick={() => {
                handleNext();
              }}
            >
              {translate("cs.btn.continue")}
            </Button>
            <Typography
              variant="body1"
              display="inline"
              style={{ marginLeft: 12 }}
            >
              {translate("cs.continueText")}{" "}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CampaignQuestionCard;
