import React from "react";

import {
  Grid,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Hidden,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import SearchField from "./SearchField";
import translate from "../../locales/translate";
import { FormattedMessage } from "react-intl";

const PaginationControler = ({
  page,
  amount,
  amounts,
  total,
  objectName,
  searchField = false,
  onAmountChange = () => {},
  onPageChange = () => {},
  onSearchChange = () => {},
  text,
}) => {
  const handlePageChange = (direction) => {
    switch (direction) {
      case "up":
        if (Math.ceil(total / amount) >= page + 1) onPageChange(page + 1);
        break;
      case "down":
        if (page - 1 >= 1) onPageChange(page - 1);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container justify="flex-start" style={{ margin: "8px 0" }}>
      <Grid item xs={12} md={6}>
        {text && (
          <Typography variant="h5" style={{ margin: "8px 0" }}>
            {text}
          </Typography>
        )}
        {searchField && (
          <FormattedMessage id="fs.search-field.placeholder">
            {
              (searchFieldPlaceHolder) => (
                <SearchField
                  placeholder={searchFieldPlaceHolder}
                  fullWidth
                  variant="outlined"
                  onChange={(value) => {
                    onSearchChange(value);
                  }}
                />
              )
            }
          </FormattedMessage>
          
        )}
      </Grid>
      {total > 0 && (
        <Grid
          item
          xs={12}
          md={6}
          style={{ textAlign: "right", margin: "8px 0" }}
        >
          <Hidden smDown>
            <Typography
              variant="body2"
              display="inline"
              style={{ marginRight: 16 }}
            >
              {/* {objectName} */}
              {translate("fs.offertsPerPage")}
            </Typography>
            <Select
              value={amount}
              onChange={({ target }) => {
                onAmountChange(target.value);
              }}
              inputProps={{ name: "amount" }}
            >
              {amounts.map((amt, index) => (
                <MenuItem key={"amount-" + index} value={amt}>
                  {amt}
                </MenuItem>
              ))}
            </Select>
          </Hidden>
          {total > amount && (
            <div style={{float: "right"}}>
              <IconButton
                size="small"
                style={{ marginLeft: 12 }}
                disabled={page === 1}
                onClick={() => {
                  handlePageChange("down");
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography
                variant="body2"
                display="inline"
                style={{ marginRight: 8, marginLeft: 8 }}
              >
                {(page - 1) * amount + 1}-
                {total < page * amount ? total : page * amount} {translate("fs.pagination.of")} {total}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  handlePageChange("up");
                }}
                disabled={page >= total / amount}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PaginationControler;
