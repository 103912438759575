import React, { useState } from "react";
import ApiService from "../../../../helpers/ApiService";
import InputMask from "react-input-mask";
import {
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import translate from "../../../../locales/translate";
import Swal from "sweetalert2";

import styled from "styled-components";
import { WhatsApp, Facebook, Twitter, Instagram } from "@material-ui/icons";

const GridContainer = styled(Grid)`
  background-color: white;
  margin: unset;
  padding: 24px;
  height: auto;
  max-height: 180vh;
  position: relative;
  /* margin-bottom: 50px; */
  @media screen and (min-width: 960px) {
    padding: 48px;
  }

  background-color: #edeff0;
  border-radius: 10px;
  & .map-container {
    padding-right: 16px !important;
    @media screen and (max-width: 960px) {
      padding-right: unset !important;
    }
  }
  & .MuiTextField-root {
    margin-bottom: 18px;
  }
  & p {
    font-weight: 700;
  }
  & .button-container {
    text-align: right;
    & button {
      width: 150px;
      color: white;
      font-weight: 700;
      height: 50px;
      border-radius: 25px;
    }
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
const StyledTextField = styled(TextField)`
  background-color: #fff;
`;

const SocialMediaButton = styled(Button)`
  width: 100%;
  margin: 0 auto;
  /* margin: 8px 0; */
  color: #fff;
  font-weight: bold;
`;

const WhatsappButton = styled(SocialMediaButton)`
  background-color: var(--whatsapp);
  &:hover {
    background-color: var(--whatsapp-hover);
  }
`;
const FacebookButton = styled(SocialMediaButton)`
  background-color: var(--facebook);
  &:hover {
    background-color: var(--facebook-hover);
  }
`;
const InstagramButtom = styled(SocialMediaButton)`
  background: var(--instagram);
  background-color: #dc2743;
  &:hover {
    background-color: var(--instagram-hover);
    background: var(--instagram-hover);
  }
`;
const TwitterButton = styled(SocialMediaButton)`
  background-color: var(--twitter);
  &:hover {
    background-color: var(--twitter-hover);
  }
`;

const ErrorMessage = styled.p`
  color: var(--danger);
  margin: 0.2rem 0 0 0;
  font-size: 0.8rem;
  position: relative;
  padding-left: 0.1rem;
  top: -1rem;
`;

const Contact = ({ language }) => {
  const { register, handleSubmit, setError, errors } = useForm();
  const [phone, setPhone] = useState("");
  const [contactSent, setContactSent] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [start, setStart] = useState(true);

  const onSuccess = async (data) => {
    setContactSent(true);
    setContactLoading(true);
    await ApiService.post("/contact", {
      name: data.name,
      email: data.email,
      message: data.message,
      phone: data.phone,
    })
      .then(() => {
        setPhone("");
        setContactLoading(false);
        setContactSent(false);

        switch (language) {
          case 'es':
            Swal.fire({
              title: "¡Éxito!",
              text: "Su información de contacto ha sido enviada con éxito, ¡espere nuestro contacto!",
              icon: "success",
            });
            break;
          case 'en-US':
            Swal.fire({
              title: "Success!",
              text: "Your contact information has been sent successfully, wait for our contact!",
              icon: "success",
            });
            break;
          default:
            Swal.fire({
              title: "Sucesso!",
              text: "Suas informações de contato foram enviadas com sucesso, aguarde nosso contato!",
              icon: "success",
            });
            break;
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <GridContainer container id="contactTop" spacing={2}>
      <Grid item sm={12}>
        <Typography
          variant="h3"
          className="dark--text"
          style={{ marginBottom: 16 }}
        >
          {translate("lp.contact.title")}
        </Typography>
      </Grid>

      <Grid item sm={10}>
        {!contactSent && (
          <React.Fragment>
            <form onSubmit={handleSubmit(onSuccess)}>
              <FormattedMessage id="contact.namePlaceholder">
                {(placeholderName) => (
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    placeholder={placeholderName}
                    name="name"
                    inputRef={register({
                      required: true,
                    })}
                    //   helperText={errors.name && InputErrorMessage}
                  />
                )}
              </FormattedMessage>
              {errors.name && <ErrorMessage>{translate("lp.contact.errors.name")}</ErrorMessage>}
              <FormattedMessage id="cs.legacy.table.email">
                {
                  (placeholderEmail) => (
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      placeholder={placeholderEmail}
                      name="email"
                      inputRef={register({
                        required: true,
                        // eslint-disable-next-line no-useless-escape
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      //   helperText={errors.email && InputErrorMessage}
                    />
                  )
                }
              </FormattedMessage>
              
              {errors.email && <ErrorMessage>{translate("lp.contact.errors.email")}</ErrorMessage>}
              <FormattedMessage id="contact.phonePlaceholder">
                {(placeholderPhone) => (
                  <InputMask
                    mask="(99) 99999-9999"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  >
                    {() => (
                      <StyledTextField
                        variant="outlined"
                        value={phone}
                        fullWidth
                        placeholder={placeholderPhone}
                        id="phone"
                        name="phone"
                        inputRef={register({
                          required: true,
                          pattern: /^\([1-9]{2}\)\s(([0-9-]{10})|([1-9][0-9]{7}))$/,
                        })}
                        //   helperText={errors.phone && InputErrorMessage}
                      />
                    )}
                  </InputMask>
                )}
              </FormattedMessage>
              {errors.phone && <ErrorMessage>{translate("lp.contact.errors.phone")}</ErrorMessage>}
              <FormattedMessage id="contact.messagePlaceholder">
                {(placeholderMessage) => (
                  <StyledTextField
                    
                    variant="outlined"
                    fullWidth
                    placeholder={placeholderMessage}
                    multiline
                    rows={5}
                    name="message"
                    inputRef={register({
                      required: true,
                      minLength: 5,
                      maxLength: 500,
                    })}
                    //   helperText={errors.message && InputErrorMessage}
                  />
                )}
              </FormattedMessage>
              {errors.message && <ErrorMessage>{translate("lp.contact.errors.message")}</ErrorMessage>}
              <div className="button-container">
                <Button className="secondary" type="submit">
                  {translate("contact.button")}
                </Button>
              </div>
            </form>
          </React.Fragment>
        )}
        {contactSent && !start && (
          <div
            style={{
              flex: 1,
              alignItems: "center",
              alignContent: "center",
              justifyItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
          >
            <Typography variant="h5">
             {translate("lp.contact.message")}
            </Typography>
          </div>
        )}
        {contactLoading && (
          <div
            style={{
              flex: 1,
              alignItems: "center",
              alignContent: "center",
              justifyItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Grid>
      <Grid item sm={2}>
        <Typography
          variant="body1"
          className="dark--text"
          style={{ marginBottom: 16 }}
        >
          {translate("lp.contact.socialmedia")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledLink
              href="https://wa.me/5511997655348"
              target="_blank"
            >
              <WhatsappButton>
                <WhatsApp style={{ marginRight: 8 }} />
                <span>Whatsapp</span>
              </WhatsappButton>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink
              href={"https://facebook.com/BigTesterOficial"}
              target="_blank"
            >
              <FacebookButton>
                <Facebook style={{ marginRight: 8 }} />
                <span>Facebook</span>
              </FacebookButton>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink
              href={"https://www.instagram.com/big_tester"}
              target="_blank"
            >
              <InstagramButtom>
                <Instagram style={{ marginRight: 8 }} />
                <span>Instagram</span>
              </InstagramButtom>
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink
              href={"https://twitter.com/Big__Tester"}
              target="_blank"
            >
              <TwitterButton>
                <Twitter style={{ marginRight: 8 }} />
                <span>Twitter</span>
              </TwitterButton>
            </StyledLink>
          </Grid>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default Contact;
