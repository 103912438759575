import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";

const Input = styled(TextField)`
  & input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & .Mui-focused fieldset {
    border-color: var(--secondary) !important;
  }
  & fieldset {
    top: -7px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-style: solid;
    border-width: 1px;
    pointer-events: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 25px;
    z-index: 0;
  }
`;

const SearchField = (props) => {
  const [text, setText] = React.useState("");

  const handleChange = ({ target }) => {
    setText(target.value);
    props.onChange(target.value);
  };

  return (
    <Input
      {...props}
      // InputProps={{
      //     endAdornment: <SearchIcon>search</SearchIcon>
      // }}
      value={text}
      onChange={handleChange}
      autoFocus
    />
  );
};

export default SearchField;
