import styled from 'styled-components';

function hexToRgbA(hex, opacity){
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+ opacity +')';
  }
}

export const Page = styled.section`
  background-image: url(${props => props.backgroundImg}); 
  background-size: cover;
  color: ${props => props.textColor};
`;

export const BgColor = styled.div`
  width: calc(100% - 4rem); // subtraindo os paddings left e right
  min-height: calc(100vh - 2rem); // subtraindo os paddings top e bottom
  padding: 0 2rem 2rem 2rem;

  background-color: ${props => hexToRgbA(props.backgroundColor, props.backgroundOpacity)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 5rem;
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 900px;
  min-height: 100%;
  margin: auto;
  padding: 5rem 0 2rem 0;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin: 0.5rem;
  }
  p {
    font-size: 0.8rem;
    font-style: italic;
  }

  .checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: ${props => `${props.size}rem`};
  line-height: 1.3;

  span {
    color: ${props => props.highlightColor};
  }

  @media only screen and (max-width: 568px) {
    font-size: ${props => props.size === '1.5' ? '1rem' : '2rem'}
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 500px;
  margin: auto;

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 568px) {
    flex-direction: column;
    padding: 1rem 0;
  }
`;

export const Input = styled.input`
  font-size: 1rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  width: 100%;

  background: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  outline: 0;
  transition: all 200ms;

  &:focus, &:hover {
    border: 1px solid ${props => props.borderColor};
  }

  @media only screen and (max-width: 568px) {
    width: calc(100% - 2rem);
    margin: auto;
    margin-bottom: 0.5rem;
  }
`;

export const Button = styled.button`
  font-size: 1rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  width: 50%;
  margin-left: 0.5rem;

  color: ${props => props.textColor};
  background: ${props => props.bgColor};

  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: 0;
  transition : all 200ms;

  &:disabled {
    cursor: not-allowed;
    background-color: #aaa;
  }
  &:hover {
    opacity: 0.9;
  }

  @media only screen and (max-width: 568px) {
    width: 100%;
    margin: auto;
  }
`;

export const Error = styled.p`
  font-size: 0.9rem;
  color: ${props => props.color};
  text-align: left;
  
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 0.25rem;
`;