/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Grid, Container, TextField, Button, Typography } from '@material-ui/core';
import ApiService from '../../helpers/ApiService';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Swal from 'sweetalert2';
import UnregisterImage from '../../img/descadastro.png'
import happy from '../../img/confirma_email.png';
import styled from 'styled-components';
import '../../themes/unsubscribe.css';
import translate from "../../locales/translate";
import * as translations from '../../locales/text'

const StyledInput = styled(TextField)`
  & * {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }
  & .Mui-focused {
    color: var(--primary) !important;
    & .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary) !important;
    }
  }
`

const useStore = () => {
  return useSelector( (state) => ({ 
    user: state.user, 
    language: state.language 
  }), shallowEqual);
}

const StyledButton = styled(Button)`
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
`

const UnregisterPageComponent = () => {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  const router = useHistory();
  const { user, language } = useStore();

  const SwalTextTranslations = {
    unsubscribeSuccessMessageBody: translations.default[language]['unsubs.success'],
    unsubscribeErrorMessageBody: translations.default[language]['unsubs.error']
  }
  
  const handleUnRegister = () => {
     if (email === '') {
      return false;
    } else {
      if (email !== user.email) { 
        toggleErrorMessage(translate('unsubs.error.emailDoesntMatch'))
      } else {
        let confirmation = {};
        switch (language) {
          case 'es':
            confirmation = {
              icon: 'question',
              html: `¿Estás seguro de que quieres eliminar tu cuenta?
              Al hacer clic en Sí, no habrá vuelta atrás y todos sus datos 
              se eliminarán por completo del sistema. Si desea salir, haga clic en 
              <span style="color:var(--danger);"><b>no</b></span>`,
              showCancelButton: true,
              cancelButtonColor: 'var(--danger)',
              cancelButtonText: 'no',
              confirmButtonColor: 'var(--primary)',
              confirmButtonText: 'sí'
            };
            break;
          case 'en-US':
            confirmation = {
              icon: 'question',
              html: `Are you sure you want to unregister?
              By clicking yes, there will be no turning back and all your data 
              will be completely removed from the system. If you want to quit, click
              <span style="color:var(--danger);"><b>no</b></span>`,
              showCancelButton: true,
              cancelButtonColor: 'var(--danger)',
              cancelButtonText: 'no',
              confirmButtonColor: 'var(--primary)',
              confirmButtonText: 'yes'
            };
            break;
          default:
            confirmation = {
              icon: 'question',
              html: `Você tem certeza que deseja remover sua conta?
              Se clicar em sim, não haverá mais volta e todos os seus dados 
              serão completamente removidos do sistema. Caso queira desistir, clique em 
              <span style="color:var(--danger);"><b>não</b></span>`,
              showCancelButton: true,
              cancelButtonColor: 'var(--danger)',
              cancelButtonText: 'não',
              confirmButtonColor: 'var(--primary)',
              confirmButtonText: 'sim'
            };
        }
        Swal.fire(confirmation).then(async response => {
          if (response.isConfirmed) {
            await ApiService
              .post('/users/unsubscribe',{email})
              .then(async (response) => {
                if (response && response.status === 200) {
                  let userAvatar = response.data;
                  if (userAvatar) {
                    userAvatar = userAvatar.split('/images/users/')[1];                    
                    await ApiService.removeFileOnS3({filename: userAvatar,source: 'users'})
                      .then((_res) => {
                        Swal.fire({
                          icon: 'success',
                          text: SwalTextTranslations.unsubscribeSuccessMessageBody,
                          timer: 2000,
                          showConfirmButton: false
                        }).then(result => {
                          if (result.dismiss) {
                            router.push("/")
                          }
                        })
                      }).catch(e => {
                        console.log('removeFileError',e);
                      });               
                  } else {
                    Swal.fire({
                      icon: 'success',
                      text: SwalTextTranslations.unsubscribeSuccessMessageBody,
                      timer: 2000,
                      showConfirmButton: false
                    }).then(result => {
                      if (result.dismiss) {
                        router.push("/")
                      }
                    })
                  }
                }
              }).catch(e => {
                Swal.fire({
                  icon: 'error',
                  text: SwalTextTranslations.unsubscribeErrorMessageBody,
                })
              })
          } else {

            let happyContent = {
              html: '',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 5000,
              timerProgressBar: false
            };

            switch (language) {
              case 'es':
                happyContent.html = `
                <center>
                <img src="${happy}" alt="img"/><br/>
                <span style="margin-top: 15px; color: var(--dark-text); font-size: 1rem;">
                  <b style="color:var(--success);">¡Uuhu!</b> ¡Me alegra que hayas decidido quedarte con nosotros!
                </span><br/>              
                </center>`;
                break;
              case 'en-US':
                happyContent.html = `
                <center>
                <img src="${happy}" alt="img"/><br/>
                <span style="margin-top: 15px; color: var(--dark-text); font-size: 1rem;">
                  <b style="color:var(--success);">Uuhu!</b> Glad you decided to stay with us!
                </span><br/>              
                </center>`;
                break;
              default:
                happyContent.html = `
                <center>
                <img src="${happy}" alt="img"/><br/>
                <span style="margin-top: 15px; color: var(--dark-text); font-size: 1rem;">
                  <b style="color:var(--success);">Uuhu!</b> que bom que você resolveu ficar conosco!
                </span><br/>              
                </center>`;
            }

            Swal.fire(happyContent);
          }
        })
      }
    }
  }

  const toggleErrorMessage = (message) => {
    setError(message)
    setTimeout(() => {
      setError('')
      setEmail('')
    }, 5000)
  }

  return (
    <Container maxWidth="lg">
      <Grid container style={{ textAlign: 'center', padding: '32px 0' }} className="dark--text">
        <Grid item xs={12} style={{ marginBottom: 18 }}>
          <div className="typewriter">
            <h1 variant="h1" style={{ fontSize: 42 }} paragraph>
              <b>{translate('unsubs.title')}</b>
            </h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          {translate('unsubs.text')}<span style={{cursor:'pointer'}} onClick={() => { router.push("/faq") }}><b>{translate('unsubs.faq')}</b></span>
        </Grid>
        <Grid item xs={12}>
          <img src={UnregisterImage} alt="Descadastrar" style={{ margin: '42px 0' }} />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            variant="outlined"
            type='email'
            label={translate('unsubs.placeholder')}
            style={{ width: '50vw', maxWidth: 250 }}
            value={email}
            onChange={({ target }) => { setEmail(target.value) }}
          />
          <StyledButton className="danger" style={{ height: '100%', width: '50vw', maxWidth: 150 }} onClick={handleUnRegister}>
          {translate('unsubs.submit')}
          </StyledButton>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography variant='body1' style={{color: 'var(--danger)', marginTop: '5px'}}>
            {error && error !== '' && error }
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default UnregisterPageComponent;