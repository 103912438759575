import React from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
} from "@material-ui/core";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CloseIcon from '@material-ui/icons/Close';

import bg3k from '../images/3k.png';
import bg4k from '../images/4k.png';
import bg5k from '../images/5k.png';

import styled from "styled-components";

const CustomCard = styled(Card)`
  position: relative;
  cursor: pointer;
`;

const CardBanner = styled(CardMedia)`
  height: 150px;
  width: 100%;
`;

const CardPrice = styled(Box)`
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 12px;
  background-color: var(--secondary);
  padding: 4px 8px;
  text-align: center;
  font-weight: bold;
  color: var(--darktext);
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
`;

const CustomDialog = styled(Dialog)`
  & .paragraph:not(:first-of-type) {
    margin: 1rem 0;
  }
  & .package-info-box {
    padding: 0.5rem;
    margin: 0.5rem 0 1rem 0;
    text-transform: uppercase;
  }
  & .pix-key-box {
    margin: 1rem auto;
    p {
      margin: 0.5rem auto;
    }
    button {
      margin: 0.5rem auto;
      display: block;
    }
  }
  & .card-typo {
    margin: 0.5rem auto;
  }

  & .card-icon {
    display: block;
    margin: auto;
    font-size: 4rem;
  }

  & .closeBtn {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    cursor: pointer;
    fill: #aaa
  }
`;

const FollowersPackageSection = () => {

  const [openModal, setOpenModal] = React.useState(false);
  const [modalHelperData, setModalHelperData] = React.useState({});
  
  const followersPackageData = [
    {
      price: 70,
      image: bg3k,
      packageName: '3k seguidores',
    },
    {
      price: 80,
      image: bg4k,
      packageName: '4k seguidores',
    },
    {
      price: 100,
      image: bg5k,
      packageName: '5k seguidores',
    },
  ]

  const FollowersPackageCard = ({ price, image, packageName }) => {
    
    return (
      <CustomCard 
        elevation={2}
        onClick={()=> handleOpenModal(packageName, price)}
      >
          <CardPrice>
            {`R$ ${price},00`}
          </CardPrice>
        <CardBanner
          image={image}
        />
        <CardContent>
          <Typography
            variant="body1"
            align="center"
            className="text-bold dark--text"
            style={{textTransform: 'uppercase'}}
            >
            {`Até bater ${packageName}`}
          </Typography>
        </CardContent>
        <Button
          fullWidth
          className="primary"
          >
          saiba mais
        </Button>
      </CustomCard>
    );
  };

  function handleOpenModal ( packageName, price ) {
    setModalHelperData({packageName, price});
    setOpenModal(true);
  }

  function copyPix () {

    var copyText = document.getElementById("copy-pix");
    var copyBtn = document.getElementById("copyPixBtn");    

    copyText.style.display = "block";

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    document.execCommand("copy");

    copyBtn.focus();

    alert("Chave Pix copiada");

    copyText.style.display = "none";
  }

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="h6" className="text-bold dark--text">
            O Big Tester divulga seu Instagram!
          </Typography>
          <Typography variant="body2" className="dark--text">
            Compre espaço no Big Tester e garanta seu "Arraste pra Cima".
          </Typography>
          <hr/>
        </Grid>

        {followersPackageData.map(data => (
          <Grid item xs={12} md={4} key={data.packageName}>
            <FollowersPackageCard
              price={data.price}
              image={data.image}
              packageName={data.packageName}
            />
          </Grid>
        ))

        }

      </Grid>

      <CustomDialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle onClose={() => setOpenModal(false)} className="customDialog-title">
          <Typography variant="h6" className="text-bold dark--text">
            O Big Tester divulga seu Instagram!
          </Typography>
          <CloseIcon onClick={()=> setOpenModal(false)} className="closeBtn" />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" className="dark--text paragraph">
            Você está adquirindo:
          </Typography>

          <Paper fullWidth elevation={1} className="package-info-box">
            <Grid container spacing={0}>
              <Grid item xs={8} >
                <Typography variant="body2" className="text-bold dark--text paragraph">
                  {modalHelperData && modalHelperData.packageName}
                </Typography>
              </Grid> 
              <Grid item xs={4} >
                <Typography variant="body2" align="right" className="text-bold dark--text paragraph">
                  {modalHelperData && `R$ ${modalHelperData.price},00`}
                </Typography>
              </Grid> 
            </Grid>
          </Paper>

          <Typography variant="body2" className="dark--text paragraph">
            Para comprar é muito fácil, basta fazer um PIX para o Big Tester no valor do pacote, utilizando esta chave:
          </Typography>

          <Box className="pix-key-box" >
            <Typography variant="body1" align="center" className="text-bold dark--text">
              43d31165-0536-42ce-8eca-9743679701c8
            </Typography>
            <Button className="primary" size="small" onClick={copyPix} id="copyPixBtn">
              copiar chave pix
            </Button>
          </Box>

          <Typography variant="body2" className="dark--text paragraph">
            Após fazer o PIX, envie um e-mail para <strong>instagram@bigtester.com.br</strong> com os seguintes dados:
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card elevation={1}>
                <CardContent>
                  <Typography variant="body1" align="center" className="text-bold dark--text card-typo">
                    Assunto
                  </Typography>
                  <MailOutlineIcon className="dark--text card-icon" fontSize="large" />
                  <Typography variant="body2" align="center" className="dark--text card-typo">
                    "COMPROVANTE SEGUIDORES"
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card elevation={1}>
                <CardContent>
                  <Typography variant="body1" align="center" className="text-bold dark--text card-typo">
                    Anexo
                  </Typography>
                  <AttachMoneyIcon className="dark--text card-icon" fontSize="large" />
                  <Typography variant="body2" align="center" className="dark--text card-typo">
                    Comprovante do PIX no valor do pacote
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card elevation={1}>
                <CardContent>
                  <Typography variant="body1" align="center" className="text-bold dark--text card-typo">
                    Informações
                  </Typography>
                  <AlternateEmailIcon className="dark--text card-icon" fontSize="large" />
                  <Typography variant="body2" align="center" className="dark--text card-typo">
                    Seu @ e a foto do seu perfil no Insta
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Typography variant="body2" className="dark--text paragraph">
            A sua compra será analisada e seu perfil entrará no site no prazo de 48 horas após a confirmação.
          </Typography>
          
          <Typography variant="body2" className="dark--text paragraph">
            E não se esqueça, cole a chave aleatória na transferência PIX e transfira o valor do pacote! <strong>É muito fácil!</strong>
          </Typography>

          <input 
            id="copy-pix" 
            value="43d31165-0536-42ce-8eca-9743679701c8" 
            style={{display: 'none', position: 'absolute', left: '-5000px'}} 
          />

        </DialogContent>
      </CustomDialog>

    </>
  )  
}

export default FollowersPackageSection;
  