import React from 'react';
import { useHistory } from "react-router-dom";
import { Card, Grid, Box, Typography } from '@material-ui/core';
import styled from 'styled-components'
import Icon from '@mdi/react';

const StyledCard = styled(Card)`
        background-color: ${props => props.color || 'var(--primary)'} !important;
        height: 175px;
        &:hover {
            box-shadow: 0px 0px 10px grey;
            backdrop-filter: grayscale(100%);
        }
`
const IconGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 !important;
    position: relative;
`
const CardTitle = styled(Grid)`
    background-color: rgba(0,0,0, 0.2);
    padding-left: 12px;
    display: flex;
    align-items: center;
    flex: initial !important;
    min-height: 35px;
`

const ExtendedDescription = styled(Box)`
    position: absolute;
    text-align: center;
    color: white;
    font-weight: 700;
    text-shadow: 1px 1px grey;
`

const ActionCard = ({ action, extended }) => {
    let history = useHistory();
    
    const handleCardClick = () => {
        history.push(action.page)
    }

    return (
        <StyledCard color={action.color} elevation={0} className="cursor" onClick={handleCardClick}>
            <Grid container direction="column" style={{ height: '100%' }}>
                <IconGrid item xs={12}>
                    <Icon
                        path={action.icon}
                        color='rgba(0,0,0,0.6)'
                        size='80px'
                    />
                    {extended && <ExtendedDescription>
                        {action.description}
                    </ExtendedDescription>}
                </IconGrid>
                <CardTitle item xs={12}>
                    <Typography variant="body2" className="white--text text-bold">
                        {action.title}
                    </Typography>
                </CardTitle>
            </Grid>
        </StyledCard>
    );
}

export default ActionCard;