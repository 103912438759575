import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from "@material-ui/core/Link";
import MediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#FAFAFA",
        height: 240,
        position: "relative"
    },
    description: {
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "absolute",
        bottom: 0,
        width: "100%",
        color: "#FFFFFF",
        // textAlign: "center",
        // padding: "16px 0",
    },
    text: {
        paddingLeft: 16,
        fontWeight: "bold",
    }
});

const CarouselItem = (props) => {
    const classes = useStyles();
    const matches = MediaQuery('(min-width: 600px)');

    return (
        <Link target="_blank" href={props.item.link}>
            <Paper className={classes.root}>
                <img src={ matches ? props.item.desktopImage : props.item.mobileImage} alt="Follow us" />
                <div className={classes.description}>
                    <p className={classes.text}>{props.item.title}</p>
                </div>
            </Paper>
        </Link>
    );
};

export default CarouselItem;