import React, { useState, useEffect } from "react";

import { Box } from "@material-ui/core";

import UserPaper from "./Paper";

import styled from "styled-components";

import ApiService from "../../../../helpers/ApiService";

import UserDefault from "../../../../img/icons/userdefault.svg";

import translate from "../../../../locales/translate";

const PeopleBox = styled(Box)`
  position: relative;
  @media screen and (max-width: 960px) {
    height: 65vh;
    min-height: 490px;
    max-height: 500px;
  }
`;

const winner1Style = `
    margin-left: 36px;
    margin-top: 2vh;
    position: relative;
`;

const winner2Style = `
    margin-left: 40%;
    margin-top: -100px;
    position: relative;
    @media only screen and (max-width: 960px) {
        margin-top: -250px;
        margin-left: 64px;
    }
`;

const fakeWinnersData = [
  {
    createdAt: new Date().getTime(),
    id: 1,
    picture: UserDefault,
    prize: 1,
    resultingNumber: 100,
    updatedAt: new Date().getTime(),
    user: {
      active: true,
      fullName: translate("lp.lasW.drawNotice"),
      gender: "M",
      addres: {
        city: " ",
        number: 100,
        postalCode: " ",
        street: " ",
      },
    },
  },
];

const People = () => {
  const [visible, setVisible] = useState(true);
  const [peopleData, setPeopleData] = useState([]);

  const showPapers = () => {
    let el = document.getElementById("ganhadores");
    if (window && el)
      setVisible(window.pageYOffset + window.innerHeight >= el.offsetTop + 200);
  };

  const getWinners = () => {
    ApiService.get("winners", {
      limit: 2,
      sort: "id DESC",
    })
      .then(({ data }) => {
        setPeopleData(
          data.winners.map(
            (dat) =>
              dat &&
              dat.user !== null && {
                ...dat,
                location:
                  dat && dat.user && dat.user.address
                    ? `${dat.user.address.city} (${dat.user.address.state})`
                    : "null",
              }
          )
        );
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getWinners();
    window.addEventListener("scroll", showPapers);

    return function cleanUp() {
      window.removeEventListener("scroll", showPapers);
    };
  }, []);

  return (
    <PeopleBox>
      {peopleData && peopleData.length > 0 ? (
        <React.Fragment>
          <UserPaper
            winner={peopleData[0]}
            visible={visible}
            smileColor="blue"
            delayed
            styling={winner1Style}
          />
          <UserPaper
            winner={peopleData[1]}
            visible={visible}
            smileColor="pink"
            styling={winner2Style}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <UserPaper
            winner={fakeWinnersData[0]}
            visible={visible}
            smileColor="blue"
            delayed
            styling={winner1Style}
          />
          <UserPaper
            winner={fakeWinnersData[0]}
            visible={visible}
            smileColor="pink"
            styling={winner2Style}
          />
        </React.Fragment>
      )}
    </PeopleBox>
  );
};

export default People;
