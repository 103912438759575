import React, { useState } from "react";
import { Grid, Typography, useMediaQuery, Button } from "@material-ui/core";

import Product from "./ProductHistoryItem";

import styled from "styled-components";
import SearchField from "../../../components/common/SearchField";
import ApiService from "../../../helpers/ApiService";
import PaginationControler from "../../../components/common/PaginationControler";
import Analytics from "../../../helpers/AnalyticsService";
import translate from "../../../locales/translate";
import { useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

const amounts = [3, 6, 12, 24, 48, 96];

const HistoryButton = styled(Button)`
  background-color: var(--primary) !important;
  color: #fff;
  &:hover {
    background-color: var(--primary-hover) !important;
  }
`;

const List = ({ categories = [] }) => {
  const [products, setProducts] = useState([]);
  // const [tags, setTags] = useState([])
  const [amount, setAmount] = useState(12);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const matches = useMediaQuery("(max-width:960px)");
  const [filter, setFilter] = useState("");
  const history = useHistory();
  const useStore = () => {
    return useSelector(
      (state) => ({
        user: state.user,
      }),
      shallowEqual
    );
  };

  const { user } = useStore();
  const getProducts = () => {
    ApiService.get("products/get-user-exchanged-products ").then(({ data }) => {
      setProducts(data);
      setTotal(data.count);
      console.log("produtos", data);
      console.log("produtos", user);
      // setTags(data.category)
    });
  };

  const goToProductsPage = () => {
    history.push("/produtos");
  };
  /**obter os produtos do usuário a partir da api */
  // const getListOfExchangedProducts = () => {

  // }

  const handleFilter = (value) => {
    setFilter(value);
    new Analytics().registerEvent({
      category: "Products",
      action: "Search",
      label: value,
    });
  };

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, categories, page, amount]);

  return (
    <GridContainer container>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" className="dark--text">
            {translate("lp.product.exchanged.title", {
              uname: user.fullName.toUpperCase(),
            })}
          </Typography>
          <br />
          <HistoryButton onClick={goToProductsPage}>
            {translate("lp.product.exchaged.btBack")}
          </HistoryButton>
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
          <SearchField
            variant="outlined"
            placeholder="Busca"
            fullWidth={matches}
            onChange={handleFilter}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 16 }}>
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
          text={translate("ps.products")}
        />
        {products.map((p, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <Product item={p} small={1} />
            </Grid>
          );
        })}
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
        />
      </Grid>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  background-color: white;
  padding: 24px;
  margin: unset;
`;

export default List;
