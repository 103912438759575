import React, { useState } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";

// import Product from '../../home/products/Product'

import styled from "styled-components";
import SearchField from "../../common/SearchField";
import ApiService from "../../../helpers/ApiService";
import PaginationControler from "../../common/PaginationControler";
import Analytics from "../../../helpers/AnalyticsService";
import MkpProduct from "./Product";

const amounts = [12, 24, 48, 96];

const MarketList = ({ stores = [] }) => {
  const [products, setProducts] = useState([]);
  const [amount, setAmount] = useState(12);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const matches = useMediaQuery("(max-width:960px)");
  const [filter, setFilter] = useState("");

  const getProducts = () => {
    ApiService.get("mkp-products", {
      limit: amount,
      skip: (page - 1) * amount,
      search: filter,
      stores: stores.join(","),
    }).then(({ data }) => {
      setProducts(data.mkpProducts);
      setTotal(data.count);
    });
  };

  const handleFilter = (value) => {
    setFilter(value);
    new Analytics().registerEvent({
      category: "Products",
      action: "Search",
      label: value,
    });
  };

  React.useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, stores, page, amount]);

  return (
    <GridContainer container>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" className="dark--text text-bold">
            produtos para você avaliar
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
          <SearchField
            variant="outlined"
            placeholder="Busca"
            fullWidth={matches}
            onChange={handleFilter}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 16 }}>
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
        />
        {products.map((p, index) => (
          <Grid item xs={12} md={4} key={index}>
            <MkpProduct item={p} />
          </Grid>
        ))}
        <PaginationControler
          {...{ page, amount, total, amounts, objectName: "Produtos" }}
          onAmountChange={(value) => {
            setPage(1);
            setAmount(value);
          }}
          onPageChange={(value) => {
            setPage(value);
          }}
        />
      </Grid>
    </GridContainer>
  );
};

const GridContainer = styled(Grid)`
  background-color: white;
  padding: 24px;
  margin: unset;
`;
// const SiteMap = styled(Grid)`
//     flex-grow: 1;
//     display: flex;
//     align-items: center;
// `

export default MarketList;
