import React from "react";
import translate from "../../../locales/translate";

import { Grid, Typography } from "@material-ui/core";

import Product from "../../home/products/Product";
import ApiService from "../../../helpers/ApiService";

const ExtraProduct = ({ category }) => {
  const [products, setProducts] = React.useState([]);

  const getProducts = () => {
    ApiService.get("/products", {
      category: category.id,
      limit: 4,
    }).then(({ data }) => {
      setProducts(data.products);
    });
  };

  React.useEffect(() => {
    if (category && category.id) getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: 24,
        backgroundColor: "white",
        margin: "unset",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h5"
          className="text-bold dark--text"
          style={{ marginBottom: 8 }}
        >
          {translate("pd.title.otherProducts")}
        </Typography>
      </Grid>
      {products.map((product, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Product item={product} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExtraProduct;
