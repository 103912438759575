import React from 'react';
import styled from 'styled-components'
import { Dialog, DialogTitle, Typography, DialogContent, Grid, TextField, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Stars from '../Stars';
import ApiService from '../../../helpers/ApiService';
import translate from '../../../locales/translate';
import { FormattedMessage } from 'react-intl';

const CloseBtn = styled(IconButton)`
    position: absolute !important;
    top: 8px;
    right: 8px;
`

const NewRating = ({ open, close, product = {}, onSaved = () => { } }) => {
    const [rating, setRating] = React.useState(0)
    const [comment, setComment] = React.useState('')
    const [error, setError] = React.useState('')

    const handleSelect = (stars) => {
        setRating(stars)
    }

    const handleSubmit = () => {
               
        if (rating !== 0) {               
            ApiService.post('comment', {
                rating,
                comment: comment === '' ? 'SEM_COMENTÁRIO' : comment,
                product: product.id
            }).then(({ data }) => {
                onSaved(data)
                setError('')
            }).catch(err => {
                setError('Não foi possível salvar seu comentário.')
                clearError();
            })
        } else {
            setError(translate("pd.evaluate.dialog.bottom-message.error"));
            clearError();
        }
    }

    const clearError = () => {
        setTimeout(() => {
            setError('');
        }, 5000);
    };
    
  
    return (
        <Dialog open={open} onClose={() => close()} maxWidth="md" fullWidth>
            <CloseBtn size="small" onClick={() => { setError(''); setRating(0); setComment(''); close(); }}>
                <CloseIcon />
            </CloseBtn>
            <DialogTitle className="dark--text">
                <Typography variant="h5" align="center">
                    {product.name}
                </Typography>
            </DialogTitle>
            <DialogContent className="dark--text">
                <Grid container spacing={2} style={{ marginBottom: 12 }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img src={product.image} alt="Produto" height="200px" />
                    </Grid>
                    <Grid item xs={9} style={{ paddingLeft: 16, textAlign: 'center' }}>
                        <Typography variant="body2">
                            {product.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant="h5" style={{ marginBottom: 4 }}>
                            {translate("pd.evaluate.dialog.title")}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Stars rating={rating} max={5} clickable onSelect={handleSelect} />
                            <Typography variant="body2" style={{ marginLeft: 4 }}>({translate("pd.evaluate.dialog.stars.amount")})</Typography>
                        </div>
                       <FormattedMessage id="pd.evaluate.dialog.textfield.placeholder">
                           {
                               (evaluatePlaceHolder) => (
                                   <TextField
                                        placeholder={evaluatePlaceHolder}
                                        rows={5}
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        style={{ marginTop: 8 }}
                                        value={comment}
                                        onChange={({ target }) => { setComment(target.value) }}
                                    />
                               )
                           }
                       </FormattedMessage>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: 16 }}>
                        <Button size="large" className="success" onClick={() => { handleSubmit() }}>
                            {translate("pd.evaluate.dialog.button.send")}
                        </Button>                        
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginTop: 5 }}>                       
                        <Typography variant="caption">
                            {error}
                        </Typography>                        
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default NewRating;