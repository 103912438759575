import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MissionCard from '../../../components/missions/Card';
import ApiService from '../../../helpers/ApiService';
import translate from '../../../locales/translate';
import "./customStyles/card.mobile.css";

const NewMissions = () => {
    const [missions, setMissions] = React.useState([])

    const getMissions = async () => {
        const { data } = await ApiService.get('/missions', {
            limit: 4
        })
        setMissions(data.missions)
    }

    React.useEffect(() => {
        getMissions()
    }, [])

    return (
        <Grid container spacing={2} style={{ marginTop: 18 }}>
            <Grid item xs={12}>
                <Typography variant="caption" className="dark--text">
                    {translate('ss.subtitle.missions')}
                </Typography>
                <hr></hr>
            </Grid>
            {missions.map((mission, index) => (
                <Grid item xs={12} md={3} key={index}>
                    <MissionCard mission={mission} />
                </Grid>
            ))}
        </Grid>
    );
}

export default NewMissions;