import React from 'react';

const basePath = '/img/bt_logos/';

const BtLogo = ({ style, type, width, height = '35px' }) => {
    const chooseimage = () => {
        switch (type) {
            case 'colored':
                return `${basePath}bt_logo_colored.png`
            case 'white':
                return `${basePath}bt_logo_white.png`
            case 'old':
                return `${basePath}../bt_logo.png`
            default:
                return `${basePath}bt_logo.png`
        }
    }

    return (
        <img alt='' src={chooseimage()} width={width} height={height} style={style} />
    );
}

export default BtLogo;