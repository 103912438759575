import React from "react";
import Pixel from "../../../components/common/Pixel";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import Option from "./Option";

const FlowCard = ({ flow = {}, apply = () => {}, nextFlow = () => {} }) => {
  const [selecteds, setSelecteds] = React.useState([]);
  const [pixel, setPixel] = React.useState(false);
  const [step, setStep] = React.useState(0);

  const handleKeyboard = (event) => {
    const key = parseInt(event.key);
    if (typeof key === "number" && key <= flow.questions[0].options.length) {
      handleSelected({
        button: key,
        option: flow.questions[0].options[key - 1],
      });
    } else if (key === "Enter" && selecteds.length > 0) {
      handleNext();
    }
  };

  const handleSelected = ({ button, option }) => {
    if (selecteds.includes(button)) {
      let tmp = [...selecteds];
      tmp.splice(
        tmp.findIndex((t) => t === button),
        1
      );
      setSelecteds(tmp);
    } else {
      setSelecteds([...selecteds, button]);
    }

    if (
      option.link !== undefined &&
      (option.link.includes("http") || option.link.includes("https"))
    ) {
      window.open(option.link, "_blank");
    }

    if (flow.questions[step].type === "radio") handleNext(button);

    setPixel(true);
  };

  const handleNext = (position) => {
    apply(
      flow,
      flow.questions[step].options[position - 1],
      flow.questions.length
    );
    if (flow.questions.length > 1 && flow.questions.length !== step + 1) {
      if (
        flow.questions[step].options[position - 1].positive === 1 ||
        flow.questions[step].options[position - 1].positive === true
      ) {
        hideAndShowQuestion();
      } else {
        nextFlow();
      }
    } else {
      nextFlow();
    }
  };

  const hideAndShowQuestion = () => {
    document.getElementById("question" + step).style.display = "none";
    let nextQuestion = step;
    nextQuestion++;
    setStep(nextQuestion);
    document.getElementById("question" + nextQuestion).style.display = "block";
  };

  React.useEffect(() => {
    window.addEventListener("keyup", handleKeyboard);

    return () => {
      window.removeEventListener("keyup", handleKeyboard);
    };
  });

  const constructQuestion = (question, index = 0) => {
    let questionVisible = index !== 0 ? "none" : "block";

    return (
      <Grid
        container
        spacing={2}
        key={`${flow.name}-question-${index}`}
        id={"question" + index}
        style={{ display: questionVisible }}
      >
        <Grid item xs={12}>
          {question.question}
        </Grid>
        {question.options.map((option, index) => (
          <Grid
            item
            xs={12}
            key={`${flow.name}-question-${index}-${option}-${index}`}
          >
            <Option
              button={index + 1}
              option={option}
              selected={selecteds.includes(index + 1)}
              updateSelected={handleSelected}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Card
      elevation={0}
      className="dark--text"
      style={{ backgroundColor: "transparent" }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" className="text-bold">
              {flow.title}
              {pixel && <Pixel />}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img alt="" src={flow.image} width="100%" />
          </Grid>
          <Grid item xs={12} md={6}>
            {flow.questions.map((question, index) =>
              constructQuestion(question, index)
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FlowCard;
