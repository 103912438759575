import React from 'react';

import Layout from '../../layout/blank'
import Terms from './components/terms/Terms';
import Topmenu from './components/top-menu-disconnected/Topmenu'

// import { Container } from './styles';

function TermsPage() {
  return (
    <Layout>
      <Topmenu />
      <Terms />
    </Layout>
  );
}

export default TermsPage;