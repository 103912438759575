import React from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import translate from "../../locales/translate";
import FeaturedBalloon from "../common/FeaturedBalloon";

// REDUX
import { useSelector, shallowEqual } from "react-redux";

// Components
import BtLogo from "./BtLogo";
import Flags from "./Flags";
import "../../themes/footer.mobile.css";
import {
  Home,
  // AttachMoney,
  EmojiEvents,
  Instagram,
  CardGiftcard,
} from "@material-ui/icons";
import {
  Grid,
  Container,
  Typography,
  Hidden,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
// social media icons import
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import {ReactComponent as TikTokIcon} from '../../img/icons/tiktok-icon.svg';
import YouTubeIcon from '@material-ui/icons/YouTube';

import Icon from '@mdi/react'; 
import { mdiRocketLaunch } from '@mdi/js';

import ApiService from "../../helpers/ApiService";

const GridContainer = styled(Grid)`
  background-color: #78909c;
  color: white;
  overflow: hidden;
  position: relative;
  margin: unset;
  text-align: center;
  z-index: 10;
  align-self: flex-end;
  min-height: 300px;
  @media screen and (min-width: 960px) {
    text-align: left;
  }

  .footerContainer {
    padding: 50px;
    margin-bottom: 35px;

    @media screen and (max-width: 960px) {
      padding: 48px 16px;
    }
  }

  .footerSocialLinks {
    height: 2rem;
    width: auto;
    fill: #fff;
    padding: 0.25rem;
  }
`;

const StyeldBottomNavbar = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #039be5;
  color: white;

  .MuiBottomNavigationAction-root {
    min-width: 60px;
    padding: 0.3rem;
    background-color: #039be5;
    color: white;
  }

  .styledDivBottomNavbar {
    position: relative;
    top: -40px;
    padding: 2.5rem 0.4rem;
    z-index: 20;
    background-color: #039be5;
    width: 80%;
    max-width: 85px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 3px 1px 1px rgba(0, 0, 0, 0.2); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 3px 1px 1px rgba(0, 0, 0, 0.2); /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 3px 1px 1px rgba(0, 0, 0, 0.2); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }

  .bottomNavigationItem.active {
    color: var(--secondary);
    background-color: var(--primary-dark);
  }

`;

const StyledLink = styled(Link)`
  color: white !important;
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      user: state.user || {},
      pagination: state.pagination,
    }),
    shallowEqual
  );
};


const Footer = () => {
  const [value, setValue] = React.useState(0);
  let history = useHistory();
  const { user, pagination } = useStore();
  const [existFeaturedBalloon, setExistFeaturedBalloon] = React.useState(false);
  const [featuredBalloons, setFeaturedBalloons] = React.useState([]);

  const screenName = window.location.pathname.split("/")[1];

  React.useEffect(() => {
    if(user && user.fullName) {

      ApiService.get(`/featuredballon/screen/${screenName}`).then(({data}) => {

        // filtra o resultado excluindo os retornos "falsos"
        const filteredBallons = data.filter( ballon => ballon !== false);

        // valida se há algum balão para a página, antes de setar
        if(filteredBallons.length > 0) {
          setFeaturedBalloons(filteredBallons);
          // eslint-disable-next-line array-callback-return
          filteredBallons.map( row => {
            row.active && setExistFeaturedBalloon(true);
          });
        }
        
      });
    } else {
      setExistFeaturedBalloon(false);
    }
  }, [user, screenName]);

  return (
    <>
      <GridContainer container>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            className="footerContainer"
            justify="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <BtLogo type="white" height="30px" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body2" className="text-bold">
                {translate("footer.iconsText")}
              </Typography>
              <a
                href="https://www.facebook.com/BigTesterOficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon
                  className="footerSocialLinks"
                />
              </a>
              <a
                href="https://www.instagram.com/big_tester/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  className="footerSocialLinks"
                />
              </a>
              <a
                href="https://twitter.com/Big__Tester"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon
                  className="footerSocialLinks"
                />
              </a>
              <a
                href="https://br.pinterest.com/big_tester/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PinterestIcon
                  className="footerSocialLinks"
                />
              </a>
              <a
                href="https://www.tiktok.com/@big_tester"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TikTokIcon
                  className="footerSocialLinks"
                  style={{ height: '1.6rem', position: 'relative', bottom: '3px' }}
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCAHjUIldX4vKL0VADpH9sOg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon
                  className="footerSocialLinks"
                />
              </a>
              {/* <Flags /> */}
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography
                variant="body2"
                className="text-bold"
                style={{ marginBottom: 8 }}
              >
                <StyledLink to="/terms">
                  {translate("footer.termsTitle")}
                </StyledLink>
                {" "}|{" "}
                <StyledLink to="/faq">
                  <span>FAQ</span>
                </StyledLink>
              </Typography>
              <Typography variant="caption">
                {translate("footer.privacyText")}
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="caption">
                {translate("footer.warning")}
              </Typography>
            </Grid>
            <Hidden mdUp>
              {user && user.fullName ? (
                <StyeldBottomNavbar
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    history.push(newValue);
                  }}
                  showLabels
                >
                
                  <BottomNavigationAction
                    label={translate("nav.mobile.start")}
                    className={`bottomNavigationItem ${pagination === 'start' ? 'active' : ''}`}
                    icon={
                      <Home />
                    }
                    value={"/inicio"}
                  />
                                      
                  <BottomNavigationAction
                    label={translate("nav.mobile.points")}
                    className={`bottomNavigationItem ${pagination === 'mission' ? 'active' : ''}`}
                    icon={
                      <Icon
                        path={mdiRocketLaunch}
                        style={{
                          width: '1em',
                          height: '1em',
                          display: 'inline-block',
                          fontSize: '1.5rem',
                          marginTop: 4,
                        }}
                      />
                    }
                    value={"/missoes"}
                  />
                    
                  <BottomNavigationAction
                    className={`bottomNavigationItem ${pagination === 'product' ? 'active' : ''}`}
                    label={translate("nav.mobile.products")}
                    icon={
                      <CardGiftcard />
                    }
                    value={"/produtos"}
                  />
                    

                  {/* {pagination === "draw" ? (
                    <BottomNavigationAction
                      label={translate("nav.mobile.draw")}
                      icon={<EmojiEvents style={{ color: "var(--secondary)", backgroundColor: "var(--primary-dark)" }} />}
                      value={"/sorteio"}
                      style={{ color: "var(--secondary)", backgroundColor: "var(--primary-dark)" }}
                    />
                  ) : (
                    <BottomNavigationAction
                      label={translate("nav.mobile.draw")}
                      icon={<EmojiEvents />}
                      value={"/sorteio"}
                    />
                  )} */}
                    
                    
                  <BottomNavigationAction
                    label={translate("nav.mobile.campaigns")}
                    className={`bottomNavigationItem ${pagination === 'campaign' ? 'active' : ''}`}
                    icon={
                      <EmojiEvents />
                    }
                    value={"/campanhas-patrocinadas"}
                  />
                    
                  <BottomNavigationAction
                    label={translate("nav.mobile.follow")}
                    className={`bottomNavigationItem ${pagination === 'follow' ? 'active' : ''}`}
                    icon={
                      <Instagram />
                    }
                    value={"/seguir"}
                  />
                  
                </StyeldBottomNavbar>
              ) : (
                <div></div>
              )}
            </Hidden>
            {user && user.fullName && existFeaturedBalloon && (
              <FeaturedBalloon
                data={featuredBalloons}
              />
            )}
          </Grid>
        </Container>
      </GridContainer>
    </>
  );
};

export default Footer;
