import React from 'react';
import DefaultLayout from '../../layout/default';
import FlowPageComponent from './components/PageComponent';

const Perfil = () => {
    return (
        <DefaultLayout>
            <FlowPageComponent />
        </DefaultLayout>
    );
}

export default Perfil;