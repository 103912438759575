import React, { useState, useEffect } from "react";

import { Grid, Container, Typography, Box } from "@material-ui/core";
import IconCard from "./IconCard";
import Background from "./Background";
import translate from "../../../../locales/translate";

import styled from "styled-components";

const HowItWorks = () => {
  const [smiles, setSmiles] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const showSmiles = () => {
    if (window)
      setSmiles(
        window.scrollY >= 100 && window.scrollY <= window.innerHeight * 2
      );
  };

  useEffect(() => {
    window.addEventListener("scroll", showSmiles);
    if (window.innerWidth > 1023) setIsMobile(false);

    return function cleanUp() {
      window.removeEventListener("scroll", showSmiles);
    };
  }, []);

  return (
    <GridContainer container id="como-funciona" ismobile={isMobile.toString()}>
      <SmilesBox>
        <Background showSmiles={smiles} />
      </SmilesBox>
      <CustomContainer container="true">
        <Grid
          container
          justify="center"
          style={{ height: "auto", marginBottom: "8px" }}
        >
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: " #CFD8DC" }}>
              Bigtester
            </Typography>
            <Title variant="h3" style={{ fontWeight: 700 }}>
              {translate("lp.how.title")}
            </Title>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction={isMobile ? "column-reverse" : "column"}
              wrap="nowrap"
              style={{ height: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                style={{ alignSelf: "flex-end" }}
              >
                <IconCard
                  icon="gift.png"
                  color="yellow"
                  position="end"
                  content={{
                    title: translate("lp.how.subtitle3"),
                    text: translate("lp.how.text3"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} style={{ alignSelf: "center" }}>
                <IconCard
                  icon="rating.png"
                  color="green"
                  position="center"
                  content={{
                    title: translate("lp.how.subtitle2"),
                    text: translate("lp.how.text2"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <IconCard
                  icon="paper.png"
                  color="blue"
                  position="start"
                  content={{
                    title: translate("lp.how.subtitle1"),
                    text: translate("lp.how.text1"),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomContainer>
    </GridContainer>
  );
};
const GridContainer = styled(Grid)`
  background-color: #f4f6fa;
  height: 100%;
  /* height: ${(props) => (props.isMobile === false ? "1024px" : "600px")}; */
  /* margin-top: ${(props) => (props.isMobile === false ? "40px" : "35px")}; */
  margin-bottom: 10px;
  padding-bottom: 10px;
  max-height: 400vh;
  overflow: ${(props) => (props.isMobile === false ? "hidden" : "auto")};
  overflow: hidden;
  z-index: 1;
  position: relative;
  background-image: url("/img/icons/line.svg");
  background-repeat: no-repeat;
  background-position: bottom 75px left;
  background-size: 100vw 100%;
`;

const SmilesBox = styled(Box)`
  position: absolute;
  max-width: 1280px;
  margin: auto;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
`;
const CustomContainer = styled(Container)`
  margin-top: 38px;
  margin-bottom: 10px;
  * {
    z-index: 1;
  }
`;

const Title = styled(Typography)`
  color: var(--darktext);
  font-weight: 700;
`;

export default HowItWorks;
