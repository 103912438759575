import React from 'react';
import DefaultLayout from '../../layout/default';
import ProductsPageComponent from './components/ProductsPageComponent';

const ProductsPage = (props) => {
    return (
        <DefaultLayout>
            <ProductsPageComponent />
        </DefaultLayout >
    );
}

export default ProductsPage;