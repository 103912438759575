import React, { useState, useEffect } from "react";
import styled from "styled-components";
import translate from "../../locales/translate";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import CustomBreadcrumbs from "../common/breadcrumbs";
import Apiservice from "../../helpers/ApiService";
// import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector, shallowEqual } from "react-redux";
import * as translations from "../../locales/text";
import Swal from "sweetalert2";


const GridContainer = styled(Grid)`
  background-color: white;
  padding: 16px;
  margin: unset;
`;

const HighlightBox = styled(Box)`
  color: darkorange;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
      user: state.user
    }),
    shallowEqual
  );
};

const Details = ({ product = {}, mode = "trade", haveTheProduct = false }) => {
  // const dispatch = useDispatch();
  const { language, user } = useStore();
  const [voucherWarn, setVoucherWarn] = useState("")
  
  var SwaltextTranslation = {
    titleOkValue: translations.default[language]["pd.swal.ok.title"],
    textOkValue: translations.default[language]["pd.swal.ok.text"],
    titleNotOkValue: translations.default[language]["pd.swal.notOk.title"],
    textNotOkValue: translations.default[language]["pd.swal.notOk.text"],
    textProductOwned: translations.default[language]["pd.access"],
    textConfirmExchange: translations.default[language]["pd.exchange.prompt.message"],
    textConfirmExchangePositiveButton: translations.default[language]["pd.exchange.prompt.btn-confirm"],
    textConfirmExchangeNegativeButton: translations.default[language]["pd.exchange.prompt.btn-cancel"],
    textConfirmExchangeFails: translations.default[language]["pd.exchange.prompt.fail-message"],
    textProductQuantityErrorMessage: translations.default[language]["pd.exchange.quantity-error.prompt.message"],
    textExchangeInsuficientBalanceMessage: translations.default[language]["pd.exchange.insufficient-points.prompt.message"],
  };

  const handleProductItem = () => {
    window.open(product.link, "_blank");
  };
  toast.configure();
  // console.log('produto',product, user.wallet)
  const handleExchangeProduct = async () => {
    
    if (user && user.wallet && user.wallet.balance >= product.price) {
      if (product.quantity > 0) {
        /**
         * `Você está prestes a trocar
          ${product.price} pontos pelo produto
          ${product.name}. Quer mesmo fazer a troca?`;
         * * */
        const ConfirmExchangeSwalText = {
          body: SwaltextTranslation
                  .textConfirmExchange
                  .replace("{price}", product.price)
                  .replace("{product}",product.name),
          positiveButtonText: SwaltextTranslation.textConfirmExchangePositiveButton,
          negativeButtonText: SwaltextTranslation.textConfirmExchangeNegativeButton
        }
        
        Swal.fire({
          icon: "info",
          text: ConfirmExchangeSwalText.body,
          showCancelButton: true,
          confirmButtonText: ConfirmExchangeSwalText.positiveButtonText,
          cancelButtonText: ConfirmExchangeSwalText.negativeButtonText,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Apiservice.post("/products/exchange", {
              product: product.id,
            })
              .then((resp) => {
                if (resp.status === 200) {
                  haveTheProduct = true;
                  if (product.link) {
                    Swal.fire({
                      icon: "success",
                      title: SwaltextTranslation.titleOkValue,
                      text: SwaltextTranslation.texttOkValue,
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: SwaltextTranslation.textProductOwned,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.open(product.link, "_blank");
                      }
                    });
                  } else {
                    Swal.fire({
                      icon: "success",
                      title: SwaltextTranslation.titleOkValue,
                      text: SwaltextTranslation.texttOkValue,
                    });
                  }
  
                  //   return (
                  //     <FormattedMessage id="product.toast">
                  //       {(t) =>
                  //         toast.success(t, {
                  //           position: "top-center",
                  //           autoClose: 5000,
                  //           hideProgressBar: false,
                  //           newestOnTop: true,
                  //           pauseOnFocusLoss: false,
                  //           closeOnClick: true,
                  //           pauseOnHover: true,
                  //           draggable: true,
                  //           progress: undefined,
                  //         })
                  //       }
                  //     </FormattedMessage>
                  //   );
                }
              })
              .catch((error) => {
                return Swal.fire({
                  icon: "error",
                  title: SwaltextTranslation.titleNotOkValue,
                  text: SwaltextTranslation.textNotOkValue,
                });
              });
          } else if (result.isDenied) {
            Swal.fire(SwaltextTranslation.textConfirmExchangeFails, "", "info");
          }
        });
      } else {       
        const QuantityErrorSwalText = {
          body: SwaltextTranslation
                  .textProductQuantityErrorMessage
                  .replace("{product}", product.name)
                  .replace("{quantity}", product.quantity)
              
        };
        Swal.fire({
          icon: "error",
          html: QuantityErrorSwalText.body,
        });
      }
    }else {
      const InsuficientBalanceSwalText = {
        body: SwaltextTranslation
                .textExchangeInsuficientBalanceMessage
                .replace("{balance}", user.wallet.balance)
                .replace("{price}", product.price)
      }
      Swal.fire({
        icon: 'error',
        html: InsuficientBalanceSwalText.body,        
      })
    }
    
  };

  useEffect(() => {
    const re = new RegExp('voucher', 'gi')
    if (product && product.name && re.test(product.name))
      setVoucherWarn(translate("pd.productVoucher.detail.message"))
  },[product])

  return (
    <GridContainer container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <CustomBreadcrumbs
          items={[
            { text: "Produtos", page: "/produtos" },
            { text: product.name, page: null },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <img src={product.image} alt={product.name} width="100%" />
          </Grid>
          <Grid item xs={12} md={9} className="dark--text">
            {product.highlight && (
              <HighlightBox>
                <WhatshotIcon style={{ marginRight: 8 }} />  
                <Typography variant="body2" className="text-bold">
                  {translate("ps.new")}
                </Typography>
              </HighlightBox>
            )}
            <Typography variant="h5" className="text-bold dark--text" paragraph>
              {product.name}
            </Typography>
            <Typography variant="body1" paragraph>
              {voucherWarn !== "" ? voucherWarn : ""}
            </Typography>
            {mode === "trade" && haveTheProduct && product.link ? (
              <Typography variant="h6" className="text-bold" paragraph>
                {translate("pd.productOwned")}
              </Typography>
            ) : mode === "trade" ? (
              <Typography variant="h6" className="text-bold" paragraph>
                {product.price} {translate("points")}
              </Typography>
            ) : (
              " "
            )}
            <Typography variant="body1" paragraph>
              {product.description}
            </Typography>
            {mode === "trade" ? (
              <>
                {haveTheProduct && product.link ? (
                  <Button
                    variant="contained"
                    className="primary"
                    size="large"
                    onClick={() => window.open(product.link, "_blank")}
                  >
                    {translate("pd.access")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="secondary"
                    size="large"
                    onClick={() => handleExchangeProduct()}
                  >
                    {translate("ps.trade")}
                  </Button>
                )}
              </>
            ) : (
              <>
                {haveTheProduct && product.link ? (
                  <Button
                    variant="contained"
                    className="primary"
                    size="large"
                    onClick={() => window.open(product.link, "_blank")}
                  >
                    {translate("pd.access")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="secondary"
                    size="large"
                    onClick={handleProductItem}
                  >
                    {translate("ps.buy")}
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default Details;
