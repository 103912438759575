import React from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import styled from 'styled-components';
import translate from '../../locales/translate';
import { Button, Dialog, useMediaQuery } from '@material-ui/core'

const BtCustom = styled(Button)`
    @media screen and (max-width: 960px){
        min-width: unset;
        padding: 6px 10px !important;
    }
`;

const getVideoHash = (url) => {
    let hash = url.split("v=");
    return hash[1];
}

const BtHowIrWorks = (props) => {
    const [modal, setModal] = React.useState(false);
    const matches = useMediaQuery('(min-width:960px)');
    const video = props.video;
    const position = props.position ? "unset" : "right";

    const handleModalEvent = event => {
        setModal(!modal)
    }

    return (
        <div style={{float: position}}>
            <BtCustom
                variant="contained"
                className="danger white--text"
                startIcon={(matches || props.buttonFull) && <PlayCircleFilledIcon />}
                onClick={handleModalEvent}
            >
                {(matches || props.buttonFull) && translate('btn.howWorks')}
                {(!matches && !props.buttonFull) && <PlayCircleFilledIcon />}
            </BtCustom>
            <Dialog open={modal} onClose={handleModalEvent} maxWidth="md" fullWidth>
                <iframe
                    width="100%"
                    height={matches ? "500" : "350"}
                    src={`https://www.youtube.com/embed/${getVideoHash(video)}`}
                    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                />
            </Dialog>
        </div>
    );
};

export default BtHowIrWorks;