import React from "react";
import { Grid, Typography, Hidden, Fade } from "@material-ui/core";
import Statement from "./Statement";
import translate from "../../locales/translate";

const Extract = ({ statements = [], pages = 1 }) => {
  const [statementBlock, setStatementBlock] = React.useState([]);

  React.useEffect(() => {
    setStatementBlock([...Array(pages)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statements]);

  return (
    <Grid container>
      <Hidden smDown>
        <Grid container style={{ marginBottom: 12 }}>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography variant="body1" className="text-bold">
              {translate("pointsScreen.section2.col1")}
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography variant="body1" className="text-bold">
              {translate("pointsScreen.section2.col2")}
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              className="text-bold"
              style={{ textAlign: "center" }}
            >
              {translate("pointsScreen.section2.col3")}
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              className="text-bold"
              style={{ textAlign: "center" }}
            >
              {translate("pointsScreen.section2.col4")}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      {statementBlock.map((n, i) => (
        <Fade in={true} key={`statement-group-${i}`} timeout={1000}>
          <Grid container>
            {statements.slice(i * 10, (i + 1) * 10).map((statement, index) => (
              <Statement
                key={`statement-${statement.id}`}
                statement={statement}
                index={index}
              />
            ))}
          </Grid>
        </Fade>
      ))}
    </Grid>
  );
};

export default Extract;
