import React from "react";
import { useDispatch } from "react-redux";
import { Fade } from "@material-ui/core";
import ApiService from "../../helpers/ApiService";
import { useHistory } from "react-router-dom";
import { withRedux } from "../../helpers/redux";
import Analytics from "../../helpers/AnalyticsService";
import Register from "./Register";
import Login from "./Login";
import Forgot from "./Forgot";

const AuthComponent = ({ reload = true, close = () => {} }) => {
  let history = useHistory();

  const dispatch = useDispatch();
  const [mode, setMode] = React.useState("login");

  const handleChangeMode = (mode) => {
    setMode(mode);
  };

  const handleUserData = (user) => {
    if (user) {
      dispatch({
        type: "SET_USER",
        user,
      });
    }
  };

  const handleForgotPhone = async (params) => {
    reload = false;
    let result = 0;
    await ApiService.post('/users/forgot-phone-number',params)
    .then(response => {
      result = response.status
    })
    .catch(e => {
      console.log('erro', e);
      result = 500;            
    });    
    return result;
  };

  const forceReload = (value) => {
    reload = value;
    if (reload) history.push("/inicio");
  }

  React.useEffect(() => {
    if (mode !== "forgotPhone") {
      ApiService.autoLogin().then((user) => {
        if (user) {
          new Analytics().registerEvent({
            category: "Leads",
            action: "Login",
            label: user.id,
          });
          handleUserData(user);
          if (reload) history.push("/inicio");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (mode) {
    case "forgot":
      return (
        <Fade in={true} timeout={1000}>
          <div>
            <Forgot />
          </div>
        </Fade>
      );
    case "register":
      return (
        <Fade in={true} timeout={1000}>
          <div>
            <Register />
          </div>
        </Fade>
      );    
    default:
      return (
        <Login
          reload={reload}
          changeMode={handleChangeMode}
          updateUser={handleUserData}
          close={close}
          forceReload={forceReload}
          handleForgotPhone={handleForgotPhone}         
        />
      );
  }
};

export default withRedux(AuthComponent);
