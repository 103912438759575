import React from 'react';
import DefaultLayout from '../../layout/default';
import UnregisterPageComponent from '../../components/unregister/PageComponent';

const UnregisterPage = () => {

  React.useEffect(() => {
    document.title = 'Descadastrar - Big Tester'
  }, [])

  return (
    <DefaultLayout>
      <UnregisterPageComponent />
    </DefaultLayout>
  )
}

export default UnregisterPage