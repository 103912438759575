import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    //FirstScreen LandingPage
    // Navbar
    "lp.navbar.winners": "testers",
    "lp.navbar.who": "who we are",
    "lp.navbar.how": "how it works",
    "lp.navbar.btn": "login",

    // Login
    "lp.login.cardTitle1": "Already registered? ",
    "lp.login.cardTitle2": " Access and keep earning points!",
    "lp.login.cellphone": "Cellphone",
    "lp.login.wrong": "Invalid email or mobile phone",
    "lp.login.enterBtn": "Login",
    "lp.login.noRegisterQuestion":
      "Don't have a registration? Click here and do it right now!",
    "lp.login.havelogin": "Already registered?",
    "lp.login.enterBtn.alternative": "Submit",
    "lp.login.forgotPhone.link":"I forgot my number",
    "lp.login.forgotPhone.link.alternative":"Back",
    "lp.login.forgotPhone": "Forgot your phone number?",
    "lp.login.forgotPhone.message": "Enter your email and we will send you a message!",
    "lp.login.forgotPhone.success": "email sent successfully, check your inbox!",
    "lp.login.forgotPhone.warning": "the informed email is invalid!",
    "lp.login.forgotPhone.error": "I'm sorry, but we were unable to send the email at this time, please try again later!",

    // Buttons
    "lp.btn.howWork": "how it works",
    "lp.btn.register": "register",
    "lp.btn.want": "I want",
    "lp.btn.viewMore": "see more products",

    // Highlight
    "lp.text": "The largest consumer product testing site of the Americas",

    // RegisterForm
    "lp.form.title": "Receive free products to test in your home!",
    "lp.form.name": "Full Name",
    "lp.form.lastname": "Last name",
    "lp.form.phone": "Mobile No.",
    "lp.form.birthday": "Date of Birth",
    "lp.form.masc": "Male",
    "lp.form.fem": "Female",
    "lp.form.btn": "sign up",

    "lp.form.invalidFullName": "please enter the full name!",
    "lp.form.errBithday": "invalid age the minimum age must be: ",
    "lp.form.invalidBirthday": "invalid date of birth",
    "lp.form.invalidCellphone": "invalid phone",
    "lp.form.errCellphone": "invalid phone number",
    "lp.form.notPossible":
      "We were unable to create your account. Please check your data and try again.",
    "lp.form.invalidEmail": "Invalid Email!",
    "lp.form.invalidCamp": "Invalid field",
    "lp.form.obrCamp": "Mandatory field",
    "lp.form.successfullyRegisterMessageTitle": "Success",
    "lp.form.successfullyRegisterMessage":
      "Thank you for registering, you will receive a confirmation email in your inbox, please check it. Importantly, at the first login of each day you will receive 20 points to use in the system, so do not miss this opportunity and good luck!",
    "lp.form.dateParseErrorMessage":
      "Date error, please verify date input into the form!",
    "lp.form.terms1": "I accept the ",
    "lp.form.terms2": "Terms of Commitment ",
    "lp.form.terms3": "and the ",
    "lp.form.terms4": "Privacy Policy",
    "lp.form.terms5":
      "I agree to receive news and new BigTester products in my email",
    "lp.form.emailAlredyInUse": "The email you entered is already in use, try login!",
    "lp.form.emailError": "The informed email is already in use, it is not possible to proceed!",
    "lp.form.notice.terms": "Before proceeding it is necessary to accept the commitement terms and the privacy policy.",
    "lp.form.greetings": "It's great to have you with us again. We found your registration in our database and we have already credited your points! Importantly, at the first login of each day you will receive 20 points to use in the system, so do not miss this opportunity and good luck!",
    "lp.form.register.error.409": "Email already registered in the previous version of BigTester. Try signing in.",
    // HowWorks
    "lp.how.title": "How does it work?",
    "lp.how.subtitle1": "1. Sign up",
    "lp.how.text1":
      "Free registration to access Big Tester’s amazing products and offers!",
    "lp.how.subtitle2": "2. Create your profile",
    "lp.how.text2":
      "Customize your profile to ensure the best free products are shipped to your home!",
    "lp.how.subtitle3": "3. Redeem your points",
    "lp.how.text3":
      "Stay active in Big Tester. The more you participate, the more points you earn which are redeemable for hundreds of cool products. Also, don’t miss out on our special free raffles!",

    // Who We Are
    "lp.whoWeAre.title": "Who we are",
    "lp.whoWeAre.text": `The Big tester is a fully interactive digital platform, where it allows the user to have a unique experience of testing products or services for free or participating in selections and sweepstakes safely! In addition, it is a showcase that allows the intermediation between brand / product to the final consumer, always providing benefits to it, such as promotional campaign, free products, and services for exclusive tests or discounts.`,

    // iPhone
    "lp.iphone.title":
      "Register and be entered into our iPhone raffles that occur throughout the year!",
    "lp.iphone.text": `When you sign up at Big Tester you immediately start earning points that can be used to enter our iPhone raffles. 
                            The more you interact with Big Tester, completing missions, responding to surveys, completing your user profile and inviting friends and family to join also, 
                            the more points you’ll accumulate.  All these points increase your chances of landing a brand new iPhone!  Don’t miss out!`,

    // lastWinners
    "lp.lastW.title": "Latest testers",
    "lp.lastW.subTitle.1": " ",
    "lp.lastW.subTitle.2": "7.7 million",
    "lp.lastW.subTitle.3": " testers",
    "lp.lastW.text":
      "Join our community of over 7.7 million testers and counting.  Thousands of new users sign up every month.  Hundreds of products sent each week to our community of satisfied product testers, who sure know how to voice an opinion!",
    "lp.lastW.subnum.1": "Over 1 billion",
    "lp.lastW.subtext.1": " points earned",
    "lp.lastW.subnum.2": "1 Bi",
    "lp.lastW.subtext.2": "of points earned",
    "lp.lasW.drawNotice":"awaiting draw",

    //winnersCustom
    "lp.lastCustom.title": "Join the winners",
    "lp.lastCustom.subTitle.1": "Do as they do",
    "lp.lastCustom.subTitle.2": "Join the team of champions",

    // Products
    "lp.product.title": "Products you can test & keep",
    "lp.product.btn": "see more products",
    "lp.product.point": "points",
    "lp.product.exchange.histBtn": "see your exchange history",
    "lp.product.exchanged.title": "{uname}, this is your product history",
    "lp.product.exchaged.btBack": "back to the products screen",

    //Contato
    "lp.contact.title": "Talk to us!",
    "lp.contact.socialmedia": "Or come through social media",
    "lp.contact.errors.name": "Invalid name",
    "lp.contact.errors.email": "Enter a valid email",
    "lp.contact.errors.phone": "Enter a valid phone number",
    "lp.contact.errors.message": "Your message must contain 5 to 500 characters",
    "lp.contact.success.title": "Success!",
    "lp.contact.success.text": "Your contact information has been sent successfully, wait for our contact!",
    "lp.contact.message": "We will contact you soon",

    // Posts
    "lp.posts.title": "latest posts",

    // Cookies
    "lp.cookies":
      "We use cookies to provide the services and features offered on our website, and to improve our user experience.",
    "lp.cookies.link": "Learn more",
    "lp.cookies.button": "Accept!",

    // Contact
    "contact.title": "Contact",
    "contact.invalidCamp": "Invalid Camp",
    "contact.googleMaps":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48388.10176976644!2d-74.39670758470541!3d40.71237266902792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3ae3f4c51b33d%3A0x90e229d96fad276f!2sSummit%2C%20NJ%2C%20EUA!5e0!3m2!1spt-BR!2sbr!4v1591121188258!5m2!1spt-BR!2sbr",
    "contact.street": "Summit - New Jersey, EUA",
    "contact.cep": "CEP: 07901",
    "contact.number": "+1 908 723 1003",
    "contact.leaveMessage": "Write Us",
    "contact.namePlaceholder": "Name",
    "contact.emailPlaceholder": "Email",
    "contact.phonePlaceholder": "Phone Number",
    "contact.messagePlaceholder": "Message",
    "contact.button": "Send",

    // ForAllUsed
    // Buttons
    "btn.iWant": "I want this",
    "btn.howWorks": "how it works",
    "btn.register": "sign up",
    "btn.knowMore": "know more",
    "btn.knowMore.disabled": "closed",
    "btn.sendOffert": "send offer",
    "btn.tradePoints": "exchange points",
    "btn.inviteFriends": "invite friends",
    "btn.seeRanking": "see ranking",

    // GainMorePoints | Cards near Footer
    "cards.majorTitle": "Get more points",
    "card1.title": "Sponsored",
    "card1.text": "participate in product testing campaigns",
    "card2.title": "Invite friends",
    "card2.text": "Invite friends and earn points",
    "card3.title": "Offers",
    "card3.text": "Take advantage of these exclusive offers",
    "card4.title": "Consumer profile",
    "card4.text": "Answer questions about your consumption behavior",

    // Footer
    "footer.language": "Languages",
    "footer.iconsText": "Enjoy our social networks",
    "footer.privacyTitle": "Privacy policy | FAQ",
    "footer.termsTitle": "Commitment Terms",
    "footer.privacyText":
      "©Adclick Ltda - All rights reserved. This site is owned by Adclick Ltda, solely responsible for its content, and is intended for residents of Brazil.",
    "footer.warning":
      "Images for illustration only. BigTester is responsible for the distribution of 100% of the products offered on the site. Big Tester is solely responsible for the purchase and distribution of prizes. The manufacturers and/or distributors of the products offered as prizes do not organize and do not participate in the commercial policy of the Website. Participate for free, accumulate points and exchange your points for the products offered",
    "footer.contact.title": "Get in touch",
    "footer.contact.text.01": "I'm a company",
    "footer.contact.text.02": "I'm a tester",

    // Navbar
    "nav.mission": "missions",
    "nav.campaign": "sponsored",
    "nav.cons": "consumption",
    "nav.aval": "evaluate products",
    "nav.product": "exchange points",
    "nav.free": "free",
    "nav.follow": "follow and gain + points",
    "nav.draw": "draw",
    "nav.invite": "invite friends",
    "nav.click.myaccount": "My account",
    "nav.click.mypoints": "my points",
    "nav.points": "points",
    "nav.quit": "logout",

    "nav.avatar.menu.account": "My account",
    "nav.avatar.menu.mypoints": "My Points",
    "nav.avatar.menu.mycupons": "My Cupons",
    "nav.avatar.menu.exit": "Exit",

    // Navbar bottom Mobile
    "nav.mobile.start": "start",
    "nav.mobile.points": "missions",
    "nav.mobile.products": "products",
    "nav.mobile.draw": "draw",
    "nav.mobile.campaigns": "campaigns",
    "nav.mobile.follow": "influencers",

    //
    // StartScreen | Beginning
    "ss.title": "Hello",
    "ss.btn.pt1": "win",
    "ss.btn.pt2": " points",
    "ss.btn.pt2.alt": " pts",
    "ss.subtitle.missions": "New missions",
    "ss.subtitle.dashboard": "Dashboard",
    "ss.dashboard.card1": "Missions",
    "ss.dashboard.card2": "Sponsored",
    "ss.dashboard.card3": "Invite friends",
    "ss.dashboard.card4": "Offers",
    "ss.subtitle.fixedmissions": "Earn more points",
    "ss.name.fixedmission1": "Answer some questions and earn points",
    "ss.name.fixedmission2": "Follow on social media and score",
    "ss.name.fixedmission3": "Click here and get free products",
    "ss.new.mission.button.text.gain.points": "earn {points} points",
    "ss.new.mission.button.text.registered.points":
      "{points} points registered",
    "ss.earn.more.points.button.text": "many",
    //

    //
    // MissionsScreen
    "ms.title": "Missions",
    "ms.titleText": "Complete the missions and collect lots of points!",
    "ms.text.01": "Attention:",
    "ms.text.02":
      "The points will be counted after the offer contained in the mission has joined.",
    "ms.text.03":
      "If there are problems in computing your points, contact us via e-mail or WhatsApp sending a print proving the adhesion to the mission.",
    "ms.text.04":
      "Here you can carry out missions and earn points and then exchange them for products.",
    "ms.text.05":
      "Every week there are new missions with exclusive offers for testers and you never stop accumulating points!",
    "ms.detailTitle.pt1": "You own ",
    "ms.detailTitle.pt2": " missions available",
    "ms.btn.pt1": "win",
    "ms.btn.pt2": " points",
    "ms.subTitle1": "Books",
    // "ms.subTitle2": "Movies",
    "ms.subTitle2": "Baby",
    // "ms.subTitle4": "Industrial",
    "ms.subTitle4": "Electronics",

    "ms.card.button.text.gain": "get {points} points",
    "ms.card.button.text.points": "{points} registered points",
    //

    //
    // CampaignScreen
    "cs.title": "Sponsored campaigns",
    "cs.titleText":
      "After you apply for sponsored campaignsm you can be selected to receive products in your home and also earn many points!",
    "cs.campaignPerPage": "Campaigns per page:",
    "cs.subTitle": "Earn more points",
    "cs.csOver": "The campaign ended!",
    "cs.csOverWhen": "Campaign over:",
    "cs.otherCampaigns": "see other campaigns",
    "cs.winners": "Winners",
    "cs.active": "Active",
    "cs.over": "Over",
    //

    //
    // CampaignScreen - Detail
    "cs.detail.period": "Campaign period",
    "cs.detail.start": "Start",
    "cs.details.end": "Final",
    "cs.detail.patro": "This campaign is sponsored by",
    "cs.detail.patro.after": "This campaign was sponsored by",
    "cs.detail.social": "Like on social media",
    "cs.detail.btn1": "already participating",
    "cs.detail.btn2": "apply",
    "cs.detail.btn3": "meet the winners",
    "cs.detail.btn4": "candidates not yet selected",
    "cs.detail.btn5": "Update my details",
    "cs.detail.noaddress": "To apply for the campaigns, it is necessary that your data, such as CPF and address, are registered in the Big Tester!",
    "cs.btn.continue": "continue",
    "cs.continueText": "Or press enter",
    "cs.inputText": "Your answer",
    "cs.watch": "Watch the video to confirm your subscription.",
    "cs.reseller.title": "Enjoy and increase your chances of winning by registering with these partners!",
    "cs.reseller.skip": "I want to miss this opportunity...",
    "cs.end.mission": "Want more chances to be selected? Complete the missions and increase your chances",
    "cs.end.title": "You have completed the campaign!",
    "cs.end.subTitle1":
      "Your",
    "cs.end.subTitle2":
      "points will be credited as soon as the campaign makes them available.",
    "cs.end.text1":
      "Confirmation of your application has been sent in your email. Check that our email has not been dropped in your Spam or Junk Mail box. It is very important that you check this and other emails BigTester to take advantage of the entire campaign experience. ",
    "cs.end.text2":
      "If your application is selected, we will send a message in this same email. So stay tuned!",
      "cs.end.btn": "See more campaigns",
      "cs.end.btn2": "change your points now!",
    "cs.end.addressTitle": "Hey, tester!",
    "cs.end.addressText": "In your register, is this address correct?",
    "cs.end.addressTextNoAddress1": "You don't have an address registered in the Big Tester.",
    "cs.end.addressTextNoAddress2": "To receive the product you are applying for, you must fill in your full address in the My Account area or by clicking the button below.",
    "cs.end.addressButton": "Update address",
    "cs.detail.titleshare":
      "When selected, don't forget to share on social networks",
    "cs.detail.descriptionshare1":
      "It's great to receive gifts and products at home, isn't it? So, when your products arrive, post on Instagram a photo with the hashtag #bigtester in Public Mode and tagging @big_tester. In this mission we hope you take amazing photos with your product! It can be in the car, at college, at home, at work, and wherever else you want! Do not forget to register in an angry photo, entitled to many clicks, huh?",
    "cs.detail.descriptionshare2":
      "We invite you to evaluate the product of the campaign and give us your opinion on what was received! Record stories on Instagram giving testimony and telling what you think of the packaging, the product and your experience in general! Don't forget to bookmark our @big_tester profile, which we can repost on our profile! Your feedback is extremely important!",
    "cs.btn.confirm": "Confirm application",
    "cs.legacy.table.name": "Name",
    "cs.legacy.table.email": "Mail",
    //

    //
    // ConsumptionScreen / FlowScreen
    "cons.init.title": "Answer these questions and earn lots of points!",
    "cons.end.title1": "Congratulations!",
    "cons.end.title2": "You have completed all available questions!",
    "cons.end.subTitle": "Soon, we will bring you more questions and points.",
    "cons.end.redirect": "You are being redirected to Missions in a few seconds...",
    //

    //
    // ProductScreen
    "ps.title": "Products for you to evaluate",
    "ps.title2":
      "Check the list of products to exchange for points and evaluate. If in doubt, consult the",
    "ps.regulation": "Regulation",
    "ps.products": "products",
    "ps.classifications": "CATEGORIES",
    "ps.winnersTitle": "last testers",
    "ps.dispatchSuccess":
      "Your Product will be shipped soon, keep an eye on your email.",
    "ps.dispatchFail": "We couldn't make the change. Contact us!",
    "ps.new": "RELEASE",
    "ps.trade": "change now",
    "ps.buy": "buy now",
    "product.toast": "Product successfully exchanged!",
    "ps.text.title": "Tester!",
    "ps.text.01": `In order to improve your convenience and get around constant problems with deliveries, logistics and intermittent postal strike, we inform you that most of the products for exchange are virtual products and, because of this, their images are merely illustrative and are just suggestions for exchanges`,
    "ps.text.02": `So:`,
    "ps.text.02.01": `In the act of changing the virtual product, our professionals will contact you within 10 working days via email and / or WhatsApp to confirm your data.`,
    "ps.text.02.02": `After the contact is made, the virtual product will be sent in your email within 20 working days and no physical product will be sent in case of exchange for virtual products.`,
    "ps.text.02.03": `You have a free choice of how to use the values ​​of the virtual cards exchanged here.`,
    "ps.text.02.04": `Some of the digital products are cumulative, and more than one voucher can be used to purchase your product on the supplier's website.`,
    "ps.text.03.01": `If you have any questions about an exchange, please contact us by email`,
    "ps.text.03.email": `instagram @ bigtester.com.br`,
    "ps.text.03.02": `. We will be happy to answer.`,
    "ps.search-field.placeholder": "Search",
    //

    // ProductDetail
    "pd.exchangeSuccess": "product successfully exchanged",
    "pd.exchangeSuccessDetail":
      "We will contact you within 48 hours to confirm!",
    "pd.exchangeError": "Something went wrong!",
    "pd.Products": "Products",
    "pd.title.avaliate": "ratings and comments",
    "pd.btn.avaliate": "evaluate also",
    "pd.title.otherProducts": "other products in this category",
    "pd.title.lastProducts": "last reviewed products",
    "pd.swal.ok.title": "Product successfully exchanged!",
    "pd.swal.ok.text": "Within 48 hours we will contact you for confirmation!",
    "pd.swal.notOk.title": "There was an error",
    "pd.swal.notOk.text": "Consult the BigTester team",
    "pd.access": "Access",
    "pd.productOwned": "Digital product purchased",
    "pd.productVoucher.detail.message": "When exchanging your points for this product you will receive a link provided by one of our partners where you can get more information on how to obtain the physical product",
    "pd.exchange.prompt.message": "You are about to exchange {price} points for product {product}. Do you really want to make the switch?",
    "pd.exchange.prompt.btn-confirm": "Yes",
    "pd.exchange.prompt.btn-cancel": "No",
    "pd.exchange.prompt.fail-message": "We were unable to make the switch at this time!",
    "pd.exchange.quantity-error.prompt.message": "I'm sorry, but the product {product} has no quantity available for exchange <span style='color: var (--instagram-hover);'> {quantity} </span> units. Please try another product!",
    "pd.exchange.insufficient-points.prompt.message": "I'm sorry, but you don't have enough points to trade, <span style = 'color:var(--instagram-hover);'> {balance} </span> from <span style='color:var(--primary );'> {price} </span> pts!",
    "pd.evaluate.dialog.title": "What did you think of the product?",
    "pd.evaluate.dialog.stars.amount": "from 1 to 5",
    "pd.evaluate.dialog.textfield.placeholder": "Describe a little of what you think of this product ...",
    "pd.evaluate.dialog.button.send": "Submit Review",
    "pd.evaluate.dialog.bottom-message.error": "You must specify a rating, if you want to cancel your evaluation, click the close button (upper right corner of this dialog)",
    //

    // FreeScreen
    "fs.title": "Free",
    "fs.titleText":
      "Get products totally free! Accumulate points and exchange for more products, those that are available in the products section of the website, as the ones in this section are free!",
    "fs.titleText.02":
      "All services and deliveries available below are the sole responsibility of the manufacturer.",
    "fs.searchPlaceholder": "Search here...",
    "fs.offertsPerPage": "Offers per page:",
    "fs.search-field.placeholder": "Search here...",
    "fs.pagination.of": "out of",
    //

    //
    // DrawingScreen
    "ds.title": "Raffle",
    "ds.subTitle1":
      "Big Tester always draws for you! It's very simple to participate!",
    "ds.subTitle2": "You own ",
    "ds.subTitle3": "lucky numbers",
    "ds.when.Text1": "The draw will be held on the day",
    "ds.when.Text2": "The more coupons, the more chances of winning!",
    "ds.when.Text3": "Switch",
    "ds.when.Text4": "50 points",
    "ds.when.Text5": " by ",
    "ds.lucky": "1 lucky number",
    "ds.btnTrade": "Exchange 50 points for 1 coupon",
    "ds.btnGoToHome": "Back to Home",

    "ds.p1.title": "How to get lucky numbers?",
    "ds.p1.subTitle.pt1":
      "Lucky Number is your lucky coupon. You win 1 coupon for registering with Big Tester. You currently have ",
    "ds.p1.subTitle.pt2": "points",
    "ds.p1.subTitle.pt3":
      " in Big Tester. You can exchange your points for Lucky Numbers. Each Lucky Number equals 50 points. That is, you exchange 50 points on the Big Tester for a Lucky Number. The more Luck Numbers you have, the more chances you have to win the draw! Good luck!",

    "ds.p2.title": "How will I know if I'm drawn?",
    "ds.p2.subTitle":
      "Big Tester will contact you via phone and/or email listed in your registration, letting you know that you were the lucky one! So keep your contacts always updated",

    "ds.p3.title": "Is the drawing legal?",
    "ds.p3.subTitle":
      "Sure! Our raffle is regulated by the Caixa Econômica Federal. Certificate number: 04.004907/2019. Check the Rules and Certificate of Drawings",

    "ds.rules": "Regulations",
    "ds.certificated": "Certified",

    "ds.follow.title": "Track your lucky numbers",
    "ds.follow.tableTitle":
      "Coupons for Licensed Wooden Chair - Draw on 31/12/2020",
    "ds.follow.tableDescription": "Description",
    "ds.follow.tableNumbers": "Coupon number",
    "ds.follow.tableDate": "Date",
    "ds.follow.tableStatus": "Status",
    "ds.follow.nothing": "You still don't have a coupon for that draw",

    "ds.info": "The draw is only for Brazilian users over 18 years old.",
    "ds.coupons.table.title": "Coupons for {prize} - Draw on {date}",
    "ds.coupons.table.chip.text": "{qty} coupons",

    "ds.swal.ok.text": "Points successfully redeemed for Coupons!",
    "ds.swal.notOk.text": "Your points could not be exchanged.",
    "ds.swal.insuficientPoints.text":
      "You do not have enough points to make this exchange.",
    "ds.fakePrize.drawingDate": " wait for the definition of the next draw",
    "ds.fakePrize.name": " The next winner could be you! ",
    //

    //
    // BlogScreen
    "bs.classifications": "categories",
    //

    //
    // RankingScreen
    "rs.title": "Ranking",
    "rs.titleText": "Lorem Ipsum",
    "rs.1Place": "1st Place",
    "rs.1Place.text": "Lorem Ipsum",
    "rs.2Place": "2nd Place",
    "rs.2Place.text": "Lorem Ipsum",
    "rs.3Place": "3rd Place",
    "rs.3Place.text": "Lorem Ipsum",

    "rs.section2.title": "Get out of the way right now!",
    "rs.section2.warningTitle": "Warning",
    "rs.section2.warningText": "lorem Ipsum",
    "rs.section2.position": "Position",
    "rs.section2.name": "Name",
    "rs.section2.sended": "Invitations sent",

    "rs.subTitle1": "Latest winners",
    "rs.subTitle2": "Rules and regulations",
    "rs.card.text1": "Lorem Ipsum",
    "rs.card.text2": "Lorem Ipsum",
    "rs.card.text3": "Lorem Ipsum",
    "rs.card.text4": "Lorem Ipsum",
    //

    //
    // InviteFriendsScreen

    "ifs.title": "Invite friends",
    "ifs.subTitle1":
      "Share with as many friends on social networks and see your points coming *!",
    "ifs.subTitle2.pt1": "Are",
    "ifs.subTitle2.pt2": "10 points",
    "ifs.subTitle2.pt3": "for each friend invited *!",
    "ifs.footnotes":
      "* Points will only be credited to your account when your friend signs up via the shared link and confirms the email registered with Big Tester. Otherwise, points will not be credited.",
    "ifs.copyLink": "Copy Link",
    "ifs.share": "Share my link",
    "ifs.shareSuccess": "Successfully shared!",
    "ifs.LinkshareSuccess": "The link has been copied to your clipboard!",
    "ifs.copied": "Link copied to clipboard",
    "ifs.card1": "The more friends that register through your invitation",
    "ifs.card2":
      "More points you earn and still participate in the monthly ranking!",
    //

    //
    // MyAccountScreen
    "mas.title": "My account",
    "mas.emailNotConfirmed.title":
      "Your email has not been verified. Check your inbox.",
    "mas.emailNotConfirmed.text":
      "Didn't receive the email? Check your spam box.",
    "mas.emailNotConfirmed.btn": "resend email",
    "mas.emailNotConfirmed.sent":
      "Confirmation email sent to your email account.",

    "mas.section1.title": "Profile",
    "mas.section1.subTitle":
      "Some information may be visible to other people using Bigtester",
    "mas.section1.name": "Name",
    "mas.section1.phone": "Mobile",
    "mas.section1.cpf": "Add your CPF",
    "mas.section1.birthday": "Date of birth",
    "mas.section1.birthdayPlaceholder": "Birth",
    "mas.section1.sex": "Gender",
    "mas.section1.mas": "Male",
    "mas.section1.fem": "Female",
    "mas.section1.btn": "save profile data",

    "mas.section2.title": "Change password",
    "mas.section2.nowPassword": "Current password",
    "mas.section2.newPassword": "New password",
    "mas.section2.newPasswordAgain": "Confirm new password",
    "mas.section2.btn": "change password",

    "mas.section3.title": "Location",
    "mas.section3.subTitle":
      "Essential information for sending your products to your home!",
    "mas.section3.cep": "zip code",
    "mas.section3.cepPlaceholder": "ZIP Code",
    // "mas.section3.cep": "CEP",
    "mas.section3.street": "Street",
    "mas.section3.number": "Number",
    "mas.section3.add": "Supplement",
    "mas.section3.district": "Neighborhood",
    "mas.section3.city": "City",
    "mas.section3.state": "State",
    "mas.section3.btn": "save location",

    "mas.section3.2.title": "Interests",
    "mas.section3.2.subTitle":
      "Your interests personalizing your ways of earning points. Be sure to score!",
    "mas.section3.2.btn": "Save interests",

    "mas.section4.title": "Your ratings",
    "mas.section4.subTitle": "Check your ratings below",
    "mas.section4.noAval": "You haven't done any evaluation yet",

    "mas.section5.title": "Unsubscribe",
    "mas.section5.btn": "I want to be unsubscribed in the big tester",
    "mas.upload.fail-message": "Could not upload. Try again.",
    "mas.birthdate.parse.fail-message": "Error date of birth, please check the corresponding field on the form!",
    "mas.birthdate.invalid-age": "Invalid age <span style='color:#f27474'>{age}</span> years, please check your birthdate again the birthdate field!",
    "mas.dispatch.session-update": "updated with success!",
    "mas.dispath.session-update.fail-message": "It is not possible to update. Try again later.",
    "mas.dispatch.zip-code.notFound-message": "Zip not found!",
    //

    //
    //  PointsScreen
    "pointsScreen.title": "My points",

    "pointsScreen.section1.title": "Balance",
    "pointsScreen.section1.subTitle.pt1": "You have ",
    "pointsScreen.section1.subTitle.pt2": " points!",
    "pointsScreen.section1.btn1": "exchange points",
    "pointsScreen.section1.btn2": "missions",
    "pointsScreen.section1.btn3": "campaigns",
    "pointsScreen.section1.btn4": "coupons",

    "pointsScreen.section2.title": "Extract of points",
    "pointsScreen.section2.col1": "Description",
    "pointsScreen.section2.col2": "Value",
    "pointsScreen.section2.col3": "Date",
    "pointsScreen.section2.col4": "Expiration",

    "pointScreen.btn": "load more records",
    //
    //

    //
    //  FollowScreen
    "follows.title": "Follow and Earn Points",
    "follows.titleText":
      "Follow these profiles on instagram and earn even more points! The points will be counted after the offer contained in the mission has joined. If there are problems in computing your points, contact us via email or WhatsApp by sending a print proving the adhesion to the mission.",
    "follows.accomplished": "Accomplished",
    "follows.btn1": "points registered",
    "follows.btn2": "win",
    "follows.points": "points",
    "follow.instagram": "Follow the Big Tester on Instagram",
    "follow.visitus": "Visit us",
    //

    //
    //  Lucky Number
    "ln.title": "Lucky Number",
    "ln.subtitle": "",

    //
    // PrivacyScreen
    privacy: "PRIVACY POLICY AND GENERAL CONDITIONS",
    "privacy.title.1": "1. SCOPE:",
    "privacy.text.1":
      "Given that Adclick Marketing do Brasil Ltda carries out its business activity in the area of ​​permission marketing campaigns. In this sense, the company presents its privacy policy stating: the forms of collection; use of personal data; safeguard; and security adopted in its treatment. Always respecting the option chosen by the users of the site. ",

    "privacy.title.2": "2. DATA COLLECTION:",
    "privacy.text.2":
      "Adclick Marketing do Brasil Ltda collects personal data, such as name, address, emails, telephone numbers, IP address, demographic and lifestyle data, through online forms, questionnaires, product and service promotions, hobbies. For the purposes indicated below, personal data, may be stored either in unidentifiable form or in aggregate form In addition, in order to be able to effectively carry out various commercial transactions, Adclick Marketing do Brasil Ltda may collect personal data , whether from individuals, customers or potential customers through questionnaires, information requests, or when hiring, purchasing, subscribing or registering certain products and services, including free demos, special offers or participation in contests or hobbies. The data collected is adequate, relevant and not excessive in relation to the scope, purposes and services determined, explicit and of Adclick Marketing do Brasil Ltda. ",

    "privacy.title.3": "3. SPONSORS:",
    "privacy.text.3.1":
      "Adclick Marketing do Brasil Ltda constantly conducts data collection hobbies for its permission marketing campaign bases.",
    "privacy.text.3.2":
      "Some of these hobbies are sponsored by companies, to whom the user's data is transmitted. This process is very transparent and visible. The user will always give his express and individual consent to share his data with each sponsor, through a page created for this purpose and after having knowledge of the privacy policy of each sponsor. You can, at the appropriate time in the registration process, choose the sponsors with whom you agree to share or not share your data. ",
    "privacy.text.3.3":
      "All registered participants also share the data with Caloga, Big Tester sponsor located at 170 rue Raymond Losserand, 75014 Paris, France. Caloga's privacy policy is available at:",
    "privacy.text.link": "http://caloga.br.com/html/dperso.php",
    "privacy.text.3.4":
      "Acting in charge of handling the management of advertising items Leadsolution Inteligencia Digital, S.L, with CIF B98816499 and registered address in Valencia Avenida Corte Valencianas no 26, Spain.",

    "privacy.title.4": "4. HOW WILL THE DATA BE USED?",
    "privacy.text.4":
      "The information provided may be used by Adclick Marketing do Brasil Ltda, as well as by its business partners and customers (for which the user has given consent), to send information about their products and services, adapting communications to their needs. and interests. In addition to these purposes, your personal data may be used to: ",
    "privacy.li.4.1":
      "Approach via telephone to offer products and services from partners",
    "privacy.li.4.2": "Product development, testing and testing",
    "privacy.li.4.3": "Conducting market studies and analyzes",
    "privacy.li.4.4": "Marketing of products and services",
    "privacy.li.4.5": "Sending advertising messages",
    "privacy.li.4.6":
      "Optimization of advertising campaigns, based on clustering.",

    "privacy.title.5":
      "5. RIGHTS OF ACCESS, RECTIFICATION, CANCELLATION AND OPPOSITION:",
    "privacy.text.5":
      "The user may exercise his rights of access, rectification, cancellation and opposition with regard to his personal data. The exercise of the rights must be carried out through any means that the user considers relevant:",
    "privacy.li.5.1":
      "Through the service cancellation links present in all communications sent via email;",
    "privacy.li.5.2":
      "By email: exit@beeleads.com.br, with the subject to remove;",
    "privacy.li.5.3":
      "By mail: Adclick Marketing do Brasil Ltda, Rua Sergipe, 475, 5th floor, São Paulo / SP. Telephone contact: +55 (11) 43715300.",

    "privacy.title.6": "6. COOKIES:",
    "privacy.text.6":
      "In all Adclick Marketing do Brasil Ltda websites, cookies are used, which allow you to improve the start of your session. If you visit this website again, the cookie recognizes that you have already been to that website. In addition, in all pass sites time of Adclick Marketing do Brasil Ltda, the IP of the user / participant is collected in order to detect possible fraud or illegitimate behavior. ",

    "privacy.title.7": "7. SECURITY AND STORAGE OF YOUR DATA:",
    "privacy.text.7.1":
      "Adclick Marketing do Brasil Ltda invests heavily to maintain the security of its sites and systems and to prevent access to your personal data by anyone who is not authorized to do so.",
    "privacy.text.7.2":
      "However, the Internet is not a 100% secure means of communication and therefore Adclick Marketing do Brasil Ltda cannot guarantee the security of any information you send us via the Web. Adclick Marketing do Brasil Ltda is also not responsible for losses or damage caused to the user or other parties as a result of the loss of the confidentiality of said information. ",

    "privacy.title.8":
      "8. OTHER WEBSITES AND LIMITS OF LIABILITY FOR ADCLICK MARKETING DO BRASIL LTDA:",
    "privacy.text.8.1":
      "The websites of Adclick Marketing do Brasil Ltda, may contain links to other websites that are outside the control of Adclick Marketing do Brasil Ltda and that are not covered by this privacy policy. In case the user has access to other websites using these links, you must be aware that these sites may eventually request and collect personal data, in accordance with their privacy policy, which may be different from ours. ",
    "privacy.text.8.2":
      "It is also important to note that the Adclick Marketing do Brasil Ltda sites may occasionally provide links to other sites created and managed by other organizations. Since these sites are not our property, we cannot guarantee their quality, suitability or functioning, and our relationship with the said sites does not imply an endorsement either of the products and services sold or advertised, or of any information present on the sites in question. We strongly recommend that you pay close attention and also take into account that under no circumstances Adclick Marketing do Brasil Ltda will be responsible, directly or indirectly, for any damage or loss caused, or supposedly caused, to the user or family in relation to the use of the contents, goods or services available on any of these websites. ",

    "privacy.title.9": "9. TRANSFER OF ASSETS",
    "privacy.text.9":
      "In the normal course of business at Adclick Marketing do Brasil Ltda, there may be changes in the shareholding and control of Adclick Marketing do Brasil Ltda, in subsidiary, affiliated or affiliated companies or eventually in the group's business areas. In any In these situations, personal data may be considered transmissible assets, provided that the requirements and authorizations legally necessary for the transmission are respected. The same will happen in the case of Adclick Marketing do Brasil Ltda or any part or sector of activity or of the companies that form the group, or associated companies, subsidiaries or subsidiaries to be purchased, situations in which personal data will be part of the assets to be transferred provided that the legally necessary requirements and authorizations for their transmission are respected. ",

    "privacy.title.10": "10. ANTISPAM POLICY",
    "privacy.text.10.1":
      "Adclick Marketing do Brasil Ltda is seriously concerned with spamming. To prove our dedication to a spam-free internet environment, we have installed a policy and procedures that ensure the integrity of our systems and our messages. Therefore, if any of our subscribers or participant in a hobby gets involved in an act of spamming, we will terminate your account or participation immediately, without prior notice, due to our anti-spam policy. Adclick Marketing do Brasil Ltda and its associated sites have always done and continued to do everything whatever is required within our policies and procedures to maintain the status of a spam-free organization within the internet community. We truly believe that organizations like ours, including ASP's, ESP's and ISPs, have a responsibility to ensure that your customers and users engage and use responsible and respectable email practices. ",
    "privacy.subtitle.10": "What is spamming?",
    "privacy.text.10.2":
      "Spamming consists not only of sending an email to someone unknown, but it can also be done by posting messages in newsgroups, forums, classifieds or chats. Excluding cases where the invitation to the service / hobby is inserted in the signature of the comments and / or ads. ",

    "privacy.title.11":
      "11. ADCLICK MARKETING DO BRASIL LTDA'S ANTISPAM POLICY",
    "privacy.text.11.1":
      "Although a substantial part of the activity of Adclick Marketing do Brasil Ltda is the organization of hobbies and consequencenently encourage your participants to disclose them among friends and acquaintances, IT IS TOTAL AND UNEQUIVELY AGAINST ANY DIRECT OR INDIRECT WAY OF SPAMMING USE. Therefore, we recommend that our members and participants only send emails to people they know and NEVER to people they don't know. ",
    "privacy.text.11.2":
      "If you feel that a participant or member of our sites has sent you spam or has questions about our spam policy, please contact our Spam Abuse department at the following email address: abuse@beeleads.com.br indicating all information we can. Then we will immediately take the appropriate measures, one of which is the immediate expulsion of the member from our service, as a minimum measure. ",

    "privacy.title.12": "12. PRIVACY POLICY CHANGES:",
    "privacy.text.12":
      "Adclick Marketing do Brasil Ltda reserves the right at any time to update or revise the privacy policy set out in this document in order to adjust any legislative changes and other constraints.",

    "privacy.title.13": "13. AGREEMENT WITH THIS PRIVACY POLICY:",
    "privacy.text.13":
      "It is understood that the user accepts the conditions of this privacy policy when entering his data on any of the sites owned by Adclick Marketing do Brasil Ltda. Filling out the forms implies the user's consent to the automated processing and use of data from in accordance with the privacy conditions described in this document. ",
    //

    // DESCADASTRAR
    "unsubs.title" : "Unsubscribe? Wow ... Did we say something wrong?",
    "unsubs.text" : "Are you sure you want to lose all your points and leave us? If there are any questions, you can ask them through the ",
    "unsubs.faq" : "FAQ",

    "unsubs.placeholder" : "Confirm your email",
    "unsubs.submit" : "unregister",

    "unsubs.error.emailDoesntMatch" : "The e-mail entered does not match the e-mail of the registration!",
    "unsubs.success" : "account removed successfully!",
    "unsubs.error" : "We were unable to remove your account at this time, please try again later!",
    //

    //FAQ
    "faq.title" : "Frequently Asked Questions",
    
    "faq.question.1" : "How to accumulate points?",
    "faq.question.2" : "Where do I take the samples?",
    "faq.question.3" : "Is it really free?",
    "faq.question.4" : "How do I receive samples at my home?",
    "faq.question.5" : "I already registered, but I never won",
    "faq.question.6" : "How do I unsubscribe?",
    "faq.question.7" : "I can't register, what should I do?",
    "faq.question.8" : "Problems with login and registration?",
    "faq.question.9" : "My points don't increase, what should I do?",

    "faq.answer.1" : "You get points by: (a) Registering; (b) Answering the questions of consumption and behavior on the website; (c) completing your registration on our website; (d) Sharing the products on your Facebook.",
    "faq.answer.2" : "The products are sent by post to your home. After requesting a product exchange, the Big Tester team gets in touch to confirm your address and then send it.",
    "faq.answer.3" : "YEA!!! All samples are sent completely free of charge. You have no cost.",
    "faq.answer.4" : "To receive samples at your home, it is very simple: you register on our website, accumulate as many points as possible, request the exchange of the desired samples and we send them to your home.",
    "faq.answer.5" : "To receive samples at home, it is not enough to register. It is necessary to accumulate points and request the exchange of the desired product on the website.",
    "faq.answer.6" : "To unsubscribe, visit: bigtester.com.br/descadastrar",
    "faq.answer.7" : "If you are unable to complete your registration, delete your browser's cookies and caches and try again to complete the registration.",
    "faq.answer.8" : "Make sure you are entering all the information correctly. '' Space '' is not allowed after the last letter or number in the registration field.",
    "faq.answer.9" : "Make sure that you have done all the processes to earn points (as described in the first clause).",
    //

    // not found
    "notFound.title" : "Ops...",
    "notFound.subtitle" : "It seems that there is nothing here.",
    "notFound.backMessage" : "How about going back to the ",
    "notFound.backLink" : "home page",
    "notFound.errorMessage" : "Error: 404",
    //
  },
};
