import React from 'react';
import { Card, Box, Typography, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import styled from 'styled-components';
import translate from '../../locales/translate';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const ShareBox = styled(Box)`
    height: 40px;
    background-color: rgba(0,0,0, 0.1);
    padding: 12px;
    display: flex;
    align-items: center;
    overflow: visible;
    /* margin-bottom: 24px; */
`

const InstagramIconWrapper = styled.span`
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    border-radius: 5px;
    display: flex;
    &:before {
        margin: auto;
    }
`

const ShareButton = styled(IconButton)`
        display: flex;
        position: absolute !important;
        right: 16px;
        /* bottom: -16px; */
        ${props => props.type === 'instagram' ? `
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );` :
        ''
    }
`

const ShareCard = ({ type, shareLink = () => { } }) => {

    const FaceBook = () => (
        <ShareBox onClick={shareLink} style={{cursor: "pointer"}} >
            <FacebookIcon fontSize="large" style={{ color: '#22589a' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                Facebook
            </Typography>
            <ShareButton type={type} size="small" style={{ backgroundColor: '#22589a' }} /*onClick={shareLink}*/>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const Instagram = () => (
        <ShareBox onClick={shareLink} style={{cursor: "pointer"}} >
            <InstagramIconWrapper>
                <InstagramIcon fontSize="large" style={{ color: 'white' }} />
            </InstagramIconWrapper>
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                Instagram
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: 'var(--danger)' }} /*onClick={shareLink}*/>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const Twitter = () => (
        <ShareBox onClick={shareLink} style={{cursor: "pointer"}} >
            <TwitterIcon fontSize="large" style={{ color: '#23aff1' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                Twitter
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: '#23aff1' }} /*onClick={shareLink} */>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const Pinterest = () => (
        <ShareBox>
            <PinterestIcon fontSize="large" style={{ color: 'red' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                Pinterest
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: 'red' }} onClick={shareLink}>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const YouTube = () => (
        <ShareBox>
            <YouTubeIcon fontSize="large" style={{ color: 'red', width: 'auto' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                YouTube
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: 'red' }} onClick={shareLink}>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const Whatsapp = () => (
        <ShareBox onClick={() => shareLink('whatsapp')} style={{cursor: "pointer"}} >
            <WhatsAppIcon fontSize="large" style={{ color: '#56af4c' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                WhatsApp
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: '#56af4c' }} /*onClick={shareLink}*/ >
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const Link = () => (
        <ShareBox onClick={shareLink} style={{cursor: "pointer"}}>
            <FileCopyIcon fontSize="large" style={{ color: 'grey' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                Copy URL
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: 'grey' }} /*onClick={shareLink} */>
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )
    const ShareDefault = () => (
        <ShareBox onClick={shareLink} style={{cursor: "pointer"}}>
            <ShareIcon fontSize="large" style={{ color: '#22589a' }} />
            <Typography variant="h6" className="text-bold" style={{ marginLeft: 8 }}>
                {translate("ifs.share")}
            </Typography>
            <ShareButton size="small" style={{ backgroundColor: '#22589a' }} onClick={() => shareLink('share')} >
                <ShareIcon style={{ padding: 4, paddingLeft: 3, color: 'white' }} />
            </ShareButton>
        </ShareBox>
    )

    const select = () => {
        switch (type) {
            case 'facebook':
                return <FaceBook />
            case 'instagram':
                return <Instagram />
            case 'pinterest':
                return <Pinterest />
            case 'twitter':
                return <Twitter />
            case 'youtube':
                return <YouTube />
            case 'whatsapp':
                return <Whatsapp />
            case 'share':
                return <ShareDefault />
            default:
                return <Link />
        }
    }

    return (
        <Card className="dark--text" style={{ position: 'relative', overflow: 'visible', marginBottom: 12, width: "100%" }}>
            {select()}
        </Card>
    );
}

export default ShareCard;