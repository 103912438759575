import React from "react";
import { useHistory } from "react-router-dom";
import translate from "../../../locales/translate";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import Stars from "../../../components/common/Stars";
import ApiService from "../../../helpers/ApiService";
import ScrollTo from "scroll-to-element";

const Product = ({ item = {}, small }) => {
  let history = useHistory();

  const isMobile = useMediaQuery("(max-width:960px)");

  const handleProductPage = () => {
    ApiService.autoLogin()
      .then((user) => {
        if (user) history.push(`/produtos/${item.id}`);
        else
          ScrollTo("body", {
            duration: 500,
          });
      })
      .catch((err) => {
        ScrollTo("body", {
          duration: 500,
        });
      });
  };

  const Description = () => {
    if (small) {
      return (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Stars rating={item.stars} max={5} />
          <Typography
            variant="h6"
            style={{ marginLeft: 6 }}
            display={isMobile ? "block" : "inline"}
          >
            <b>{item.price + " "}</b>{" "}
            <small>{translate("lp.product.point")}</small>
          </Typography>
        </Box>
      );
    } else {
      return <Typography variant="body2">{item.description}</Typography>;
    }
  };

  return (
    <a href=" " onClick={handleProductPage}>
      <Card elevation={3} style={{ height: "100%", position: "relative" }}>
        <CardMedia
          title={item.name}
          image={item.image}
          style={{ height: "150px" }}
        />
        <CardContent className="dark--text" style={{ marginBottom: 0 }}>
          <Typography variant="body1" className="text-bold">
            {item.name}
          </Typography>
          <Description />
        </CardContent>
        {/* <Button
                fullWidth
                variant="contained"
                className="success"
                style={{ textDecoration: 'none', position: 'absolute', bottom: 0, borderRadius: "0 0 4px 4px" }}>
                {translate("btn.iWant")}
            </Button> */}
      </Card>
    </a>
  );
};

export default Product;
