import React from "react";

import { useSelector, shallowEqual } from "react-redux";
import { withRedux } from "./helpers/redux";
import { I18nProvider } from "./locales";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
// import analyticsGTM from "./helpers/GTM";
import Routes from "./routes";
import "./themes/main.css";

const tagManagerArgs = {
  gtmId: "GTM-5WBC2C",
};
TagManager.initialize(tagManagerArgs);

const useStore = () => {
  return useSelector(
    (state) => ({
      language: state.language,
    }),
    shallowEqual
  );
};

function App() {
  const { language } = useStore();

  React.useEffect(() => {
    // analyticsGTM.page()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {language === "en-US" ? (
        <Helmet>
          <title>
            Big Tester - The largest consumer product testing site of the
            Americas!
          </title>
          <meta
            property="og:title"
            content="Big Tester - The largest consumer product testing site of the Americas!"
          />
          <meta
            name="description"
            content="Product testing platform. Register and compete for an iPhone every month!"
          />
          <meta name="language" content="English" />
          <meta name="keywords" content="Product, Test, Leads, Free" />
        </Helmet>
      ) : language === "es" ? (
        <Helmet>
          <title>
            Big Tester - ¡La plataforma de pruebas más grande de América Latina!
          </title>
          <meta
            name="description"
            content="Big Tester - ¡La plataforma de pruebas más grande de América Latina"
          />
          <meta
            property="og:title"
            content="Plataforma de prueba de productos. ¡Regístrese y compita por un iPhone todos los meses!"
          />
          <meta name="language" content="Spanish" />
          <meta name="keywords" content="Producto, Prueba, Leads, Gratis" />
        </Helmet>
      ) : (
        <Helmet>
          <title>
            Big Tester - A maior plataforma de teste de América Latina!
          </title>
          <meta
            property="og:title"
            content="Big Tester - A maior plataforma de teste de América Latina!"
          />
          <meta
            name="description"
            content="Plataforma de teste de produtos. Cadastre-se e concorra a um iPhone todo mês!"
          />
          <meta name="language" content="Portuguese" />
          <meta name="keywords" content="Produto, Teste, Leads, Gratis" />
        </Helmet>
      )}

      <I18nProvider locale={language}>
        <Routes />
      </I18nProvider>
    </>
  );
}

export default withRedux(App);
